import React from "react";
import { Tab, Tabs } from "@mui/material";
import { TabContext } from "@mui/lab";
import ProductsFilter from "./filter";
import Loading from "../../../components/Loading";
import PremiumPackagesComponent from "./Packages";
import PremiumProductsComponent from "./Products";
import DiscoverProducts from "./DiscoverProducts";
import {
  IPremiumJobBoardsComponent,
  PREMIUM_ITEM_TYPES,
} from "../../../models/SingleJob";
import {
  StyledPremiumJobBoardsContainer,
  StyledTabPanel,
  StyledTabsContainer,
} from "../styles";

const PremiumJobBoardsComponent = ({
  t,
  isLoading,
  packages,
  products,
  selectedProducts,
  selectedTab,
  isDiscoverPage,
  isJobEditorPage,
  totalShopProducts,
  isTabs,
  isPremiumJobBoardsTab = false,
  selectedPremiumJobBoardsTab,
  isSelected,
  onOpenProduct,
  onAddPackage,
  onAddProduct,
  onRemoveFromCart,
  onResetFilters,
  onSelectTab,
  onOpenCart,
  onHoverLeaveSelectPackageButton,
}: IPremiumJobBoardsComponent) =>
  isLoading ? (
    <Loading />
  ) : (
    <StyledPremiumJobBoardsContainer mt={isDiscoverPage ? 3 : 0}>
      <TabContext value={selectedTab}>
        {isTabs && (
          <StyledTabsContainer isJobEditorPage={!!isJobEditorPage}>
            <Tabs value={selectedTab} onChange={onSelectTab}>
              {[PREMIUM_ITEM_TYPES.PRODUCTS, PREMIUM_ITEM_TYPES.PACKAGES].map(
                (tab: string, index: number) => (
                  <Tab
                    key={tab + index}
                    label={t(`create_job_fourth_step.${tab}`)}
                    value={tab}
                  />
                )
              )}
            </Tabs>
          </StyledTabsContainer>
        )}
        {selectedTab === PREMIUM_ITEM_TYPES.PRODUCTS && (
          <ProductsFilter
            isPremiumJobBoardsTab
            isJobEditorPage={!!isJobEditorPage}
            t={t}
          />
        )}

        <StyledTabPanel
          value={PREMIUM_ITEM_TYPES.PRODUCTS}
          isPremiumJobBoardsTab={isPremiumJobBoardsTab}
        >
          {isDiscoverPage ? (
            <DiscoverProducts
              t={t}
              products={products}
              totalShopProducts={totalShopProducts}
              isSelected={isSelected}
              onAddProduct={onAddProduct}
              onRemoveFromCart={onRemoveFromCart}
              onResetFilters={onResetFilters}
              onOpenProduct={onOpenProduct}
            />
          ) : (
            <PremiumProductsComponent
              t={t}
              isPremiumJobBoardsTab={isPremiumJobBoardsTab}
              products={products}
              selectedProducts={selectedProducts}
              onOpenCart={onOpenCart}
              isSelected={isSelected}
              onAddProduct={onAddProduct}
              onRemoveFromCart={onRemoveFromCart}
              onResetFilters={onResetFilters}
              onOpenProduct={onOpenProduct}
            />
          )}
        </StyledTabPanel>
        <StyledTabPanel
          isJobEditorPage={isJobEditorPage}
          isDicoverPage={isDiscoverPage}
          isPackages={
            selectedPremiumJobBoardsTab === PREMIUM_ITEM_TYPES.PACKAGES
          }
          value={PREMIUM_ITEM_TYPES.PACKAGES}
        >
          <PremiumPackagesComponent
            t={t}
            isJobEditorPage={isJobEditorPage}
            packages={packages}
            isPremiumJobBoardsTab={isPremiumJobBoardsTab}
            selectedProducts={selectedProducts}
            onOpenCart={onOpenCart}
            isSelected={isSelected}
            onAddPackage={onAddPackage}
            onOpenProduct={onOpenProduct}
            onRemoveFromCart={onRemoveFromCart}
            onResetFilters={onResetFilters}
            onHoverLeaveSelectPackageButton={onHoverLeaveSelectPackageButton}
          />
        </StyledTabPanel>
      </TabContext>
    </StyledPremiumJobBoardsContainer>
  );

export default PremiumJobBoardsComponent;
