import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { Box, Button, Stack } from "@mui/material";
import React from "react";
import { IExpandedBlockComponent } from "../../models/common";
import {
  StyledExpandedBlockBody,
  StyledExpandedBlockContainer,
  StyledExpandedBlockTitle
} from "./styles";

const ExpandedBlockComponent = ({
  t,
  title,
  body,
  bodyRef,
  isLongText,
  isExpanded,
  isRendered,
  onExpand
}: IExpandedBlockComponent) => (
  <StyledExpandedBlockContainer isExpanded={isExpanded} spacing={2}>
    <StyledExpandedBlockTitle>{title}</StyledExpandedBlockTitle>
    <StyledExpandedBlockBody
      dangerouslySetInnerHTML={{ __html: body || "" }}
      ref={bodyRef}
      isRendered={isRendered}
    />
    {isLongText && (
      <Button onClick={onExpand}>
        {isExpanded ? (
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Box>{t("jobDetails.showLessBtn")}</Box>
            <ChevronUpIcon width={24} height={24} />
          </Stack>
        ) : (
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Box>{t("jobDetails.showMoreBtn")}</Box>
            <ChevronDownIcon width={24} height={24} />
          </Stack>
        )}
      </Button>
    )}
  </StyledExpandedBlockContainer>
);

export default ExpandedBlockComponent;
