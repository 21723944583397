import React from "react";
import { Stack, Typography } from "@mui/material";
import SelectedTriggerAutomation from "./SelectedTriggerAutomation";
import {
  ACTION_TRIGGER_VARIANT,
  IAddActionContainer,
} from "../../models/Automation";

const AddActionContainer = ({
  t,
  onOpenSelectActionContainer,
}: IAddActionContainer) => (
  <Stack spacing={2}>
    <Typography fontSize={16} fontWeight={500}>
      {t("automation.addAction")}
    </Typography>
    <SelectedTriggerAutomation
      onClick={onOpenSelectActionContainer}
      t={t}
      variant={ACTION_TRIGGER_VARIANT.ADD_ACTION_CONTAINER}
      data={{
        const: "add_action_container",
        name: t("automation.addAction"),
      }}
      subText={t("automation.addActionSubText") as string}
    />
  </Stack>
);

export default AddActionContainer;
