import React from "react";
import { useTranslation } from "react-i18next";
import { StyledStatusContainer, StyledStatusText } from "./style";

const BookingsStatus = ({
  status,
}: {
  readonly status:
    | "not_started"
    | "in_production"
    | "customer_action_pending"
    | "published"
    | "finished"
    | "aborted";
}) => {
  const { t } = useTranslation();

  const ordersStatuses = {
    not_started: t("team_members.pending"),
    in_production: t("team_members.pending"),
    customer_action_pending: t("team_members.pending"),
    published: "Online",
    finished: "Online",
    aborted: t("aborted"),
  };

  return (
    <StyledStatusContainer status={status}>
      <StyledStatusText status={status}>
        {ordersStatuses[status]}
      </StyledStatusText>
    </StyledStatusContainer>
  );
};

export default BookingsStatus;
