import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ApplicationCreatedTriggerComponent from "./component";
import { getAutomationState } from "../../../../store_new/selectors/Automation";
import {
  fetchSelectedActionData,
  setCurrentAutomation,
  setTrigger,
} from "../../../../store_new/reducers/Automation";
import { TListOption } from "../../../../models/common";
import {
  applicationCreatedTriggerFormFieldsDefaultValue,
  getApplicationCreatedTriggerFormFields,
} from "./config";
import { IApplicationCreatedTriggerFormFields } from "../../../../models/Automation";

const ApplicationCreatedTrigger = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedJobs, setSelectedJobs] = useState<string | string[]>([]);

  const { selectedActionData, currentAutomation, isSelectedActionDataLoading } =
    useSelector(getAutomationState);

  const {
    control,
    clearErrors,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    defaultValues: applicationCreatedTriggerFormFieldsDefaultValue,
    resolver: yupResolver(getApplicationCreatedTriggerFormFields(t)),
  });

  const jobs = useMemo(() => {
    if (selectedActionData?.jobs) {
      const formattedJobs = Object.entries(selectedActionData.jobs).map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([value, label]) => ({
          value,
          label,
        })
      );
      return formattedJobs;
    }

    return [];
  }, [selectedActionData]);

  const isLoading = isSelectedActionDataLoading || !jobs.length;

  const modifiedJobs = useMemo(() => {
    const selectDeseltAll = [
      {
        label:
          selectedJobs.length < jobs.length ? t("selectAll") : t("deselectAll"),
        value: "0000",
      },
    ];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const newJobs = selectDeseltAll.concat(jobs);
    return newJobs;
  }, [selectedJobs, jobs]);

  const handleChangeJobs = (values: string | string[]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isSelectedDeselectedAll = values.some((job) => job.value === "0000");
    if (isSelectedDeselectedAll) {
      const newJobs = selectedJobs.length === jobs.length ? [] : jobs;
      setSelectedJobs(newJobs as unknown as string[]);
      setValue("jobs", newJobs as unknown as string[]);
    } else {
      setSelectedJobs(values);
      setValue("jobs", values as string[]);
    }

    if (Object.keys(errors).length && values.length) {
      clearErrors();
    }
  };

  const onSubmit = (data: IApplicationCreatedTriggerFormFields) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const formattedConditions = data.jobs.map((job: TListOption) => ({
      condition: "equals",
      param: "job",
      value: job.value,
    }));
    if (currentAutomation.trigger) {
      dispatch(
        setCurrentAutomation({
          ...currentAutomation,
          trigger: {
            ...currentAutomation?.trigger,
            isCompleted: true,
            isOpen: false,
          },
          conditions: formattedConditions,
          conditions_operator: "OR",
        })
      );
    }
  };

  useEffect(() => {
    if (selectedActionData && !Object.keys(selectedActionData?.jobs).length) {
      dispatch(fetchSelectedActionData("jobs"));
    }
  }, []);

  useEffect(() => {
    if (
      currentAutomation?.conditions &&
      currentAutomation?.conditions.length &&
      currentAutomation.trigger &&
      jobs.length
    ) {
      dispatch(
        setTrigger({
          ...currentAutomation.trigger,
          isCompleted: true,
        })
      );
      const currentSelectedJobs = [...currentAutomation.conditions].map(
        (condition) => {
          const findJob = jobs.find((job) => job.value === condition.value);
          return findJob;
        }
      );
      setSelectedJobs(currentSelectedJobs as unknown as string[]);
    }
  }, [jobs]);

  return (
    <ApplicationCreatedTriggerComponent
      t={t}
      currentAutomation={currentAutomation}
      jobs={modifiedJobs as unknown as TListOption[]}
      isLoading={isLoading}
      selectedJobs={selectedJobs}
      control={control}
      errors={errors}
      onChangeJobs={handleChangeJobs}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
    />
  );
};

export default ApplicationCreatedTrigger;
