import { Box, Button, Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledJobsContainer = styled(Box)({
  marginTop: 24,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  button: { border: "none" }
});

export const StyledJobsTabsContainer = styled(Box)({
  borderBottom: "1px solid #E5E7EB",
  maxHeight: 41,
  button: {
    textTransform: "capitalize"
  }
});

export const StyledJobsFilterContainer = styled(Stack)({
  justifyContent: "space-between",
  paddingTop: 16,
  ".quick-search-container": {
    width: "35%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ".MuiFormControl-root": {
      maxWidth: "60%"
    }
  },
  ".location-search-container": {
    width: "100%",
    ".MuiFormControl-root": {
      maxWidth: "100%"
    }
  },
  ".advanced-filters-container": {
    width: "100%",
    justifyContent: "flex-end",
    ".MuiFormControl-root": {
      width: "100%"
    }
  },
  ".clear-icon": {
    cursor: "pointer",
    marginRight: 6,
    height: 18,
    width: 18
  },
  ".input-filter": { width: 180 },
  ".input-selector-filter": {
    width: "100%",
    textDecoration: "none",
    textTransform: "none",
    "& .MuiInputBase-input": {
      width: "100%",
      position: "relative",
      top: 8
    }
  }
});

export const StyledJobsToolbarContainer = styled(Stack)({
  margin: "12px 0",
  button: {
    background: "#818CF8",
    color: "#fff",
    boxShadow: "none",
    "&.Mui-disabled": {
      opacity: "30%",
      color: "#fff"
    },
    "&:hover": {
      background: "#6366F1",
      color: "#fff",
      boxShadow: "none"
    }
  },
  ".btn-icon": {
    width: 20,
    height: 20,
    stroke: "#fff"
  }
});

export const StyledWarningTextContainer = styled(Stack)(
  ({ type }: { type: "warning" | "success" }) => ({
    svg: {
      width: 24,
      height: 24,
      stroke: type === "warning" ? "#EF4444" : "#22C55E"
    },
    p: { fontSize: 14 }
  })
);

export const StyledNoResultContainer = styled(Stack)({
  height: "100%",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  img: {
    width: 205,
    height: 200
  }
});

export const StyledJobListModalCancelButton = styled(Button)({
  ":hover": {
    background: "rgba(239, 68, 68, 0.04)"
  },
  "&:disabled": {
    border: "1px solid rgba(0, 0, 0, 0.12)!important"
  }
});
