import { Divider, Stack } from "@mui/material";
import React from "react";
import { IJobApplicationProcessComponent } from "../../../models/JobEditor";
import { SELECTED_EDIT_SECTIONS } from "../../../models/SingleJob";
import StyledSectionTitle from "../../SectionTitle";
import { StyledJobDialogFormSection } from "../styles";
import AdditionalQuestionsSection from "./AdditionalQuestionsSection";
import JobBasicQuestionsSection from "./BasicQuestionsSection";
import JobDocumentsSection from "./DocumentsSection";

const JobApplicationProcessComponent = ({
  t,
  formRef,
  control,
  errors,
  register,
  selectedSections,
  basicQuestionsTitles,
  questions,
  questionTypes,
  handleSubmit,
  onAddQuestion,
  onRemoveQuestion,
  onSubmit
}: IJobApplicationProcessComponent) => (
  <StyledJobDialogFormSection spacing={2}>
    <StyledSectionTitle
      title={t("create_job_first_step.application_process")}
    />
    <Divider />
    <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
      <Stack spacing={1.5}>
        {selectedSections.includes(
          SELECTED_EDIT_SECTIONS.JOB_APPLICATION_PROCESS_BASIC_QUESTIONS
        ) && (
          <JobBasicQuestionsSection
            t={t}
            basicQuestionsTitles={basicQuestionsTitles}
            control={control}
          />
        )}
        {selectedSections.includes(
          SELECTED_EDIT_SECTIONS.JOB_APPLICATION_PROCESS_DOCUMENTS
        ) && <JobDocumentsSection t={t} control={control} />}
        {selectedSections.includes(
          SELECTED_EDIT_SECTIONS.JOB_APPLICATION_PROCESS_ADDITIONAL_QUESTIONS
        ) && (
          <AdditionalQuestionsSection
            t={t}
            errors={errors}
            control={control}
            register={register}
            questions={questions}
            questionTypes={questionTypes}
            onAddQuestion={onAddQuestion}
            onRemoveQuestion={onRemoveQuestion}
          />
        )}
      </Stack>
    </form>
  </StyledJobDialogFormSection>
);

export default JobApplicationProcessComponent;
