import React, { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import i18next from "i18next";
import {
  Dialog,
  Typography,
  Button,
  Stack,
  Divider,
  Slider,
} from "@mui/material";
import { XMarkIcon, ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StyledUploadPictureDialogContainer } from "./style";
import { getCroppedImg } from "../../../utils";
import { getDialogsState } from "../../../store_new/selectors/Dialogs";
import { ICropValue, IJobWidgetPreviewDialog } from "../../../models/dialogs";
import { setPreviewImage } from "../../../store_new/reducers/dialogs";
import {
  fetchUser,
  updateCurrentUserPhoto,
} from "../../../store_new/reducers/CurrentUser";
import {
  fetchCompanyData,
  updateCompanyData,
} from "../../../store_new/reducers/CompanySettings";
import {
  addMessage,
  turnOffNotifications,
} from "../../../store_new/reducers/Snackbar";

export default function UploadPictureDialog({
  t,
  desktop = true,
  onClose,
}: IJobWidgetPreviewDialog) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { previewImage } = useSelector(getDialogsState);
  const [cropValues, setCropValues] = useState({ x: 0, y: 0 });
  const [zoomValue, setZoomValue] = useState(3);
  const [title, setTitle] = useState<string>(
    t("userSettings.upload_profile_picture") as string
  );
  const { isUploadPictureDialogOpen: isOpen } = useSelector(getDialogsState);
  const [type, setType] = useState<"rect" | "round">("rect");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<ICropValue | null>(
    null
  );

  const expectedDymensionsText =
    type === "rect"
      ? `${t("userSettings.expected_dimensions")} (2:1)`
      : `${t("userSettings.expected_dimensions")} (1:1)`;

  const onDialogClose = () => {
    onClose();
    clearValues();
  };

  const onSuccesUpdateUser = (newLanguage?: string) => {
    i18next.changeLanguage(newLanguage);
    dispatch(fetchUser());
    dispatch(
      addMessage({
        type: "success",
        title: t("userSettings.snackbar_title"),
      })
    );
  };

  const handleOnUpdateUserPhoto = (photo?: File) => {
    dispatch(turnOffNotifications());
    dispatch(
      updateCurrentUserPhoto({
        photo,
        callback: () => onSuccesUpdateUser(),
      })
    );
  };

  const handleOnUpdateCompanyLogo = (photo?: File) => {
    dispatch(
      updateCompanyData({
        type: "logo",
        data: photo ? { logo: photo } : { reset_logo: true },
        callback: () => dispatch(fetchCompanyData()),
      })
    );
  };

  const onUploadPhoto = (imageFile: File) => {
    if (location.pathname.includes("user")) {
      handleOnUpdateUserPhoto(imageFile);
    } else {
      handleOnUpdateCompanyLogo(imageFile);
    }
  };

  const onSave = async () => {
    if (!previewImage && !croppedAreaPixels) return;
    try {
      const croppedImageBlob = await getCroppedImg(
        previewImage?.preview,
        croppedAreaPixels,
        previewImage.name
      );
      const imageFile = new File(
        [croppedImageBlob as unknown as BlobPart],
        previewImage.name,
        {
          type: previewImage.type,
        }
      );
      onUploadPhoto(imageFile);
    } catch (e) {
      throw e;
    }
    clearValues();
    onDialogClose();
  };

  const onCropChange = (crop: ICropValue) => {
    setCropValues(crop);
  };

  const onCropComplete = (
    croppedArea: ICropValue,
    croppedAreaPixelsValue: ICropValue | null
  ) => {
    setCroppedAreaPixels(croppedAreaPixelsValue);
  };

  const onZoomChange = (zoom: number) => {
    if (zoom < 5 && zoom >= 1) {
      setZoomValue(zoom);
    }
  };

  const clearValues = () => {
    dispatch(setPreviewImage(null));
    setZoomValue(3);
    setCropValues({ x: 0, y: 0 });
    setCroppedAreaPixels(null);
  };

  const handleChangePhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];
      dispatch(
        setPreviewImage(
          Object.assign(img, {
            preview: URL.createObjectURL(img),
          })
        )
      );
    }
  };

  useEffect(() => {
    if (location.pathname.includes("user")) {
      setTitle(t("userSettings.upload_profile_picture") as string);
      setType("round");
    } else {
      setTitle(t("userSettings.upload_profile_picture") as string);
      setType("rect");
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={onDialogClose}
      PaperProps={{ elevation: 0 }}
      data-testid={"upload-company-logo-dialog"}
    >
      <StyledUploadPictureDialogContainer width={desktop ? 512 : "100%"}>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography>{title}</Typography>
          </Stack>
          <Stack>
            <XMarkIcon className="close-icon" onClick={onDialogClose} />
          </Stack>
        </Stack>
        <Divider className="divider" />
        {previewImage ? (
          <Stack width="100%" height={320}>
            <Stack width="100%" height="100%" position="relative">
              <Cropper
                image={previewImage?.preview}
                crop={cropValues}
                zoom={zoomValue}
                aspect={type === "rect" ? 130 / 64 : 1}
                cropShape={type}
                showGrid={false}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
                objectFit="contain"
              />
            </Stack>
            <Stack mt={2} pl={1}>
              <Slider
                className="slider"
                color="secondary"
                value={zoomValue}
                min={1}
                max={5}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => onZoomChange(zoom as number)}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack
            width="100%"
            height={previewImage ? 320 : "auto"}
            p={previewImage ? 0 : 3}
            alignItems="center"
            spacing={previewImage ? 2 : 1}
            border="1px solid #E5E7EB"
            borderRadius="6px"
            sx={{
              borderStyle: "dashed",
            }}
          >
            <Stack
              width={40}
              height={40}
              padding={1}
              justifyContent="center"
              alignItems="center"
              alignSelf="center"
              component="label"
              borderRadius="50%"
              sx={{
                cursor: "pointer",
                backgroundColor: "#EDF7FF",
              }}
            >
              <ArrowDownTrayIcon strokeWidth={1} stroke="#178CF2" />
              <input
                hidden
                type="file"
                accept=".png, .jpg, .jpeg"
                onChange={handleChangePhoto}
              />
            </Stack>
            <Typography color="secondary" variant="body2" align="center">
              {expectedDymensionsText}
            </Typography>
            <Stack alignItems="center">
              <Typography variant="subtitle2" color="textSecondary">
                {t("userSettings.min_width")} 130px
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {t("userSettings.select_picture_formats")}
              </Typography>
            </Stack>
          </Stack>
        )}
        <Stack
          mt={desktop ? 4 : 0}
          direction={desktop ? "row" : "column"}
          width="100%"
          justifyContent="space-between"
          spacing={desktop ? 0 : 1.5}
        >
          <Stack>
            {previewImage && (
              <Button variant="contained" component="label">
                <input
                  hidden
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleChangePhoto}
                />
                {t("userSettings.select_picture")}
              </Button>
            )}
          </Stack>
          <Stack
            direction={desktop ? "row" : "column"}
            spacing={1.5}
            justifyContent="flex-end"
          >
            <Button variant="outlined" onClick={onDialogClose}>
              {t("button.secondCancel")}
            </Button>
            <Button
              variant="contained"
              onClick={onSave}
              disabled={!previewImage}
            >
              {t("button.save")}
            </Button>
          </Stack>
        </Stack>
      </StyledUploadPictureDialogContainer>
    </Dialog>
  );
}
