import React from "react";
import { Stack, Typography, Divider } from "@mui/material";
import TriggerToolbar from "./TriggerToolbar";
import SelectedTriggerAutomation from "./SelectedTriggerAutomation";
import Loading from "../../components/Loading";
import {
  ACTION_TRIGGER_VARIANT,
  IAutomationTrigger,
  ITriggerSelectionComponent,
} from "../../models/Automation";

const TriggerSelection = ({
  t,
  triggers,
  activeTriggerGroup,
  triggersGroup,
  currentAutomation,
  isTriggersLoading,
  onChangeActiveTrigger,
  onSelectTrigger,
}: ITriggerSelectionComponent) => (
  <Stack spacing={2}>
    <Typography fontSize={16} fontWeight={500}>
      {currentAutomation?.trigger
        ? t("automation.addAction")
        : t("automation.selectTriggerTitle")}
    </Typography>
    <Divider />
    {isTriggersLoading ? (
      <Loading />
    ) : (
      <Stack spacing={2}>
        <TriggerToolbar
          t={t}
          triggers={triggers}
          triggersGroup={triggersGroup}
          activeTriggerGroup={activeTriggerGroup}
          onChangeActiveTrigger={onChangeActiveTrigger}
          onSelectTrigger={onSelectTrigger}
        />
        <Stack className="triggers-container">
          {triggers.map((trigger: IAutomationTrigger, index: number) => (
            <Stack minWidth="49%" key={index}>
              <SelectedTriggerAutomation
                onClick={onSelectTrigger}
                t={t}
                data={trigger}
                currentAutomation={currentAutomation}
                variant={
                  currentAutomation?.trigger
                    ? ACTION_TRIGGER_VARIANT.ACTIONS_LIST
                    : ACTION_TRIGGER_VARIANT.TRIGGERS_LIST
                }
                hideWhenThenText
              />
            </Stack>
          ))}
        </Stack>
      </Stack>
    )}
  </Stack>
);

export default TriggerSelection;
