import { call, put, takeLatest } from "redux-saga/effects";
import {
  apiDisconnectCustomer,
  apiGetCompanyData,
  apiGetCountries,
  apiGetRegistrationData,
  apiUpdateCompanyAddress,
  apiUpdateCompanyData,
} from "../../api/Company";
import { PayloadActionWithCallback } from "../../models/common";
import {
  TCompanyAddressUpdatePayload,
  TCompanySettingsCompany,
  TCompanySettingsCountriesResponse,
  TCompanySettingsEmployeesResponse,
  TCompanySettingsIndustriesResponse,
  TCompanyUpdatePayload,
} from "../../models/CompanySettings";
import {
  disconnectAgency,
  disconnectAgencyFailed,
  disconnectAgencySuccess,
  downloadCompanyLogo,
  downloadCompanyLogoFailed,
  downloadCompanyLogoSuccess,
  fetchCompanyData,
  fetchCompanyDataFailed,
  fetchCompanyDataSuccess,
  fetchCountries,
  fetchCountriesFailed,
  fetchCountriesSuccess,
  fetchLogoBlob,
  fetchLogoBlobFailed,
  fetchLogoBlobSuccess,
  fetchRegistrationData,
  fetchRegistrationDataFailed,
  fetchRegistrationDataSuccess,
  updateCompanyAddress,
  updateCompanyAddressFailed,
  updateCompanyAddressSuccess,
  updateCompanyData,
  updateCompanyDataFailed,
  updateCompanyDataSuccess,
} from "../reducers/CompanySettings";
import { PayloadAction } from "@reduxjs/toolkit";
import { axiosInstanceDownload } from "../../api/config";

const baseUrl = `/company`;

function* handleOnFetchCompanyData() {
  const url = `${baseUrl}/companies/my`;

  try {
    const { data } = yield call(apiGetCompanyData, { url });
    const { company }: { readonly company: TCompanySettingsCompany } = data;
    const formattedCompanyWebsite = company.website.replace(/https:\/\//g, "");
    const formattedCompany = {
      ...company,
      website: formattedCompanyWebsite,
    };
    yield put(fetchCompanyDataSuccess({ company: formattedCompany }));
  } catch (e) {
    yield put(fetchCompanyDataFailed(e)); // TODO handle error
  }
}

function* handleOnUpdateCompanyData({
  payload,
}: PayloadActionWithCallback<{
  type: "logo" | "form";
  data: TCompanyUpdatePayload;
}>) {
  const { data, type, callback } = payload;
  const url = `${baseUrl}/profile/update`;

  try {
    const apiPayload = data;
    yield call(apiUpdateCompanyData, { url, apiPayload });
    yield put(updateCompanyDataSuccess({ type }));
    yield call(callback);
  } catch (e) {
    yield put(updateCompanyDataFailed({ type, error: e })); // TODO handle error
  }
}

function* handleOnFetchRegistrationData() {
  const url = `/registration`;

  try {
    const { data } = yield call(apiGetRegistrationData, { url });
    const {
      employees,
      industries,
    }: {
      readonly employees: TCompanySettingsEmployeesResponse[];
      readonly industries: TCompanySettingsIndustriesResponse;
    } = data;
    const modifiedEmployees = employees.map(
      (employee: TCompanySettingsEmployeesResponse) => ({
        label: employee.number,
        value: employee.id,
      })
    );
    const modifiedIndustries = Object.keys(industries).map(
      (industryKey: string) => ({
        label: industries[industryKey],
        value: industryKey,
      })
    );
    yield put(
      fetchRegistrationDataSuccess({
        employees: modifiedEmployees,
        industries: modifiedIndustries,
      })
    );
  } catch (e) {
    yield put(fetchRegistrationDataFailed(e)); // TODO handle error
  }
}

function* handleOnFetchCountries() {
  // TODO move this saga to COMMON saga later
  try {
    const { data } = yield call(apiGetCountries);
    const {
      countries,
    }: { readonly countries: TCompanySettingsCountriesResponse } = data;
    const modifiedCountries = countries.map((country) => ({
      label: country.Label,
      value: country.Id,
    }));
    yield put(fetchCountriesSuccess({ countries: modifiedCountries }));
  } catch (e) {
    yield put(fetchCountriesFailed(e)); // TODO handle error
  }
}

function* handleOnDisconnectAgency({
  payload,
}: PayloadActionWithCallback<{
  id: number;
}>) {
  const { id, callback } = payload;
  const url = `/company/agency/disconnect/${id}`;
  try {
    yield call(apiDisconnectCustomer, { url });
    yield put(disconnectAgencySuccess());
    yield call(callback);
  } catch (e) {
    yield put(disconnectAgencyFailed(e)); // TODO handle error
  }
}

function* handleOnUpdateCompanyAdress({
  payload,
}: PayloadActionWithCallback<{ data: TCompanyAddressUpdatePayload }>) {
  const { callback, data } = payload;
  const url = `${baseUrl}/profile/address-update`;
  try {
    yield call(apiUpdateCompanyAddress, { url, apiPayload: data });
    yield put(updateCompanyAddressSuccess());
    yield call(callback);
  } catch (e) {
    yield put(updateCompanyAddressFailed(e)); // TODO handle error
  }
}

function* handleOnDownloadCompanyLogo() {
  const url = "/company/companies/download-logo";
  try {
    const { data } = yield axiosInstanceDownload.post(url);

    const blob = new Blob([data], {
      type: data.type,
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    blob.name = "logo.60e48654.png";
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    blob.lastModified = new Date();
    const downloadUrl = URL.createObjectURL(blob);
    yield put(downloadCompanyLogoSuccess(downloadUrl));
  } catch (e) {
    yield put(downloadCompanyLogoFailed(e)); // TODO handle error
  }
}

function* handleOnFetchLogoBlob({ payload }: PayloadAction<string>) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const companyBlob = yield call(() =>
      fetch(payload).then((res) => res.blob())
    );
    yield put(fetchLogoBlobSuccess(companyBlob));
  } catch (e) {
    yield put(fetchLogoBlobFailed(e));
  }
}

function* CompanySettingsSaga() {
  yield takeLatest(fetchCompanyData, handleOnFetchCompanyData);
  yield takeLatest(fetchRegistrationData, handleOnFetchRegistrationData);
  yield takeLatest(fetchCountries, handleOnFetchCountries);
  yield takeLatest(updateCompanyData, handleOnUpdateCompanyData);
  yield takeLatest(disconnectAgency, handleOnDisconnectAgency);
  yield takeLatest(updateCompanyAddress, handleOnUpdateCompanyAdress);
  yield takeLatest(downloadCompanyLogo, handleOnDownloadCompanyLogo);
  yield takeLatest(fetchLogoBlob, handleOnFetchLogoBlob);
}

export default CompanySettingsSaga;
