import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const StyledCompanyHeaderContainer = styled(Stack)(({
  desktop,
  companyColor,
  isJobPreviewPage,
}: {
  desktop?: boolean;
  companyColor?: string;
  isJobPreviewPage?: boolean;
}) => {
  const baseStyle = {
    padding: 24,
    width: "100%",
    background: "#F9FAFB",
    color: "#6B7280",
    ".logo": {
      width: desktop ? 130 : 90,
      height: desktop ? 64 : 44,
      borderRadius: "6px",
      objectFit: "contain",
    },
    ".avatar": {
      height: 64,
      width: 64,
      position: "relative",
      bottom: 5,
    },
    ".icon": {
      width: 20,
      height: 20,
      strokeWidth: 1.5,
      stroke: companyColor,
    },
    ".show-more": {
      color: "#178CF2",
      cursor: "pointer",
    },
  };
  if (desktop) {
    return {
      ...baseStyle,
      maxWidth: isJobPreviewPage ? "auto" : 1128,
      margin: isJobPreviewPage ? 0 : "auto",
      padding: isJobPreviewPage ? "24px" : "64px 0px",
    };
  }
  return baseStyle;
});

export const StyledCompanyDescription = styled(Typography)(
  ({ isShowMore }: { isShowMore: boolean }) => ({
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: isShowMore ? 5 : 0,
    WebkitBoxOrient: "vertical",
    wordBreak: "break-word",
  })
);

export const StyledJobContainer = styled(Stack)(({
  isJobHovered,
  companyColor,
}: {
  isJobHovered: boolean;
  companyColor?: string;
}) => {
  const baseStyle = {
    background: "#fff",
    border: "1px solid #E5E7EB",
    borderRadius: "6px",
    cursor: "pointer",
    "&:hover": {
      border: `1px solid ${companyColor}`,
    },
    ".job": {
      alignItems: "center",
      height: 88,
      padding: "0px 16px",
      gap: 16,
    },
    ".avatar": {
      position: "relative",
      borderRadius: "6px",
      top: 4,
      height: 56,
      width: 56,
      stroke: "#131E30",
      "& img": {
        objectFit: "contain",
      },
    },
    ".icon": {
      width: 20,
      height: 20,
      strokeWidth: 1,
      stroke: companyColor,
    },
    ".arrow-icon": {
      width: 20,
      height: 20,
      strokeWidth: 1.5,
      stroke: isJobHovered ? companyColor : "#6B7280",
    },
    ".company-logo": {
      width: 112,
      height: 56,
      objectFit: "contain",
      borderRadius: "6px",
    },
  };
  return baseStyle;
});
