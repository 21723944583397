import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from "@mui/material";
import { IEditDialog } from "../../../models/dialogs";

const MessageDialog = ({
  t,
  isOpen,
  title,
  body,
  saveButtonText,
  onClose,
  onSave
}: IEditDialog) => {
  return (
    <Dialog
      open={Boolean(isOpen)}
      onClose={onClose}
      PaperProps={{
        elevation: 0
      }}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            minWidth: 300
          }
        }
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography fontSize={14} color="textSecondary">
          {body}
        </Typography>
      </DialogContent>
      <DialogActions style={{ padding: "16px 24px", paddingTop: "0px" }}>
        <Button variant="outlined" onClick={onClose} autoFocus>
          {t("button.secondCancel")}
        </Button>
        <Button variant="contained" onClick={onSave}>
          {saveButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessageDialog;
