import React from "react";
import { Stack, Typography } from "@mui/material";
import { EyeIcon } from "@heroicons/react/24/outline";
import { TFunction } from "i18next";

const NoMessages = ({ t }: { t: TFunction }) => {
  return (
    <Stack
      m="auto"
      p={3}
      height="85%"
      justifyContent="center"
      alignItems="center"
      spacing={1.5}
    >
      <Stack
        width={40}
        height={40}
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: "#EEF2FF"
        }}
        borderRadius="50%"
      >
        <EyeIcon width={24} height={24} stroke="#818CF8" />
      </Stack>
      <Typography color="textSecondary" variant="subtitle2">
        {t("messages_page.no_messages")}
      </Typography>
    </Stack>
  );
};

export default NoMessages;
