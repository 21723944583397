import React, { useMemo, useState } from "react";
import ReactQuill from "react-quill";
import { Typography } from "@mui/material";
import "react-quill/dist/quill.snow.css";
import "../../style.css";
import CustomToolbar from "./CustomToolbar";
import { TListOption } from "../../models/common";

const TextEditorCustomToolbar = ({
  items,
  value,
  errorMessage,
  isOnlyVariables = false,
  onChange,
}: {
  readonly items: TListOption[];
  readonly value?: string;
  readonly errorMessage?: string;
  readonly isOnlyVariables?: boolean;
  readonly onChange: (data: string) => void;
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [RTEValue, setRTEValue] = useState(value);
  const [quillRef, setQuillRef] = useState<any>(null);
  const defaultValue = useMemo(() => value, [value]);

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
  ];

  const modules = useMemo(
    () => ({
      toolbar: {
        container: "#toolbar",
      },
    }),
    []
  );

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleOnChange = (value: string) => {
    setRTEValue(value);
    onChange(value);
  };

  const handleOnSelect = (data: string) => {
    if (quillRef) {
      quillRef.insertText(
        quillRef.selection.cursor.selection.savedRange.index,
        data
      );
      quillRef.setSelection(
        quillRef.selection.cursor.selection.savedRange.index + data.length
      );
    }
  };

  return (
    <div
      className={
        errorMessage
          ? "quill-container quill-error"
          : isFocused
          ? "quill-container quill-focused"
          : "quill-container"
      }
    >
      <CustomToolbar
        onSelect={handleOnSelect}
        items={items}
        isOnlyVariables={isOnlyVariables}
      />
      <ReactQuill
        ref={(el) => {
          if (el) {
            setQuillRef(el.getEditor());
          }
        }}
        modules={modules}
        value={RTEValue || defaultValue}
        onChange={handleOnChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        theme="snow"
        formats={formats}
      />
      {errorMessage && (
        <Typography color="error" variant="subtitle2">
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};
export default TextEditorCustomToolbar;
