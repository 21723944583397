import { styled } from "@mui/system";
import { Paper, Stack } from "@mui/material";
import { DateRangePicker } from "rsuite";
import analyticsImg from "../../assets/analyticsImg.png";

export const StyledReportContainer = styled(Stack)({
  height: "100%",
  width: "100%",
  padding: "16px 12px",
  background: "#FFFFFF",
  borderRadius: 6,
  boxShadow: "0px 1px 2px -1px #1018281A, 0px 1px 3px 0px #1018281A",
  ".title": {
    fontSize: 14,
    fontWeight: 500,
    color: "#6B7280",
  },
  ".value": {
    fontSize: 18,
    fontWeight: 600,
    color: "#0F141B",
  },
});

export const StyledReportChartContainer = styled(StyledReportContainer)({
  padding: "24px 16px",
});

export const StyledNoDataContainer = styled(Stack)({
  alignItems: "center",
  justifyContent: "center",
  height: 350,
  h5: { fontWeight: 500, margin: 0 },
  h6: { fontWeight: 500, margin: 0 },
});

export const StyledQuickReportIconContainer = styled(Stack)({
  alignItems: "center",
  justifyContent: "center",
  width: 48,
  height: 48,
  background: "#EEF2FF",
  borderRadius: 6,
  svg: {
    stroke: "#818CF8",
    width: 24,
    height: 24,
  },
});

export const StyledQuickReportChipContainer = styled(Stack)({
  justifyContent: "flex-end",
  marginLeft: "auto!important",
});

export const StyledQuickReportChip = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isIncrease",
})(({ isIncrease }: { isIncrease: boolean }) => ({
  alignItems: "center",
  justifyContent: "center",
  height: 20,
  padding: "0px 4px",
  lineHeight: "12px",
  gap: 4,
  borderRadius: 24,
  background: isIncrease ? "#22C55E" : "#EF4444",
  fontSize: 12,
  color: "#FFF",
  marginBottom: 2,
  svg: {
    width: 12,
    height: 12,
    strokeWidth: 3,
  },
}));

export const StyledChartContainer = styled(Stack)({
  justifyContent: "space-between",
  maxHeight: 500,
  ".chart": {
    minHeight: "350px!important",
  },
});

export const StyledChartContainerTitle = styled(Stack)({
  flexDirection: "row",
  height: 40,
  alignItems: "center",
  gap: 12,
  padding: "0 8px",
  h4: { fontWeight: 500, margin: 0 },
  lineHeight: "20px",
  svg: {
    margin: 0,
    width: 16,
    height: 16,
    color: "#178CF2",
  },
});

export const StyledDatePickerContainer = styled(DateRangePicker)({
  ".rs-date-range-input,.rs-input": {
    height: "38px!important",
    fontSize: "14px!important",
  },
  ".rs-picker-input-group": {
    height: "40px!important",
  },
});

// Premium request component

export const StyledPremiumRequestContainer = styled(Stack)({
  marginTop: 24,
  width: "100%",
  height: "calc(100vh - 128px)",
  overflow: "hidden",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  borderRadius: 6,
});

export const StyledPremiumRequestBGContainer = styled(Stack)({
  height: "100%",
  width: "100%",
  backgroundColor: "rgba(0,0,0,0.2)",
  backgroundImage: `url(${analyticsImg})`,
  backgroundPosition: "center top",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  filter: "blur(8px)",
});

export const StyledPremiumRequestBGShadeContainer = styled(Stack)({
  height: "100%",
  width: "100%",
  backgroundColor: "rgba(0,0,0,0.25)",
  opacity: 0.5,
  position: "absolute",
});

export const StyledPremiumRequestPaperContainer = styled(Paper)({
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  padding: 24,
  width: "30%",
  zIndex: 2,
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  ".image-container": {
    alignItems: "center",
    img: { width: 230, height: 140 },
  },
  ".title-container": {
    alignItems: "center",
    h2: {
      marginTop: 20,
      marginBottom: 8,
      color: "#131E30",
      fontSize: 18,
      fontWeight: 500,
      lineHeight: "28px",
    },
  },
  ".main-text": {
    textAlign: "center",
    fontSize: "14px!important",
    color: "#6B7280",
  },
  button: {
    marginTop: 32,
  },
});

export const StyledAnalyticsTooltip = styled(Stack)({
  padding: 8,
});
