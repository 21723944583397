import React from "react";
import { Stack, Button, Switch, Tooltip } from "@mui/material";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { IAutomationHeaderComponent } from "../../models/Automation";
import {
  StyledAutomationTitleField,
  StyledDeleteNotCompletedIcon,
} from "./style";

const CreateAutomationHeader = ({
  t,
  currentAutomation,
  isCurrentAutomationCompleted,
  onEnabledDisabledAutmation,
  onChangeCurrentAutomationName,
  onSaveUpdateAutomation,
}: IAutomationHeaderComponent) => (
  <Stack
    direction="row"
    height={56}
    p="12px 16px"
    alignItems="center"
    justifyContent="space-between"
    borderRadius={1.5}
    sx={{ background: "#fff" }}
  >
    <Stack width="100%" direction="row" height="100%" alignItems="center">
      <StyledAutomationTitleField
        defaultValue={currentAutomation.name}
        onChange={(e) => onChangeCurrentAutomationName(e.target.value)}
        placeholder={t("automation.untitledAutomation") as string}
      />
    </Stack>
    <Stack direction="row" spacing={1.75} alignItems="center">
      {!currentAutomation.name.length && (
        <Tooltip title={t("automation.noAutomationTitle")}>
          <StyledDeleteNotCompletedIcon background="#FEF3C7">
            <ExclamationCircleIcon
              width={20}
              height={20}
              stroke="#D97706"
              fill="transparent"
            />
          </StyledDeleteNotCompletedIcon>
        </Tooltip>
      )}
      <Button
        variant="contained"
        disabled={!isCurrentAutomationCompleted}
        onClick={onSaveUpdateAutomation}
      >
        {t("button.save")}
      </Button>
      <Switch
        checked={currentAutomation.enabled}
        onChange={() => {
          if (onEnabledDisabledAutmation) onEnabledDisabledAutmation();
        }}
      />
    </Stack>
  </Stack>
);

export default CreateAutomationHeader;
