import { call, put, takeLatest, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  apiCheckCompanyName,
  apiCheckRecruiterEmail,
  apiCompanyMembersMagicLinkLogin,
  apiCompleteInvitation,
  apiConfirmEmailCode,
  apiConfirmExistingCompanyToAgency,
  apiConfirmInvitation,
  apiRegisterCompany,
  apiResendLink,
} from "../../api/Registration";
import { PayloadActionWithCallback } from "../../models/common";
import {
  TCompanyRecruiterFormFields,
  TCompanyRegistrationCompanyDataPayload,
} from "../../models/Registration";
import {
  checkCompanyName,
  checkCompanyNameFailed,
  checkCompanyNameSuccess,
  checkRecruiterEmail,
  checkRecruiterEmailFailed,
  checkRecruiterEmailSuccess,
  completeInvitation,
  completeInvitationFailed,
  completeInvitationSuccess,
  confirmEmailCode,
  confirmEmailCodeFailed,
  confirmEmailCodeSuccess,
  confirmExistingCompanyToAgency,
  confirmExistingCompanyToAgencyFailed,
  confirmExistingCompanyToAgencySuccess,
  confirmInvitation,
  confirmInvitationFailed,
  confirmInvitationSuccess,
  registerCompany,
  registerCompanyFailed,
  registerCompanySuccess,
  resendLink,
  resendLinkFailed,
  resendLinkSuccess,
} from "../reducers/Registration";
import { setCurrentUserToken } from "../reducers/CurrentUser";
import { getRegistrationState } from "../selectors/Registration";

const companiesBaseUrl = "/companies";
const registrationBaseUrl = "/registration";

function* handleOnCheckCompanyName({
  payload,
}: PayloadActionWithCallback<{ company: string }>) {
  const { company, callback } = payload;
  const url = `${companiesBaseUrl}/name-exists`;

  try {
    yield call(apiCheckCompanyName, { url, apiPayload: { name: company } });

    yield put(checkCompanyNameSuccess());
    yield call(callback);
  } catch (e) {
    yield put(checkCompanyNameFailed(e)); // TODO handle error
  }
}

function* handleOnCheckRecruiterEmail({
  payload,
}: PayloadActionWithCallback<{ email: string }>) {
  const { email, callback } = payload;
  const url = `${companiesBaseUrl}/email-exists`;

  try {
    yield call(apiCheckRecruiterEmail, { url, apiPayload: { email } });

    yield put(checkRecruiterEmailSuccess());
    yield call(callback);
  } catch (e) {
    yield put(checkRecruiterEmailFailed(e)); // TODO handle error
  }
}

function* handleOnConfirmInvitation({
  payload,
}: PayloadAction<{ urlKey: string }>) {
  const { urlKey } = payload;
  const url = `${registrationBaseUrl}/invitation-confirm/${urlKey}`;

  try {
    const { data } = yield call(apiConfirmInvitation, { url });
    yield put(
      confirmInvitationSuccess({
        email: data.email,
        agency_id: data?.agency_id,
      })
    );
  } catch (e) {
    yield put(confirmInvitationFailed(e)); // TODO handle error
  }
}

function* handleOnCompleteInvitation({
  payload,
}: PayloadActionWithCallback<{
  url_key: string;
  recruiterData: Omit<TCompanyRecruiterFormFields, "checked" | "email">;
}>) {
  const { url_key, recruiterData, callback } = payload;
  const url = `${registrationBaseUrl}/invitation-complete/${url_key}`;

  try {
    yield call(apiCompleteInvitation, { url, apiPayload: recruiterData });

    yield put(completeInvitationSuccess());
    yield call(callback);
  } catch (e) {
    yield put(completeInvitationFailed(e)); // TODO handle error
  }
}

function* handleOnRegisterCompany({
  payload,
}: PayloadActionWithCallback<{
  data: TCompanyRegistrationCompanyDataPayload;
}>) {
  const { data, callback } = payload;
  const url = `${registrationBaseUrl}/complete`;

  try {
    yield call(apiRegisterCompany, { url, apiPayload: data });

    yield put(registerCompanySuccess());
    yield call(callback);
  } catch (e) {
    yield put(registerCompanyFailed(e)); // TODO handle error
  }
}

function* handleOnResendLink({
  payload,
}: PayloadActionWithCallback<{
  candidateUrlKey?: string;
  jobUrlKey?: string;
}>) {
  const { companyData } = yield select(getRegistrationState);
  const { candidateUrlKey, jobUrlKey, callback } = payload;
  const url = jobUrlKey ? `apply/resend/${jobUrlKey}` : "registration/resend";

  try {
    yield call(apiResendLink, {
      url,
      apiPayload: {
        candidate_urlkey: candidateUrlKey || "",
        email: companyData?.email || "",
      },
    });

    yield put(resendLinkSuccess());
    yield call(callback);
  } catch (e) {
    yield put(resendLinkFailed(e)); // TODO handle error
  }
}

function* handleOnConfirmEmailCode({
  payload,
}: PayloadActionWithCallback<{ code: string }>) {
  const { code, callback } = payload;
  const url = `${registrationBaseUrl}/confirm/${code}`;

  try {
    const { data } = yield call(apiConfirmEmailCode, { url });
    yield put(confirmEmailCodeSuccess());
    localStorage.setItem("token", data.token);
    yield put(setCurrentUserToken(data.token));
    yield call(callback);
  } catch (e) {
    yield put(confirmEmailCodeFailed(e)); // TODO handle error
  }
}

function* handleOnConfirmExistingCompanyToAgency({
  payload,
}: PayloadActionWithCallback<{ code: string }>) {
  const { code, callback } = payload;
  const url = `/company/agency/accept/${code}`;
  const companyMemberLoginUrl = `/company/members/login/${code}`;
  try {
    const { data } = yield call(apiCompanyMembersMagicLinkLogin, {
      url: companyMemberLoginUrl,
    });
    localStorage.setItem("token", data.token);
    yield call(apiConfirmExistingCompanyToAgency, { url });
    yield put(confirmExistingCompanyToAgencySuccess());
    yield call(callback);
  } catch (e) {
    yield call(callback);
    yield put(confirmExistingCompanyToAgencyFailed(e)); // TODO handle error
  }
}

function* ApplicationsSaga() {
  yield takeLatest(checkCompanyName, handleOnCheckCompanyName);
  yield takeLatest(checkRecruiterEmail, handleOnCheckRecruiterEmail);
  yield takeLatest(completeInvitation, handleOnCompleteInvitation);
  yield takeLatest(registerCompany, handleOnRegisterCompany);
  yield takeLatest(resendLink, handleOnResendLink);
  yield takeLatest(confirmEmailCode, handleOnConfirmEmailCode);
  yield takeLatest(confirmInvitation, handleOnConfirmInvitation);
  yield takeLatest(
    confirmExistingCompanyToAgency,
    handleOnConfirmExistingCompanyToAgency
  );
}

export default ApplicationsSaga;
