import React from "react";
import ReactQuill from "react-quill";
import { Divider } from "@mui/material";
import {
  StyledJobPreviewTemplateSectionContainer,
  StyledJobPreviewTemplateSectionContent,
  StyledJobPreviewTemplateSectionTitle,
} from "../styles";

const JobTemplateSection = ({
  title,
  content,
  companyColor,
  desktop,
  isFirstElement = false,
}: {
  readonly title: string;
  readonly content: string;
  readonly companyColor: string;
  readonly desktop: boolean;
  readonly isFirstElement?: boolean;
}) => (
  <StyledJobPreviewTemplateSectionContainer
    companyColor={companyColor}
    isFirstElement={isFirstElement}
  >
    <StyledJobPreviewTemplateSectionTitle
      pb={desktop ? 1.5 : 1}
      color={companyColor}
    >
      {title}
    </StyledJobPreviewTemplateSectionTitle>
    {desktop && <Divider sx={{ borderColor: "#E5E7EB" }} />}
    <StyledJobPreviewTemplateSectionContent pt={desktop ? 3 : 0}>
      <ReactQuill
        className={"quill-preview"}
        readOnly
        value={content}
        theme={"bubble"}
      />
    </StyledJobPreviewTemplateSectionContent>
  </StyledJobPreviewTemplateSectionContainer>
);

export default JobTemplateSection;
