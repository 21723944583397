import React from "react";
import { Box, InputLabel, Stack, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { IJobDetailsSalaryComponent } from "../../../models/JobEditor";
import AutocompleteFilter from "../../filters_new/AutocompleteFilter";
import { StyledSubSectionContainer, StyledOptionalLabel } from "../styles";

const SalarySection = ({
  t,
  control,
  register,
  salaryTypes,
  salaryCurrencies,
}: IJobDetailsSalaryComponent) => (
  <>
    <Stack direction="row" alignItems="center">
      <InputLabel> {t("create_job_second_step.salary")} </InputLabel>
      <StyledOptionalLabel mb="0px !important">Optional</StyledOptionalLabel>
    </Stack>
    <StyledSubSectionContainer direction={"row"} spacing={2}>
      <Stack sx={{ flex: 1 }}>
        <InputLabel>{t("create_job_second_step.minimum_salary")}</InputLabel>
        <TextField
          placeholder={
            t("create_job_second_step.minimum_salary_placeholder") as string
          }
          type={"number"}
          fullWidth
          {...register("min_salary")}
        />
      </Stack>
      <Stack sx={{ flex: 1 }}>
        <InputLabel>{t("create_job_second_step.maximum_salary")}</InputLabel>
        <TextField
          placeholder={
            t("create_job_second_step.maximum_salary_placeholder") as string
          }
          type={"number"}
          fullWidth
          {...register("max_salary")}
        />
      </Stack>
      <Box sx={{ flex: 1 }}>
        <InputLabel>
          {t("create_job_second_step.currency") as string}
        </InputLabel>
        <Controller
          name={"salary_currency"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <AutocompleteFilter
              onChange={onChange}
              value={value}
              options={salaryCurrencies}
              placeholder={t("create_job_second_step.currency")}
            />
          )}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <InputLabel>
          {t("create_job_second_step.interval") as string}
        </InputLabel>
        <Controller
          name={"salary_type"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <AutocompleteFilter
              onChange={onChange}
              value={value}
              options={salaryTypes}
              placeholder={t("create_job_second_step.interval")}
            />
          )}
        />
      </Box>
    </StyledSubSectionContainer>
  </>
);

export default SalarySection;
