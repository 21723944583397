import { Mention, MentionsInput } from "react-mentions";
import { Avatar, Button, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { StyledCandidateNoteInputFieldContainer } from "../../styles";
import { IApplicationNotesInputFieldComponent } from "../../../../models/Applications";

const CandidateNoteInputFieldComponent = ({
  t,
  isDisabled,
  value,
  userList,
  onChangeInput,
  onSubmit,
}: IApplicationNotesInputFieldComponent) => (
  <StyledCandidateNoteInputFieldContainer>
    <Divider />
    <MentionsInput
      value={value}
      onChange={onChangeInput}
      placeholder={t("candidate.write_a_note") as string}
      className="mentions"
    >
      <Mention
        trigger="@"
        data={userList as any}
        markup="@{{__type__||__id__||__display__}}"
        // className="mentions__mention"
        appendSpaceOnAdd
        renderSuggestion={(suggestion: any) => (
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Avatar
              src={suggestion.profile_photo}
              alt={suggestion.display}
              sx={{ width: 32, height: 32 }}
            />
            <Typography variant="subtitle2" color="#131E30" fontWeight={500}>
              {suggestion.name}
            </Typography>
          </Stack>
        )}
      />
    </MentionsInput>
    <Button variant={"contained"} onClick={onSubmit} disabled={isDisabled}>
      <AddIcon />
    </Button>
  </StyledCandidateNoteInputFieldContainer>
);

export default CandidateNoteInputFieldComponent;
