import { Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledExpandedBlockContainer = styled(Stack)(
  ({ isExpanded }: { isExpanded: boolean }) => ({
    border: "1px solid #E5E7EB",
    padding: "12px 16px",
    borderRadius: 6,
    ...(!isExpanded && { maxHeight: 200 }),
  }),
);

export const StyledExpandedBlockTitle = styled("span")({
  color: "#131E30",
  fontSize: 16,
  fontWeight: "500",
});

export const StyledExpandedBlockBody = styled("span")(
  ({ isRendered }: { isRendered: boolean }) => ({
    opacity: isRendered ? 1 : 0,
    color: "#6B7280",
    fontSize: 14,
    fontWeight: "400",
    "-webkit-line-clamp": "4",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    p: {
      margin: 0,
      lineHeight: "20px",
    },
  }),
);
