import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  StyledDashboardSecondaryText,
  StyledProgressBar,
  StyledProgressButton,
  StyledProgressItemBlock,
  StyledProgressItemContainer,
  StyledProgressItemWrapper,
  StyledSuccessIcon,
} from "../styles";

const ProgressBox = ({
  title,
  actionType,
  progress,
  onAction,
}: {
  readonly title: string;
  readonly actionType?: string;
  readonly progress: 0 | 100;
  readonly onAction?: () => void;
}) => (
  <StyledProgressItemWrapper>
    <StyledProgressItemContainer>
      <StyledDashboardSecondaryText>{title}</StyledDashboardSecondaryText>
      <StyledProgressItemBlock>
        <StyledProgressBar variant="determinate" value={progress} />
      </StyledProgressItemBlock>
    </StyledProgressItemContainer>
    {progress ? (
      <StyledSuccessIcon data-testid={`done-icon-${actionType}`}>
        <DoneIcon />
      </StyledSuccessIcon>
    ) : (
      <StyledProgressButton
        data-testid={`action-button-${actionType}`}
        onClick={() => {
          if (onAction) onAction();
        }}
      >
        <ChevronRightIcon />
      </StyledProgressButton>
    )}
  </StyledProgressItemWrapper>
);

export default ProgressBox;
