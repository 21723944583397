import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import {
  ICompanyInfoState,
  TCompany,
  TFilters,
} from "../../models/CompanyInfo";

const initialState: ICompanyInfoState = {
  isLoading: false,
  error: false,
  company: {
    title: undefined,
    website: undefined,
    logo: undefined,
    about_us: undefined,
    color: undefined,
    linkedin_url: undefined,
    xing_url: undefined,
    employees: undefined,
    jobs: undefined,
    jobs_total: 0,
  },
  filterOptions: {
    locations: [],
    workFields: [],
  },
  filterValue: {
    location: "",
    workField: "",
  },
  pagination: {
    pageNum: 1,
    pageSize: 10,
  },
};

const companyInfoReducers = {
  fetchCompanyInfo: (
    state: Draft<ICompanyInfoState>,
    _: PayloadAction<{
      readonly companyKey: string;
      readonly locationId?: string;
      readonly workFieldId?: string;
      readonly pageNum?: number;
      readonly pageSize?: number;
    }>
  ) => {
    state.isLoading = true;
  },
  fetchCompanyInfoSuccess: (
    state: Draft<ICompanyInfoState>,
    action: PayloadAction<{
      company: TCompany;
      filters: TFilters;
    }>
  ) => {
    state.isLoading = false;
    state.error = false;
    state.company = action.payload.company;
    state.filterOptions = {
      locations: action.payload.filters.locations,
      workFields: action.payload.filters.workFields,
    };
  },
  fetchCompanyInfoFailed: (
    state: Draft<ICompanyInfoState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  setCompanyFilter: (
    state: Draft<ICompanyInfoState>,
    action: PayloadAction<{
      key: "location" | "workField";
      value: any;
    }>
  ) => {
    const { key, value } = action.payload;
    state.filterValue[key] = value;
    state.pagination = initialState.pagination;
  },
  setPageNum: (
    state: Draft<ICompanyInfoState>,
    action: PayloadAction<number>
  ) => {
    state.pagination.pageNum = action.payload;
  },
};

export const CompanyInfoSlice = createSlice({
  name: "COMPANY INFO",
  initialState,
  reducers: {
    ...companyInfoReducers,
  },
});

export const {
  fetchCompanyInfo,
  fetchCompanyInfoFailed,
  fetchCompanyInfoSuccess,
  setCompanyFilter,
  setPageNum,
} = CompanyInfoSlice.actions;

export default CompanyInfoSlice.reducer;
