import { createSelector } from "@reduxjs/toolkit";
import { TJobListFilters } from "../../models/JobList";
import { RootState } from "../config";

export const getJobListFiltersState = (state: RootState) =>
  state.jobListNew.filters;

export const getJobListNavigationState = (state: RootState) =>
  state.jobListNew.navigation;

export const getJobListPaginationState = (state: RootState) =>
  state.jobListNew.pagination;

export const getJobListJobsState = (state: RootState) => state.jobListNew.jobs;

export const getJobListIsLoading = (state: RootState) =>
  state.jobListNew.isLoading;

export const getJobListIsListLoading = (state: RootState) =>
  state.jobListNew.isListLoading;

export const getJobListSelectedJobs = (state: RootState) =>
  state.jobListNew.selectedJobs;

export const getCurrentJobStatus = (state: RootState) =>
  state.jobListNew.navigation.selectedJobStatus;

export const getJobListIsDraftImagesLoading = (state: RootState) =>
  state.jobListNew.isDraftImagesLoading;

export const getAllActiveJobs = (state: RootState) =>
  state.jobListNew.allActiveJobs;

export const getJobListFilteredPaginationState = createSelector(
  [getJobListNavigationState, getJobListPaginationState],
  ({ selectedJobStatus }, pagination) =>
    pagination[selectedJobStatus.toLowerCase() as keyof TJobListFilters]
);

export const getCurrentStatusJobListFiltersState = createSelector(
  [getJobListFiltersState, getJobListNavigationState],
  (filters, { selectedJobStatus }) =>
    filters[selectedJobStatus.toLowerCase() as keyof TJobListFilters]
);
