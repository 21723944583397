import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { IMarketingPageState, TMarketingForm } from "../../models/Marketing";
import { PayloadActionWithCallback } from "../../models/common";

const initialState: IMarketingPageState = {
  jobUrl: "",
  jobData: undefined,
  step: 1,
  isAgree: false,
  isLoading: false,
  error: undefined,
};

const setReducers = {
  setJobUrl: (
    state: Draft<IMarketingPageState>,
    action: PayloadAction<string>
  ) => {
    state.jobUrl = action.payload;
  },
  setIsAgree: (
    state: Draft<IMarketingPageState>,
    action: PayloadAction<boolean>
  ) => {
    state.isAgree = action.payload;
  },
  setJobData: (
    state: Draft<IMarketingPageState>,
    action: PayloadAction<TMarketingForm>
  ) => {
    state.jobData = action.payload;
  },
  setStep: (
    state: Draft<IMarketingPageState>,
    action: PayloadAction<number>
  ) => {
    state.step = action.payload;
  },
};

const apiReducers = {
  extractJobData: (
    state: Draft<IMarketingPageState>,
    _: PayloadAction<string>
  ) => {
    state.isLoading = true;
  },
  extractJobDataFailed: (
    state: Draft<IMarketingPageState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  extractJobDataSuccess: (
    state: Draft<IMarketingPageState>,
    action: PayloadAction<any>
  ) => {
    state.isLoading = false;
    state.jobData = action.payload;
  },
  sendJobCompanyData: (
    state: Draft<IMarketingPageState>,
    _: PayloadActionWithCallback<{
      content: TMarketingForm;
    }>
  ) => {
    state.isLoading = true;
  },
  sendJobCompanyDataFailed: (
    state: Draft<IMarketingPageState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  sendJobCompanyDataSuccess: (state: Draft<IMarketingPageState>) => {
    state.isLoading = false;
  },
};

export const MarketingSlice = createSlice({
  name: "MARKETING",
  initialState,
  reducers: {
    ...setReducers,
    ...apiReducers,
  },
});

export const {
  setJobUrl,
  setIsAgree,
  setJobData,
  setStep,
  extractJobData,
  extractJobDataFailed,
  extractJobDataSuccess,
  sendJobCompanyData,
  sendJobCompanyDataFailed,
  sendJobCompanyDataSuccess,
} = MarketingSlice.actions;

export default MarketingSlice.reducer;
