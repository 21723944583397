import { TFunction } from "i18next";
import * as yup from "yup";
import { TCompanySettingsCompanyFormFields } from "../../models/CompanySettings";

export const getFormSettings = (t: TFunction) => {
  return yup
    .object()
    .shape({
      title: yup.string().required(t("register.message") as string),
      city: yup.string().required(t("register.message") as string),
      zip: yup.string().required(t("register.message") as string),
      street: yup.string().required(t("register.message") as string),
      number: yup.string().required(t("register.message") as string),
      country: yup.string().required(t("register.message") as string),
      linkedin: yup.string(),
      xing: yup.string(),
      pp_link: yup.string(),
      tc_link: yup.string(),
      apiKey: yup.string().required(t("register.message") as string),
      website: yup.string().required(t("register.message") as string),
      employees: yup.string().required(t("register.message") as string),
      industry_id: yup.string().required(t("register.message") as string),
      is_page_active: yup.boolean(),
      about_us: yup.string(),
      company_color: yup.string(),
    })
    .required();
};

export const formDefaultValues: TCompanySettingsCompanyFormFields = {
  title: "",
  city: "",
  zip: "",
  street: "",
  number: "",
  country: "",
  linkedin: "",
  xing: "",
  pp_link: "",
  tc_link: "",
  apikey: "",
  website: "",
  employees: "",
  industry_id: "",
  is_page_active: 1,
  about_us: "",
  agency_name: null,
  connected_to_agency_at: null,
  company_color: "#178CF2",
};
