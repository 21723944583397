import React from "react";
import Column from "./Column";
import { IColumnsComponent } from "../../models/Kanban";
import { StyledColumns } from "./style";

const Columns = ({
  t,
  columns,
  variant,
  selectedItems,
  isDragDisabled,
  isSelectWorkflowDialogOpen,
  provided,
  editWorkflow,
  onAddNewStatus,
  setColumns,
  onSelectItem,
  onItemClick,
}: IColumnsComponent) => (
  <StyledColumns
    columns={columns}
    variant={variant}
    ref={provided.innerRef}
    {...provided.droppableProps}
  >
    {Object.entries(columns).map(([columnId, column]) => (
      <Column
        key={columnId}
        t={t}
        variant={variant}
        column={column}
        columnId={columnId}
        columns={columns}
        selectedItems={selectedItems}
        isDragDisabled={isDragDisabled}
        isSelectWorkflowDialogOpen={isSelectWorkflowDialogOpen}
        editWorkflow={editWorkflow}
        onAddNewStatus={onAddNewStatus}
        setColumns={setColumns}
        onSelectItem={onSelectItem}
        onItemClick={onItemClick}
      />
    ))}
    {provided.placeholder}
  </StyledColumns>
);

export default Columns;
