import { Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledIconBox = styled(Stack)({
  width: 32,
  height: 32,
  cursor: "pointer",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid #5b5c5e",
  borderRadius: 6,
  "&:hover": {
    background: "#f3f4f6",
  },
  svg: {
    width: 18,
    height: 18,
    stroke: "#0D0F10",
    strokeWidth: 0,
  },
});
