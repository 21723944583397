import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AdditionalQuestionsQuestionsComponent from "./component";
import { getJobPreviewState } from "../../../../store_new/selectors/JobPreview";
import { IAdditionalQuestionSimpleSection } from "../../../../models/JobPreview";
import { TJobQuestions } from "../../../../models/SingleJob";

const AdditionalQuestionsQuestions = ({
  desktop,
  control,
  companyColor,
  boldHeading,
}: IAdditionalQuestionSimpleSection) => {
  const { t } = useTranslation();
  const {
    jobData: { job_questions },
  } = useSelector(getJobPreviewState);

  const { fields, append } = useFieldArray({
    control,
    name: "questions",
  });

  useEffect(() => {
    if (job_questions && !fields.length) {
      job_questions.forEach((item: TJobQuestions) => {
        append({
          answer: item.is_answer_type_yesno ? "Yes" : "",
          question_id: item.id,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          is_answer_type_yesno: item.is_answer_type_yesno,
        });
      });
    }
  }, [job_questions]);

  return (
    <AdditionalQuestionsQuestionsComponent
      t={t}
      control={control}
      desktop={desktop}
      fields={fields}
      questions={job_questions}
      companyColor={companyColor}
      boldHeading={boldHeading}
    />
  );
};

export default AdditionalQuestionsQuestions;
