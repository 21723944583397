//   token: storageToken || null,
//   profile_picture: undefined,
//   previewImage: null,
//   linkedin_url: undefined,
//   xing_url: undefined,
//   noPassword: undefined,

import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

export interface ITopHeaderState {
  title: string;
  subtitle: string;
}

const initialState: ITopHeaderState = {
  title: "",
  subtitle: ""
};

export const TopHeaderSlice = createSlice({
  name: "TOP_HEADER",
  initialState,
  reducers: {
    setTopHeaderTitle: (
      state: Draft<ITopHeaderState>,
      action: PayloadAction<string>
    ) => {
      state.title = action.payload;
    },
    setTopHeaderSubtitle: (
      state: Draft<ITopHeaderState>,
      action: PayloadAction<string>
    ) => {
      state.subtitle = action.payload;
    }
  }
});

export const { setTopHeaderTitle, setTopHeaderSubtitle } =
  TopHeaderSlice.actions;

export default TopHeaderSlice.reducer;
