import React from "react";
import { Stack, Chip } from "@mui/material";
import ShowMoreTooltipComponent from "../../ShowMoreTooltip";

const SkillsCell = ({ skills }: { readonly skills: string[] }) => {
  const formattedSkill = skills.map((skill: string) => ({
    firstname: skill,
  }));

  return (
    <Stack direction="row" spacing={1}>
      {skills[0]?.length ? (
        <ShowMoreTooltipComponent
          items={
            [
              {
                firstname: skills[0],
              },
            ] as unknown as []
          }
        >
          <Chip
            label={
              skills[0].length > 12 ? `${skills[0].slice(0, 12)}...` : skills[0]
            }
            variant="outlined"
            sx={{ cursor: "pointer" }}
          />
        </ShowMoreTooltipComponent>
      ) : (
        "-"
      )}
      {!!skills[1]?.length && (
        <ShowMoreTooltipComponent
          items={
            [
              {
                firstname: skills[1],
              },
            ] as unknown as []
          }
        >
          <Chip
            label={
              skills[1].length > 12 ? `${skills[1].slice(0, 12)}...` : skills[1]
            }
            variant="outlined"
            sx={{ cursor: "pointer" }}
          />
        </ShowMoreTooltipComponent>
      )}
      <ShowMoreTooltipComponent items={formattedSkill as []}>
        {skills?.length > 2 && (
          <Chip label="..." variant="outlined" sx={{ cursor: "pointer" }} />
        )}
      </ShowMoreTooltipComponent>
    </Stack>
  );
};

export default SkillsCell;
