import * as yup from "yup";
import { TMarketingForm } from "../../models/Marketing";

const regMatch =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w[a-zA-Z-_%/@?]+)*([^/\w[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

export const getCountrySettings = () => {
  return yup.object().shape({
    title: yup.string(),
    country: yup.string(),
    city: yup.string(),
    aboutTitle: yup.string(),
    about: yup.string(),
    tasksTitle: yup.string(),
    tasks: yup.string(),
    requirementsTitle: yup.string(),
    requirements: yup.string(),
    benefitsTitle: yup.string(),
    benefits: yup.string(),
    contactTitle: yup.string(),
    contact: yup.string(),
    company: yup.string().required("Feld ist erforderlich"),
    website: yup
      .string()
      .required("Feld ist erforderlich")
      .matches(regMatch, "Bitte gib eine gültige URL an"),
    mainCity: yup.string().required("Feld ist erforderlich"),
    postcode: yup.string().required("Feld ist erforderlich"),
    address: yup.string().required("Feld ist erforderlich"),
    number: yup.string().required("Feld ist erforderlich"),
    firstname: yup.string().required("Feld ist erforderlich"),
    mainCountry: yup.string().required("Feld ist erforderlich"),
    lastname: yup.string().required("Feld ist erforderlich"),
    email: yup
      .string()
      .required("Feld ist erforderlich")
      .email("Ungültige Email-Adress"),
    phone: yup.string().required("Feld ist erforderlich"),
  });
};

export const formDefaultValues: TMarketingForm = {
  title: "",
  country: "",
  city: "",
  aboutTitle: "",
  about: "",
  tasksTitle: "",
  tasks: "",
  requirementsTitle: "",
  requirements: "",
  benefitsTitle: "",
  benefits: "",
  contactTitle: "",
  contact: "",
  company: "",
  website: "",
  mainCity: "",
  postcode: "",
  address: "",
  number: "",
  mainCountry: "",
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
};
