import React from "react";
import { Stack } from "@mui/material";
import List from "./List";
import Header from "./Header";
import { IColumnComponent } from "../../models/Kanban";

const Column = ({
  t,
  selectedItems,
  variant,
  columnId,
  column,
  isDragDisabled,
  isSelectWorkflowDialogOpen,
  editWorkflow,
  setColumns,
  onItemClick,
  onSelectItem,
  onAddNewStatus,
}: IColumnComponent) => (
  <Stack width="100%" className="dnd-column">
    {variant !== "workflowStatus" && (
      <Header
        title={column.title || column.label || ""}
        color={column.color}
        length={column.items?.length}
      />
    )}
    <List
      t={t}
      selectedItems={selectedItems}
      isDragDisabled={isDragDisabled}
      isSelectWorkflowDialogOpen={isSelectWorkflowDialogOpen}
      editWorkflow={editWorkflow}
      setColumns={setColumns}
      onItemClick={onItemClick}
      onSelectItem={onSelectItem}
      onAddNewStatus={onAddNewStatus}
      listId={columnId}
      variant={variant}
      column={column}
    />
  </Stack>
);

export default Column;
