import { TFunction } from "i18next";
import {
  Control,
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";
import { TListOption } from "./common";

// api

export type TApiAddAutomationPayload = {
  name: string;
  event_const: string;
  action_const: string[];
  conditions?: IAutomationCondition[];
  params: IAutomationParam[];
  enabled: boolean | number;
};

export type TApiAddAutomationStatusPayload = {
  enabled: boolean | number;
};

export type TApiFetchAutomationsPayload = {
  name: string;
  pagesize: number;
};

export type TApiFetchAutomationEntity = {
  entity: string;
};

export interface IAutomationState {
  readonly automations: IAutomation[];
  readonly currentAutomation: ICurrentAutomation;
  readonly isTriggersLoading: boolean;
  readonly isActionsLoading: boolean;
  readonly isSaveLoading: boolean;
  readonly error: unknown;
  readonly filter: string;
  readonly isAddActionContainer: boolean;
  readonly triggers: IAutomationTrigger[];
  readonly actions: IAutomationAction[];
  readonly selectedAction?: IAutomationAction;
  readonly isListLoading: boolean;
  readonly selectedActionData?: {
    jobs: TListOption[];
    members: TListOption[];
  };
  readonly isSelectedActionDataLoading: boolean;
}

export interface IAutomation {
  name: string;
  event_const: string;
  action_const: string[];
  params: IAutomationParam[];
  enabled: boolean;
  conditions?: IAutomationCondition[];
  firstname: string;
  lastname: string;
  photo?: string | null;
  updated_at: string;
}

export interface ICurrentAutomation {
  readonly id: string | number | null;
  readonly name: string;
  readonly trigger: ICurrentAutomationTrigger | null;
  readonly actions: IAutomationAction[];
  readonly enabled: boolean;
  readonly conditions: IAutomationCondition[];
  readonly conditions_operator?: string;
}

export interface IAutomationTrigger {
  readonly name: string;
  readonly type: string;
  readonly params: { [key: string]: string }[];
  readonly const?: string;
}

export interface ICurrentAutomationTrigger {
  readonly name: string;
  readonly const?: string | TRIGGERS_TYPE;
  readonly params: any;
  readonly type: string;
  readonly isCompleted?: boolean;
  readonly isOpen?: boolean;
}

export interface IAutomationParam {
  readonly subject?: string;
  readonly message?: string;
  readonly to?: string | string[];
  readonly sender_email?: string;
  readonly sender_name?: string;
  readonly name: string;
  readonly cc?: string[];
  readonly bcc?: string[];
  readonly isCCorBCC?: boolean;
  readonly status?: string;
  readonly application_id?: string;
  readonly job_id?: string;
}

export interface IAutomationAction {
  readonly id?: string;
  readonly name: string;
  readonly type: string;
  readonly const: string;
  readonly actionCompleted: boolean;
  readonly params: IAutomationParam;
}

export interface IAutomationHeaderComponent {
  readonly t: TFunction;
  readonly currentAutomation: ICurrentAutomation;
  readonly isCurrentAutomationCompleted: boolean;
  readonly onEnabledDisabledAutmation?: () => void;
  readonly onSaveUpdateAutomation: () => void;
  readonly onChangeCurrentAutomationName: (name: string) => void;
}

export interface IAutomationComponent {
  readonly t: TFunction;
  readonly triggers: IAutomationTrigger[];
  readonly triggersGroup: IAutomationTrigger[];
  readonly activeTriggerGroup: string;
  readonly isTriggersLoading: boolean;
  readonly isAddActionContainer: boolean;
  readonly selectedAction?: IAutomationAction;
  readonly currentAutomation: ICurrentAutomation;
  readonly isCurrentAutomationCompleted: boolean;
  readonly onOpenTrigger?: () => void;
  readonly onChangeCurrentAutomationName: (name: string) => void;
  readonly onEnabledDisabledAutmation?: () => void;
  readonly onSaveUpdateAutomation: () => void;
  readonly onHoverLeaveDeleteActionIcon: (value: boolean) => void;
  readonly onDeleteTriggerAction: (
    variant: ACTION_TRIGGER_VARIANT,
    actionId: string
  ) => void;
  readonly onOpenAction: (action: IAutomationAction) => void;
  readonly onOpenSelectActionContainer: () => void;
  readonly onChangeActiveTrigger: (value: string | undefined) => void;
  readonly onSelectTrigger: (value: string) => void;
}

export interface IAutomationInfoComponent {
  readonly t: TFunction;
  readonly currentAutomation: ICurrentAutomation;
  readonly onOpenAction: (action: IAutomationAction) => void;
  readonly onOpenTrigger?: () => void;
  readonly onDeleteTriggerAction: (
    variant: ACTION_TRIGGER_VARIANT,
    actionId: string
  ) => void;
  readonly onHoverLeaveDeleteActionIcon: (value: boolean) => void;
}

export interface IAutomationSelectedTrigger {
  readonly t: TFunction;
  readonly variant: ACTION_TRIGGER_VARIANT;
  readonly data: any;
  readonly hideWhenThenText?: boolean;
  readonly currentAutomation?: ICurrentAutomation;
  readonly subText?: string;
  readonly index?: number;
  readonly onClick?: (value: string) => void;
  readonly onHoverLeaveDeleteActionIcon?: (value: boolean) => void;
  readonly onDeleteTriggerAction?: (
    variant: ACTION_TRIGGER_VARIANT,
    actionId: string
  ) => void;
}

export interface ITriggerSelectionComponent {
  readonly t: TFunction;
  readonly currentAutomation?: ICurrentAutomation;
  readonly triggers: IAutomationTrigger[];
  readonly triggersGroup: IAutomationTrigger[];
  readonly activeTriggerGroup: string;
  readonly isTriggersLoading?: boolean;
  readonly isAddActionContainer?: boolean;
  readonly selectedAction?: IAutomationAction;
  readonly onOpenSelectActionContainer?: () => void;
  readonly onChangeActiveTrigger: (value: string | undefined) => void;
  readonly onSelectTrigger: (value: string) => void;
}

export interface ISelectedActionComponent {
  readonly t: TFunction;
  readonly selectedAction?: IAutomationAction;
  readonly onCloseAction: () => void;
}

export interface IIncompleteDeleteDataComponent {
  readonly t: TFunction;
  readonly data: any;
  readonly variant: string;
  readonly currentAutomation?: ICurrentAutomation;
  readonly onDeleteTriggerAction?: (
    variant: ACTION_TRIGGER_VARIANT,
    actionId: string
  ) => void;
  readonly onHoverLeaveDeleteActionIcon?: (value: boolean) => void;
}

export interface ICreateNoteFormField {
  readonly message: string;
}

export interface ISendMessageFormField extends ICreateNoteFormField {
  readonly to: string;
}

export interface ISendEmailFormField extends ICreateNoteFormField {
  readonly to: string[];
  readonly subject: string;
  readonly cc: string[];
  readonly bcc: string[];
}

export interface IArchiveJobFormField {
  readonly job_id: string;
}

export interface IAddActionContainer {
  readonly t: TFunction;
  readonly onOpenSelectActionContainer: () => void;
}

export interface IAutomationsComponent {
  readonly t: TFunction;
  readonly automations: IAutomation[];
  readonly isListLoading: boolean;
  readonly isDeleteDialogOpen: boolean;
  readonly onEnabledDisabledAutomation: (data: unknown) => void;
  readonly onCreateAutomation: () => void;
  readonly onChangeAutomationsFilter: (value: string) => void;
  readonly onOpenAutomation: (data: unknown) => void;
  readonly onOpenCloseDeleteDialog: (data: unknown) => void;
  readonly onDeleteAutomation: () => void;
}

export interface ICreateNoteComponent {
  readonly t: TFunction;
  readonly formattedVariables: TListOption[];
  readonly errors: FieldErrors<ICreateNoteFormField>;
  readonly control: Control<ICreateNoteFormField>;
  readonly selectedAction?: IAutomationAction;
  readonly onCloseAction: () => void;
  readonly onSubmit: (data: ICreateNoteFormField) => void;
  readonly handleSubmit: UseFormHandleSubmit<ICreateNoteFormField>;
}

export interface ISendMessageComponent {
  readonly t: TFunction;
  readonly applications: TListOption[];
  readonly formattedVariables: TListOption[];
  readonly errors: FieldErrors<ISendMessageFormField>;
  readonly control: Control<ISendMessageFormField>;
  readonly onCloseAction: () => void;
  readonly onSubmit: (data: ISendMessageFormField) => void;
  readonly handleSubmit: UseFormHandleSubmit<ISendMessageFormField>;
}

export interface ISendEmailComponent {
  readonly t: TFunction;
  readonly open: boolean;
  readonly anchorEl: null | HTMLElement;
  readonly formattedMembers: TListOption[];
  readonly formattedVariables: TListOption[];
  readonly maximumFormHeight: string | number;
  readonly selectedAction?: IAutomationAction;
  readonly errors: FieldErrors<ISendEmailFormField>;
  readonly control: Control<ISendEmailFormField>;
  readonly selectedEmails: string | string[];
  readonly selectedCC: string | string[];
  readonly selectedBCC: string | string[];
  readonly onChangeCC: (emails: string | string[]) => void;
  readonly onChangeBCC: (emails: string | string[]) => void;
  readonly onChangeEmails: (emails: string | string[]) => void;
  readonly register: UseFormRegister<ISendEmailFormField>;
  readonly onChangeCCorBcc: (value: boolean) => void;
  readonly onCloseAction: () => void;
  readonly onCloseAnchorEl: () => void;
  readonly onOpenVariables: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
  readonly onSelectVariable: (value: string) => void;
  readonly onSubmit: (data: ISendEmailFormField) => void;
  readonly handleSubmit: UseFormHandleSubmit<ISendEmailFormField>;
}

export interface IChangeApplicationStatusComponent {
  readonly t: TFunction;
  readonly statuses: TListOption[];
  readonly selectedStatus?: string;
  readonly onCloseAction: () => void;
  readonly onChangeStatus: (value: string) => void;
  readonly onSave: () => void;
}

export interface IApplicationCreatedTriggerComponent {
  readonly t: TFunction;
  readonly jobs: TListOption[];
  readonly currentAutomation: ICurrentAutomation;
  readonly isLoading: boolean;
  readonly selectedJobs: string | string[];
  readonly control: Control<IApplicationCreatedTriggerFormFields>;
  readonly errors: FieldErrors<IApplicationCreatedTriggerFormFields>;
  readonly onChangeJobs: (value: string | string[]) => void;
  readonly onSubmit: (data: IApplicationCreatedTriggerFormFields) => void;
  readonly handleSubmit: UseFormHandleSubmit<IApplicationCreatedTriggerFormFields>;
}

export interface IArchiveJobComponent {
  readonly t: TFunction;
  readonly jobs: TListOption[];
  readonly selectedAction?: IAutomationAction;
  readonly errors: FieldErrors<IArchiveJobFormField>;
  readonly control: Control<IArchiveJobFormField>;
  readonly onCloseAction: () => void;
  readonly onSubmit: (data: IArchiveJobFormField) => void;
  readonly handleSubmit: UseFormHandleSubmit<IArchiveJobFormField>;
}

export interface IApplicationStatusUpdatedTriggerComponent {
  readonly t: TFunction;
  readonly statusFrom: string;
  readonly statusTo: string;
  readonly currentAutomation: ICurrentAutomation;
  readonly statuses: IAutomationApplicationStatusUpdatedActionStatuses;
  readonly onChangeStatus: (value: string, type: string) => void;
  readonly onSave: () => void;
}

export interface IAutomationCondition {
  readonly condition: string;
  readonly param: string;
  readonly value: string;
}

export interface IAutomationApplicationStatusUpdatedActionStatuses {
  readonly toStatuses: TListOption[];
  readonly fromStatuses: TListOption[];
}

export interface IApplicationCreatedTriggerFormFields {
  jobs: string[];
}

export enum TRIGGERS_TYPE {
  APPLICATION_CREATED = "application_created",
  APPLICATION_UPDATED = "application_updated",
  APPLICATION_STATUS_UPDATED = "application_status_updated",
  JOB_CREATED = "job_created",
  JOB_UPDATED = "job_updated",
  DELETE_JOB = "delete_job",
  JOB_DELETED = "job_deleted",
  JOB_ACTIVATED = "job_activated",
  ARCHIVE_JOB = "archive_job",
  JOB_ARCHIVED = "job_archived",
  JOB_DRAFT_DUPLICATED = "job_draft_duplicated",
  JOB_DRAFT_SAVED = "job_draft_saved",
  JOB_PUBLISHED = "job_published",
  MEMBER_INVITED = "member_invited",
  CREATE_NOTE = "create_not",
  NOTE_CREATED = "note_created",
  NOTE_MEMBER_TAGGED = "note_member_tagged",
  TALENTPOOL_APPLICATION_ADDED = "talentpool_application_added",
  TALENTPOOL_APPLICATION_DELETED = "talentpool_application_deleted",
  TALENTPOOL_CREATED = "talentpool_created",
  TALENTPOOL_UPDATED = "talentpool_updated",
  TALENTPOOL_RECORED_ADDED = "talentpool_record_added",
  TALENTPOOL_RECORD_DELETED = "talentpool_record_deleted",
  WORKFLOW_CREATED = "workflow_created",
  WORKFLOW_UPDATED = "workflow_updated",
  WORKFLOW_DELETED = "workflow_deleted",
}

export enum TRIGGERS_GROUP_TYPE {
  APPLICATION = "Application",
}

export enum ACTION_TYPE {
  CHANGE_APPLICATION_STATUS = "change_application_status",
  TRANSITION_APPLICATION_TO = "transition_application_to",
  SEND_MESSAGE = "send_message",
  SEND_MAIL = "send_mail",
  CREATE_NOTE = "create_note",
  ARCHIVE_JOB = "archive_job",
  DELETE_JOB = "delete_job",
  ADD_ACTION_CONTAINER = "add_action_container",
}

export enum ACTION_TRIGGER_VARIANT {
  TRIGGER = "trigger",
  COMPONENT = "component",
  EMPTY_COMPONENT = "emptyComponent",
  ACTIONS_LIST = "actionList",
  TRIGGERS_LIST = "triggersList",
  ADD_ACTION_CONTAINER = "addActionContainer",
}
