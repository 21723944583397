import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AdditionalQuestionsSkillsComponent from "./component";
import { getJobPreviewState } from "../../../../store_new/selectors/JobPreview";
import { createFilterOptions } from "@mui/material";
import {
  IAdditionalQuestionSimpleSection,
  IAdditionalQuestionsSkillsOnAddPayload,
  IAdditionalQuestionsSkillsOnRemovePayload,
} from "../../../../models/JobPreview";

const AdditionalQuestionsSkills = ({
  control,
  desktop,
  companyColor,
  boldHeading,
}: IAdditionalQuestionSimpleSection) => {
  const { t } = useTranslation();
  const {
    jobData: { skills },
  } = useSelector(getJobPreviewState);

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    matchFrom: "start",
    limit: 10,
  });

  const handleOnAdd = ({
    selectedSkills,
    newSkill,
    onChange,
  }: IAdditionalQuestionsSkillsOnAddPayload) => {
    if (
      newSkill &&
      selectedSkills.findIndex((skill) => skill.id === newSkill.id) === -1
    ) {
      onChange([...selectedSkills, newSkill]);
    }
  };
  const handleOnRemove = ({
    selectedSkills,
    skillToRemove,
    onChange,
  }: IAdditionalQuestionsSkillsOnRemovePayload) => {
    onChange(selectedSkills.filter((skill) => skill.id !== skillToRemove.id));
  };

  return (
    <AdditionalQuestionsSkillsComponent
      t={t}
      control={control}
      boldHeading={boldHeading}
      desktop={desktop}
      companyColor={companyColor}
      filterOptions={filterOptions}
      skills={skills}
      onAdd={handleOnAdd}
      onRemove={handleOnRemove}
    />
  );
};

export default AdditionalQuestionsSkills;
