import { call, put, takeLatest } from "redux-saga/effects";
import {
  apiCandidateLogin,
  apiLogIn,
  apiLogInAsCustomer,
  apiRecoveryPassword,
  apiResetPassword,
} from "../../api/Auth";
import { TLoginPayload, TNewPasswordFormFields } from "../../models/Auth";
import { PayloadActionWithCallback } from "../../models/common";
import {
  candidateLogIn,
  candidateLogInFailed,
  candidateLogInSuccess,
  logIn,
  logInAsCustomer,
  logInFailed,
  logInSuccess,
  logOut,
  recoveryPassword,
  recoveryPasswordFailed,
  recoveryPasswordSuccess,
  resetPassword,
  resetPasswordFailed,
  resetPasswordSuccess,
} from "../reducers/Auth";
import { apiUserLogout } from "../../api/Users";
import { fetchUser, resetCurrentUser } from "../reducers/CurrentUser";
import { resetShopProductsAndPackages } from "../reducers/SingleJob";

function* handleOnLogIn({ payload }: { payload: TLoginPayload }) {
  const { username, password } = payload;
  const url = `/login`;
  const apiPayload = {
    username,
    password,
  };

  try {
    const { data } = yield call(apiLogIn, { url, apiPayload });
    localStorage.setItem("token", data.token);
    yield put(logInSuccess());
    yield put(fetchUser());
  } catch (e) {
    yield put(logInFailed(e)); // TODO handle error
  }
}

function* handleOnLogInAsCustomer({
  payload,
}: PayloadActionWithCallback<{ customerId: string | number }>) {
  try {
    const { customerId, callback } = payload;
    const { data } = yield call(apiLogInAsCustomer, { customerId });
    const { agency_token, customer_token } = data;
    localStorage.setItem("token", customer_token);
    localStorage.setItem("agencyToken", agency_token);
    yield put(logInSuccess());
    yield put(fetchUser());
    if (callback) yield call(callback);
  } catch (e) {
    yield put(logInFailed(e)); // TODO handle error
  }
}

function* handleOnCandidateLogin({
  payload,
}: PayloadActionWithCallback<{ code: string }>) {
  const { callback, code } = payload;
  const url = `/candidate/login/${code}`;
  try {
    const { data } = yield call(apiCandidateLogin, {
      url,
    });
    localStorage.setItem("token", data.token);
    yield put(candidateLogInSuccess());
    yield put(fetchUser());
    yield call(callback);
  } catch (e) {
    yield put(candidateLogInFailed(e)); // TODO handle error
  }
}

function* handleOnLogout({ payload }: PayloadActionWithCallback<undefined>) {
  try {
    const agencyToken = localStorage.getItem("agencyToken");
    if (agencyToken) {
      const { callback } = payload;
      localStorage.setItem("token", agencyToken);
      localStorage.removeItem("agencyToken");
      yield put(logInSuccess());
      yield put(fetchUser());
      if (callback) yield call(callback);
    } else {
      yield call(apiUserLogout);
      yield put(resetCurrentUser());
      localStorage.removeItem("token");
      yield put(resetShopProductsAndPackages());
    }
  } catch (_error) {
    //do nothing
  }
}

function* handleOnResetPassword({
  payload,
}: PayloadActionWithCallback<{ email: string }>) {
  const { email, callback } = payload;
  const url = `/password/reset`;

  try {
    yield call(apiResetPassword, { url, apiPayload: { email } });
    yield call(callback);
    yield put(resetPasswordSuccess());
  } catch (e) {
    yield put(resetPasswordFailed(e)); // TODO handle error
  }
}

function* handleOnRecoveryPassword({
  payload,
}: PayloadActionWithCallback<TNewPasswordFormFields & { code: string }>) {
  const { code, password, confirmPassword: confirm, callback } = payload;
  const url = `/password/recovery/${code}`;

  try {
    yield call(apiRecoveryPassword, { url, apiPayload: { password, confirm } });
    yield call(callback);
    yield put(recoveryPasswordSuccess());
  } catch (e) {
    yield put(recoveryPasswordFailed(e)); // TODO handle error
  }
}

function* ApplicationsSaga() {
  yield takeLatest(logIn, handleOnLogIn);
  yield takeLatest(logInAsCustomer, handleOnLogInAsCustomer);
  yield takeLatest(logOut, handleOnLogout);
  yield takeLatest(resetPassword, handleOnResetPassword);
  yield takeLatest(recoveryPassword, handleOnRecoveryPassword);
  yield takeLatest(candidateLogIn, handleOnCandidateLogin);
}

export default ApplicationsSaga;
