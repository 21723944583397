import { Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledBigColorPickerContainer = styled(Stack)({
  margin: "8px 0",
  cursor: "pointer",
  height: 96,
  borderRadius: 12,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  border: "1.5px dashed #E5E7EB",
  "&:hover": {
    border: "1px solid #178CF2",
  },
});
