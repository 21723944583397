import React from "react";
import {
  Popover,
  Typography,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import { IUserMenuComponent } from "../../models/Messages";

const UserMenu = ({
  t,
  anchorEl,
  currentUser,
  handleReadUnread,
  handleCloseUserMenu,
}: IUserMenuComponent) => {
  const open = Boolean(anchorEl);
  const id = open ? "messagess-user-popover" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl as HTMLDivElement}
      onClose={handleCloseUserMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        "& .MuiPaper-root": {
          boxShadow:
            "0px 2px 4px -2px rgba(16, 24, 40, 0.10), 0px 4px 6px -1px rgba(16, 24, 40, 0.10)",
          border: "1px solid #E5E7EB",
          borderRadius: "6px",
        },
        "& .MuiList-root": {
          cursor: "pointer",
        },
        "& .MuiListItem-root": {
          height: 40,
        },
      }}
    >
      <List disablePadding>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              handleReadUnread(
                currentUser,
                currentUser?.is_new_messages == 0 ? true : false
              );
              handleCloseUserMenu();
            }}
          >
            <Typography
              variant="subtitle2"
              color="textSecondary"
              sx={{
                cursor: "pointer",
              }}
            >
              {currentUser?.is_new_messages == 0
                ? t("messages_page.mark_as_unread")
                : t("messages_page.mark_as_read")}
            </Typography>
          </ListItemButton>
        </ListItem>
      </List>
    </Popover>
  );
};

export default UserMenu;
