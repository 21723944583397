import React from "react";
import { Typography, Stack, AlertTitle, AlertColor } from "@mui/material";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ActionContainer from "./ActionContainer";
import { StyledAlert } from "./style";
import { ISnackbarComponent } from "../../models/Snackbar";

const SnackbarComponent = ({
  desktop,
  isOpen,
  notification,
  onTurnOffNotifications,
  onCloseSnackbar,
}: ISnackbarComponent) => (
  <Snackbar
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    key={notification?.uuid}
    open={isOpen}
    autoHideDuration={notification?.visibilityTime as number}
    onClose={(e: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) =>
      onCloseSnackbar(notification.uuid as string, e, reason)
    }
    sx={{
      maxWidth: "90vw",
      width: "max-content",
      margin: "auto",
      ".MuiAlert-action": {
        width: desktop ? "auto" : "100% !important",
        margin: desktop ? "auto" : "auto 0px auto 0px !important",
        padding: desktop
          ? "0px 0px 0px 16px"
          : notification?.action
          ? "16px !important"
          : "0px",
      },
      ".MuiAlert-message": {
        padding: notification?.action
          ? "0px !important"
          : desktop
          ? "0px 0px 2px 16px"
          : "0px 0px 8px 16px",
      },
    }}
  >
    <StyledAlert
      desktop={desktop}
      icon={desktop ? notification?.icon ?? false : false}
      action={
        <ActionContainer
          desktop={desktop}
          type={notification?.type}
          action={notification?.action}
          firstButtonText={notification?.firstButtonText as string}
          onClose={onTurnOffNotifications}
          onFirstButtonClick={notification?.onFirstButtonClick}
        />
      }
      onClose={(
        e: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason
      ) => onCloseSnackbar(notification.uuid as string, e, reason)}
      severity={notification?.type as AlertColor}
      variant="filled"
    >
      {notification?.title && (
        <Stack
          position="relative"
          width="100%"
          direction={desktop ? "column" : "row"}
          justifyContent="center"
          alignItems={notification.firstButtonText ? "flex-start" : "center"}
          height={desktop ? 80 : "auto"}
          pt={1}
          spacing={1}
        >
          <AlertTitle
            sx={{
              pl: !notification.action
                ? 0
                : !desktop &&
                  (notification.action ||
                    !notification.action?.includes("without"))
                ? notification?.action?.includes("without")
                  ? 2.5
                  : 2
                : 0,
              fontWeight: 400,
              pb: desktop ? 0 : 0.25,
              maxWidth: desktop ? "auto" : "90% !important",
            }}
          >
            {notification?.title}
          </AlertTitle>
          {!desktop &&
            (notification?.action ||
              !notification?.action?.includes("without")) && (
              <XMarkIcon
                width={20}
                height={20}
                onClick={(
                  e: React.SyntheticEvent | Event,
                  reason?: SnackbarCloseReason
                ) => onCloseSnackbar(notification.uuid as string, e, reason)}
                cursor="pointer"
                style={{ marginBottom: 7.5, marginRight: 12 }}
              />
            )}
        </Stack>
      )}
      {typeof notification?.message === "string" ? (
        <Typography
          variant="subtitle2"
          sx={{
            wordWrap: "break-word",
          }}
        >
          {notification?.message}
        </Typography>
      ) : (
        notification?.message?.map((msg: string, index: number) => (
          <Typography key={index} variant="subtitle2" display="block">
            {msg}
          </Typography>
        ))
      )}
    </StyledAlert>
  </Snackbar>
);

export default SnackbarComponent;
