import { Box, Stack } from "@mui/material";
import { styled } from "@mui/system";
import { TListOption } from "../../models/common";
import { generateBackgroundColor } from "../../utils";

export const StyledColorPickerContainer = styled(Stack)({
  height: 40,
  width: "100%",
  "& .workflow-color-textfield": {
    "& input": {
      position: "relative",
      right: 7,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      boxShadow: "0px 0px 0px 4px #C6E5FF",
    },
  },
});

export const StyledColorBlockContainer = styled(Stack)(
  ({ customTextValue }: { customTextValue?: string }) => ({
    alignItems: "center",
    padding: customTextValue ? "0px 0px 0px 8px" : 8,
    borderRadius: 6,
    border: "1px solid #E5E7EB",
    h6: { lineHeight: "24px" },
  })
);

export const StyledColorBlock = styled(Box)(
  ({
    bgColor,
    isEditMode,
    customTextValue,
    options,
    value,
  }: {
    bgColor?: string;
    isEditMode?: boolean;
    customTextValue?: string;
    options?: TListOption[];
    value?: string;
  }) => ({
    width: 24,
    height: 24,
    border: customTextValue ? "none" : "1px solid #E5E7EB",
    borderRadius: 6,
    background: bgColor ?? generateBackgroundColor(options, value),
    ...(isEditMode && { cursor: "pointer" }),
  })
);

export const StyledPickerContainer = styled(Stack)({
  position: "fixed",
  zIndex: 2,
});
