import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DIALOG_TYPE } from "../../../models/common";
import { ITeamsNoResultOverlay } from "../../../models/Team";
import { setQuickSearch } from "../../../store_new/reducers/Team";
import { getTeamsFiltersState } from "../../../store_new/selectors/Team";
import TeamSettingsNoResultComponent from "./component";

const TeamSettingsNoResultOverlay = ({
  t,
  onOpenDialog,
}: ITeamsNoResultOverlay) => {
  const dispatch = useDispatch();
  const { quickSearch } = useSelector(getTeamsFiltersState);

  const hasFilters = useMemo(() => Boolean(quickSearch), [quickSearch]);

  const actionText = useMemo(
    () =>
      t(
        `${
          hasFilters
            ? "joblist.reset_filters_button"
            : "team_members.invite_button"
        }`
      ),
    [hasFilters]
  );
  const titleText = useMemo(
    () =>
      t(
        `${
          hasFilters
            ? "joblist.empty_first_text_filters_exists"
            : "team_members.invite_text"
        }`
      ),
    [hasFilters]
  );

  const handleOnOpenInviteDialog = () => {
    onOpenDialog({ type: DIALOG_TYPE.INVITE });
  };

  const handleOnResetFilters = () => {
    dispatch(setQuickSearch(""));
  };

  return (
    <TeamSettingsNoResultComponent
      actionText={actionText}
      titleText={titleText}
      hasFilters={hasFilters}
      onResetFilters={handleOnResetFilters}
      onOpenDialog={handleOnOpenInviteDialog}
    />
  );
};

export default TeamSettingsNoResultOverlay;
