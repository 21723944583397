import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { Box } from "@mui/material";
import React from "react";
import { TApplicationDocument } from "../../../models/Applications";
import { StyledCVContainer } from "../styles";

const CVCell = ({
  cv,
  onDownloadCV,
}: {
  cv: TApplicationDocument;
  onDownloadCV?: (data: string) => void;
}) => {
  const linkAsArray = cv?.link?.split("_");
  const cvName = linkAsArray ? linkAsArray[linkAsArray.length - 1] : undefined;
  return cv && cvName ? (
    <StyledCVContainer>
      <DocumentTextIcon
        className={"cv-icon"}
        onClick={() => onDownloadCV && onDownloadCV(cv.link)}
      />
      <Box
        onClick={() => onDownloadCV && onDownloadCV(cv.link)}
        className={"cv-file-name"}
      >
        {cvName}
      </Box>{" "}
    </StyledCVContainer>
  ) : (
    <Box>-</Box>
  );
};

export default CVCell;
