import React from "react";
import { embedVideoLink } from "../../../utils";

const Video = ({ url }: { readonly url: string }) => {
  const formatedUrl = embedVideoLink(url);

  return (
    <iframe
      width="100%"
      height="350"
      src={formatedUrl}
      title="Video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    ></iframe>
  );
};

export default Video;
