import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import AutocompleteFilter from "../../../components/filters_new/AutocompleteFilter";
import SelectorFilter from "../../../components/filters_new/SelectorFilter";
import AuthLayout from "../../../layouts/Auth";
import { ICompanyRegistrationComponent } from "../../../models/Registration";
import { getEmployeeId } from "../../../utils/companySettings";

const RegisterCompanyComponent = ({
  t,
  control,
  errors,
  register,
  isLoading,
  employees,
  industries,
  desktop,
  handleSubmit,
  onSubmit,
  onSignIn,
}: ICompanyRegistrationComponent) => (
  <AuthLayout desktop={desktop}>
    <Typography variant="body1" lineHeight={2.5}>
      {t("register.register_as_a_company")}
    </Typography>
    <Typography variant={"subtitle2"} color={"textSecondary"}>
      {t("register.set_your_company_profile")}
    </Typography>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mt={3.5} mb={2.5}>
        <InputLabel>{t("register.company_name")}</InputLabel>
        <TextField
          {...register("company")}
          variant={"outlined"}
          size={"small"}
          fullWidth
          placeholder={t("register.placeholder_company_name") as string}
          error={Boolean(errors.company)}
          helperText={errors.company?.message}
        />
      </Box>
      <Box mt={2.5} mb={2}>
        <InputLabel>{t("register.company_website")}</InputLabel>
        <TextField
          {...register("website")}
          variant={"outlined"}
          size={"small"}
          fullWidth
          placeholder={t("register.placeholder_company_website") as string}
          error={Boolean(errors.website)}
          helperText={errors.website?.message}
        />
      </Box>
      <Box mt={2.5} mb={errors["industry_id"] ? 5 : 2}>
        <InputLabel>{t("register.industry")}</InputLabel>
        <Controller
          name={"industry_id"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <AutocompleteFilter
              onChange={onChange}
              value={value}
              options={industries}
              placeholder={t("register.select_industry")}
              errors={errors["industry_id"]}
            />
          )}
        />
      </Box>
      <Box mt={2.5} mb={2.5}>
        <InputLabel>
          {t("company_settings.number_of_employees") as string}
        </InputLabel>
        <Controller
          name={"employees_id"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <SelectorFilter
              isDisabled={false}
              value={getEmployeeId({ employees, value })}
              onChange={onChange}
              label={"test"}
              placeholder={t("register.select_number_of_employees") as string}
              error={errors.employees_id?.message}
              options={employees}
              className={"input-selector-filter"}
            />
          )}
        />
      </Box>
      <Box>
        <Button
          color={"primary"}
          variant={"contained"}
          size={"small"}
          type={"submit"}
          disableElevation
          fullWidth
          endIcon={
            isLoading ? (
              <CircularProgress
                size={16}
                style={{
                  color: "#9A9FAF",
                }}
              />
            ) : null
          }
          disabled={isLoading}
        >
          {t("register.continue")}
        </Button>
      </Box>
      <Typography variant="subtitle2" align="center" color="textSecondary">
        {t("register.alredy_have_account")}
        <Link underline="none" display="inline" onClick={onSignIn}>
          {t("login.sign_in")}
        </Link>
      </Typography>
    </form>
  </AuthLayout>
);

export default RegisterCompanyComponent;
