import React, { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import WorkflowComponent from "./component";
import {
  setCurrentWorkflowId,
  setIsLimitDialogOpen,
  setIsWorkflowDialogOpen,
} from "../../store_new/reducers/dialogs";
import { getWorkflowState } from "../../store_new/selectors/Workflow";
import {
  deleteWorkflow,
  fetchWorkflows,
  setCurrentWorkflowName,
  setCurrentWorkflowStatuses,
  setEditWorkflow,
  setIsDefaultWorkflow,
} from "../../store_new/reducers/Workflow";
import { getCompanySettingsCompanyState } from "../../store_new/selectors/Company";
import { fetchCompanyData } from "../../store_new/reducers/CompanySettings";
import { IWorkflow } from "../../models/Workflow";
import { getCurrentUserIsCandidate } from "../../store_new/selectors/CurrentUser";
import { addMessage } from "../../store_new/reducers/Snackbar";

const WorkflowPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [activeWorkflowId, setActiveWorkflowId] = useState<null | number>(null);

  const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const { company } = useSelector(getCompanySettingsCompanyState);
  const { workflows, isListLoading, editWorkflow } =
    useSelector(getWorkflowState);
  const isCandidate = useSelector(getCurrentUserIsCandidate);

  const isWorkflowsLoading = isListLoading || isCandidate === undefined;

  const defaultWorkflows = [
    {
      id: 0.1,
      defaultWorkflow: true,
      title: t("workflow.defaultWorkflow"),
      is_default: 1,
      created_at: "2024-08-09 15:05:56",
      updated_at: "2024-08-12 13:32:44",
      statuses: [
        {
          id: "0",
          label: t("status.new"),
          value: "new",
          color: "#818CF8",
          defaultStatus: true,
        },
        {
          id: "1",
          label: t("status.in_progress"),
          value: "review",
          color: "#F59E0B",
          defaultStatus: true,
        },
        {
          id: "2",
          label: t("status.hired"),
          value: "hired",
          color: "#22C55E",
          defaultStatus: true,
        },
        {
          id: "3",
          label: t("status.rejected"),
          value: "rejected",
          color: "#EF4444",
          defaultStatus: true,
        },
      ],
    },
  ];

  const formattedWorkflows =
    workflows.length > 0 ? workflows : defaultWorkflows;

  const handleCloseWorkflowMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenCreateWorkflowDialog = () => {
    handleCloseWorkflowMenu();
    if (editWorkflow) {
      dispatch(
        setIsDefaultWorkflow(editWorkflow.id == company?.default_workflow_id)
      );
      dispatch(setCurrentWorkflowName(editWorkflow.title));
      dispatch(setCurrentWorkflowId(editWorkflow.id));
      dispatch(setIsWorkflowDialogOpen(true));
    } else if (company?.workflows_creation_allowed) {
      dispatch(setCurrentWorkflowName(""));
      dispatch(setIsDefaultWorkflow(true));
      dispatch(setIsWorkflowDialogOpen(true));
    } else {
      dispatch(setIsLimitDialogOpen(true));
    }
  };

  const handleSetActiveWorkflowId = (id: number | null) => {
    setActiveWorkflowId(id);
  };

  const handleOpenWorkflowMenu = (event: MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
    if (editWorkflow) {
      dispatch(setCurrentWorkflowStatuses(editWorkflow.statuses));
    }
  };

  const onSuccessDeleteWorkflow = () => {
    dispatch(
      addMessage({
        title: t("workflow.workflowDeletedSnackbar"),
        variant: "success",
      })
    );
    dispatch(fetchWorkflows(defaultWorkflows));
    dispatch(fetchCompanyData());
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteWorkflow = () => {
    dispatch(
      deleteWorkflow({
        id: editWorkflow?.id as unknown as number,
        callback: () => onSuccessDeleteWorkflow(),
      })
    );
  };

  const handleOpenCloseDeleteDialog = (isOpen: boolean) => {
    setIsDeleteDialogOpen(isOpen);
    handleCloseWorkflowMenu();
  };

  const handleSetEditWorkflow = (workflow: IWorkflow | null) =>
    dispatch(setEditWorkflow(workflow));

  return (
    <WorkflowComponent
      t={t}
      activeWorkflowId={activeWorkflowId}
      isListLoading={isWorkflowsLoading}
      workflows={formattedWorkflows}
      anchorEl={anchorEl}
      isDeleteDialogOpen={isDeleteDialogOpen}
      editWorkflow={editWorkflow}
      onOpenCloseDeleteDialog={handleOpenCloseDeleteDialog}
      onDeleteWorkflow={handleDeleteWorkflow}
      onSetEditWorkflow={handleSetEditWorkflow}
      onOpenCreateWorkFlowDialog={handleOpenCreateWorkflowDialog}
      onSetActiveWorkflowId={handleSetActiveWorkflowId}
      onOpenWorkflowMenu={handleOpenWorkflowMenu}
      onCloseWorkflowMenu={handleCloseWorkflowMenu}
    />
  );
};
export default WorkflowPage;
