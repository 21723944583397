import { apiRequest } from "./config";
import {
  ITalentPool,
  ITalentPoolsCandidateFormFields,
} from "../models/TalentPools";

export const apiFetchTalentPools = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiAddTalentPool = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: ITalentPool;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiDeleteTalentPool = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiFetchTalentPoolCandidates = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: any;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiAddCandidateInTalentPool = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: {
    application_id: string | number;
  };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiDeleteCandidateFromTalentPool = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: {
    application_id?: string | number;
    recordId?: string;
  };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiAddCandidateToTalentPoolManually = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: ITalentPoolsCandidateFormFields & {
    talentpool_id: string;
  };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiCandidateChangeTalentPool = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: {
    application_id: string | number;
  };
}) => apiRequest({ type: "post", url, apiPayload });
