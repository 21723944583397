import React from "react";
import { Stack, Typography } from "@mui/material";
import { BriefcaseIcon } from "@heroicons/react/24/outline";
import { TFunction } from "i18next";

export default function NoJobs({
  t,
  companyColor
}: {
  readonly t: TFunction;
  readonly companyColor?: string;
}) {
  return (
    <Stack height={210} justifyContent="center" alignItems="center">
      <Stack height={104} justifyContent="center" alignItems="center">
        <BriefcaseIcon width={32} height={32} stroke={companyColor} />
        <Typography variant="h6" paddingTop={1}>
          {t("company_page.no_available_jobs")}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {t("company_page.no_positions")}
        </Typography>{" "}
      </Stack>
    </Stack>
  );
}
