import { Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledCustomerDetailContainer = styled(Stack)({
  marginTop: 30,
  padding: 24,
  background: "#fff",
  border: "1px solid #E5E7EB",
  borderRadius: 6,
  ".MuiInputBase-root": {
    background: "#F3F4F6 !important",
  },
});
