import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { INotification, ISnackbarState } from "../../models/Snackbar";

const initialState: ISnackbarState = {
  notifications: [],
  isPasswordNotificationShowed: false,
};

const snackbarReducers = {
  addMessage: (
    state: Draft<ISnackbarState>,
    action: PayloadAction<INotification>
  ) => {
    const notification = {
      uuid: uuidv4(),
      type: action.payload.type,
      title: action.payload.title || "",
      message: action.payload.message || "",
      visibilityTime:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        action.payload.visibilityTime === "allTime"
          ? null
          : action.payload.visibilityTime || 4000,
      source: action.payload.source,
      icon: action.payload.icon,
      action: action.payload.action,
      firstButtonText: action.payload.firstButtonText,
      onFirstButtonClick: action.payload.onFirstButtonClick,
    };
    state.notifications.push(notification as INotification);
  },
  deleteMessage: (
    state: Draft<ISnackbarState>,
    action: PayloadAction<string>
  ) => {
    const index = state.notifications.findIndex(
      (value) => value.uuid === action.payload
    );
    if (index === -1) {
      return;
    }

    state.notifications.splice(index, 1);
  },
  turnOffNotifications: (state: Draft<ISnackbarState>) => {
    state.notifications = initialState.notifications;
  },
  setPasswordNotificationShowed: (state: Draft<ISnackbarState>) => {
    state.isPasswordNotificationShowed = true;
  },
  setNotifications: (state: Draft<ISnackbarState>) => {
    const lastNotifications =
      state.notifications[state.notifications.length - 1];
    state.notifications = [lastNotifications];
  },
  setCreatePasswordSnackbar: (
    state: Draft<ISnackbarState>,
    action: PayloadAction<INotification>
  ) => {
    const notifications = [...state.notifications];
    notifications[0] = {
      ...notifications[0],
      firstButtonText: action.payload.firstButtonText,
      title: action.payload.title,
    };
    state.notifications = notifications;
  },
};

export const SnackbarSlice = createSlice({
  name: "SNACKBAR",
  initialState,
  reducers: {
    ...snackbarReducers,
  },
});

export const {
  addMessage,
  deleteMessage,
  turnOffNotifications,
  setPasswordNotificationShowed,
  setCreatePasswordSnackbar,
  setNotifications,
} = SnackbarSlice.actions;

export default SnackbarSlice.reducer;
