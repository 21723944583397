import React from "react";
import { EyeIcon } from "@heroicons/react/24/outline";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { Controller } from "react-hook-form";
import ColorPicker from "../../../components/ColorPicker";
import AutocompleteFilter from "../../../components/filters_new/AutocompleteFilter";
import ProfilePicture from "../../../components/ProfilePicture_new";
import { TListOption } from "../../../models/common";
import { ICompanySettingsCareerPage } from "../../../models/CompanySettings";
import { getEmployeeId } from "../../../utils/companySettings";
import {
  StyledSectionContainer,
  StyledSelect,
  StyledSwitchContainer,
  StyledTextArea
} from "../styles";

const colorPickerStyle = {
  bottom: "5%",
  marginLeft: -240
};

const CareerPage = ({
  t,
  control,
  employees,
  errors,
  industries,
  logo,
  register,
  onPreview,
  onDeleteLogo,
  onOpenUploadDialog
}: ICompanySettingsCareerPage) => (
  <Box>
    <Typography variant={"body2"}>
      {t("company_settings.career_page")}
    </Typography>
    <StyledSectionContainer spacing={1.5}>
      <Stack direction={"row"} spacing={3}>
        <ProfilePicture
          type="square"
          imgSrc={logo}
          onDelete={onDeleteLogo}
          onOpenUploadDialog={onOpenUploadDialog}
        />
        <Stack sx={{ flex: 1 }} spacing={1}>
          <Stack direction={"row"} spacing={3} sx={{ flex: 1 }} mb={1}>
            <Stack sx={{ flex: 2 }} direction={"row"} spacing={3}>
              <Box sx={{ flex: 1 }} mb={errors["website"] ? 1 : 0}>
                <InputLabel>
                  {t("company_settings.link_to_website") as string}
                </InputLabel>
                <TextField
                  fullWidth
                  {...register("website")}
                  error={Boolean(errors["website"])}
                  helperText={errors["website"]?.message}
                  placeholder={"company.com"}
                  InputProps={{
                    startAdornment: (
                      <Typography variant="subtitle2">https://</Typography>
                    )
                  }}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <InputLabel>
                  {t("company_settings.number_of_employees") as string}
                </InputLabel>
                <Controller
                  name={"employees"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <StyledSelect
                      disabled={false}
                      fullWidth
                      value={getEmployeeId({ employees, value })}
                      displayEmpty
                      variant="outlined"
                      onChange={onChange}
                    >
                      {employees.map((employee: TListOption) => (
                        <MenuItem key={employee.value} value={employee.value}>
                          {employee.label}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  )}
                />
              </Box>
            </Stack>
            <Box sx={{ flex: 1 }}>
              <InputLabel>{t("company_settings.industry")}</InputLabel>
              <Controller
                name={"industry_id"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutocompleteFilter
                    onChange={onChange}
                    value={value}
                    options={industries}
                    placeholder={t("company_settings.industry")}
                    errors={errors["industry_id"]}
                  />
                )}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <InputLabel>{t("company_settings.company_color")}</InputLabel>
              <Controller
                name={"company_color"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ColorPicker
                    title={t("company_settings.color")}
                    color={value}
                    onChange={onChange}
                    customStyle={colorPickerStyle}
                  />
                )}
              />
            </Box>
          </Stack>
          <Stack direction={"row"} spacing={3} sx={{ flex: 1 }}>
            <Controller
              name={"is_page_active"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <StyledSwitchContainer direction={"row"}>
                  <p>
                    {value
                      ? t("company_settings.switch_text_on")
                      : t("company_settings.switch_text_off")}
                  </p>
                  <Box sx={{ padding: 1.25 }}>
                    <Switch
                      checked={Boolean(value)}
                      color="secondary"
                      onChange={(event, checked) => {
                        onChange(checked ? 1 : 0);
                      }}
                    />
                  </Box>
                </StyledSwitchContainer>
              )}
            />
            <Stack sx={{ flex: 1 }}>
              <Button
                variant={"outlined"}
                startIcon={<EyeIcon width={24} height={24} />}
                onClick={onPreview}
              >
                {t("company_settings.preview_company_page")}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <StyledTextArea
        {...register("about_us")}
        rows={4}
        placeholder={t("company_settings.about_us") as string}
      />
    </StyledSectionContainer>
  </Box>
);

export default CareerPage;
