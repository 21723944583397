import React from "react";
import { TFunction } from "i18next";
import { Stack, Typography, Button } from "@mui/material";
import noCustomersImage from "../../assets/no_jobs.png";

const NoCustomers = ({
  t,
  jassId,
  onOpenDialog,
  onOpenImportCustomersDialog,
}: {
  readonly t: TFunction;
  readonly jassId?: string | number;
  readonly onOpenDialog: () => void;
  readonly onOpenImportCustomersDialog: () => void;
}) => (
  <Stack height="78vh" alignItems="center" justifyContent="center">
    <img src={noCustomersImage} alt="no-customer" />
    <Stack mt={4} spacing={7}>
      <Stack spacing={0.5} alignItems="center">
        <Typography fontWeight={500}>
          {t("customers.no_customers_title")}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t("customers.no_customers_subtitle_one")}
        </Typography>
        {jassId && (
          <Typography variant="body2" color="textSecondary">
            {t("customers.no_customers_subtitle_two")}
          </Typography>
        )}
      </Stack>
      <Stack direction="row" spacing={3} justifyContent="center">
        {jassId && (
          <Button variant="outlined" onClick={onOpenImportCustomersDialog}>
            {t("customers.no_customers_sync")}
          </Button>
        )}
        <Button variant="contained" onClick={onOpenDialog}>
          {t("customers.no_customers_invite")}
        </Button>
      </Stack>
    </Stack>
  </Stack>
);

export default NoCustomers;
