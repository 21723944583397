import React from "react";
import { Typography } from "@mui/material";
import {
  StyledMarketingMainInfoContainer,
  StyledMarketingPageButton,
  //  StyledMarketingPageTextField,
} from "./style";
import { IMarketingMainInfoComponent } from "../../models/Marketing";

const MarketingMainInfo = ({
  desktop,
  isError,
  // onChangeJobUrl,
  onGenerateJobData,
}: IMarketingMainInfoComponent) => (
  <StyledMarketingMainInfoContainer>
    <Typography fontWeight={500} fontSize={desktop ? 42 : 30} color="#fff">
      Willkommen bei{" "}
      <Typography
        display="inline"
        fontWeight={500}
        fontSize={desktop ? 42 : 30}
        color="#6366F1"
      >
        Karriera.
      </Typography>
      <Typography fontWeight={500} fontSize={desktop ? 42 : 30} color="#fff">
        Buche dein Job Paket zum Sparpreis!
      </Typography>
    </Typography>
    <Typography color="#fff" fontSize={14} fontWeight={500} textAlign="justify">
      Karriera ist eine innovative Recruiting-Plattform, die Unternehmen bei der
      schnellen und zielgerichteten Besetzung von Stellen unterstützt. Dank
      effizienter Funktionen zur Stellenanzeigenerstellung, flexibler
      Recruiting-Workflows und einer benutzerfreundlichen Bewerbungsmaske für
      Kandidaten ist Karriera besonders attraktiv für Unternehmen, die
      unkompliziert und schnell Top-Kandidaten erreichen möchten.
    </Typography>
    {/*     <Stack spacing={isError ? 2 : 3}>
      <Stack width="100%" spacing={1} alignItems="flex-start">
        <StyledMarketingPageTextField
          onChange={(e) => onChangeJobUrl(e.target.value)}
          placeholder="Bitte gib einen Jobtitel an oder füge einen Link zu deiner Stellenanzeige ein"
          fullWidth
        />
        {isError && (
          <Typography variant="subtitle2" color="#fff" fontWeight={500}>
            Feld ist erforderlich
          </Typography>
        )}
      </Stack>
    </Stack> */}
    <StyledMarketingPageButton onClick={onGenerateJobData} disabled={isError}>
      Stellenanzeige erstellen
    </StyledMarketingPageButton>
  </StyledMarketingMainInfoContainer>
);

export default MarketingMainInfo;
