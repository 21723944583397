import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ResetPasswordComponent from "./component";
import { resetPassword } from "../../../store_new/reducers/Auth";
import { getAuthLoginState } from "../../../store_new/selectors/Auth";

const ResetPassword_new = ({ desktop }: { readonly desktop: boolean }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { error, isLoading } = useSelector(getAuthLoginState);

  const formDefaultValues: { email: string } = { email: "" };
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .email(t("valid_email_message") as string)
          .required(t("register.message") as string),
      })
    ),
    defaultValues: formDefaultValues,
  });

  useEffect(() => {
    if (error) {
      setError("email", {
        type: "manual",
        message: t("reset_password_page.email_not_exist") as string,
      });
    }
  }, [error]);

  const onSubmit = ({ email }: { email: string }) => {
    dispatch(
      resetPassword({
        email,
        callback: () => navigate("/forgot-password/resend"),
      })
    );
  };

  const handleOnReturn = () => {
    navigate("/");
  };

  return (
    <ResetPasswordComponent
      t={t}
      register={register}
      errors={errors}
      isLoading={isLoading}
      desktop={desktop}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      onReturn={handleOnReturn}
    />
  );
};

export default ResetPassword_new;
