import React from "react";
import { Stack } from "@mui/material";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import { getSelectedCustomer } from "../../../store_new/selectors/Customers";
import { setMobileDrawerOpen } from "../../../store_new/reducers/Navigation";
import { StyledHeaderTitle } from "../styles";

const CustomerDetailsHeader = ({ desktop }: { readonly desktop: boolean }) => {
  const selectedCustomer = useSelector(getSelectedCustomer);
  const dispatch = useDispatch();
  return (
    <Stack direction="row" spacing={1.5}>
      {!desktop && (
        <Bars3Icon
          className="menu-icon"
          onClick={() => dispatch(setMobileDrawerOpen(true))}
        />
      )}

      <StyledHeaderTitle desktop={desktop} alignItems="flex-start !important">
        {selectedCustomer?.name}
      </StyledHeaderTitle>
    </Stack>
  );
};

export default CustomerDetailsHeader;
