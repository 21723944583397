import React from "react";
import {
  CellClickedEvent,
  ColDef,
  ICellRendererParams,
  ValueGetterParams,
} from "ag-grid-community";
import ActionsCell from "../cellRenderers/actions";
import ApplicantsNumber from "../cellRenderers/applicationsNumber";
import iconAndText from "../cellRenderers/iconAndText";
import { TDataGridGetColDef } from "../../../models/DataGrid";
import { generateCandidateDate } from "../../../utils";

const getTalentPoolsColDef = ({
  t,
  actions,
}: Pick<
  TDataGridGetColDef,
  "t" | "actions" | "onSelect" | "type"
>): ColDef[] => [
  {
    field: "name",
    headerName: t("applications.name") as string,
    flex: 1,
    cellClass: "ag-grid-cell-clickable",
    cellRenderer: (params: ICellRendererParams) =>
      iconAndText({
        text: params.data.title,
        variant: "dot",
        iconColor: params.data.color,
        src: params.data.color,
      }),
    onCellClicked: (params: CellClickedEvent) => {
      if (actions.onClick) actions.onClick(params.data);
    },
  },
  {
    field: "description",
    headerName: t("talentPools.description") as string,
    flex: 1.5,
    cellStyle: {
      whiteSpace: "normal",
      wordWrap: "break-word",
      lineHeight: "20px",
    },
  },
  {
    field: "date",
    headerName: t("talentPools.created") as string,
    flex: 0.7,
    valueGetter: (params: ValueGetterParams) =>
      generateCandidateDate(params.data["date"]) || "-",
  },
  {
    field: "profiles",
    headerName: t("talentPools.profiles") as string,
    flex: 0.6,
    cellRenderer: (params: ValueGetterParams) => (
      <ApplicantsNumber
        onClick={() => actions.onClick && actions.onClick(params.data)}
        applicantsNumber={params.data.applicationsCount}
      />
    ),
  },
  {
    field: "actions",
    headerName: "",
    sortable: false,
    flex: 0.3,
    cellStyle: { justifyContent: "center" },
    cellRenderer: (params: ICellRendererParams) =>
      ActionsCell({
        t,
        actions: {
          onEdit: () => actions.onEdit && actions.onEdit(params.data),
          onRemove: () => actions.onRemove && actions.onRemove(params.data),
        },
        type: params.data.status,
      }),
  },
];

export default getTalentPoolsColDef;
