import { Box, Stack, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { StyledAdditionalQuestionsFieldContainer } from "../styles";
import React from "react";
import { IAdditionalQuestionSimpleSection } from "../../../models/JobPreview";
import { TFunction } from "i18next";

const AdditionalQuestionsProfessionalStatus = ({
  t,
  desktop,
  control
}: IAdditionalQuestionSimpleSection & { readonly t: TFunction }) => (
  <StyledAdditionalQuestionsFieldContainer desktop={desktop}>
    <Stack className={"title"}>
      <Typography variant="subtitle2">
        {t("additional_questions.current_profession")}
      </Typography>
    </Stack>
    <Controller
      name={"professionalStatus"}
      control={control}
      render={({ field }) => (
        <Box className={"input-field"}>
          <TextField
            placeholder={
              t("additional_questions.type_something_placeholder") as string
            }
            fullWidth
            {...field}
          />
        </Box>
      )}
    />
  </StyledAdditionalQuestionsFieldContainer>
);

export default AdditionalQuestionsProfessionalStatus;
