import { Button, Typography } from "@mui/material";
import React from "react";
import { ITeamsNoResultComponent } from "../../../models/Team";
import { StyledNoResultContainer } from "../styles";
import NoJobsImg from "../../../assets/no_jobs.png";

const TeamSettingsNoResultComponent = ({
  actionText,
  titleText,
  hasFilters,
  onResetFilters,
  onOpenDialog
}: ITeamsNoResultComponent) => (
  <StyledNoResultContainer data-testid={"teams-settings-empty-list-wrapper"}>
    <img src={NoJobsImg} alt="no-jobs" />
    <Typography variant="body1" paddingTop={3} paddingBottom={3}>
      {titleText}
    </Typography>
    <Button
      variant="contained"
      onClick={hasFilters ? onResetFilters : onOpenDialog}
    >
      {actionText}
    </Button>
  </StyledNoResultContainer>
);

export default TeamSettingsNoResultComponent;
