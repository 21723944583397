import React from "react";
import { TFunction } from "i18next";
import { useSelector } from "react-redux";
import { getJobEditorState } from "../../../store_new/selectors/JobEditor";
import JobEditorStepperComponent from "./component";

const JobEditorStepper = ({ t }: { t: TFunction }) => {
  const { currentStep, completedSteps } = useSelector(getJobEditorState);

  return (
    <JobEditorStepperComponent
      t={t}
      currentStep={currentStep}
      completedSteps={completedSteps}
    />
  );
};

export default JobEditorStepper;
