import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import SendMessageComponent from "./component";
import {
  IAutomationAction,
  ISelectedActionComponent,
  ISendMessageFormField,
} from "../../../../models/Automation";
import {
  getSendMessageFormFields,
  sendMessageFormFieldsDefaultValue,
} from "./config";
import { getAutomationState } from "../../../../store_new/selectors/Automation";
import {
  setActions,
  setSelectedAction,
} from "../../../../store_new/reducers/Automation";
import { TListOption } from "../../../../models/common";

const SendMessage = ({
  t,
  selectedAction,
  onCloseAction,
}: ISelectedActionComponent) => {
  const dispatch = useDispatch();

  const { currentAutomation } = useSelector(getAutomationState);
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    defaultValues: sendMessageFormFieldsDefaultValue,
    resolver: yupResolver(getSendMessageFormFields(t)),
  });

  const applications = [
    {
      value: "{candidate_email}",
      label: t("automation.variables.candidateMail"),
    },
  ];

  const formattedVariables = useMemo(() => {
    if (currentAutomation.trigger) {
      const variables = Object.entries(currentAutomation.trigger.params).map(
        ([key]) => ({
          value: key,
          label: t(`automation.variables.${key}`),
        })
      );
      return variables;
    }

    return [];
  }, [currentAutomation.trigger?.params]);

  const filteredVariables = formattedVariables.filter(
    (variable) => variable.value !== "jobId"
  );

  const onSubmit = (data: ISendMessageFormField) => {
    const formattedData = {
      message: data.message.replace(/<p>|<\/p>|<br>|<\/br>/g, ""),
      application_id: "{application_id}",
    };
    const updatedActions = currentAutomation.actions.map(
      (action: IAutomationAction) => {
        if (action.id === selectedAction?.id) {
          return {
            ...selectedAction,
            actionCompleted: true,
            params: formattedData,
          };
        }
        return action;
      }
    );
    dispatch(setActions(updatedActions as IAutomationAction[]));
    dispatch(setSelectedAction(undefined));
  };

  useEffect(() => {
    if (currentAutomation?.id || selectedAction?.actionCompleted) {
      setValue("to", "{candidate_email}");
    }

    if (selectedAction?.params.message !== "string") {
      setValue("message", selectedAction?.params.message as string);
    }
  }, [selectedAction]);

  return (
    <SendMessageComponent
      t={t}
      applications={applications as TListOption[]}
      formattedVariables={filteredVariables}
      errors={errors}
      control={control}
      onCloseAction={onCloseAction}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
    />
  );
};

export default SendMessage;
