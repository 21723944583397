import { styled } from "@mui/system";
import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";

export const StyledDashboardWrapper = styled(Stack)({
  paddingTop: 24,
  gap: 24,
  position: "relative",
});

export const StyledDashboardRow = styled(Stack)({
  flexDirection: "row",
  flex: 1,
  gap: 24,
});

export const StyledDashboardItemContainer = styled(Stack)({
  position: "relative",
  background: "#fff",
  borderRadius: "6px",
  border: "1px solid #E5E7EB",
  padding: 24,
  height: "100%",
  width: "100%",
  ".full-height": {
    height: "100%",
  },
});

export const StyledFirstStepsContainer = styled(Stack)({
  gap: 12,
  overflowY: "auto",
  maxHeight: "100%",
  "&::-webkit-scrollbar ": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#E5E7EB",
    borderRadius: "6px",
  },
});

export const StyledDashboardItemTitle = styled(Typography)({
  fontSize: 18,
  color: "#131E30",
  fontWeight: 500,
  lineHeight: "18px",
});

export const StyledDashboardSecondaryText = styled(Typography)({
  fontSize: 14,
  color: "#6B7280",
  fontWeight: 400,
  lineHeight: "20px",
  "&.title-row": {
    marginLeft: "auto",
    ".link": {
      textDecoration: "none",
      color: "#178CF2",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
  "&.support-footer": {
    marginTop: "auto!important",
  },
});

// First steps box
export const StyledProgressItemWrapper = styled(Stack)({
  flexDirection: "row",
  gap: 24,
  height: 34,
});

export const StyledProgressItemContainer = styled(Stack)({
  alignItems: "flex-start",
  width: "calc(100% - 32px - 24px)",
  gap: 8,
});

export const StyledProgressItemBlock = styled(Box)({
  width: "100%",
});

export const StyledProgressBar = styled(LinearProgress)({
  height: 6,
  borderRadius: 6,
  flex: 11,
  backgroundColor: "#F3F4F6",
  ".MuiLinearProgress-bar": {
    background: "#22C55E",
    borderRadius: 6,
  },
});

export const StyledSuccessIcon = styled(Stack)({
  width: 32,
  height: 32,
  alignItems: "center",
  justifyContent: "center",
  alignSelf: "flex-end",
  borderRadius: "50%",
  background: "#F0FDF4",
  color: "#22C55E",
  svg: { width: 20, height: 20 },
});

export const StyledProgressButton = styled(IconButton)({
  width: 32,
  height: 32,
  alignItems: "center",
  justifyContent: "center",
  alignSelf: "flex-end",
  borderRadius: "50%",
  background: "#EEF2FF",
  color: "#818CF8",
  svg: { width: 20, height: 20 },
});

// Support box
export const StyledSupportWrapper = styled(Stack)({
  alignItems: "center",
  height: "100%",
  overflowY: "auto",
  gap: 24,
  "&::-webkit-scrollbar ": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#E5E7EB",
    borderRadius: "6px",
  },
  img: { width: 225, height: 150 },
});

export const StyledSubtitleText = styled(Typography)({
  ...StyledDashboardSecondaryText,
  marginTop: "auto!important",
});

// Jobs box
export const StyledJobListContainer = styled(Stack)({
  gap: 8,
  overflowY: "auto",
  "&::-webkit-scrollbar ": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#E5E7EB",
    borderRadius: "6px",
  },
});

export const StyledJobListItemContainer = styled(Stack)({
  padding: 12,
  gap: 12,
  background: "#F9FAFB",
  borderRadius: 6,
  svg: { width: 20, height: 20, strokeWidth: 1, stroke: "#6B7280" },
});

export const StyledJobTitle = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "24px",
  textAlign: "left",
  color: "#131E30",
  cursor: "pointer",
  ":hover": {
    textDecoration: "underline",
  },
});

export const StyledJobListItemDescriptionContainer = styled(Stack)({
  flexDirection: "row",
  flexWrap: "wrap",
  gap: 8,
});

export const StyledJobListItemIconContainer = styled(Stack)({
  flexDirection: "row",
  gap: 8,
  "&.link": {
    cursor: "pointer",
    color: "#178CF2",
    svg: { stroke: "#178CF2" },
    ":hover": {
      textDecoration: "underline",
    },
  },
});

export const StyledJobsEmptyListContainer = styled(Stack)({
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  gap: 12,
  ".icon-container": {
    borderRadius: "50%",
    width: 40,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    background: "#EEF2FF",
  },
  svg: { width: 24, height: 24, stroke: "#818CF8" },
  button: { width: "fit-content" },
});
