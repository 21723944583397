import React from "react";
import { Stack, Typography } from "@mui/material";
import { IHeaderComponent } from "../../models/Kanban";

const Header = ({ title, color, length }: IHeaderComponent) => (
  <Stack
    position="sticky"
    top={0}
    width="100%"
    height={36}
    padding={1}
    justifyContent="center"
    borderBottom={`2px solid ${color}`}
    boxShadow="0px 1px 2px 0px #1018280D"
    zIndex={1}
    sx={{
      background: "#fff",
    }}
  >
    <Typography variant="subtitle2" fontWeight={500} color="#131E30">
      {title} ({length})
    </Typography>
  </Stack>
);

export default Header;
