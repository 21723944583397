import { apiRequest } from "./config";

const baseUrl = "/company/analytics";

export const apiFetchAnalyticsJobList = () =>
  apiRequest({
    type: "post",
    url: `/company/jobs/list/`
  });

export const apiFetchAnalyticsNumberOfApplications = ({
  jobUrlKey,
  apiPayload
}: {
  jobUrlKey: string;
  apiPayload: { since: string; until: string };
}) =>
  apiRequest({
    type: "post",
    url: `${baseUrl}/applications/${jobUrlKey}`,
    apiPayload
  });
