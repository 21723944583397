import { TApiAddUpdateWorkflow } from "../models/Workflow";
import { apiRequest } from "./config";

export const apiFetchWorkflows = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: {
    pagesize: number;
  };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiAddUpdateWorkflow = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: TApiAddUpdateWorkflow;
}) => apiRequest({ type: "post-json", url, apiPayload });

export const apiDeleteWorkflow = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiResetWorkflow = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });
