import { Grid, Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledGridContainer = styled(Grid)({
  height: "100vh",
  ".phone-input": {
    width: "100%!important",
    border: "1px solid #E5E7EB!important",
    height: 40,
    "&.error": {
      color: "#FCA5A5!important",
      border: "1px solid #FCA5A5!important",
      boxShadow: "0 0 5px 2px #FEE2E2!important"
    }
  },
  ".disabled-link": {
    pointerEvents: "none",
    color: "grey !important"
  }
});

export const StyledAuthLayoutFormContainer = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

export const StyledAuthLayoutFormContent = styled(Stack)({
  width: "55%",
  height: "100%",
  justifyContent: "center",
  img: {
    position: "relative",
    right: 5,
    width: 127,
    height: 48
  },
  button: { marginBottom: 20 },
  ".MuiTypography-subtitle2": {
    lineHeight: "121.9%"
  },
  ".MuiFormLabel-root": {
    lineHeight: "122%",
    color: "#131E30",
    margin: "2px 0"
  },
  ".MuiLink-root": {
    cursor: "pointer"
  },
  ".MuiFormControl-root": { width: "100%" },
  ".input-selector-filter": {
    width: "100%",
    textDecoration: "none",
    textTransform: "none",
    "[data-testid=CloseIcon]": {
      cursor: "pointer",
      width: 18,
      height: 18,
      marginRight: 8
    },
    "& .MuiInputBase-input": {
      lineHeight: "40px",
      height: "40px!important"
    }
  }
});
