import React, { useEffect } from "react";
import { TFunction } from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { fetchBookings } from "../../../store_new/reducers/SingleJob";
import {
  getSingleJobBookingsState,
  getSingleJobState,
} from "../../../store_new/selectors/SingleJob";
import Bookings_NewComponent from "./component";

const Bookings_new = ({ t }: { t: TFunction }) => {
  const dispatch = useDispatch();

  const orders = useSelector(getSingleJobBookingsState);
  const { isLoading, jobDetails } = useSelector(getSingleJobState);

  const loading = isLoading || !jobDetails;

  useEffect(() => {
    if (jobDetails?.url_key) {
      dispatch(
        fetchBookings({
          jobUrlKey: jobDetails?.url_key as string,
        })
      );
    }
  }, [jobDetails]);

  return <Bookings_NewComponent t={t} orders={orders} isLoading={loading} />;
};

export default Bookings_new;
