import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Stack, Typography } from "@mui/material";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import ColorPicker from "../../components/ColorPicker";
import {
  ISingleWorkflowComponent,
  IWorkflowStatus,
} from "../../models/Workflow";

const Workflow = ({
  workflow,
  activeWorkflowId,
  onSetEditWorkflow,
  onSetActiveWorkflowId,
  onOpenWorkflowMenu,
}: ISingleWorkflowComponent) => (
  <motion.div layout transition={{ duration: 0.5 }}>
    <Stack
      padding="8px 24px"
      border="1px solid #E5E7EB"
      borderRadius={1.5}
      component={motion.div}
      onMouseOver={() => onSetEditWorkflow(workflow)}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="subtitle2" fontWeight={500}>
            {workflow.title}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1.5} alignSelf="center" width={60}>
          {activeWorkflowId === workflow.id ? (
            <ChevronUpIcon
              width={24}
              height={24}
              stroke="#131E30"
              cursor="pointer"
              onClick={() => onSetActiveWorkflowId(null)}
            />
          ) : (
            <ChevronDownIcon
              width={24}
              height={24}
              stroke="#131E30"
              cursor="pointer"
              onClick={() => onSetActiveWorkflowId(workflow.id ?? null)}
            />
          )}

          <EllipsisVerticalIcon
            width={24}
            height={24}
            stroke="#131E30"
            cursor="pointer"
            onClick={(e) => onOpenWorkflowMenu(e, workflow.id)}
          />
        </Stack>
      </Stack>

      <AnimatePresence>
        {activeWorkflowId == workflow.id && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Stack mt={1.5} spacing={1.5} pb={1.5}>
              {workflow?.statuses?.map(
                (status: IWorkflowStatus, index: number) => (
                  <Stack
                    key={index}
                    sx={{
                      background: "#F9FAFB",
                      "& h6": {
                        color: "#9CA3AF !important",
                      },
                    }}
                  >
                    <ColorPicker
                      title={status.label}
                      color={status.color}
                      onChange={() => {}}
                      customStyle={{}}
                      hideColorText
                      isDefaultColor
                    />
                  </Stack>
                )
              )}
            </Stack>
          </motion.div>
        )}
      </AnimatePresence>
    </Stack>
  </motion.div>
);

export default Workflow;
