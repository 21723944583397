import React from "react";
import { Radio } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getTalentPoolState } from "../../../store_new/selectors/Talentpool";
import { setSelectedJob } from "../../../store_new/reducers/TalentPools";
import { TJobListItem } from "../../../models/JobList";

const RadioButtonSelect = ({ data }: { readonly data: TJobListItem }) => {
  const dispatch = useDispatch();
  const { selectedJob } = useSelector(getTalentPoolState);

  return (
    <Radio
      value={selectedJob?.id}
      checked={selectedJob?.id === data.id}
      onClick={() => dispatch(setSelectedJob(data))}
    />
  );
};

export default RadioButtonSelect;
