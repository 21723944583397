import React from "react";
import { Stack, Typography } from "@mui/material";
import { TFunction } from "i18next";
import { TSelectedCustomer } from "../../models/Agency";
import ConnectedAgencyHeader from "../../components/ConnectedAgencyHeader";

const Header = ({
  t,
  selectedCustomer,
  openDisconnectDialog,
}: {
  t: TFunction;
  selectedCustomer: TSelectedCustomer;
  openDisconnectDialog: () => void;
}) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center">
    <Typography variant="body2" fontWeight={500} color="#000">
      {t("create_job_second_step.general_information")}
    </Typography>
    <ConnectedAgencyHeader
      t={t}
      agencyName={selectedCustomer?.agency_name}
      connectionDate={selectedCustomer?.connection_date}
      openDisconnectDialog={openDisconnectDialog}
    />
  </Stack>
);

export default Header;
