import React from "react";
import { Stack, Button } from "@mui/material";
import { ITalentPoolsToolbar } from "../../models/TalentPools";
// import SimpleFilter from "../../components/filters_new/SimpleFilter";

const TalentPoolToolbar = ({
  t,
  // quickSearch,
  // onChangeFilters,
  talentPools,
  onOpenCreateTalentPoolDialog,
}: ITalentPoolsToolbar) => (
  <Stack direction="row" alignItems="center" justifyContent="flex-end">
    {/*     <Stack width="32%" maxWidth={352}> 
      <SimpleFilter
        value={quickSearch}
        onChange={(value) =>
          onChangeFilters({ value, filterType: "quickSearch" })
        }
        placeholder={t("applications.search_placeholder")}
      />
    </Stack> */}
    {!!talentPools?.length && (
      <Button
        data-testid="create-talent-pool-button"
        variant="contained"
        onClick={() => onOpenCreateTalentPoolDialog(null)}
      >
        {t("talentPools.addTalentPool")}
      </Button>
    )}
  </Stack>
);
export default TalentPoolToolbar;
