import React from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, FieldArrayWithId } from "react-hook-form";
import { TListOption } from "../../../../models/common";
import { StyledAdditionalQuestionsFieldContainer } from "../../styles";
import { IAdditionalQuestionsQuestionsComponent } from "../../../../models/JobPreview";

const AdditionalQuestionsQuestionsComponent = ({
  t,
  control,
  desktop,
  fields,
  questions,
  companyColor,
  boldHeading,
}: IAdditionalQuestionsQuestionsComponent) => (
  <>
    {fields?.map((field: FieldArrayWithId<TListOption>, index: number) => (
      <StyledAdditionalQuestionsFieldContainer
        borderBottom={boldHeading ? "0px !important" : "1px solid #E5E7EB"}
        py={
          boldHeading
            ? fields[index - 1]
              ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                fields[index - 1].is_answer_type_yesno == 1
                ? "2px !important"
                : "12px !important"
              : "0px !important"
            : "24px !important"
        }
        key={field.id}
        desktop={desktop}
        companyColor={companyColor}
      >
        <Stack
          className={"title"}
          height={boldHeading ? "auto !important" : "30px !important"}
        >
          <Typography
            sx={{ height: "100%" }}
            variant="subtitle2"
            fontWeight={boldHeading ? 500 : 400}
          >
            {questions[index].question}
          </Typography>
        </Stack>
        <Stack
          className={"input-field"}
          spacing={2}
          sx={{
            alignItems: boldHeading ? "flex-start" : "flex-end",
          }}
        >
          <Controller
            name={`questions.${index}`}
            control={control}
            render={({ field: { onChange, value } }) =>
              questions[index].is_answer_type_yesno ? (
                <FormControl>
                  <RadioGroup
                    row
                    value={value.answer}
                    defaultValue={"Yes"}
                    onChange={(_, newValue) => {
                      onChange({ ...value, answer: newValue });
                    }}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label={
                        <Typography variant="body2" color="textSecondary">
                          {t("yes")}
                        </Typography>
                      }
                      color="#818CF8"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label={
                        <Typography variant="body2" color="textSecondary">
                          {t("no")}
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              ) : (
                <TextField
                  {...field}
                  value={value.answer}
                  placeholder={
                    t(
                      "additional_questions.type_something_placeholder"
                    ) as string
                  }
                  onChange={(event) => {
                    onChange({ ...value, answer: event.target.value });
                  }}
                  fullWidth
                />
              )
            }
          />
        </Stack>
      </StyledAdditionalQuestionsFieldContainer>
    ))}
  </>
);

export default AdditionalQuestionsQuestionsComponent;
