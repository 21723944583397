export default {
  white: "#FFFFFF",

  50: "#F5F5F5",

  100: "#CCCCCC",

  200: "#B2B2B2",

  300: "#909090",

  400: "#7F7F7F",

  500: "#666666",

  600: "#4C4C4C",

  700: "#2C2C2C",

  800: "#1D1D21",

  black: "#0F141B",
};
