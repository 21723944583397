import React, { MouseEvent, useMemo, useState } from "react";
import { EllipsisVerticalIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Box, Menu, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import {
  IDataGridActionCell,
  IDataGridActionCellRenderButtons,
  TDataGridActions,
} from "../../../models/DataGrid";
import { JOB_STATUSES } from "../../../models/JobList";
import {
  StyledActionButton,
  StyledActionLoadingButton,
  StyledDeleteMenuItem,
  StyledIconButton,
} from "../styles";
import { getJobListIsDraftImagesLoading } from "../../../store_new/selectors/JobList";
import { getCurrentUserData } from "../../../store_new/selectors/CurrentUser";

const renderActionButtons = ({
  t,
  actions,
  type,
  connectType,
  hasMultipleActions,
  isDraftImagesLoading,
  continueEditingButtonWidth,
  isAgency,
  onClick,
}: IDataGridActionCellRenderButtons) => (
  <Box>
    {actions && actions.onDuplicate && (
      <MenuItem
        data-testid={"duplicate-button"}
        onClick={() => onClick(actions.onDuplicate as () => void)}
      >
        {t("joblist.duplicate")}
      </MenuItem>
    )}
    {actions && actions.onEdit && type !== "draft" && (
      <MenuItem
        data-testid={"edit-button"}
        onClick={() => onClick(actions.onEdit as () => void)}
      >
        {t("single_job.edit")}
      </MenuItem>
    )}
    {actions && actions.onAddCandidateToActiveJob && (
      <MenuItem
        onClick={() => onClick(actions.onAddCandidateToActiveJob as () => void)}
      >
        {t("talentPools.addToActiveJob")}
      </MenuItem>
    )}
    {actions && actions.onPreview && type !== "draft" && (
      <MenuItem
        data-testid={"preview-button"}
        onClick={() => onClick(actions.onPreview as () => void)}
      >
        {t("create_job_first_step.preview")}
      </MenuItem>
    )}
    {actions && actions.onArchiveRestore && type !== "draft" && (
      <Box>
        {hasMultipleActions ? (
          <MenuItem
            data-testid={"archive-restore-button"}
            onClick={() => onClick(actions.onArchiveRestore as () => void)}
          >
            {type === JOB_STATUSES.ACTIVE
              ? t("joblist.archive")
              : t("joblist.activate")}
          </MenuItem>
        ) : (
          <StyledActionButton
            onClick={() => onClick(actions.onArchiveRestore as () => void)}
          >
            {t("joblist.activate")}
          </StyledActionButton>
        )}
      </Box>
    )}
    {actions && actions.onDownloadCV && (
      <MenuItem onClick={() => onClick(actions.onDownloadCV as () => void)}>
        {t("applications.download_cv")}
      </MenuItem>
    )}
    {actions && actions.onInvite && (
      <MenuItem
        data-testid={"invite-button"}
        onClick={() => onClick(actions.onInvite as () => void)}
      >
        {t("team_members.invite_team")}
      </MenuItem>
    )}
    {actions && actions.onExport && type !== "draft" && (
      <MenuItem
        data-testid={"export-button"}
        onClick={() => onClick(actions.onExport as () => void)}
      >
        {t("joblist.export")}
      </MenuItem>
    )}
    {actions && actions.onSetWorkflow && !isAgency && (
      <MenuItem
        data-testid={"set-workflow-button"}
        onClick={() => onClick(actions.onSetWorkflow as () => void)}
      >
        {t("workflow.setWorkflow")}
      </MenuItem>
    )}
    {actions && actions.onPromote && (
      <StyledActionButton
        data-testid={"promote-button"}
        onClick={() => onClick(actions.onPromote as () => void)}
      >
        {t("joblist.promote")}
      </StyledActionButton>
    )}
    {actions && actions.onContinueEditing && (
      <StyledActionLoadingButton
        loadingPosition="end"
        loading={isDraftImagesLoading}
        continueEditingButtonWidth={continueEditingButtonWidth}
        onClick={() => onClick(actions.onContinueEditing as () => void)}
      >
        {t("joblist.continue_editing")}
      </StyledActionLoadingButton>
    )}
    {actions && actions.onReInviteTeamMember && (
      <StyledActionButton
        onClick={() => onClick(actions.onReInviteTeamMember as () => void)}
      >
        {t("team_members.resend_invite")}
      </StyledActionButton>
    )}
    {actions && actions.onConnect && (
      <StyledActionButton
        onClick={() => onClick(actions.onConnect as () => void)}
      >
        {t("basic_job_board.company_profile_connect", { title: connectType })}
      </StyledActionButton>
    )}
    {actions && actions.onLogInAsCustomer && (
      <StyledActionButton
        onClick={() => onClick(actions.onLogInAsCustomer as () => void)}
      >
        {t("logInAsCustomer")}
      </StyledActionButton>
    )}
    {actions && actions.onUpload && (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <StyledActionButton component={"label"}>
        <input
          hidden
          type="file"
          accept="image/*"
          onChange={(e) =>
            onClick(
              () => actions.onUpload && actions.onUpload(e.target.files?.[0])
            )
          }
        />
        {t("basic_job_board.upload_header_image")}
      </StyledActionButton>
    )}
    {actions && actions.onRemove && (
      <StyledDeleteMenuItem
        onClick={() => onClick(actions.onRemove as () => void)}
      >
        {t("talentPools.delete")}
      </StyledDeleteMenuItem>
    )}
    {actions &&
      actions.onDelete &&
      (hasMultipleActions ? (
        <StyledDeleteMenuItem
          data-testid={"delete-button"}
          onClick={() => onClick(actions.onDelete as () => void)}
        >
          {t("talentPools.delete")}
        </StyledDeleteMenuItem>
      ) : (
        <StyledIconButton
          data-testid={"delete-icon-button"}
          onClick={() => onClick(actions.onDelete as () => void)}
        >
          <XMarkIcon />
        </StyledIconButton>
      ))}
  </Box>
);

const ActionsCell = ({
  t,
  actions,
  type,
  connectType,
  forceMultiple,
}: IDataGridActionCell) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const isDraftImagesLoading = useSelector(getJobListIsDraftImagesLoading);
  const { lang, isAgency } = useSelector(getCurrentUserData);

  const continueEditingButtonWidth = lang === "en_US" ? 170 : 205;

  const hasMultipleActions = useMemo(
    () =>
      Object.keys(actions).filter(
        (action: string) => actions[action as keyof TDataGridActions]
      )?.length > 1 || forceMultiple,
    [actions]
  );
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnClickMenuItem = (action: () => void) => {
    if (action) action();
    handleClose();
  };

  return hasMultipleActions ? (
    <Box>
      <StyledIconButton
        data-testid={"data-grid-action-menu-button"}
        id="actions-button"
        aria-controls={open ? "actions-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <EllipsisVerticalIcon />
      </StyledIconButton>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "actions-button",
        }}
      >
        {renderActionButtons({
          t,
          actions,
          type,
          isAgency,
          hasMultipleActions,
          connectType,
          isDraftImagesLoading,
          continueEditingButtonWidth,
          onClick: handleOnClickMenuItem,
        })}
      </Menu>
    </Box>
  ) : (
    <Box>
      {renderActionButtons({
        t,
        actions,
        type,
        hasMultipleActions,
        connectType,
        isDraftImagesLoading,
        continueEditingButtonWidth,
        isAgency,
        onClick: handleOnClickMenuItem,
      })}
    </Box>
  );
};

export default ActionsCell;
