import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  fetchAllAnalytics,
  fetchAllAnalyticsFailed,
  fetchAllAnalyticsSuccess,
  fetchAnalyticsJobList,
  fetchAnalyticsJobListFailed,
  fetchAnalyticsJobListSuccess,
  fetchAnalyticsNumberOfApplications,
  fetchAnalyticsNumberOfApplicationsFailed,
  fetchAnalyticsNumberOfApplicationsSuccess,
} from "../reducers/Analytics";
import {
  apiFetchAnalyticsJobList,
  apiFetchAnalyticsNumberOfApplications,
} from "../../api/Analytics";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  getAnalyticsInitialJobList,
  getAnalyticsSelectedJob,
} from "../selectors/Analytics";
import { TListOption } from "../../models/common";
import moment from "moment/moment";
import { apiFetchWorkflows } from "../../api/Workflows";

function* handleOnFetchAnalyticsJobList() {
  try {
    const { data } = yield call(apiFetchAnalyticsJobList);

    yield put(fetchAnalyticsJobListSuccess(data.jobs));
  } catch (e) {
    yield put(fetchAnalyticsJobListFailed(e)); // TODO define type of error and handle error
  }
}

function* handleOnFetchAnalyticsNumberOfApplications({
  payload,
}: PayloadAction<{ since: string; until: string }>) {
  const selectedJob: null | TListOption = yield select(getAnalyticsSelectedJob);

  try {
    const { data } = yield call(apiFetchAnalyticsNumberOfApplications, {
      jobUrlKey: selectedJob?.value || "",
      apiPayload: payload,
    });
    yield put(fetchAnalyticsNumberOfApplicationsSuccess(data));
  } catch (e) {
    yield put(fetchAnalyticsNumberOfApplicationsFailed(e)); // TODO handle error
  }
}

function* handleOnFetchAllAnalytics() {
  const defaultWorkflowOrder = {
    new: 0,
    review: 1,
    hired: 2,
    rejected: 3,
  };
  try {
    const jobs: any[] = yield select(getAnalyticsInitialJobList);

    const { data } = yield call(apiFetchAnalyticsNumberOfApplications, {
      jobUrlKey: "",
      apiPayload: { since: "", until: "" },
    });
    const apiPayload = {
      pagesize: -1,
    };
    const { data: workflowData } = yield call(apiFetchWorkflows, {
      url: "/company/workflows",
      apiPayload,
    });
    const { workflows } = workflowData;
    const workFlowOrder = workflows.reduce((acc: any[], item: any) => {
      if (item.statuses) {
        const { colors } = JSON.parse(JSON.parse(item.statuses));
        const orderEnum = colors.reduce(
          (acc: any, cItem: any) => ({ ...acc, [cItem.value]: +cItem.id }),
          {},
        );
        return [...acc, { id: item.id, orderEnum }];
      }
    }, []);

    const creationDates: string[] = [];
    data.byDay.forEach(
      ({ date, quantity }: { date: string; quantity: number }) => {
        for (let i = 0; i < quantity; i++) creationDates.push(date);
      },
    );

    const getNextStatus = ({
      details,
      workflow_id,
    }: {
      details: string | null;
      workflow_id?: number;
    }) => {
      const currentWorkflowOrder =
        workFlowOrder.find((wf: { id: number }) => wf.id === workflow_id)
          ?.orderEnum || defaultWorkflowOrder;
      if (details)
        if (JSON.parse(details).new_status)
          return JSON.parse(details).new_status;

      return Object.keys(currentWorkflowOrder).find(
        (val) => currentWorkflowOrder[val] === 0,
      );
    };

    const uniqueApps = data.status
      .reduce((acc: any, item: any) => {
        const { workflow_id } = jobs.find((job) => job.id === item.job_id);
        const existingItemIndex = acc.findIndex(
          (accItem: { application_id: number }) =>
            accItem.application_id === item.application_id,
        );
        if (existingItemIndex > -1) {
          acc[existingItemIndex] = {
            ...acc[existingItemIndex],
            details: [
              ...acc[existingItemIndex].details,
              {
                status: item.details
                  ? getNextStatus({ details: item.details, workflow_id })
                  : "",
                timestamp: item.application_timestamp,
              },
            ],
          };
          return acc;
        }
        return [
          ...acc,
          {
            ...item,
            details: [
              {
                status: getNextStatus({ details: item.details, workflow_id }),
                timestamp: item.application_timestamp,
              },
            ],
          },
        ];
      }, [])
      .sort(
        (a: { application_id: number }, b: { application_id: number }) =>
          a.application_id - b.application_id,
      )
      .map((item: any, index: number) => ({
        ...item,
        createdAt: creationDates[index],
      }));

    const uniqueAppsWithStatuses = uniqueApps.map((app: any) => {
      const statuses: string[] = Array.from(
        new Set(app.details.map((detail: any) => detail.status)),
      );
      const statusesMap = new Map();
      if (app.details.length <= 1) {
        const diff = moment().diff(moment(app.createdAt), "days");
        statusesMap.set(app.details[0].status, diff);
      } else {
        let currentStatus = app.details[0].status;
        let lastTimestamp = app.details[0].timestamp;

        for (let i = 1; i <= app.details.length; i++) {
          const currentDetail = app.details[i] || {
            status: null,
            timestamp: moment(),
          };
          const diff =
            moment(currentDetail.timestamp).diff(
              moment(lastTimestamp),
              "days",
            ) + 1;
          statusesMap.set(
            currentStatus,
            (statusesMap.get(currentStatus) || 0) + diff,
          );
          if (
            currentDetail.status !== currentStatus ||
            i === app.details.length
          ) {
            currentStatus = currentDetail.status;
            lastTimestamp = currentDetail.timestamp;
          }
        }
      }

      const currentWorkflowOrder =
        workFlowOrder.find((wf: { id: number }) => wf.id === app.job_id)
          ?.orderEnum || defaultWorkflowOrder;

      return {
        ...app,
        statusesMap: Array.from(statusesMap, ([name, value]) => ({
          name,
          value,
        })).sort(
          (a: { name: string }, b: { name: string }) =>
            currentWorkflowOrder[a.name] - currentWorkflowOrder[b.name],
        ),
        statuses,
      };
    });

    const jobsWithStats = uniqueAppsWithStatuses
      .reduce((acc: any, item: any) => {
        const currentJob = jobs.find((job) => job.id === item.job_id);
        const existingIJobIndex = acc.findIndex(
          (job: { id: number }) => job.id === item.job_id,
        );
        if (existingIJobIndex > -1) {
          acc[existingIJobIndex] = {
            ...acc[existingIJobIndex],
            apps: [...acc[existingIJobIndex].apps, ...item.statusesMap],
          };
          return acc;
        }

        return [
          ...acc,
          {
            id: item.job_id,
            title: currentJob?.title,
            apps: [...item.statusesMap],
          },
        ];
      }, [])
      .map((item: any) => {
        const sumStat = item.apps.reduce((acc: any, app: any) => {
          if (acc[app.name])
            return {
              ...acc,
              [app.name]: {
                value: acc[app.name].value + app.value,
                quantity: acc[app.name].quantity + 1,
              },
            };

          return { ...acc, [app.name]: { value: app.value, quantity: 1 } };
        }, {});
        const currentWorkflowOrder =
          workFlowOrder.find((wf: { id: number }) => wf.id === item.job_id)
            ?.orderEnum || defaultWorkflowOrder;

        const normalisedSumStat = Object.keys(currentWorkflowOrder).reduce(
          (acc, sumStatKey) =>
            sumStat[sumStatKey]
              ? { ...acc, [sumStatKey]: sumStat[sumStatKey] }
              : { ...acc, [sumStatKey]: { value: 0, quantity: 0 } },
          {},
        );
        return { ...item, sumStat: normalisedSumStat };
      });

    const affectedStatuses: any[] = Array.from(
      new Set(
        uniqueAppsWithStatuses.flatMap(
          (item: { statuses: string[] }) => item.statuses,
        ),
      ),
    ).sort(
      (a: any, b: any) =>
        defaultWorkflowOrder[a as "new" | "review" | "hired" | "rejected"] -
        defaultWorkflowOrder[b as "new" | "review" | "hired" | "rejected"],
    );
    yield put(fetchAllAnalyticsSuccess({ jobsWithStats, affectedStatuses }));
  } catch (e) {
    yield put(fetchAllAnalyticsFailed(e)); // TODO handle error
  }
}

function* AnalyticsSaga() {
  yield takeLatest(fetchAllAnalytics, handleOnFetchAllAnalytics);
  yield takeLatest(fetchAnalyticsJobList, handleOnFetchAnalyticsJobList);
  yield takeLatest(
    fetchAnalyticsNumberOfApplications,
    handleOnFetchAnalyticsNumberOfApplications,
  );
}

export default AnalyticsSaga;
