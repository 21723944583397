import React, { useMemo } from "react";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { getJobPreviewState } from "../../../store_new/selectors/JobPreview";
import { StyledJobPreviewTemplateImageContainer } from "../styles";

const Carousel = ({ logoPrefix }: { readonly logoPrefix: string }) => {
  const { jobTemplate } = useSelector(getJobPreviewState);
  const images = useMemo(
    () =>
      [jobTemplate?.header_1, jobTemplate?.header_2, jobTemplate?.header_3]
        .map((img) => (img ? `${logoPrefix}/${img}` : ""))
        .filter((img) => img),
    [jobTemplate]
  );

  const settings = {
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    draggable: false,
    autoplaySpeed: 5000,
  };

  if (images.length === 0) {
    return null;
  }

  if (images.length === 1) {
    return (
      <StyledJobPreviewTemplateImageContainer>
        <img src={images[0]} alt={"company header"} />
      </StyledJobPreviewTemplateImageContainer>
    );
  }

  return (
    <Slider {...settings}>
      {images.map((image: string, index: number) => (
        <StyledJobPreviewTemplateImageContainer key={index.toString()}>
          <img src={image} alt={"company header"} />
        </StyledJobPreviewTemplateImageContainer>
      ))}
    </Slider>
  );
};

export default Carousel;
