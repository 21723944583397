import React from "react";
import { Stack } from "@mui/material";
import CompanyHeader from "./CompanyHeader";
import Loading from "../../components/Loading";
import Filters from "./Filters";
import NoJobs from "./NoJobs";
import Jobs from "./Jobs";
import TermsFooter from "../../components/TermsFooter";
import NoData from "../../components/NoData";
import { TCompanyInfoComponent } from "../../models/CompanyInfo";

const CompanyInfoComponent = ({
  t,
  isLoading,
  desktop,
  company,
  filterOptions,
  filterValue,
  page,
  error,
  industry,
  isShowMore,
  isShowMoreExist,
  shortCompanyDescription,
  onShowMoreLess,
  paginate,
  onFilterChange,
}: TCompanyInfoComponent) => (
  <>
    {!company.title && !error ? (
      <Loading />
    ) : (
      company.title && (
        <Stack overflow="hidden auto">
          <CompanyHeader
            t={t}
            desktop={desktop}
            company={company}
            isShowMore={isShowMore}
            industry={industry}
            isShowMoreExist={isShowMoreExist}
            shortCompanyDescription={shortCompanyDescription}
            onShowMoreLess={onShowMoreLess}
          />
          <Stack
            px={desktop ? 0 : 3}
            width={desktop ? "100%" : "90%"}
            margin="auto"
            maxWidth={desktop ? 1128 : "auto"}
            justifyContent="center"
            alignItems="center"
          >
            <Filters
              t={t}
              company={company}
              desktop={desktop}
              filterOptions={filterOptions}
              filterValue={filterValue}
              onFilterChange={onFilterChange}
            />
            {company.jobs_total === 0 ? (
              <NoJobs t={t} companyColor={company.color} />
            ) : isLoading ? (
              <Loading />
            ) : (
              <Jobs
                company={company}
                page={page}
                desktop={desktop}
                paginate={paginate}
              />
            )}
            <TermsFooter />
          </Stack>
        </Stack>
      )
    )}
    {error && (
      <NoData
        title={t("company_page.no_company_title")}
        headings={[
          {
            text: t("company_page.no_company_subtitle_one"),
          },
          {
            text: t("company_page.no_company_subtitle_two"),
            link: "support@karriera.com",
          },
        ]}
        subtitles={[t("company_page.no_company_thank_you")]}
      />
    )}
  </>
);

export default CompanyInfoComponent;
