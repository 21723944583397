import React from "react";

import { Stack, Typography } from "@mui/material";
import {
  BriefcaseIcon,
  FolderOpenIcon,
  MapPinIcon
} from "@heroicons/react/24/outline";

const AdditionalQuestionsJobInfo = ({
  desktop,
  location,
  title,
  type,
  position,
  companyColor
}: {
  readonly desktop: boolean;
  readonly location: string;
  readonly title: string;
  readonly type: string;
  readonly position: string;
  readonly companyColor: string;
}) => {
  const iconProps = {
    width: 20,
    height: 20,
    strokeWidth: 1,
    stroke: companyColor
  };

  return (
    <Stack mt={5} spacing={1} alignItems={"center"}>
      <Typography variant="body1" fontSize={desktop ? 20 : 18}>
        {title}
      </Typography>
      <Stack direction={desktop ? "row" : "column"} spacing={desktop ? 3.5 : 1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <MapPinIcon {...iconProps} />
          <Typography variant="subtitle2" color="textSecondary">
            {location}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <BriefcaseIcon {...iconProps} />
          <Typography variant="subtitle2" color="textSecondary">
            {position}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <FolderOpenIcon {...iconProps} />
          <Typography variant="subtitle2" color="textSecondary">
            {type}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AdditionalQuestionsJobInfo;
