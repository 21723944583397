import React from "react";
import { Stack, InputLabel, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import TextEditor from "../../components/TextEditor";
import { IJobDescriptionFieldComponent } from "../../models/Marketing";
import { StyledJobDescriptionField } from "./style";

const JobDescriptionField = ({
  label,
  placeholder,
  register,
  control,
  property,
  textEditorLabel,
  textEditorProperty,
}: IJobDescriptionFieldComponent) => (
  <StyledJobDescriptionField>
    <Stack spacing={0.5}>
      <InputLabel>{label}</InputLabel>
      <TextField {...register(property)} placeholder={placeholder} fullWidth />
    </Stack>
    <Stack>
      <InputLabel>{textEditorLabel}</InputLabel>
      <Controller
        name={textEditorProperty}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextEditor value={value} onChange={onChange} />
        )}
      />
    </Stack>
  </StyledJobDescriptionField>
);

export default JobDescriptionField;
