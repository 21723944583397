import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  fetchCustomers,
  fetchCustomersFailed,
  fetchCustomersSuccess,
  inviteCustomers,
  inviteCustomersSuccess,
  inviteCustomersFailed,
  importCustomersSuccess,
  importCustomersFailed,
  importCustomers,
  fetchAllActiveCustomerssSuccess,
  fetchAllActiveCustomers,
  fetchCustomerSuccess,
  fetchCustomerFailed,
  fetchCustomer,
  disconnectCustomerSuccess,
  disconnectCustomerFailed,
  disconnectCustomer,
  fetchJassCustomersSuccess,
  fetchJassCustomersFailed,
  fetchJassCustomers,
} from "../reducers/Customers";
import {
  apiDisconnectCustomer,
  apiGetAllActiveCustomers,
  apiGetCustomer,
  apiGetCustomers,
  apiGetJassCustomers,
  apiImportCustomers,
  apiInviteCustomers,
} from "../../api/Customers";
import { getCustomersFilterState } from "../selectors/Customers";
import { TCustomer } from "../../models/Agency";
import { PayloadActionWithCallback } from "../../models/common";

function* handleOnFetchCustomers() {
  const url = `/company/customers`;

  const { quickSearch, location } = yield select(getCustomersFilterState);
  const apiPayload = {
    name_filter: quickSearch || undefined,
    location_filter: location || undefined,
  };

  try {
    const { data } = yield call(apiGetCustomers, { url, apiPayload });
    const { customers } = data;
    yield put(
      fetchCustomersSuccess({
        list: customers,
      })
    );
  } catch (e) {
    yield put(fetchCustomersFailed(e)); // TODO handle error
  }
}

function* handleOnFetchAllActiveCustomers() {
  const url = `/company/customers`;
  try {
    const { data } = yield call(apiGetAllActiveCustomers, { url });
    const { customers } = data;
    const activeCustomers = customers.filter((item: TCustomer) => item.jass_id);
    yield put(
      fetchAllActiveCustomerssSuccess({
        list: activeCustomers,
      })
    );
  } catch (e) {
    yield put(fetchCustomersFailed(e)); // TODO handle error
  }
}

function* handleOnInviteCustomers({
  payload,
}: PayloadActionWithCallback<{ emails: string[] }>) {
  const url = `/company/members/invite-customers`;
  const { callback, emails } = payload;
  const apiPayload = {
    emails,
  };

  try {
    yield call(apiInviteCustomers, { url, apiPayload });
    yield put(inviteCustomersSuccess());
    yield call(callback);
  } catch (e) {
    yield put(inviteCustomersFailed(e)); // TODO handle error
  }
}

function* handleOnImportCustomers({
  payload,
}: PayloadActionWithCallback<{ emails: string[] }>) {
  const url = `/company/customers/import`;
  const { callback, emails } = payload;

  const apiPayload = { customers_emails: emails };
  try {
    yield call(apiImportCustomers, {
      url,
      apiPayload,
    });
    yield put(importCustomersSuccess());
    yield call(callback);
  } catch (e) {
    yield put(importCustomersFailed(e)); // TODO handle error
  }
}

function* handleOnFetchJassCustomers({
  payload,
}: PayloadActionWithCallback<unknown>) {
  const { callback } = payload;
  const url = `/company/customers/preimport`;
  try {
    const { data } = yield call(apiGetJassCustomers, {
      url,
    });
    yield put(fetchJassCustomersSuccess(data.customers));
    yield call(callback);
  } catch (e) {
    yield put(fetchJassCustomersFailed(e)); // TODO handle error
  }
}

function* handleOnFetchCustomer({ payload }: { payload: number }) {
  const apiPayload = {
    id: payload,
  };
  const url = `/company/customers/details/${payload}`;
  try {
    const { data } = yield call(apiGetCustomer, { url, apiPayload });
    yield put(fetchCustomerSuccess(data));
  } catch (e) {
    yield put(fetchCustomerFailed(e)); // TODO handle error
  }
}

function* handleOnDisconnectCustomer({
  payload,
}: PayloadActionWithCallback<{
  id: number;
}>) {
  const { id, callback } = payload;
  const url = `/company/customers/disconnect/${id}`;
  try {
    yield call(apiDisconnectCustomer, { url });
    yield put(disconnectCustomerSuccess());
    yield call(callback);
  } catch (e) {
    yield put(disconnectCustomerFailed(e)); // TODO handle error
  }
}

function* CustomersSaga() {
  yield takeLatest(fetchCustomers, handleOnFetchCustomers);
  yield takeLatest(fetchAllActiveCustomers, handleOnFetchAllActiveCustomers);
  yield takeLatest(inviteCustomers, handleOnInviteCustomers);
  yield takeLatest(importCustomers, handleOnImportCustomers);
  yield takeLatest(fetchCustomer, handleOnFetchCustomer);
  yield takeLatest(disconnectCustomer, handleOnDisconnectCustomer);
  yield takeLatest(fetchJassCustomers, handleOnFetchJassCustomers);
}

export default CustomersSaga;
