import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import {
  ICustomersState,
  TCustomer,
  TCustomersFilters,
  TJassCustomer,
} from "../../models/Agency";
import { PayloadActionWithCallback } from "../../models/common";

const initialState: ICustomersState = {
  filters: {
    quickSearch: "",
    location: "",
  },
  customers: {
    list: [],
  },
  selectedCustomer: undefined,
  allActiveCustomers: {
    list: [],
  },
  error: false,
  isLoading: false,
  isListLoading: false,
  jassCustomers: [],
  selectedImportCustomers: [],
};

const filtersReducer = {
  setCustomersFilters: (
    state: Draft<ICustomersState>,
    action: PayloadAction<{
      filterType: keyof TCustomersFilters;
      value: string;
    }>
  ) => {
    const { filterType, value } = action.payload;
    switch (filterType) {
      case "location":
      case "quickSearch":
        state.filters[filterType] = value;
    }
  },
  resetCustomersFilters: (state: Draft<ICustomersState>) => {
    state.filters = initialState.filters;
  },
};

const customersReducer = {
  fetchCustomers: (state: Draft<ICustomersState>) => {
    state.isListLoading = true;
  },
  fetchCustomersSuccess: (
    state: Draft<ICustomersState>,
    action: PayloadAction<{
      list: TCustomer[];
    }>
  ) => {
    state.isListLoading = false;
    state.customers.list = action.payload.list;
  },
  fetchCustomersFailed: (
    state: Draft<ICustomersState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isListLoading = false;
  },
  fetchCustomer: (state: Draft<ICustomersState>, _: PayloadAction<number>) => {
    state.isLoading = true;
  },
  fetchCustomerSuccess: (
    state: Draft<ICustomersState>,
    action: PayloadAction<any>
  ) => {
    state.isLoading = false;
    state.selectedCustomer = action.payload;
  },
  fetchCustomerFailed: (
    state: Draft<ICustomersState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isLoading = false;
  },
  disconnectCustomer: (
    state: Draft<ICustomersState>,
    _: PayloadActionWithCallback<{
      id: number;
    }>
  ) => {
    state.isLoading = true;
  },
  disconnectCustomerSuccess: (state: Draft<ICustomersState>) => {
    state.isLoading = false;
  },
  disconnectCustomerFailed: (
    state: Draft<ICustomersState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isLoading = false;
  },
  fetchAllActiveCustomers: (state: Draft<ICustomersState>) => {
    state.isListLoading = true;
  },
  fetchAllActiveCustomerssSuccess: (
    state: Draft<ICustomersState>,
    action: PayloadAction<{
      list: TCustomer[];
    }>
  ) => {
    state.isListLoading = false;
    state.allActiveCustomers.list = action.payload.list;
  },
  inviteCustomers: (
    state: Draft<ICustomersState>,
    _: PayloadActionWithCallback<{ emails: string[] }>
  ) => {
    state.isLoading = true;
  },
  inviteCustomersSuccess: (state: Draft<ICustomersState>) => {
    state.isLoading = false;
  },
  inviteCustomersFailed: (
    state: Draft<ICustomersState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isLoading = false;
  },
  importCustomers: (
    state: Draft<ICustomersState>,
    _: PayloadActionWithCallback<{ emails: string[] }>
  ) => {
    state.isListLoading = true;
  },
  importCustomersSuccess: (state: Draft<ICustomersState>) => {
    state.isListLoading = false;
  },
  importCustomersFailed: (
    state: Draft<ICustomersState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isListLoading = false;
  },
  fetchJassCustomers: (
    state: Draft<ICustomersState>,
    _: PayloadActionWithCallback<unknown>
  ) => {
    state.isLoading = true;
  },
  fetchJassCustomersSuccess: (
    state: Draft<ICustomersState>,
    action: PayloadAction<TJassCustomer[]>
  ) => {
    state.isLoading = false;
    state.jassCustomers = action.payload;
  },
  fetchJassCustomersFailed: (
    state: Draft<ICustomersState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isLoading = false;
  },
  setSelectedImportCustomers: (
    state: Draft<ICustomersState>,
    action: PayloadAction<TJassCustomer | null>
  ) => {
    let updatedImportCustomers: TJassCustomer[] = [];

    if (action.payload) {
      if (
        state.selectedImportCustomers.findIndex(
          (customer) => customer.email === action.payload?.email
        ) > -1
      ) {
        updatedImportCustomers = state.selectedImportCustomers.filter(
          (customer) => customer.email !== action.payload?.email
        );
      } else {
        updatedImportCustomers = [
          ...state.selectedImportCustomers,
          action.payload,
        ];
      }
    } else {
      updatedImportCustomers = [];
    }
    state.selectedImportCustomers = updatedImportCustomers;
  },
  resetSelectedCustomer: (state: Draft<ICustomersState>) => {
    state.selectedCustomer = initialState.selectedCustomer;
  },
};

export const CustomersSlice = createSlice({
  name: "APPLICATIONS",
  initialState,
  reducers: {
    ...filtersReducer,
    ...customersReducer,
    resetCustomersState: () => initialState,
  },
});

export const {
  setCustomersFilters,
  resetCustomersFilters,
  fetchCustomers,
  fetchCustomersSuccess,
  fetchCustomersFailed,
  fetchCustomer,
  fetchCustomerSuccess,
  fetchCustomerFailed,
  fetchAllActiveCustomers,
  fetchAllActiveCustomerssSuccess,
  inviteCustomers,
  inviteCustomersSuccess,
  inviteCustomersFailed,
  importCustomers,
  importCustomersSuccess,
  importCustomersFailed,
  disconnectCustomer,
  disconnectCustomerFailed,
  disconnectCustomerSuccess,
  resetCustomersState,
  resetSelectedCustomer,
  fetchJassCustomers,
  fetchJassCustomersFailed,
  fetchJassCustomersSuccess,
  setSelectedImportCustomers,
} = CustomersSlice.actions;

export default CustomersSlice.reducer;
