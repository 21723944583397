import { RootState } from "../config";

export const getCompanySettingsCompanyState = ({
  companySettings,
}: RootState) => companySettings;

export const getCompanySettingsIsFormDataLoading = ({
  companySettings,
}: RootState) =>
  companySettings.isCompanyDataLoading ||
  companySettings.isCountriesLoading ||
  companySettings.isEmployeesLoading ||
  companySettings.isIndustriesLoading;

export const getCompanyInfoState = ({ companyInfo }: RootState) => companyInfo;
export const getCompanyInfoPagination = ({ companyInfo }: RootState) =>
  companyInfo.pagination;
