import React, { useState, useRef } from "react";
import { CSVLink } from "react-csv";
import {
  ArrowDownTrayIcon,
  ArrowDownOnSquareIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import DeleteDialog from "../dialogs/DeleteDialog";
import StatusMenu from "./StatusMenu";
import TalentPoolPopover from "../TalentPoolPopover";
import { StyledFloatingMenu, StyledFloatingMenuItem } from "./style";
import { IFloatingMenuComponent } from "../../models/Kanban";
import {
  setIsLimitDialogOpen,
  setIsTalentPoolsDialogOpen,
} from "../../store_new/reducers/dialogs";
import { DIALOG_TYPE } from "../../models/common";
import { getTalentPoolState } from "../../store_new/selectors/Talentpool";
import { addCandidateInTalentPool } from "../../store_new/reducers/TalentPools";
import { fetchJobApplications } from "../../store_new/reducers/SingleJob";
import {
  deleteApplication,
  deleteApplications,
  fetchApplications,
  updateApplicationStatus,
} from "../../store_new/reducers/Applications";
import { getCompanySettingsCompanyState } from "../../store_new/selectors/Company";
import { TCandidateApplication } from "../../models/ApplicationPage";
import {
  getApplicationsIsDeleteApplicationLoading,
  getCurrentApplication,
} from "../../store_new/selectors/Applications";
import { getCurrentUserData } from "../../store_new/selectors/CurrentUser";
import { getSingleJobState } from "../../store_new/selectors/SingleJob";
import { addMessage } from "../../store_new/reducers/Snackbar";

const FloatingMenu = ({
  t,
  statuses,
  items,
  handleDeselectAll,
  handleMoveAll,
}: IFloatingMenuComponent) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { job_id } = useParams();
  const singleJobId = location.pathname.slice(6, -13);
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isTalentPoolMenuHovered, setIsTalentPoolMenuHovered] =
    useState<boolean>(false);
  const [talentPoolsAnchorEl, setTalentPoolsAnchorEl] =
    useState<null | HTMLElement>(null);
  const talentPoolMenuOpen = Boolean(talentPoolsAnchorEl);
  const id = talentPoolMenuOpen ? "simple-popover" : undefined;
  const { isAgency } = useSelector(getCurrentUserData);
  const { talentPools } = useSelector(getTalentPoolState);
  const isDeleteApplicationLoading = useSelector(
    getApplicationsIsDeleteApplicationLoading
  );
  const currentApplication = useSelector(getCurrentApplication);

  const { jobId } = useSelector(getSingleJobState);
  const { company } = useSelector(getCompanySettingsCompanyState);

  const handleStatusMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportCandidates = () => {
    handleDeselectAll();
    csvLinkRef?.current?.link.click();
  };

  const handleTalentPoolMenuClick = (
    event: React.MouseEvent<HTMLLIElement>
  ) => {
    setTalentPoolsAnchorEl(event.currentTarget);
  };

  const handleCloseTalentPoolMenu = () => {
    if (!isTalentPoolMenuHovered) {
      setTalentPoolsAnchorEl(null);
    }
  };

  const handleOnTalentPoolHoverLeave = (hover: boolean) => {
    setIsTalentPoolMenuHovered(hover);
  };

  const handleOnOpenTalentPoolDialog = () => {
    if (company?.talentpools_creation_allowed) {
      dispatch(setIsTalentPoolsDialogOpen(DIALOG_TYPE.CREATE_EDIT_TALENT_POOL));
    } else {
      dispatch(setIsLimitDialogOpen(true));
    }
  };

  const onSuccessAddCandidatesInTalentPool = (
    type: "single" | "multiple",
    candidateApplications: TCandidateApplication | TCandidateApplication[]
  ) => {
    if (type === "multiple") {
      dispatch(
        addMessage({
          type: "success",
          title: t("talentPools.successCandidatesAddedInTalentPool"),
        })
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      candidateApplications?.forEach((candidate: TCandidateApplication) => {
        dispatch(
          updateApplicationStatus({
            index: "0",
            applicationId: candidate.application_id as string,
            newStatus: "rejected",
            callback: () => {
              handleMoveAll("rejected");
              dispatch(fetchApplications({ withoutLoader: true }));
            },
          })
        );
      });
    } else {
      dispatch(
        updateApplicationStatus({
          index: "0",
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          applicationId: candidateApplications.application_id as string,
          newStatus: "rejected",
          callback: () =>
            dispatch(
              fetchJobApplications({
                jobId: singleJobId as string,
                callback: () => {
                  handleMoveAll("rejected");
                  dispatch(fetchApplications({ withoutLoader: true }));
                },
              })
            ),
        })
      );
      dispatch(
        addMessage({
          type: "success",
          title: t("talentPools.successCandidateAddedInTalentPool"),
        })
      );
    }
  };

  const handleOnClickTalentPoolMenuItem = (id: number) => {
    const candidateApplications = items.map(
      (candidate: TCandidateApplication) => candidate
    );
    if (candidateApplications.length > 1) {
      dispatch(
        addCandidateInTalentPool({
          id,
          applications: candidateApplications,
          callback: () =>
            onSuccessAddCandidatesInTalentPool(
              "multiple",
              candidateApplications
            ),
        })
      );
    } else {
      dispatch(
        addCandidateInTalentPool({
          id,
          applications: candidateApplications[0],
          callback: () =>
            onSuccessAddCandidatesInTalentPool(
              "single",
              candidateApplications[0]
            ),
        })
      );
    }
    handleDeselectAll();
    setTalentPoolsAnchorEl(null);
  };

  const onSuccessDeleteApplications = () => {
    dispatch(fetchApplications({ withoutLoader: true }));
    dispatch(
      addMessage({
        title: t("applications.delete_application_snackbar"),
        type: "success",
      })
    );
    handleDeselectAll();
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteApplications = () => {
    const applicationsIds = items.map(
      (item: TCandidateApplication) => item.application_id
    );
    if (items.length > 1) {
      dispatch(
        deleteApplications({
          applicationsIds,
          callback: () => onSuccessDeleteApplications(),
        })
      );
    } else {
      dispatch(
        deleteApplication({
          applicationId: items[0].application_id,
          callback: () => onSuccessDeleteApplications(),
        })
      );
    }
  };

  const handleOnOpenCloseDeleteDialog = (boolean: boolean) =>
    setIsDeleteDialogOpen(boolean);

  return (
    <StyledFloatingMenu>
      {jobId && (
        <StyledFloatingMenuItem onClick={handleStatusMenuClick}>
          <ArrowDownOnSquareIcon /> {t("floating_menu.change_status")}
        </StyledFloatingMenuItem>
      )}
      <StyledFloatingMenuItem onClick={handleExportCandidates}>
        <ArrowDownTrayIcon /> {t("joblist.export")}
      </StyledFloatingMenuItem>
      <StyledFloatingMenuItem onClick={handleDeselectAll}>
        <XMarkIcon /> {t("floating_menu.deselect_all")}
      </StyledFloatingMenuItem>
      <StyledFloatingMenuItem
        onClick={() => handleOnOpenCloseDeleteDialog(true)}
      >
        <XMarkIcon /> {t("talentPools.delete")}
      </StyledFloatingMenuItem>
      <StatusMenu
        t={t}
        isAgency={isAgency}
        talentPoolMenuOpen={talentPoolMenuOpen}
        items={statuses}
        anchorEl={anchorEl}
        currentApplication={currentApplication}
        handleMoveAll={handleMoveAll}
        onTalentPoolMenuClick={handleTalentPoolMenuClick}
        closeStatusMenu={handleClose}
      />
      <CSVLink
        data={items}
        className="hidden"
        ref={csvLinkRef}
        filename={items.length === 1 ? items[0].name : "Candidates"}
      />
      <TalentPoolPopover
        t={t}
        id={id}
        onCloseTalentPoolMenu={handleCloseTalentPoolMenu}
        open={talentPoolMenuOpen}
        anchorEl={talentPoolsAnchorEl}
        jobId={job_id}
        talentPools={talentPools}
        onTalentPoolHoverLeave={handleOnTalentPoolHoverLeave}
        onClickTalentPoolMenuItem={handleOnClickTalentPoolMenuItem}
        onOpenCreateTalentPoolDialog={handleOnOpenTalentPoolDialog}
      />
      <DeleteDialog
        t={t}
        isLoading={isDeleteApplicationLoading}
        isOpen={isDeleteDialogOpen}
        title={t("applications.delete_application")}
        body={
          items.length > 1
            ? t("applications.delete_multiple_application_text")
            : t("applications.delete_application_text")
        }
        note={t("applications.delete_application_note") as string}
        customDeleteButtonText={t("applications.delete_application") as string}
        onCancel={() => handleOnOpenCloseDeleteDialog(false)}
        onDelete={handleDeleteApplications}
      />
    </StyledFloatingMenu>
  );
};

export default FloatingMenu;
