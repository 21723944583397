import React from "react";
import { TabContext } from "@mui/lab";
import { Tab, Tabs, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Applications_new from "../Applications_new";
import BasicJobBoard_new from "./BasicJobBoard";
import PremiumJobBoards from "./PremiumJobBoards";
import JobDetails_new from "./JobDetails";
import Bookings_new from "./Bookings";
import {
  StyledPremiumJobBoardsTabMenu,
  StyledSingleJobContainer,
  StyledTabPanel,
} from "./styles";
import { StyledTeamsTabsContainer } from "../TeamSettings_new/styles";
import {
  ISingleJobComponent,
  JOB_TABS_KEY,
  PREMIUM_ITEM_TYPES,
} from "../../models/SingleJob";

const SingleJobComponent = ({
  t,
  jobTabs,
  selectedJobTab,
  view,
  tabsWidth,
  cardsApplications,
  list,
  menuOpen,
  anchorEl,
  selectedPremiumJobBoardsTab,
  lang,
  onTabChange,
  onOpenCart,
  onSelectMenuItem,
  onClickPremiumJobBoardsTab,
}: ISingleJobComponent) => (
  <StyledSingleJobContainer
    mt={
      list.length &&
      view === "cards" &&
      cardsApplications &&
      Object.keys(cardsApplications).length > 4 &&
      selectedJobTab === JOB_TABS_KEY.APPLICATIONS
        ? 6
        : 4
    }
    overflow={
      selectedJobTab === JOB_TABS_KEY.APPLICATIONS ? "hidden" : "visible"
    }
    mb={-4}
  >
    <TabContext value={selectedJobTab}>
      <StyledTeamsTabsContainer
        view={view}
        tabsWidth={tabsWidth}
        cardsApplications={cardsApplications}
        selectedJobTab={selectedJobTab}
        list={list}
      >
        <Tabs value={selectedJobTab} onChange={onTabChange}>
          {jobTabs.map((tab: JOB_TABS_KEY, index: number) =>
            tab === JOB_TABS_KEY.PREMIUM_JOB_BOARDS ? (
              <Tab
                key={tab + index}
                label={
                  <div
                    aria-controls={menuOpen ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? "true" : undefined}
                    className="premium-job-boards-tab"
                    onClick={onClickPremiumJobBoardsTab}
                  >
                    {t(JOB_TABS_KEY.PREMIUM_JOB_BOARDS)}
                    <ArrowDropDownIcon />
                  </div>
                }
                value={tab}
              />
            ) : (
              <Tab key={tab + index} label={t(tab)} value={tab} />
            )
          )}
        </Tabs>
      </StyledTeamsTabsContainer>

      <StyledTabPanel view={view} value={JOB_TABS_KEY.APPLICATIONS}>
        <Applications_new
          containerMargin={
            cardsApplications &&
            Object.keys(cardsApplications).length > 4 &&
            view === "cards" &&
            list.length
              ? 9.5
              : 0
          }
        />
      </StyledTabPanel>
      <StyledTabPanel value={JOB_TABS_KEY.BASIC_JOB_BOARDS}>
        <BasicJobBoard_new t={t} />
      </StyledTabPanel>
      <StyledTabPanel
        value={JOB_TABS_KEY.PREMIUM_JOB_BOARDS}
        isPackages={selectedPremiumJobBoardsTab === PREMIUM_ITEM_TYPES.PACKAGES}
        isPremiumJobBoardsTab
      >
        <PremiumJobBoards isPremiumJobBoardsTab onOpenCart={onOpenCart} />
      </StyledTabPanel>
      <StyledTabPanel value={JOB_TABS_KEY.JOB_DETAILS}>
        <JobDetails_new t={t} />
      </StyledTabPanel>
      <StyledTabPanel value={JOB_TABS_KEY.BOOKINGS}>
        <Bookings_new t={t} />
      </StyledTabPanel>
    </TabContext>
    <StyledPremiumJobBoardsTabMenu
      lang={lang}
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={() => onSelectMenuItem()}
    >
      <MenuItem onClick={() => onSelectMenuItem(PREMIUM_ITEM_TYPES.PRODUCTS)}>
        {t("create_job_fourth_step.products")}
      </MenuItem>
      <MenuItem onClick={() => onSelectMenuItem(PREMIUM_ITEM_TYPES.PACKAGES)}>
        {t("create_job_fourth_step.packages")}
      </MenuItem>
    </StyledPremiumJobBoardsTabMenu>
  </StyledSingleJobContainer>
);

export default SingleJobComponent;
