import { Button, Stack, TextField } from "@mui/material";
import { styled } from "@mui/system";
import MarketingPageBackgroundImage from "./assets/marketing-page-background.png";

export const StyledMarketingPageContainer = styled(Stack)(
  ({ desktop }: { desktop: boolean }) => ({
    width: desktop ? 744 : "100%",
    height: desktop ? "min-content" : "auto",
    padding: "24px 0px",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.2)",
    backgroundImage: `url(${MarketingPageBackgroundImage})`,
    backgroundPosition: "center top",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: 6,
  })
);

export const StyledJobDescriptionField = styled(Stack)({
  background: "#EEF2FF",
  gap: 16,
  padding: 24,
  ".quill-container": {
    background: "#fff",
  },
});

export const StyledMarketingJobDataContainer = styled(Stack)(
  ({ desktop }: { desktop: boolean }) => ({
    width: desktop ? 744 : "100%",
    borderRadius: 6,
    ".phone-input": {
      width: "100%!important",
      border: "1px solid #E5E7EB!important",
      height: 40,
      "&.error": {
        color: "#FCA5A5!important",
        border: "1px solid #FCA5A5!important",
        boxShadow: "0 0 5px 2px #FEE2E2!important",
      },
    },
  })
);

export const StyledMarketingFooter = styled(Stack)(
  ({ desktop }: { desktop: boolean }) => ({
    width: desktop ? "95vw" : "auto",
    marginTop: 84,
    bottom: 32,
    flexDirection: desktop ? "row" : "column",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    color: "#6366F1 !important",
    ".footer-mail": {
      color: "#6366F1 !important",
      textDecorationColor: "#6366F1 !important",
    },
    ".footer-phone": {
      textDecoration: "none",
      color: "#6366F1 !important",
    },
  })
);

export const StyledMarketingPageTextField = styled(TextField)({
  ".MuiInputBase-root": {
    background: "transparent !important",
    color: "#fff !important",
    fontWeight: "500 !important",
    "& fieldset": {
      color: "#fff !important",
      border: "2px solid #fff !important",
    },
    "&.Mui-focused fieldset": {
      color: "#fff !important",
      fontWeight: "500 !important",
      border: "1px solid #fff !important",
      boxShadow: "0 0 5px 2px #fff",
    },
    "& input": {
      "&::placeholder": {
        color: "#fff !important",
        fontWeight: "500 !important",
        opacity: "1 !important",
      },
    },
  },
});

export const StyledMarketingPageButton = styled(Button)({
  background: "#6366F1",
  color: "#fff",
  "&:hover": {
    background: "#6366F1",
    color: "#fff",
    opacity: 0.8,
  },
  "&:disabled": {
    background: "#818CF8",
    color: "#fff",
    opacity: 0.8,
  },
});

export const StyledMarketingContactButton = styled(Button)({
  color: "#6366F1",
  borderColor: "#6366F1 !important",
  "&:hover": {
    opacity: 0.8,
  },
});

export const StyledMarketingActionContainer = styled(Stack)(
  ({ desktop }: { desktop: boolean }) => ({
    width: desktop ? 360 : "95vw",
    borderRadius: 6,
    background: "#fff",
  })
);

export const StyledMarketingMainInfoContainer = styled(Stack)({
  textAlign: "center",
  padding: 24,
  gap: 24,
});
