import {
  CellClickedEvent,
  ColDef,
  ICellRendererParams,
  ValueGetterParams,
} from "ag-grid-community";
import StatusCell from "../cellRenderers/status";
import iconAndText from "../cellRenderers/iconAndText";
import { TDataGridGetColDef } from "../../../models/DataGrid";
import { JOB_STATUSES } from "../../../models/JobList";
import ActionsCell from "../cellRenderers/actions";

const getCustomersColDef = ({
  t,
  actions,
}: Pick<
  TDataGridGetColDef,
  "t" | "actions" | "onSelect" | "type"
>): ColDef[] => [
  {
    field: "company",
    headerName: t("customers.company") as string,
    flex: 1,
    cellClass: "ag-grid-cell-clickable",
    cellRenderer: (params: ICellRendererParams) =>
      iconAndText({
        src: params.data.companyLogo,
        text: params.data.companyName,
        variant: "photo",
      }),

    onCellClicked: (params: CellClickedEvent) => {
      if (actions.onClickItem)
        actions.onClickItem(params.data.id || params.data.company_id);
    },
  },
  {
    field: "email",
    headerName: t("candidate.email") as string,
    flex: 0.75,
    valueGetter: (params: ValueGetterParams) => params.data["email"] || "-",
  },
  {
    field: "country",
    headerName: t("candidate.country") as string,
    flex: 0.7,
    valueGetter: (params: ValueGetterParams) => params.data["country"] || "-",
  },
  {
    field: "location",
    headerName: t("joblist.location_placeholder") as string,
    flex: 0.4,
    valueGetter: (params: ValueGetterParams) => params.data["location"] || "-",
  },
  {
    field: "status",
    flex: 0.4,
    cellRenderer: (params: ICellRendererParams) =>
      StatusCell({
        label: params.data.jass_id
          ? JOB_STATUSES.ACTIVE
          : JOB_STATUSES.INACTIVE,
        type: params.data.jass_id ? JOB_STATUSES.ACTIVE : JOB_STATUSES.INACTIVE,
      }),
  },
  {
    headerName: "",
    sortable: false,
    flex: 0.5,
    width: 150,
    cellRenderer: (params: ICellRendererParams) => {
      if (params.data.is_agency_managed && params.data.is_active)
        return ActionsCell({
          t,
          actions: {
            onLogInAsCustomer: () =>
              actions.onLogInAsCustomer &&
              actions.onLogInAsCustomer(params.data),
          },
        });

      return null;
    },
  },
];

export default getCustomersColDef;
