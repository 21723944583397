import React, { ReactNode } from "react";
import BackgroundImage from "../../components/BackgroundImage";
import logo from "../../assets/logo.svg";
import {
  StyledAuthLayoutFormContainer,
  StyledAuthLayoutFormContent,
  StyledGridContainer,
} from "./styles";

const AuthLayout = ({
  children,
  desktop,
}: {
  children: ReactNode;
  desktop: boolean;
}) => (
  <StyledGridContainer container>
    <StyledAuthLayoutFormContainer item xs={desktop ? 6 : 12}>
      <StyledAuthLayoutFormContent>
        <img src={logo} alt={"karriera logo"} />
        {children}
      </StyledAuthLayoutFormContent>
    </StyledAuthLayoutFormContainer>
    {desktop && <BackgroundImage />}
  </StyledGridContainer>
);

export default AuthLayout;
