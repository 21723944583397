import React, { useEffect } from "react";
import { Stack, Button, Dialog, Divider, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getDialogsState } from "../../../store_new/selectors/Dialogs";
import { fetchAllActiveJobs } from "../../../store_new/reducers/JobList";
import { setIsBuyProductDialogOpen } from "../../../store_new/reducers/dialogs";
import { getAllActiveJobs } from "../../../store_new/selectors/JobList";
import AutocompleteFilter from "../../filters_new/AutocompleteFilter";
import {
  resetApplicationsFilters,
  setApplicationFilters,
} from "../../../store_new/reducers/Applications";
import { TApplicationsFilters } from "../../../models/Applications";
import { getApplicationsFilters } from "../../../store_new/selectors/Applications";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  openCheckout,
  openJobEditor,
  setJobUrlKey,
} from "../../../store_new/reducers/JobEditor";
import { setSelectedProducts } from "../../../store_new/reducers/SingleJob";

const BuyProductDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isBuyProductDialogOpen } = useSelector(getDialogsState);

  const allJobs = useSelector(getAllActiveJobs);

  const { appliedTo } = useSelector(getApplicationsFilters);

  const formattedAllActiveJobs = allJobs?.map((item) => ({
    label: item.title,
    value: item.url_key,
  }));

  const onCancel = () => {
    dispatch(setIsBuyProductDialogOpen(false));
    dispatch(resetApplicationsFilters());
    dispatch(setSelectedProducts([]));
  };

  const onConinue = () => {
    dispatch(setJobUrlKey(appliedTo.value ?? ""));
    dispatch(openCheckout());
    dispatch(setIsBuyProductDialogOpen(false));
    dispatch(resetApplicationsFilters());
  };

  const onCreateJob = () => {
    dispatch(setIsBuyProductDialogOpen(false));
    dispatch(resetApplicationsFilters());
    dispatch(openJobEditor());
  };

  const handleOnChangeFilter = ({
    value,
    filterType,
  }: {
    readonly value: string;
    readonly filterType: keyof TApplicationsFilters;
  }) => {
    dispatch(setApplicationFilters({ filterType, value }));
  };

  useEffect(() => {
    dispatch(fetchAllActiveJobs());
  }, []);

  return (
    <Dialog open={isBuyProductDialogOpen} onClose={onCancel}>
      <Stack p={3} width={512} spacing={1} sx={{ backgroundColor: "#fff" }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography>
              {t(
                `create_job_fourth_step.${
                  formattedAllActiveJobs && formattedAllActiveJobs?.length
                    ? "select_and_publish_job_title"
                    : "create_job_and_publish_title"
                }`
              )}
            </Typography>
          </Stack>
          <Stack>
            <XMarkIcon
              onClick={onCancel}
              width={24}
              height={24}
              stroke="#131E30"
              style={{ cursor: "pointer" }}
            />
          </Stack>
        </Stack>
        <Stack pt={2}>
          <Divider
            sx={{
              width: "100%",
              borderColor: "#E5E7EB",
            }}
          />
        </Stack>
        <Stack pt={1} spacing={2}>
          <Stack direction="row" pt={1} spacing={2} alignItems="center">
            {formattedAllActiveJobs && formattedAllActiveJobs?.length > 0 && (
              <AutocompleteFilter
                listBoxProps={{
                  className: "applications-jobs-autocomplete",
                }}
                onChange={(value: any) => {
                  handleOnChangeFilter({ value, filterType: "appliedTo" });
                }}
                value={appliedTo.value}
                options={formattedAllActiveJobs || []}
                placeholder={t("applications.job_placeholder")}
              />
            )}
            {formattedAllActiveJobs && formattedAllActiveJobs?.length > 0 && (
              <Typography variant="subtitle2" color="textSecondary">
                {t("userSettings.or")}
              </Typography>
            )}
            <Button variant="contained" onClick={onCreateJob} fullWidth>
              {t(`create_job_fourth_step.create_new_job`)}
            </Button>
          </Stack>
          {formattedAllActiveJobs && formattedAllActiveJobs?.length > 0 && (
            <Stack direction="row" spacing={1.5} justifyContent="flex-end">
              <Button variant="outlined" onClick={onCancel}>
                {t("button.secondCancel")}
              </Button>
              <Button
                variant="contained"
                disabled={!appliedTo.value}
                onClick={onConinue}
              >
                {t("button.continue")}
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default BuyProductDialog;
