import { SnackbarCloseReason } from "@mui/material/Snackbar";

export interface ISnackbarState {
  notifications: INotification[];
  isPasswordNotificationShowed: boolean;
}

export interface ISnackbarComponent {
  readonly desktop: boolean;
  readonly isOpen: boolean;
  readonly notification: INotification;
  readonly onTurnOffNotifications: () => void;
  readonly onCloseSnackbar: (
    id: string,
    e: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => void;
}

export interface INotification {
  readonly uuid?: string;
  readonly variant?: "error" | "info" | "success" | "warning" | "secondary";
  readonly type?: "error" | "info" | "success" | "warning" | "secondary";
  readonly title: string;
  readonly message?: string | string[];
  readonly visibilityTime?: number | string;
  readonly action?: any;
  readonly icon?: React.ReactNode;
  readonly onFirstButtonClick?: any;
  readonly firstButtonText?: string;
  readonly source?: any;
}

export interface ISnackbarActionContainer {
  readonly desktop: boolean;
  readonly type?: "error" | "info" | "success" | "warning" | "secondary";
  readonly onClose: () => void;
  readonly action?: any;
  readonly firstButtonText: string;
  readonly onFirstButtonClick?: any;
}

export interface ISnackarContainedButton {
  readonly desktop: boolean;
  readonly type?: "error" | "info" | "success" | "warning" | "secondary";
  readonly buttonText: string;
  readonly onClick: any;
  readonly onClose?: () => void;
}

export interface ISingleNotificationComponent {
  readonly open: boolean;
  readonly variant: "info";
  readonly message: string;
  readonly setOpen: (value: boolean) => void;
}

export const SNACKBAR_ACTION_TYPE = {
  OUTLINE_BUTTON_WITH_CLOSE_ICON: "outline_button_with_close_icon",
  CONTAINED_BUTTON_WITH_CLOSE_ICON: "contained_button_with_close_icon",
  CONTAINED_BUTTON_WITHOUT_CLOSE_ICON: "contained_button_without_close_icon",
};
