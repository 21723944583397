import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link, Stack, Typography } from "@mui/material";
import { IMarketingFooterComponent } from "../../models/Marketing";
import { StyledMarketingFooter } from "./style";

const MarketingFooter = ({ desktop }: IMarketingFooterComponent) => (
  <AnimatePresence>
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 2 }}
    >
      <StyledMarketingFooter desktop={desktop} className="footer-container">
        <Stack
          direction={desktop ? "row" : "column"}
          alignItems="center"
          spacing={desktop ? 4 : 1}
        >
          <Typography variant="subtitle2">
            © 2024 Karriera. Alle Rechte vorbehalten.
          </Typography>
          <Link href="mailto:support@karriera.de" className="footer-mail">
            support@karriera.de
          </Link>
          <Link href="tel:+99-565-654" className="footer-phone">
            +49 (0) 30 69 00 45 96 9
          </Link>
          <Link
            href="https://www.karriera.de/agb"
            target="_blank"
            className="footer-phone"
          >
            AGB
          </Link>
          <Link
            href="https://www.karriera.de/impressum"
            target="_blank"
            className="footer-phone"
          >
            Impressum
          </Link>
        </Stack>
      </StyledMarketingFooter>
    </motion.div>
  </AnimatePresence>
);

export default MarketingFooter;
