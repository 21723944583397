import React from "react";
import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Controller } from "react-hook-form";
import {
  IAdditionalQuestionsSkillsComponent,
  TAdditionalQuestionSkill,
} from "../../../../models/JobPreview";
import {
  StyledAdditionalQuestionsFieldContainer,
  StyledAdditionalQuestionsSkillChip,
  StyledAdditionalQuestionsSkillsContainer,
} from "../../styles";

const AdditionalQuestionsSkillsComponent = ({
  t,
  control,
  desktop,
  filterOptions,
  skills,
  companyColor,
  boldHeading,
  onAdd,
  onRemove,
}: IAdditionalQuestionsSkillsComponent) => (
  <StyledAdditionalQuestionsFieldContainer
    borderBottom={boldHeading ? "0px !important" : "1px solid #E5E7EB"}
    py={boldHeading ? "0px !important" : "24px !important"}
    desktop={desktop}
  >
    <Stack
      className={"title"}
      height={boldHeading ? "auto !important" : "30px !important"}
    >
      <Typography variant="subtitle2" fontWeight={boldHeading ? 500 : 400}>
        {t("additional_questions.skills")}
      </Typography>
    </Stack>
    <Stack className={"input-field"}>
      <Controller
        name={`skills`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            className={"skills-autocomplete"}
            // size={"small"}
            disabled={!skills?.length}
            disablePortal
            filterSelectedOptions
            clearOnEscape
            value={null}
            blurOnSelect={true}
            options={skills}
            filterOptions={filterOptions}
            getOptionLabel={(option) => option.title}
            onChange={(_, newSkill: TAdditionalQuestionSkill | null) => {
              onAdd({ selectedSkills: value, newSkill, onChange });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={
                  t("additional_questions.skills_placeholder") as string
                }
              />
            )}
          />
        )}
      />
    </Stack>
    {
      <Stack sx={{ width: "100%" }} mt={2} mr={desktop ? 4 : 0}>
        <Controller
          name={`skills`}
          control={control}
          render={({ field: { onChange, value } }) =>
            value.length ? (
              <StyledAdditionalQuestionsSkillsContainer direction={"row"}>
                {value.map((skill: TAdditionalQuestionSkill) => (
                  <StyledAdditionalQuestionsSkillChip
                    companyColor={companyColor}
                    size={"small"}
                    key={skill.id}
                    variant={"outlined"}
                    label={skill.title}
                    deleteIcon={<ClearIcon />}
                    onDelete={() => {
                      onRemove({
                        selectedSkills: value,
                        skillToRemove: skill,
                        onChange,
                      });
                    }}
                  />
                ))}
              </StyledAdditionalQuestionsSkillsContainer>
            ) : (
              <></>
            )
          }
        />
      </Stack>
    }
  </StyledAdditionalQuestionsFieldContainer>
);

export default AdditionalQuestionsSkillsComponent;
