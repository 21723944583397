import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Stack, Typography } from "@mui/material";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

const MarketingStepThree = ({ desktop }: { readonly desktop: boolean }) => (
  <AnimatePresence>
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Stack
        spacing={2}
        p={desktop ? 0 : 3}
        width={desktop ? 1128 : "85vw"}
        height={desktop ? "75vh" : "70vh"}
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        borderRadius={1.5}
        sx={{ background: "#fff" }}
      >
        <CheckCircleIcon height={48} width={48} fill="#22C55E" stroke="#fff" />
        <Typography
          fontSize={desktop ? 24 : 16}
          color="#131E30"
          fontWeight={500}
        >
          Ihr Auftrag wurde erfolgreich erstellt
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Unser Team überprüft nun Ihre Daten, und der Job wird in Kürze
          veröffentlicht. Sollten Informationen fehlen, melden wir uns umgehend
          bei Ihnen.
        </Typography>
      </Stack>
    </motion.div>
  </AnimatePresence>
);

export default MarketingStepThree;
