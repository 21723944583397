import { Select, Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledCompanySettingsContainer = styled(Stack)({
  marginTop: 30,
  padding: 24,
  background: "#fff",
  border: "1px solid #E5E7EB",
  borderRadius: 6,
  ".submit-btn": {
    width: 120,
    textDecoration: "none",
    textTransform: "none",
  },
});

export const StyledSectionContainer = styled(Stack)({
  marginTop: 24,
  padding: 16,
  backgroundColor: "#F9FAFB",
  borderRadius: 6,
  ".MuiFormHelperText-root": {
    position: "absolute",
    top: 40,
    textTransform: "none",
    fontSize: 12,
  },
});

export const StyledSwitchContainer = styled(Stack)({
  flex: 2,
  height: 40,
  backgroundColor: "#EEF2FF!important",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: 6,
  p: {
    fontSize: 14,
    color: "#6B7280",
    padding: 10,
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#fff!important",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#818CF8 !important",
  },
});

export const StyledSelect = styled(Select)({
  ".MuiSelect-select": {
    position: "relative",
    top: 8,
  },
});

export const StyledApiTokenIconContainer = styled(Stack)({
  width: 20,
  height: 20,
  cursor: "pointer",
});

export const StyledTextArea = styled("textarea")({
  marginTop: 4,
  fontFamily: "Roboto,Helvetica,Arial,sans-serif",
  fontSize: 14,
  border: "1px solid #E5E7EB",
  borderRadius: 6,
  resize: "none",
  padding: 8,
  height: 144,
  "&:focus": {
    outline: "none !important",
    border: "1px solid #178CF2",
    boxShadow: "0 0 5px 2px #C6E5FF",
  },
  "&::-webkit-scrollbar ": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#E5E7EB",
    borderRadius: "6px",
  },
});
