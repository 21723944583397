import { InputLabel, Stack, Typography } from "@mui/material";
import React from "react";
import { IApplicationAdditionalInfoSkills } from "../../../models/ApplicationPage";
import { StyledQuestionTitle, StyledSkillChip } from "../styles";
import IconWithTooltip from "./IconWithTooltip";

const Skills = ({
  t,
  skills,
  label,
  isActive,
}: IApplicationAdditionalInfoSkills) => (
  <Stack spacing={1}>
    <StyledQuestionTitle direction={"row"}>
      <InputLabel>{label}</InputLabel>
      {isActive ? <IconWithTooltip /> : null}
    </StyledQuestionTitle>
    <Stack flexWrap="wrap" direction={"row"} spacing={1} gap={1}>
      {skills?.length ? (
        skills?.map((item: string) => (
          <StyledSkillChip key={item} label={item} />
        ))
      ) : (
        <Typography variant={"subtitle2"}>
          {t("candidate_questions.no_answer")}
        </Typography>
      )}
    </Stack>
  </Stack>
);

export default Skills;
