import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import {
  BriefcaseIcon,
  ChatBubbleOvalLeftIcon,
  CogIcon,
  CreditCardIcon,
  RectangleGroupIcon,
  UserIcon,
  UsersIcon,
  UserGroupIcon,
  ChartBarIcon,
} from "@heroicons/react/24/outline";
import { TFunction } from "i18next";
import { StyledSubItemIconContainer } from "./styles";
import { JOB_STATUSES } from "../../../models/JobList";
import { TEAM_USER_STATUSES } from "../../../models/Team";
import { ISidebarMenuItem, SIDEBAR_MENU_ITEM_IDS } from "../../../models/Menu";

export const getMenuItems = ({
  t,
  isAdmin,
  isAgency,
  agencyMenuItems,
  isCandidate,
}: {
  t: TFunction;
  isAdmin: boolean;
  isAgency: boolean;
  agencyMenuItems: ISidebarMenuItem[];
  isCandidate: boolean;
}) =>
  isCandidate
    ? [
        {
          label: "Dashboard",
          icon: <RectangleGroupIcon />,
          nodeId: SIDEBAR_MENU_ITEM_IDS.DASHBOARD,
          route: `/`,
        },
        {
          label: t("navigation.messages"),
          icon: <ChatBubbleOvalLeftIcon />,
          nodeId: SIDEBAR_MENU_ITEM_IDS.MESSAGES,
          route: `/messages`,
        },
        {
          label: t("navigation.profile"),
          icon: <UserIcon />,
          nodeId: SIDEBAR_MENU_ITEM_IDS.USER_SETTINGS,
          route: `/user-settings`,
        },
      ]
    : isAgency
    ? agencyMenuItems
    : [
        {
          label: "Dashboard",
          icon: <RectangleGroupIcon />,
          nodeId: SIDEBAR_MENU_ITEM_IDS.DASHBOARD,
          route: `/dashboard`,
        },
        {
          label: "Jobs",
          icon: <BriefcaseIcon />,
          nodeId: SIDEBAR_MENU_ITEM_IDS.JOBS,
          route: `/jobs/${JOB_STATUSES.ACTIVE}`,
        },
        {
          label: t("navigation.applications"),
          icon: <UsersIcon />,
          nodeId: SIDEBAR_MENU_ITEM_IDS.APPLICATIONS,
          route: `/applications`,
        },
        {
          label: t("talentPools.talentPools"),
          icon: <UserGroupIcon />,
          nodeId: SIDEBAR_MENU_ITEM_IDS.TALENTPOOLS,
          route: `/talentpools`,
        },
        {
          label: t("navigation.messages"),
          icon: <ChatBubbleOvalLeftIcon />,
          nodeId: SIDEBAR_MENU_ITEM_IDS.MESSAGES,
          route: `/messages`,
        },
        {
          label: t("navigation.analytics"),
          icon: <ChartBarIcon />,
          nodeId: SIDEBAR_MENU_ITEM_IDS.ANALYTICS,
          route: `/analytics`,
        },
        {
          label: "Shop",
          icon: <CreditCardIcon />,
          route: `/discover`,
          nodeId: SIDEBAR_MENU_ITEM_IDS.DISCOVER,
        },
        /* AFTER FINISH inventory        {
          label: "Shop",
          icon: <CreditCardIcon />,
          nodeId: "shop",
          children: [
            {
              label: t("navigation.discover"),
              icon: (
                <StyledSubItemIconContainer>
                  <CircleIcon className={"circle-icon"} />
                </StyledSubItemIconContainer>
              ),
              nodeId: SIDEBAR_MENU_ITEM_IDS.DISCOVER,
              route: `/discover`,
            },
            {
              label: t("navigation.inventory"),
              icon: (
                <StyledSubItemIconContainer>
                  <CircleIcon className={"circle-icon"} />
                </StyledSubItemIconContainer>
              ),
              route: `/inventory`,
            },
          ],
        }, */
        {
          label: t("navigation.settings"),
          icon: <CogIcon />,
          nodeId: "settings",
          children: [
            {
              label: t("navigation.company_profiles"),
              icon: (
                <StyledSubItemIconContainer>
                  <CircleIcon className={"circle-icon"} />
                </StyledSubItemIconContainer>
              ),
              nodeId: SIDEBAR_MENU_ITEM_IDS.COMPANY_SETTINGS,
              route: `/company-settings`,
            },
            {
              label: "Job Widget",
              icon: (
                <StyledSubItemIconContainer>
                  <CircleIcon className={"circle-icon"} />
                </StyledSubItemIconContainer>
              ),
              nodeId: SIDEBAR_MENU_ITEM_IDS.JOB_WIDGET,
              route: `/job-widget`,
            },
            {
              label: t("navigation.workflow"),
              icon: (
                <StyledSubItemIconContainer>
                  <CircleIcon className={"circle-icon"} />
                </StyledSubItemIconContainer>
              ),
              nodeId: SIDEBAR_MENU_ITEM_IDS.WORKFLOW,
              route: `/workflow`,
            },
            {
              label: t("navigation.automation"),
              icon: (
                <StyledSubItemIconContainer>
                  <CircleIcon className={"circle-icon"} />
                </StyledSubItemIconContainer>
              ),
              nodeId: SIDEBAR_MENU_ITEM_IDS.AUTOMATION,
              route: `/automations`,
            },
            isAdmin
              ? {
                  label: "Team",
                  icon: (
                    <StyledSubItemIconContainer>
                      <CircleIcon className={"circle-icon"} />
                    </StyledSubItemIconContainer>
                  ),
                  nodeId: SIDEBAR_MENU_ITEM_IDS.TEAM,
                  route: `/team/settings/${TEAM_USER_STATUSES.JOINED}`,
                }
              : null,
          ],
        },
      ];
