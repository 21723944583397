import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ApplicationStatusUpdatedTriggerComponent from "./component";
import { getAutomationState } from "../../../../store_new/selectors/Automation";
import {
  setCurrentAutomation,
  setTrigger,
} from "../../../../store_new/reducers/Automation";
import { getWorkflowState } from "../../../../store_new/selectors/Workflow";

const ApplicationStatusUpdatedTrigger = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { currentAutomation } = useSelector(getAutomationState);

  const { workflows } = useSelector(getWorkflowState);

  const allStatuses = workflows.flatMap((workflow) =>
    workflow.statuses.map((status) => ({
      label:
        workflow.title === t("workflow.defaultWorkflow")
          ? `${status.label} (${t("workflow.allWorkflows")})`
          : `${status.label} (Workflow:${workflow.title})`,
      value: status.value,
      color: status.color,
    }))
  );

  const allUniqueStatuses = allStatuses.filter(
    (item, index, self) =>
      index === self.findIndex((t) => t.value === item.value)
  );

  const defaultStatuses = [
    {
      label: "New (All Workflows)",
      value: "new",
      color: "#818CF8",
    },
    {
      label: "In Progress (All Workflows)",
      value: "review",
      color: "#F59E0B",
    },
    {
      label: "Hired (All Workflows)",
      value: "hired",
      color: "#22C55E",
    },
    {
      label: "Rejected (All Workflows)",
      value: "rejected",
      color: "#EF4444",
    },
  ];

  const formattedStatuses = allUniqueStatuses.length
    ? allUniqueStatuses
    : defaultStatuses;

  const statuses = useMemo(() => {
    let toStatuses = formattedStatuses;
    let fromStatuses = formattedStatuses;
    if (currentAutomation?.conditions[1]?.value) {
      const filtredFromStatuses = formattedStatuses.filter(
        (status) => status.value !== currentAutomation?.conditions[1]?.value
      );
      fromStatuses = filtredFromStatuses;
    }
    if (currentAutomation?.conditions[0]?.value) {
      const filteredToStatuses = formattedStatuses.filter(
        (status) => status.value !== currentAutomation?.conditions[0]?.value
      );
      toStatuses = filteredToStatuses;
    }
    return {
      toStatuses,
      fromStatuses,
    };
  }, [currentAutomation.conditions]);

  const handleChangeStatus = (value: string, type: string) => {
    if (currentAutomation.trigger) {
      dispatch(
        setCurrentAutomation({
          ...currentAutomation,
          trigger: {
            ...currentAutomation.trigger,
            params: {
              ...currentAutomation.trigger.params,
              jobId: value,
            },
          },
          conditions: [
            {
              condition: "equals",
              param: "old_status",
              value:
                type === "from"
                  ? value
                  : currentAutomation.conditions[0]?.value || "",
            },
            {
              condition: "equals",
              param: "new_status",
              value:
                type === "to"
                  ? value
                  : currentAutomation.conditions[1]?.value || "",
            },
          ],
        })
      );
    }
  };

  const handleSave = () => {
    if (currentAutomation.trigger) {
      dispatch(
        setTrigger({
          ...currentAutomation?.trigger,
          isCompleted: true,
          isOpen: false,
        })
      );
    }
  };

  useEffect(() => {
    if (
      currentAutomation?.conditions &&
      currentAutomation?.conditions[0]?.value &&
      currentAutomation.trigger &&
      !currentAutomation.trigger.isOpen
    ) {
      dispatch(
        setTrigger({
          ...currentAutomation.trigger,
          isCompleted: true,
          isOpen: false,
        })
      );
    }
  }, [currentAutomation]);

  return (
    <ApplicationStatusUpdatedTriggerComponent
      t={t}
      currentAutomation={currentAutomation}
      statuses={statuses}
      statusFrom={currentAutomation?.conditions[0]?.value || ""}
      statusTo={currentAutomation?.conditions[1]?.value || ""}
      onChangeStatus={handleChangeStatus}
      onSave={handleSave}
    />
  );
};

export default ApplicationStatusUpdatedTrigger;
