import React from "react";
import { Draggable } from "react-beautiful-dnd";
import RenderItem from "./RenderItem";
import { IListItemComponent } from "../../models/Kanban";

const ListItem = ({
  t,
  column,
  variant,
  selectedItems,
  isDragDisabled,
  item,
  index,
  setColumns,
  onItemClick,
  onSelectItem,
}: IListItemComponent) => (
  <Draggable
    key={item.id}
    draggableId={item.id}
    isDragDisabled={isDragDisabled}
    index={index}
  >
    {(dragProvided) => (
      <RenderItem
        t={t}
        {...dragProvided.draggableProps}
        {...dragProvided.dragHandleProps}
        variant={variant}
        ref={dragProvided.innerRef}
        provided={dragProvided}
        data={item}
        index={index}
        selectedItems={selectedItems}
        column={column}
        setColumns={setColumns}
        onSelectItem={onSelectItem}
        onItemClick={onItemClick}
      />
    )}
  </Draggable>
);

export default ListItem;
