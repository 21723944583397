import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { Avatar, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import CVSection from "./CVSection";
import { StyledLoadingApplyButton, StyledUserDataContainer } from "../styles";
import { getJobPreviewState } from "../../../store_new/selectors/JobPreview";
import { applyToJob } from "../../../store_new/reducers/JobPreview";
import { getCurrentUserData } from "../../../store_new/selectors/CurrentUser";

const LoggedCandidate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { key: jobUrlKey } = useParams();
  const [searchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source") || "";
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { email, username, firstName, lastName, profile_picture, cv } =
    useSelector(getCurrentUserData);
  const { isApplyInProgress, companyColor } = useSelector(getJobPreviewState);

  const [uploadedCV, setUploadedCV] = useState<FileList | null>(null);
  const [oldCVFileName, setOldCVFileName] = useState<string>("");

  useEffect(() => {
    if (cv?.length) setOldCVFileName(cv.substring(66));
  }, [cv]);

  const handleOnUploadCV = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) setUploadedCV(event.target.files);
  };

  const handleOnResetCV = (event: MouseEvent) => {
    setUploadedCV(null);
    setOldCVFileName("");
    event.preventDefault();
  };

  const handleOnNavigateHome = () => {
    navigate("/");
  };

  const handleOnApply = () => {
    if (email && firstName && lastName && jobUrlKey)
      dispatch(
        applyToJob({
          jobUrlKey,
          body: {
            email,
            firstname: firstName,
            lastname: lastName,
            ...(uploadedCV && { files: uploadedCV }),
            ...(!uploadedCV && { use_latest_cv: true }),
            ...(utm_source && { utm_source }),
          },
          callback: () => {
            navigate(`/candidate/login/additional-questions/${jobUrlKey}`);
          },
        }),
      );
  };

  return (
    <Stack mt={3} spacing={3}>
      <StyledUserDataContainer
        companyColor={companyColor}
        direction={"row"}
        spacing={1.5}
        onClick={handleOnNavigateHome}
      >
        <Avatar
          src={profile_picture || ""}
          alt="profile-photo"
          className="login-avatar"
          data-testid={"apply-job-user-avatar"}
        />
        <Stack spacing={0.5} height="100%" justifyContent="center">
          <Typography
            variant="subtitle2"
            noWrap
            maxWidth={176}
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {firstName} {lastName}
          </Typography>

          <Typography variant="subtitle2" color="textSecondary" fontSize={12}>
            {email || username}
          </Typography>
        </Stack>
        <ChevronRightIcon />
      </StyledUserDataContainer>
      <CVSection
        t={t}
        oldCVFileName={oldCVFileName}
        uploadedCV={uploadedCV?.[0]}
        onUploadCV={handleOnUploadCV}
        onResetCV={handleOnResetCV}
      />
      <StyledLoadingApplyButton
        data-testid={"apply-job-logged-customer-submit-button"}
        onClick={handleOnApply}
        variant={"contained"}
        loadingPosition="end"
        loading={isApplyInProgress}
        companyColor={companyColor}
      >
        {t("apply_page.apply_now")}
      </StyledLoadingApplyButton>
    </Stack>
  );
};

export default LoggedCandidate;
