import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { PayloadActionWithCallback, TListOption } from "../../models/common";
import {
  IJobOrder,
  ISingleJobState,
  JOB_TABS_KEY,
  PREMIUM_ITEM_TYPES,
  TBasicJobBoard,
  TConvertedPremiumProduct,
  TJobDetails,
  TJobLocation,
  TJobOwner,
  TJobQuestions,
  TJobTemplate,
  TPremiumPackage,
  TPremiumProductItem,
} from "../../models/SingleJob";

const initialState: ISingleJobState = {
  navigation: {
    jobTabs: [
      JOB_TABS_KEY.APPLICATIONS,
      JOB_TABS_KEY.JOB_DETAILS,
      JOB_TABS_KEY.BASIC_JOB_BOARDS,
      JOB_TABS_KEY.PREMIUM_JOB_BOARDS,
      JOB_TABS_KEY.BOOKINGS,
    ],
    selectedJobTab: JOB_TABS_KEY.APPLICATIONS,
  },
  filterValue: {
    country: "",
    industry: "",
    name: "",
    duration: "",
  },
  filterOptions: {
    countries: [],
    industries: [],
    countriesToIndustries: {},
    industriesToCountries: {},
    durations: [
      { label: "upTo30", value: "-30" },
      { label: "upTo60", value: "-60" },
      { label: "upTo90", value: "-90" },
      { label: "moreThan90", value: "+90" },
    ],
  },
  jobId: null,
  company: null,
  jobDetails: null,
  jobTemplate: null,
  jobLocations: null,
  jobQuestions: null,
  jobOwners: null,
  packages: null,
  products: null,
  shopPackages: null,
  shopProducts: null,
  totalShopProducts: 0,
  basicJobBoards: [],
  error: false,
  isLoading: false,
  isListLoading: false,
  bookings: undefined,
  tempJobDetails: null,
  isCartOpen: false,
  selectedProducts: [],
  noStepCheckout: false,
  selectedPremiumJobBoardsTab: PREMIUM_ITEM_TYPES.PRODUCTS,
};

const navigationReducers = {
  setSelectedJobTab: (
    state: Draft<ISingleJobState>,
    action: PayloadAction<JOB_TABS_KEY>
  ) => {
    state.navigation.selectedJobTab = action.payload;
  },
  setPremiumJobBoardsTab: (
    state: Draft<ISingleJobState>,
    action: PayloadAction<PREMIUM_ITEM_TYPES>
  ) => {
    state.selectedPremiumJobBoardsTab = action.payload;
  },
};

const cartReducers = {
  setCartOpen(state: Draft<ISingleJobState>, action: PayloadAction<boolean>) {
    state.isCartOpen = action.payload;
  },
  setSelectedProducts(
    state: Draft<ISingleJobState>,
    action: PayloadAction<
      TPremiumPackage[] | TConvertedPremiumProduct[] | TPremiumProductItem[]
    >
  ) {
    state.selectedProducts = action.payload;
  },
  resetSelectedProducts(state: Draft<ISingleJobState>) {
    state.selectedProducts = initialState.selectedProducts;
  },
  setNoStepCheckOut(
    state: Draft<ISingleJobState>,
    action: PayloadAction<boolean>
  ) {
    state.noStepCheckout = action.payload;
  },
};

const filterReducers = {
  setProductsFilter: (
    state: Draft<ISingleJobState>,
    action: PayloadAction<{
      type: "name" | "country" | "industry" | "duration";
      value: string;
    }>
  ) => {
    const { type, value } = action.payload;
    state.filterValue[type] = value;
  },
  resetProductsFilter: (state: Draft<ISingleJobState>) => {
    state.filterValue = initialState.filterValue;
  },
};

const jobDetailsReducers = {
  setJobId: (
    state: Draft<ISingleJobState>,
    action: PayloadAction<{ jobId: string | null }>
  ) => {
    const { jobId } = action.payload;
    state.jobId = jobId;
  },
  fetchJobDetails: (
    state: Draft<ISingleJobState>,
    _: PayloadAction<{ jobUrlKey: string }>
  ) => {
    state.isLoading = true;
  },
  fetchJobDetailsSuccess: (
    state: Draft<ISingleJobState>,
    action: PayloadAction<{
      jobDetails: TJobDetails;
      locations: TJobLocation[];
      questions: TJobQuestions[];
      owners: TJobOwner[];
    }>
  ) => {
    const { jobDetails, locations, questions, owners } = action.payload;
    state.jobDetails = jobDetails;
    state.jobLocations = locations;
    state.jobQuestions = questions;
    state.jobOwners = owners;
    state.error = null;
    state.isLoading = false;
  },
  fetchJobDetailsFailed: (
    state: Draft<ISingleJobState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isLoading = false;
  },
  updateHeaderImage: (
    state: Draft<ISingleJobState>,
    _: PayloadAction<{
      jobUrlKey: string;
      title: string;
      template_name: string;
      template_header_1: File;
      company_id: string;
    }>
  ) => {
    state.isLoading = true;
  },
  updateHeaderImageSuccess: (state: Draft<ISingleJobState>) => {
    state.error = null;
    state.isLoading = false;
  },
  updateHeaderImageFailed: (
    state: Draft<ISingleJobState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isLoading = false;
  },
  fetchJobData: (
    state: Draft<ISingleJobState>,
    _: PayloadActionWithCallback<{ jobId: string }>
  ) => {
    state.isLoading = true;
  },
  fetchJobDataSuccess: (state: Draft<ISingleJobState>) => {
    state.error = null;
    state.isLoading = false;
  },
  fetchJobDataFailed: (
    state: Draft<ISingleJobState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isLoading = false;
  },
  duplicateJob: (
    state: Draft<ISingleJobState>,
    _: PayloadActionWithCallback<{ urlKey: string }>
  ) => {
    state.error = false;
  },
  duplicateJobSuccess: (state: Draft<ISingleJobState>) => {
    state.error = null;
  },
  duplicateJobFailed: (
    state: Draft<ISingleJobState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
  },
  setTempJobDetails: (
    state: Draft<ISingleJobState>,
    action: PayloadAction<TJobDetails | null>
  ) => {
    state.tempJobDetails = action.payload;
  },
  resetSingleJobOwners: (state: Draft<ISingleJobState>) => {
    state.jobOwners = initialState.jobOwners;
  },
};

const jobTemplateReducers = {
  fetchJobTemplate: (
    state: Draft<ISingleJobState>,
    _: PayloadAction<{ templateId: string }>
  ) => {
    state.isLoading = true;
  },
  fetchJobTemplateSuccess: (
    state: Draft<ISingleJobState>,
    action: PayloadAction<{
      template: TJobTemplate;
    }>
  ) => {
    const { template } = action.payload;
    state.jobTemplate = template;
    state.error = null;
    state.isLoading = false;
  },
  fetchJobTemplateFailed: (
    state: Draft<ISingleJobState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isLoading = false;
  },
};

const applicationsReducers = {
  fetchJobApplications: (
    state: Draft<ISingleJobState>,
    _: PayloadActionWithCallback<{ jobId: string; isApplyDialog?: boolean }>
  ) => {
    state.isLoading = true;
  },
  fetchJobApplicationsSuccess: (state: Draft<ISingleJobState>) => {
    state.error = null;
    state.isLoading = false;
  },
  fetchJobApplicationsFailed: (
    state: Draft<ISingleJobState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isLoading = false;
  },
};

const premiumJobBoardsReducers = {
  fetchProductsAndPackages: (
    state: Draft<ISingleJobState>,
    _: PayloadAction<{ urlKey: string }>
  ) => {
    state.isLoading = true;
  },
  fetchProductsAndPackagesSuccess: (
    state: Draft<ISingleJobState>,
    action: PayloadAction<{
      products: TConvertedPremiumProduct[];
      packages: TPremiumPackage[];
      shopProducts?: TConvertedPremiumProduct[];
      shopPackages?: TPremiumPackage[];
      countries: TListOption[];
      industries: TListOption[];
      industriesToCountries: { [x: string]: string[] };
      countriesToIndustries: { [x: string]: number[] };
    }>
  ) => {
    const {
      products,
      packages,
      shopProducts,
      shopPackages,
      countriesToIndustries,
      countries,
      industries,
      industriesToCountries,
    } = action.payload;

    const formattedCountries = countries.map((country: any) => ({
      value: country.label.Id,
      label: country.label.Label,
      iso: country.label.Id,
    }));

    state.products = products;
    state.packages = packages;
    if (shopPackages) {
      state.shopPackages = shopPackages;
    }
    if (shopProducts) {
      state.shopProducts = shopProducts;
      state.totalShopProducts = shopProducts.length;
    }

    state.filterOptions = {
      countries: formattedCountries,
      industries,
      countriesToIndustries,
      industriesToCountries,
      durations: state.filterOptions.durations,
    };
    state.isLoading = false;
    state.error = null;
  },
  fetchProductsAndPackagesFailed: (
    state: Draft<ISingleJobState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isLoading = false;
  },
  resetShopProductsAndPackages: (state: Draft<ISingleJobState>) => {
    state.shopPackages = initialState.shopPackages;
    state.shopProducts = initialState.shopProducts;
    state.totalShopProducts = initialState.totalShopProducts;
  },
};

const basicJobBoardsReducers = {
  fetchBasicJobBoards: (state: Draft<ISingleJobState>) => {
    state.isListLoading = true;
  },
  fetchBasicJobBoardsSuccess: (
    state: Draft<ISingleJobState>,
    action: PayloadAction<{
      list: TBasicJobBoard[];
    }>
  ) => {
    const { list } = action.payload;
    state.basicJobBoards = list;
    state.isListLoading = false;
    state.error = null;
  },
  fetchBasicJobBoardsFailed: (
    state: Draft<ISingleJobState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isListLoading = false;
  },
};

const bookingsReducers = {
  fetchBookings: (
    state: Draft<ISingleJobState>,
    _: PayloadAction<{ jobUrlKey: string }>
  ) => {
    state.isLoading = true;
  },
  fetchBookingsSuccess: (
    state: Draft<ISingleJobState>,
    action: PayloadAction<IJobOrder[]>
  ) => {
    state.bookings = action.payload;
    state.isLoading = false;
    state.error = null;
  },
  fetchBookingsFailed: (
    state: Draft<ISingleJobState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isLoading = false;
  },
};

const resetStateReducers = {
  resetJobDetailsAndBookings: (state: Draft<ISingleJobState>) => {
    state.jobDetails = initialState.jobDetails;
    state.bookings = initialState.bookings;
  },
};

export const SingleJobSlice = createSlice({
  name: "SINGLE_JOB",
  initialState,
  reducers: {
    ...cartReducers,
    ...navigationReducers,
    ...filterReducers,
    ...jobDetailsReducers,
    ...jobTemplateReducers,
    ...applicationsReducers,
    ...basicJobBoardsReducers,
    ...premiumJobBoardsReducers,
    ...bookingsReducers,
    ...resetStateReducers,
  },
});

export const {
  setPremiumJobBoardsTab,
  setCartOpen,
  setSelectedProducts,
  setNoStepCheckOut,
  setProductsFilter,
  setSelectedJobTab,
  setJobId,
  fetchJobDetails,
  fetchJobDetailsSuccess,
  fetchJobDetailsFailed,
  updateHeaderImage,
  updateHeaderImageSuccess,
  updateHeaderImageFailed,
  fetchJobTemplate,
  fetchJobTemplateSuccess,
  fetchJobTemplateFailed,
  fetchJobApplications,
  fetchJobApplicationsSuccess,
  fetchJobApplicationsFailed,
  fetchProductsAndPackages,
  fetchProductsAndPackagesSuccess,
  fetchProductsAndPackagesFailed,
  fetchBasicJobBoards,
  fetchBasicJobBoardsSuccess,
  fetchBasicJobBoardsFailed,
  fetchBookings,
  fetchBookingsFailed,
  fetchBookingsSuccess,
  fetchJobData,
  fetchJobDataFailed,
  fetchJobDataSuccess,
  resetJobDetailsAndBookings,
  duplicateJob,
  duplicateJobFailed,
  duplicateJobSuccess,
  setTempJobDetails,
  resetProductsFilter,
  resetSingleJobOwners,
  resetShopProductsAndPackages,
  resetSelectedProducts,
} = SingleJobSlice.actions;

export default SingleJobSlice.reducer;
