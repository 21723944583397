import React, { useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ADSChartOptions, getPerformedName } from "./utils";
import { useSelector } from "react-redux";
import {
  getAllAnalyticsIsLoadingState,
  getAnalyticsAffectedStatuses,
  getAnalyticsJobsWithStats,
} from "../../store_new/selectors/Analytics";
import { useTranslation } from "react-i18next";
import { StyledChartContainer, StyledChartContainerTitle } from "./styles";
import { Stack } from "@mui/material";
import Loading from "../../components/Loading";
import IconWithTooltip from "./IconWithTooltip";

const AverageDaysInStage = () => {
  const { t } = useTranslation();

  const isLoading = useSelector(getAllAnalyticsIsLoadingState);
  const stages = useSelector(getAnalyticsAffectedStatuses);
  const jobs = useSelector(getAnalyticsJobsWithStats);
  const [width, setWidth] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setWidth("100%");
    }, 1000);
  }, [isLoading]);

  const { series, categories } = useMemo((): {
    categories: string[];
    series: { name: string; data: number[] }[];
  } => {
    let categories: string[] = [];
    let series: { name: string; data: number[] }[] = [];

    if (jobs.length) {
      categories = jobs.map((job) => job.title);
      series = stages.map((stage) => {
        return {
          name: t(getPerformedName({ name: stage, path: "defaultStatuses" })),
          data: jobs.map((job) => {
            const { value, quantity } = job.sumStat[stage] || {};
            return value ? Math.floor(value / quantity) : 0;
          }),
        };
      });
    }

    return { categories, series };
  }, [jobs]);

  return (
    <StyledChartContainer width={width}>
      <StyledChartContainerTitle>
        <h4>{t("analytics.timeToHire")}</h4>
        <IconWithTooltip title={t("analytics.infoText.timeToHire")} />
      </StyledChartContainerTitle>
      {isLoading ? (
        <Stack maxHeight={350}>
          <Loading />
        </Stack>
      ) : (
        <ReactApexChart
          options={{
            ...ADSChartOptions(jobs, stages),
            xaxis: { ...ADSChartOptions(jobs, stages).xaxis, categories },
          }}
          series={series}
          type="bar"
          height={350}
        />
      )}
    </StyledChartContainer>
  );
};

export default AverageDaysInStage;
