import { Stack } from "@mui/material";
import { styled } from "@mui/system";
import { ICurrentAutomation } from "../../models/Automation";

export const StyledAutomationTitleField = styled("input")({
  flex: 1,
  border: 0,
  outline: 0,
  fontSize: 16,
  fontWeight: 500,
  fontFamily: "Roboto,Helvetica,Arial,sans-serif",
  maxWidth: "90%",
});

export const StyledSelectedTriggerAutomationContainer = styled(Stack)(({
  variant,
  currentAutomation,
}: {
  variant: string;
  currentAutomation?: ICurrentAutomation;
}) => {
  const emptyComponent =
    variant === "emptyComponent" && !currentAutomation?.trigger;
  return {
    flexDirection: "row",
    cursor: "pointer",
    width: "100%",
    height: 69,
    alignItems: "center",
    justifyContent: "space-between",
    padding: 16,
    borderRadius: 6,
    background: "#fff",
    opacity: emptyComponent ? 0.7 : 1,
  };
});

export const StyledCreateAutomationContainer = styled(Stack)({
  marginTop: 32,
  height: "100%",
  ".variable-button": {
    left: 16,
    display: "flex !important",
    flexDirection: "row !important",
    color: "#131E30",
    "&:hover": {
      color: "#0066CC",
    },
    svg: {
      position: "relative",
      right: 4,
      rotate: "90deg",
    },
  },
  ".inactive-trigger-button": {
    background: "#EFF6FF",
    color: "#3B82F6",
    "&:disabled": {
      color: "#C6E5FF",
    },
    "&:hover": {
      backgroundColor: "#C6E5FF",
    },
  },
  ".trigger-icon": {
    width: 24,
    height: 24,
    stroke: "#6366F1",
    strokeWidth: 1.67,
  },
  ".action-icon": {
    width: 24,
    height: 24,
    stroke: "#3B82F6",
    strokeWidth: 1.67,
  },
  ".job-created-icon": {
    width: 24,
    height: 24,
    stroke: "#6366F1",
    rotate: "180deg",
    strokeWidth: 1.67,
  },
  ".triggers-container": {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 12,
    minHeight: 100,
    maxHeight: "calc(100vh - 358px)",
    paddingBottom: 3,
    overflow: "auto",
    "&::-webkit-scrollbar ": {
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#E5E7EB",
      borderRadius: "6px",
    },
  },
  ".automation-info-container": {
    padding: 16,
    width: "35%",
    gap: 16,
    borderRadius: 6,
    background: "#fff",
    minHeight: 100,
    maxHeight: "calc(100vh - 214px)",
    overflow: "auto",
    "&::-webkit-scrollbar ": {
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#E5E7EB",
      borderRadius: "6px",
    },
  },
  ".selection-container": {
    width: "65%",
    padding: 16,
    borderRadius: 6,
    background: "#fff",
    minHeight: 100,
    maxHeight: "calc(100vh - 214px)",
    overflow: "auto",
    "&::-webkit-scrollbar ": {
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#E5E7EB",
      borderRadius: "6px",
    },
  },
  ".required-label": {
    textTransform: "capitalize",
    "&::after": {
      content: '"*"',
      position: "absolute",
      marginLeft: 4,
      color: "#EF4444",
      fontSize: 20,
    },
  },
  ".cc-bcc": {
    lineHeight: "20px",
    color: "#3B82F6",
    cursor: "pointer",
    fontWeight: 500,
    fontSize: 14,
  },
  ".ql-container": {
    maxHeight: window.innerHeight > 703 ? "auto" : 118,
  },
});

export const StyledDeleteNotCompletedIcon = styled(Stack)(
  ({ background }: { background: string }) => ({
    width: 32,
    height: 32,
    padding: 6.5,
    cursor: "pointer",
    borderRadius: "50%",
    background,
  })
);

export const StyledSendMailFormContainer = styled(Stack)({
  marginTop: 16,
  paddingRight: 6,
  overflow: "auto",
  "&::-webkit-scrollbar ": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#E5E7EB",
    borderRadius: "6px",
  },
});
