import { styled } from "@mui/system";
import { Stack } from "@mui/material";

export const StyledJobContainer = styled(Stack)(
  ({ companyColor }: { readonly companyColor?: string }) => ({
    alignItems: "center",
    background: "#fff",
    width: "100%",
    border: "1px solid #E5E7EB",
    borderRadius: "6px",
    cursor: "pointer",
    svg: { width: 20, height: 20, strokeWidth: 1, stroke: companyColor },
    ".company-logo": {
      height: 56,
      width: 56,
      stroke: "#131E30",
      strokeWidth: 2
    },
    ".next-icon": {
      stroke: "#131E30",
      strokeWidth: 1.5
    },
    ":hover": {
      background: "rgba(0, 0, 0, 0.04)",
      border: `1px solid ${companyColor || "#6B7280"}`,
      ".next-icon": {
        stroke: companyColor || "#6B7280"
      }
    }
  })
);

export const StyledJobItemContainer = styled(Stack)({
  alignItems: "center"
});
