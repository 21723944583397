import React from "react";
import { Stack, Typography } from "@mui/material";
import EmptyData from "../../components/EmptyData";
import Loading from "../../components/Loading";
import CandidateApplication from "./CandidateApplication";
import {
  ICandidateDashboardComponent,
  TCandidateApplication,
} from "../../models/ApplicationPage";

const CandidateDashboardComponent = ({
  t,
  desktop,
  isLoading,
  applications,
  isCandidate,
  onSelectJob,
  allStatuses,
}: ICandidateDashboardComponent) =>
  isLoading ? (
    <Loading />
  ) : isCandidate ? (
    applications && applications.length > 0 ? (
      <Stack spacing={1.5} mt={3}>
        <Typography variant="subtitle2" color="textSecondary">
          {t("candidate_dashboard.subtitle")}
        </Typography>
        {applications?.map((application: TCandidateApplication) => (
          <CandidateApplication
            key={application.id}
            t={t}
            desktop={desktop}
            application={application}
            allStatuses={allStatuses}
            onSelectJob={onSelectJob}
          />
        ))}
      </Stack>
    ) : (
      <EmptyData title={t("candidate_dashboard.no_jobs") as string} />
    )
  ) : (
    <></>
  );

export default CandidateDashboardComponent;
