export const getTaggedIds = (value: string) => {
  const ID_REGEX = /\[@(.*?)\]\((.*?)\)/g;
  const matches = value.matchAll(ID_REGEX);
  const uniqueIds = new Set(Array.from(matches, (match) => match[2]));
  return Array.from(uniqueIds);
};

export const getFormattedText = (string: string) => {
  const pattern = /@\[@(.*?)\]\((\d+)\)/g;
  return string.replace(pattern, "@$1");
};
