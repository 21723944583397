import React from "react";
import { Stack, Typography } from "@mui/material";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { StyledDiscountLabel } from "../../Checkout/styles";
import { StyledMenuButton } from "../styles";

const SubPageMainHeader = ({
  title,
  mainRoute,
  discount,
}: {
  readonly title: string;
  readonly mainRoute: string;
  readonly discount?: string;
}) => {
  const navigate = useNavigate();

  const handleOnNavigateBack = () => {
    navigate(mainRoute);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="center"
    >
      <StyledMenuButton onClick={handleOnNavigateBack}>
        <ArrowLeftIcon />
      </StyledMenuButton>
      <Typography color="#131E30" fontSize={20} fontWeight={500}>
        {title}
      </Typography>
      {discount && <StyledDiscountLabel>{discount} %</StyledDiscountLabel>}
    </Stack>
  );
};

export default SubPageMainHeader;
