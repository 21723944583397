import { Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { StyledStatusChip } from "../../../../components/DataGrid_new/styles";
import StyledSectionTitle from "../../../../components/SectionTitle";
import { IJodBasicQuestionsPreviewComponent } from "../../../../models/SingleJob";
import { getJobBasicQuestionsFields } from "./config";
import { StyledSectionContainer } from "./styles";

const JobBasicQuestionsPreview = ({
  t,
  jobDetails,
  onEdit,
}: IJodBasicQuestionsPreviewComponent) => (
  <StyledSectionContainer spacing={2}>
    <StyledSectionTitle
      title={t("singleJob.jobDetails.titles.applicationProcess")}
      onClick={onEdit}
    />
    <Divider />
    {getJobBasicQuestionsFields({ t, jobDetails }).map((field) => (
      <Stack
        key={field.title}
        direction={"row"}
        justifyContent={"space-between"}
      >
        <Typography variant={"subtitle2"} color={"#6B7280"}>
          {field.title}
        </Typography>
        <StyledStatusChip label={field.status} type={field.status} />
      </Stack>
    ))}
  </StyledSectionContainer>
);

export default JobBasicQuestionsPreview;
