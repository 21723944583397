import React from "react";
import { Stack, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { redirectionPrivacy, redirectionTerms } from "../../utils";
import { StyledLink } from "../Checkout/styles";
import { StyledTermsConditionsContainer } from "../Checkout/styles";

const TermsFooter = () => {
  const { t } = useTranslation();
  return (
    <Stack
      width="100%"
      alignItems="center"
      justifyContent="center"
      my={4}
      spacing={3}
    >
      <Divider sx={{ borderColor: "#E5E7EB", width: "100%" }} />
      <StyledTermsConditionsContainer
        direction={"row"}
        spacing={3}
        fontSize={14}
      >
        <StyledLink onClick={redirectionTerms}>{t("terms")}</StyledLink>
        <StyledLink onClick={redirectionPrivacy}>{t("privacy")}</StyledLink>
      </StyledTermsConditionsContainer>
    </Stack>
  );
};

export default TermsFooter;
