import React from "react";
import { Box, Button, CircularProgress, Link, Typography } from "@mui/material";
import AuthLayout from "../../../layouts/Auth";
import { IResendPasswordComponent } from "../../../models/Auth";

const ResendPasswordComponent_new = ({
  t,
  isDisabled,
  isLoading,
  desktop,
  onReset,
  onReturn,
}: IResendPasswordComponent) => (
  <AuthLayout desktop={desktop}>
    <Typography variant="body1" lineHeight={2.5}>
      {t("reset_password_page.check_inbox")}
    </Typography>
    <Typography variant="subtitle2" color="textSecondary">
      {t("reset_password_page.email_exist_text")}
    </Typography>
    <Typography variant="subtitle2" color="textSecondary">
      {t("reset_password_page.email_exist_second_text")}
    </Typography>
    <Typography variant="subtitle2" color="textSecondary" mt={3} mb={3}>
      {t("reset_password_page.check_spam")}
    </Typography>
    <Box>
      <Button
        color={"primary"}
        variant={"contained"}
        size={"small"}
        onClick={onReset}
        disableElevation
        fullWidth
        endIcon={
          isLoading ? (
            <CircularProgress
              size={16}
              style={{
                color: "#9A9FAF",
              }}
            />
          ) : null
        }
        disabled={isLoading || isDisabled}
      >
        {t("reset_password_page.button")}
      </Button>
    </Box>
    <Link
      variant="subtitle2"
      underline="none"
      display="inline"
      onClick={onReturn}
    >
      {t("reset_password_page.link")}
    </Link>
  </AuthLayout>
);

export default ResendPasswordComponent_new;
