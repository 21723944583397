import * as yup from "yup";
import { TFunction } from "i18next";
import { ISendEmailFormField } from "../../../../models/Automation";
import { isEmptyHtmlString } from "../../../../utils/singleJob";

export const getSendEmailFormFields = (t: TFunction) =>
  yup.object().shape({
    to: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string(),
          value: yup.string(),
        })
      )
      .nullable()
      .transform((val) => (!val ? [] : val))
      .min(1, t("register.message") as string),
    subject: yup.string().required(t("register.message") as string),
    message: yup
      .string()
      .transform((htmlString: string) =>
        isEmptyHtmlString(htmlString) ? null : htmlString
      )
      .required(t("register.message") as string),
    cc: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string(),
          value: yup.string(),
        })
      )
      .nullable()
      .transform((val) => (!val ? [] : val)),
    bcc: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string(),
          value: yup.string(),
        })
      )
      .nullable()
      .transform((val) => (!val ? [] : val)),
  });

export const sendEmailFormFieldsDefaultValue: ISendEmailFormField = {
  to: [],
  subject: "",
  message: "",
  cc: [],
  bcc: [],
};
