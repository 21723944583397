import React, { useEffect, useMemo, useRef, useState } from "react";
import { IExpandedBlock } from "../../models/common";
import ExpandedBlockComponent from "./component";

const ExpandableBlock = ({ t, title, body }: IExpandedBlock) => {
  const bodyRef = useRef<HTMLElement>(null);
  const [isRendered, setIsRendered] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const handleOnExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (body && bodyRef.current) setIsRendered(true);
  }, [body, bodyRef.current]);

  const isLongText = useMemo(() => {
    if (bodyRef.current) {
      return bodyRef.current.clientHeight > 80;
    }
    return false;
  }, [bodyRef?.current]);

  return (
    <ExpandedBlockComponent
      t={t}
      title={title}
      body={body}
      bodyRef={bodyRef}
      isLongText={isLongText}
      isExpanded={isExpanded}
      isRendered={isRendered}
      onExpand={handleOnExpand}
    />
  );
};

export default ExpandableBlock;
