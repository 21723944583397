import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MobileChatTopHeaderComponent from "./component";
import { getMessagesState } from "../../../store_new/selectors/Messages";
import { resetCurrentMessageState } from "../../../store_new/reducers/Messages";
import {
  getCurrentUserData,
  getCurrentUserIsCandidate,
} from "../../../store_new/selectors/CurrentUser";
import { TApplication } from "../../../models/Applications";
import { getAllApplications } from "../../../store_new/selectors/Applications";
import { StyledHeaderTitle } from "../styles";
import { setMobileDrawerOpen } from "../../../store_new/reducers/Navigation";

const SingleApplicationCandidateHeader = ({
  desktop,
}: {
  desktop: boolean;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { username } = useSelector(getCurrentUserData);
  const isCandidate = useSelector(getCurrentUserIsCandidate);
  const allApplications = useSelector(getAllApplications);
  const { messagesInfo, isLoading } = useSelector(getMessagesState);

  const [activeCandidate, setActiveCandidate] = useState<TApplication | null>(
    null
  );

  const handleOnNavigateBack = () => {
    dispatch(dispatch(resetCurrentMessageState()));
  };

  const handleOpenCandidate = () => {
    if (isCandidate) {
      navigate(`/job/${messagesInfo?.job_url_key}`);
    } else {
      navigate(
        `/applications/candidate/${activeCandidate?.candidate_url_key}/${activeCandidate?.job_id}`
      );
    }
  };

  useEffect(() => {
    if (isCandidate) {
      const findCandidate: any = allApplications?.find((item: TApplication) => {
        return item.id == messagesInfo?.application_id;
      });
      if (findCandidate) {
        const fullCandidate: TApplication = {
          email: username,
          ...findCandidate,
        };
        setActiveCandidate(fullCandidate);
      }
    } else {
      const findCandidate = allApplications?.find(
        (item) => Number(item?.application_id) == messagesInfo?.application_id
      );
      if (findCandidate) {
        setActiveCandidate(findCandidate);
      }
    }
  }, [messagesInfo]);

  return !desktop && messagesInfo ? (
    <MobileChatTopHeaderComponent
      t={t}
      desktop={desktop}
      messagesInfo={messagesInfo}
      isLoading={isLoading}
      isCandidate={isCandidate as boolean}
      onNavigateBack={handleOnNavigateBack}
      handleOpenCandidate={handleOpenCandidate}
    />
  ) : (
    <Stack direction="row" spacing={1.5}>
      {!desktop && (
        <Bars3Icon
          className="menu-icon"
          onClick={() => dispatch(setMobileDrawerOpen(true))}
        />
      )}
      <StyledHeaderTitle desktop={desktop}>
        {t("navigation.messages")}
      </StyledHeaderTitle>
    </Stack>
  );
};

export default SingleApplicationCandidateHeader;
