import React from "react";
import { Stack, Typography, Button } from "@mui/material";
import NoDataImg from "../../assets/no_jobs.png";

const EmptyData = ({
  title,
  subtitle,
  buttonText,
  onButtonClick,
}: {
  readonly title: string;
  readonly subtitle?: string;
  readonly buttonText?: string;
  readonly onButtonClick?: () => void;
}) => (
  <Stack height="100%" alignItems="center" justifyContent="center" spacing={4}>
    <img src={NoDataImg} alt="no-data-img" width={204.25} height={200} />
    <Stack spacing={1} alignItems="center">
      <Typography fontWeight={500}>{title}</Typography>
      {subtitle && (
        <Typography variant="subtitle2" color="textSecondary" align="center">
          {subtitle}
        </Typography>
      )}
    </Stack>
    {buttonText && (
      <Button variant="contained" onClick={onButtonClick}>
        {buttonText}
      </Button>
    )}
  </Stack>
);

export default EmptyData;
