import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getCurrentApplication,
  getSelectedApplicationNoteList,
  getSelectedApplicationNoteListLoadingState,
  getSelectedJobOwners,
} from "../../store_new/selectors/Applications";
import React, { useEffect, useMemo, useState } from "react";
import {
  addNewNote,
  addNewNoteComment,
  deleteNote,
  fetchApplicationNoteList,
} from "../../store_new/reducers/Applications";
import Loading from "../Loading";
import CandidateMessagesComponent from "./component";
import { OnChangeHandlerFunc } from "react-mentions";
import { getTaggedIds } from "./utils";

const CandidateMessages_new = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoading = useSelector(getSelectedApplicationNoteListLoadingState);
  const noteList = useSelector(getSelectedApplicationNoteList);
  const currentApplication = useSelector(getCurrentApplication);
  const selectedJobOwners = useSelector(getSelectedJobOwners);

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (currentApplication?.application_id)
      dispatch(fetchApplicationNoteList());
  }, []);

  const displayedNoteList = useMemo(
    () =>
      noteList.map((note) => {
        const selectedUser = selectedJobOwners?.find(
          (jobOwner) => jobOwner.id === note.note.author_id,
        );
        return {
          ...note,
          note: { ...note.note, avatar: selectedUser?.profile_photo || "" },
        };
      }),
    [noteList, selectedJobOwners],
  );

  const handleOnChangeInput = (_: any, __: any, newPlainText: string) => {
    setInputValue(newPlainText);
  };

  const handleOnSubmitNote = () => {
    dispatch(
      addNewNote({ note: inputValue, tagged_ids: getTaggedIds(inputValue) }),
    );
    setInputValue("");
  };

  const handleOnSubmitNoteComment = ({
    comment,
    note_id,
  }: {
    comment: string;
    note_id: string;
  }) => {
    dispatch(addNewNoteComment({ comment, note_id }));
  };

  const handleOnDeleteNote = (note_id: string) => {
    dispatch(deleteNote(note_id));
  };

  return isLoading ? (
    <Loading />
  ) : (
    <CandidateMessagesComponent
      t={t}
      inputValue={inputValue}
      noteList={displayedNoteList}
      onDeleteNote={handleOnDeleteNote}
      onChangeInput={handleOnChangeInput as OnChangeHandlerFunc}
      onSubmit={handleOnSubmitNote}
      onSubmitComment={handleOnSubmitNoteComment}
    />
  );
};

export default CandidateMessages_new;
