import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { confirmExistingCompanyToAgency } from "../../../store_new/reducers/Registration";
import { fetchUser } from "../../../store_new/reducers/CurrentUser";

const AgencyExistingCompanyConfirmation_new = () => {
  const dispatch = useDispatch();
  const { urlKey } = useParams();

  useEffect(() => {
    dispatch(
      confirmExistingCompanyToAgency({
        code: urlKey as string,
        callback: () => {
          dispatch(fetchUser());
        },
      })
    );
  }, []);

  return <></>;
};

export default AgencyExistingCompanyConfirmation_new;
