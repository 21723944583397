import React, { ReactNode } from "react";
import { Stack, Typography } from "@mui/material";
import { openUrlnNewTab } from "../../utils";

export default function WebSiteItem({
  url,
  text,
  icon,
  companyColor,
}: {
  readonly url?: string;
  readonly text: string;
  readonly icon: ReactNode;
  readonly companyColor?: string;
}) {
  return (
    <Stack
      px={2}
      direction="row"
      spacing={1}
      border="1px solid #E5E7EB"
      borderRadius="100px"
      color="#131E30"
      alignItems="center"
      justifyContent="flex-end"
      height={40}
      sx={{
        cursor: "pointer",
        "&:hover": {
          border: `1px solid ${companyColor} !important`,
        },
      }}
      onClick={() => openUrlnNewTab(url)}
    >
      {icon}

      <Typography fontSize={14} fontWeight={500} paddingTop={0.5}>
        {text}
      </Typography>
    </Stack>
  );
}
