import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import CreateNoteComponent from "./component";
import {
  IAutomationAction,
  ICreateNoteFormField,
  ISelectedActionComponent,
} from "../../../../models/Automation";
import { getAutomationState } from "../../../../store_new/selectors/Automation";
import {
  setActions,
  setSelectedAction,
} from "../../../../store_new/reducers/Automation";
import {
  getCreateNoteFormFields,
  createNoteFormFieldsDefaultValue,
} from "./config";

const CreateNote = ({
  t,
  selectedAction,
  onCloseAction,
}: ISelectedActionComponent) => {
  const dispatch = useDispatch();

  const { currentAutomation } = useSelector(getAutomationState);
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    defaultValues: createNoteFormFieldsDefaultValue,
    resolver: yupResolver(getCreateNoteFormFields(t)),
  });

  const formattedVariables = useMemo(() => {
    if (currentAutomation.trigger) {
      const variables = Object.entries(currentAutomation.trigger.params).map(
        ([key]) => ({
          value: key,
          label: t(`automation.variables.${key}`),
        })
      );
      return variables;
    }

    return [];
  }, [currentAutomation.trigger?.params]);

  const onSubmit = (data: ICreateNoteFormField) => {
    const formattedData = {
      message: data.message.replace(/<p>|<\/p>/g, ""),
      application_id: "{application_id}",
    };
    const updatedActions = currentAutomation.actions.map(
      (action: IAutomationAction) => {
        if (action.id === selectedAction?.id) {
          return {
            ...selectedAction,
            actionCompleted: true,
            params: formattedData,
          };
        }
        return action;
      }
    );
    dispatch(setActions(updatedActions as IAutomationAction[]));
    dispatch(setSelectedAction(undefined));
  };

  useEffect(() => {
    if (selectedAction?.params.message !== "string") {
      setValue("message", selectedAction?.params.message as string);
    }
  }, [selectedAction]);

  return (
    <CreateNoteComponent
      t={t}
      formattedVariables={formattedVariables}
      errors={errors}
      control={control}
      selectedAction={selectedAction}
      onCloseAction={onCloseAction}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
    />
  );
};

export default CreateNote;
