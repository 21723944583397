import React from "react";
import { Stack } from "@mui/material";
import ListItem from "./ListItem";
import { IListContainerComponent } from "../../models/Kanban";
import { StyledAddWorkflowStatusButton } from "./style";

const InnerList = ({
  t,
  dropProvided,
  variant,
  selectedItems,
  column,
  isDragDisabled,
  isSelectWorkflowDialogOpen,
  editWorkflow,
  onAddNewStatus,
  setColumns,
  onSelectItem,
  onItemClick,
}: IListContainerComponent) => (
  <Stack
    ref={dropProvided.innerRef}
    spacing="8px !important"
    pb={variant === "candidate" ? 10 : 0}
  >
    {column?.items?.map((item, index) => (
      <ListItem
        key={item.id}
        t={t}
        isDragDisabled={isDragDisabled}
        column={column}
        variant={variant}
        selectedItems={selectedItems}
        item={item}
        index={index}
        setColumns={setColumns}
        onSelectItem={onSelectItem}
        onItemClick={onItemClick}
      />
    ))}
    {dropProvided.placeholder}
    {variant === "workflowStatus" &&
      !isSelectWorkflowDialogOpen &&
      editWorkflow?.id != 0.1 && (
        <StyledAddWorkflowStatusButton onClick={onAddNewStatus}>
          + {t("workflow.addNewState")}
        </StyledAddWorkflowStatusButton>
      )}
  </Stack>
);

export default InnerList;
