import React from "react";
import { Stack, Typography, Divider } from "@mui/material";

export const MobileHeaderWithDivider = ({ title }: { title: string }) => {
  return (
    <Stack spacing={2.25} mb={3}>
      <Typography variant="body1" color="#000000">
        {title}
      </Typography>
      <Divider />
    </Stack>
  );
};

export default MobileHeaderWithDivider;
