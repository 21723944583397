import { TFunction } from "i18next";
import * as yup from "yup";

export const getTalentProfileFormFields = (t: TFunction) => {
  return yup.object().shape({
    firstname: yup.string().required(t("register.message") as string),
    lastname: yup.string().required(t("register.message") as string),
    email: yup
      .string()
      .email()
      .required(t("register.message") as string),
    phonenumber: yup.string().required(t("register.message") as string),
    location: yup.string().required(t("register.message") as string),
    country: yup.string(),
    cv: yup.mixed(),
  });
};

export const profileDefaultValues = {
  firstname: "",
  lastname: "",
  email: "",
  phonenumber: "",
  location: "",
  country: "",
  cv: {},
};
