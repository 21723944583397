import { TFunction } from "i18next";
import * as yup from "yup";

export const getFormSettings = (t: TFunction) => {
  return yup
    .object()
    .shape({
      title: yup
        .string()
        .required(t("customers.no_selected_customer") as string),
    })
    .required();
};
