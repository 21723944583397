import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  addUpdateWorkflow,
  addUpdateWorkflowFailed,
  addUpdateWorkflowSuccess,
  deleteWorkflow,
  deleteWorkflowFailed,
  deleteWorkflowwSuccess,
  fetchWorkflows,
  fetchWorkflowsFailed,
  fetchWorkflowsSuccess,
} from "../reducers/Workflow";
import {
  apiAddUpdateWorkflow,
  apiDeleteWorkflow,
  apiFetchWorkflows,
  apiResetWorkflow,
} from "../../api/Workflows";
import { IWorkflow, TApiAddUpdateWorkflow } from "../../models/Workflow";
import {
  getWorkflowStatuses,
  translateDefaultWorkflowStatuses,
} from "../../utils";
import { PayloadActionWithCallback } from "../../models/common";
import { getWorkflowState } from "../selectors/Workflow";

const baseUrl = "/company/workflows";

function* handleOnFetchWorkflows({ payload }: { payload: IWorkflow[] }) {
  try {
    const apiPayload = {
      pagesize: -1,
    };
    const { data } = yield call(apiFetchWorkflows, {
      url: `${baseUrl}`,
      apiPayload,
    });

    const formattedWorkflows = [...data.workflows].map(
      (workflow: IWorkflow) => ({
        id: workflow.id,
        title: workflow.title,
        is_default: workflow.is_default,
        created_at: workflow.created_at,
        updated_at: workflow.updated_at,
        statuses: getWorkflowStatuses(workflow.statuses),
      })
    );

    const translatedWorkflows =
      translateDefaultWorkflowStatuses(formattedWorkflows);

    const allWorkflows = payload.concat(translatedWorkflows);

    yield put(fetchWorkflowsSuccess(allWorkflows));
  } catch (e) {
    yield put(fetchWorkflowsFailed(e)); // TODO handle error
  }
}

function* handleOnAddUpdateWorkflow({
  payload,
}: PayloadActionWithCallback<TApiAddUpdateWorkflow>) {
  const { workflows } = yield select(getWorkflowState);
  const { id, title, statuses, is_default, callback } = payload;
  try {
    const url =
      id && workflows.length > 0 ? `${baseUrl}/update/${id}` : `${baseUrl}/add`;
    const apiPayload = {
      id: id && workflows.length > 0 ? id : undefined,
      title,
      statuses: { colors: statuses },
      is_default,
    };
    yield call(apiAddUpdateWorkflow, {
      url,
      apiPayload,
    });
    yield put(addUpdateWorkflowSuccess);
    yield call(callback);
  } catch (e) {
    yield put(addUpdateWorkflowFailed(e)); // TODO handle error
  }
}

function* handleOnDeleteWorkflow({
  payload,
}: PayloadActionWithCallback<{ id: number }>) {
  const { id, callback } = payload;
  try {
    const url = `${baseUrl}/delete/${id}`;
    const resetWorkflowUrl = `${baseUrl}/reset/${id}`;
    yield call(apiResetWorkflow, {
      url: resetWorkflowUrl,
    });
    yield call(apiDeleteWorkflow, {
      url,
    });
    yield put(deleteWorkflowwSuccess);
    yield call(callback);
  } catch (e) {
    yield put(deleteWorkflowFailed(e)); // TODO handle error
  }
}

function* WorkflowsSaga() {
  yield takeLatest(fetchWorkflows, handleOnFetchWorkflows);
  yield takeLatest(addUpdateWorkflow, handleOnAddUpdateWorkflow);
  yield takeLatest(deleteWorkflow, handleOnDeleteWorkflow);
}

export default WorkflowsSaga;
