import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const StyledStatusContainer = styled(Stack)(({
  status,
}: {
  status: string;
}) => {
  const baseStyle = {
    justifyContent: "center",
    alignItems: "center",
    height: 20,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: "100px",
    width: "min-content",
  };
  switch (status) {
    case "new":
      return {
        ...baseStyle,
        backgroundColor: "#EEF2FF",
      };
    case "review":
      return {
        ...baseStyle,
        backgroundColor: "#FFFBEB",
      };
    case "rejected":
      return {
        ...baseStyle,
        backgroundColor: "#FEF2F2",
      };
    case "offline":
      return {
        ...baseStyle,
        backgroundColor: "#FEF2F2",
      };
    case "hired":
      return {
        ...baseStyle,
        backgroundColor: "#F0FDF4",
      };
    case "Active":
      return {
        ...baseStyle,
        backgroundColor: "#DCFCE7",
      };
    case "online":
      return {
        ...baseStyle,
        backgroundColor: "#DCFCE7",
      };
    case "Aktiv":
      return {
        ...baseStyle,
        backgroundColor: "#DCFCE7",
      };
    case "Inactive":
      return {
        ...baseStyle,
        backgroundColor: "#FEF2F2",
      };
    case "Inaktiv":
      return {
        ...baseStyle,
        backgroundColor: "#FEF2F2",
      };
    case "Joined":
      return {
        ...baseStyle,
        backgroundColor: "#DCFCE7",
      };
    case "Pending":
      return {
        ...baseStyle,
        backgroundColor: "#FFFBEB",
      };
    case "Admin":
      return {
        ...baseStyle,
        backgroundColor: "#EEF2FF",
      };
    case "Recruiter":
      return {
        ...baseStyle,
        backgroundColor: "#F3F4F6",
      };
    case "Archived":
      return {
        ...baseStyle,
        backgroundColor: "#FEF2F2",
      };
    case "not_started":
      return {
        ...baseStyle,
        backgroundColor: "#EDF7FF",
      };
    case "in_production":
      return {
        ...baseStyle,
        backgroundColor: "#EDF7FF",
      };
    case "customer_action_pending":
      return {
        ...baseStyle,
        backgroundColor: "#EDF7FF",
      };
    case "published":
      return {
        ...baseStyle,
        backgroundColor: "#DCFCE7",
      };
    case "finished":
      return {
        ...baseStyle,
        backgroundColor: "#DCFCE7",
      };
    case "aborted":
      return {
        ...baseStyle,
        backgroundColor: "#FEF2F2",
      };
    case "Ausgelaufen":
      return {
        ...baseStyle,
        backgroundColor: "#FEF2F2",
      };
    case "Expired":
      return {
        ...baseStyle,
        backgroundColor: "#FEF2F2",
      };
    case "Archiviert":
      return {
        ...baseStyle,
        backgroundColor: "#FEF2F2",
      };
  }
  return {
    ...baseStyle,
    color: "#6B7280",
  };
});

export const StyledStatusText = styled(Typography)(({
  status,
}: {
  status: string;
}) => {
  const baseStyle = {
    fontSize: 12,
  };
  switch (status) {
    case "new":
      return {
        ...baseStyle,
        color: "#818CF8",
      };
    case "review":
      return {
        ...baseStyle,
        color: "#F59E0B",
      };
    case "rejected":
      return {
        ...baseStyle,
        color: "#EF4444",
      };
    case "offline":
      return {
        ...baseStyle,
        color: "#EF4444",
      };
    case "hired":
      return {
        ...baseStyle,
        color: "#22C55E",
      };
    case "Active":
      return {
        ...baseStyle,
        color: "#22C55E",
      };
    case "online":
      return {
        ...baseStyle,
        color: "#22C55E",
      };
    case "Aktiv":
      return {
        ...baseStyle,
        color: "#22C55E",
      };
    case "Inactive":
      return {
        ...baseStyle,
        color: "#EF4444",
      };
    case "Inaktiv":
      return {
        ...baseStyle,
        color: "#EF4444",
      };
    case "Joined":
      return {
        ...baseStyle,
        color: "#22C55E",
      };
    case "Pending":
      return {
        ...baseStyle,
        color: "#F59E0B",
      };
    case "Admin":
      return {
        ...baseStyle,
        color: "#818CF8",
      };
    case "Recruiter":
      return {
        ...baseStyle,
        color: "#6B7280",
      };
    case "Archived":
      return {
        ...baseStyle,
        color: "#EF4444",
      };
    case "not_started":
      return {
        ...baseStyle,
        color: "#178CF2",
      };
    case "in_production":
      return {
        ...baseStyle,
        color: "#178CF2",
      };
    case "customer_action_pending":
      return {
        ...baseStyle,
        color: "#178CF2",
      };
    case "published":
      return {
        ...baseStyle,
        color: "#22C55E",
      };
    case "finished":
      return {
        ...baseStyle,
        color: "#22C55E",
      };
    case "aborted":
      return {
        ...baseStyle,
        color: "#EF4444",
      };
    case "Ausgelaufen":
      return {
        ...baseStyle,
        color: "#EF4444",
      };
    case "Expired":
      return {
        ...baseStyle,
        color: "#EF4444",
      };
    case "Archiviert":
      return {
        ...baseStyle,
        color: "#EF4444",
      };
  }
  return {
    ...baseStyle,
    color: "#6B7280",
  };
});
