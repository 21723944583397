import { Stack, TextField } from "@mui/material";
import { styled } from "@mui/system";

export const StyledInviteTeamMemberContainer = styled(Stack)({
  background: "#fff",
  padding: 24,
  "& hr": {
    margin: "20px 0px 20px 0px",
  },
  "& .MuiChip-root": {
    position: "relative",
    bottom: 16,
  },
});

export const StyledTextField = styled(TextField)({
  width: "100%",
  textDecoration: "none",
  textTransform: "none",
  "& .MuiInputBase-input": {
    position: "relative",
    bottom: 8,
  },
});

export const StyledSelectedMemberContainerWrapper = styled(Stack)({
  height: 176,
  border: "1px solid #E5E7EB",
  borderRadius: 6,
  overflow: "hidden auto",
  "&::-webkit-scrollbar ": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#E5E7EB",
    borderRadius: "6px",
  },
});

export const StyledSelectedMemberContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  gap: 8,
  padding: 8,
});

export const StyledSelectedMemberItem = styled(Stack)({
  "& div": {
    background: "#EDF7FF",
    borderRadius: 24,
    flexDirection: "row",
    gap: 6,
    alignItems: "center",
    padding: "2px 12px",
    color: "#178CF2",
  },
  "& svg": {
    width: 16,
    height: 16,
    strokeWidth: 1.13,
    stroke: "#178CF2",
    cursor: "pointer",
  },
});
