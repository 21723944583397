import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ProductPageComponent from "./component";
import { getActiveProduct } from "../../store_new/selectors/CurrentUser";
import { setIsBuyProductDialogOpen } from "../../store_new/reducers/dialogs";
import {
  TConvertedPremiumProduct,
  TPremiumPackage,
  TPremiumProductItem,
} from "../../models/SingleJob";
import { getSingleJobState } from "../../store_new/selectors/SingleJob";
import { filterPackages } from "../../utils";
import { setActiveProduct } from "../../store_new/reducers/CurrentUser";
import {
  setCartOpen,
  setSelectedProducts,
} from "../../store_new/reducers/SingleJob";

const ProductPageNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [activeProductId, setActiveProductId] = useState<number | null>(null);
  const [isSelectPackageButtonHovered, setIsSelectPackageButtonHovered] =
    useState<boolean>(false);

  const activeProduct: any = useSelector(getActiveProduct);
  const { packages, selectedProducts } = useSelector(getSingleJobState);

  const productPackages = useMemo(
    () => (packages ? filterPackages(packages, activeProduct?.name) : []),
    [packages, activeProduct]
  );

  const isSelected = (currentItem: TPremiumProductItem | TPremiumPackage) => {
    return selectedProducts.some(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (item: TPremiumProductItem | TPremiumPackage) =>
        item.id === currentItem.id
    );
  };

  const handleOnChangeActiveProductId = (id: number | null) => {
    setActiveProductId(id);
  };

  const handleOpenBuyProductDialog = (productItem: TPremiumProductItem) => {
    dispatch(setSelectedProducts([productItem]));
    dispatch(setIsBuyProductDialogOpen(true));
  };

  const handleOpenCart = () => {
    dispatch(setCartOpen(true));
  };

  const handleOnAddPackage = (jPackage: TPremiumPackage) => {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSelectedProducts([
        ...selectedProducts,
        {
          ...jPackage,
          name: jPackage.package_name,
          retail_price: jPackage.package_price,
          market_price: jPackage.package_price,
          recommended_retail_price: jPackage.oldPrice,
          discount_price: jPackage.package_price,
        },
      ])
    );
    handleOpenCart();
  };

  const onOpenProduct = (
    product: TConvertedPremiumProduct | TPremiumPackage
  ) => {
    if (isSelectPackageButtonHovered) {
      handleOnAddPackage(product as TPremiumPackage);
    } else {
      navigate("/package");
      dispatch(
        setActiveProduct(product as unknown as TConvertedPremiumProduct)
      );
    }
  };

  const handleHoverLeaveSelectPackageButton = (value: boolean) => {
    setIsSelectPackageButtonHovered(value);
  };

  useEffect(() => {
    if (!activeProduct) navigate("/discover");
  }, []);

  return (
    <ProductPageComponent
      t={t}
      activeProduct={activeProduct as unknown as TConvertedPremiumProduct}
      activeProductId={activeProductId}
      productPackages={productPackages}
      isSelected={isSelected}
      onChangeActiveProductId={handleOnChangeActiveProductId}
      onOpenBuyProductDialog={handleOpenBuyProductDialog}
      onOpenProduct={onOpenProduct}
      onHoverLeaveSelectPackageButton={handleHoverLeaveSelectPackageButton}
    />
  );
};

export default ProductPageNew;
