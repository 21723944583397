import React from "react";
import { Stack, Typography, Avatar } from "@mui/material";
import { TFunction } from "i18next";
import { useSelector } from "react-redux";
import ByDateText from "./ByDateText";
import StatusCell from "../DataGrid_new/cellRenderers/status";
import { TSelectedJobCandidateApplication } from "../../models/ApplicationPage";
import { getSelectedJobOwners } from "../../store_new/selectors/Applications";
import { getWorkflowState } from "../../store_new/selectors/Workflow";

const RenderActivityLogItem = ({
  t,
  item,
  currentApplication,
}: {
  readonly t: TFunction;
  readonly item: any;
  readonly currentApplication: TSelectedJobCandidateApplication;
}) => {
  const selectedJobOwners = useSelector(getSelectedJobOwners);
  const { workflows } = useSelector(getWorkflowState);

  const allStatuses = Array.from(
    new Set(
      workflows?.flatMap(
        (workflow) =>
          workflow?.statuses?.map((status) => ({
            label: status.label,
            value: status.value,
            color: status.color,
          }))
      )
    )
  );

  const oldStatus = allStatuses.find(
    (status) => status.value === item.details.old_status
  );
  const newStatus = allStatuses.find(
    (status) => status.value === item.details.new_status
  );

  const recruiter = selectedJobOwners?.find(
    (owner) =>
      owner.firstname === item.details?.recruiter?.firstname &&
      owner.lastname === item.details?.recruiter?.lastname
  );

  let message = "";
  let isTalentPoolAction = false;
  switch (item.type) {
    case "APPLICATION_CREATED":
      return (
        <Stack direction="row" width="100%" spacing={1.75}>
          <Avatar src={currentApplication.photo} />
          <Stack width="100%" justifyContent="space-between">
            <Typography variant="subtitle2" color="textSecondary">
              <Typography
                display="inline"
                variant="subtitle2"
                color="#000"
                fontWeight={500}
              >
                {currentApplication.firstname} {currentApplication.lastname}{" "}
              </Typography>
              {t("candidate.applied_to_first_text")}{" "}
              <Typography
                display="inline"
                variant="subtitle2"
                color="secondary"
              >
                {currentApplication.title}{" "}
              </Typography>
              {t("candidate.applied_to_second_text")}
            </Typography>
            <ByDateText
              t={t}
              firstname={currentApplication.firstname}
              lastname={currentApplication.lastname}
              date={item.timestamp}
            />
          </Stack>
        </Stack>
      );
    case "QUESTIONS_ANSWERED":
      return (
        <Stack direction="row" width="100%" spacing={1.75}>
          <Avatar src={currentApplication.photo} />
          <Stack width="100%" justifyContent="space-between">
            <Typography variant="subtitle2" color="textSecondary">
              <Typography
                display="inline"
                variant="subtitle2"
                color="#000"
                fontWeight={500}
              >
                {currentApplication.firstname} {currentApplication.lastname}{" "}
              </Typography>
              {t("candidate.answered_additional_questions_to_first_text")}{" "}
              <Typography
                display="inline"
                variant="subtitle2"
                color="secondary"
              >
                {currentApplication.title}{" "}
              </Typography>
              {t("candidate.answered_additional_questions_to_second_text")}
            </Typography>
            <ByDateText
              t={t}
              firstname={currentApplication.firstname}
              lastname={currentApplication.lastname}
              date={item.timestamp}
            />
          </Stack>
        </Stack>
      );
    case "ANSWERS_UPDATED":
      return (
        <Stack direction="row" width="100%" spacing={1.75}>
          <Avatar src={null as unknown as string} />
          <Stack width="100%" justifyContent="space-between">
            <Typography variant="subtitle2" color="textSecondary">
              <Typography
                display="inline"
                variant="subtitle2"
                color="#000"
                fontWeight={500}
              >
                {t("candidate.update_additional_questions_first_text")}{" "}
              </Typography>
              <Typography
                display="inline"
                variant="subtitle2"
                color="secondary"
              >
                {currentApplication?.title}{" "}
              </Typography>
              <Typography
                display="inline"
                variant="subtitle2"
                color="#000"
                fontWeight={500}
              >
                {t("candidate.update_additional_questions_second_text")}
              </Typography>
            </Typography>
            <ByDateText t={t} date={item.timestamp} />
          </Stack>
        </Stack>
      );
    case "DOCUMENTS_UPLOADED":
      return (
        <Stack direction="row" width="100%" spacing={1.75}>
          <Avatar src={currentApplication.photo} />
          <Stack width="100%" justifyContent="space-between">
            <Typography variant="subtitle2" color="textSecondary">
              <Typography
                display="inline"
                variant="subtitle2"
                color="#000"
                fontWeight={500}
              >
                {currentApplication.firstname} {currentApplication.lastname}{" "}
              </Typography>
              {t("candidate.uploaded_document")}
              {item.details.filename ? " - " : ""}
              {item.details.filename && (
                <Typography
                  display="inline"
                  variant="subtitle2"
                  color="secondary"
                >
                  {item.details.filename.substring(17)}
                </Typography>
              )}
            </Typography>
            <ByDateText
              t={t}
              firstname={currentApplication.firstname}
              lastname={currentApplication.lastname}
              date={item.timestamp}
            />
          </Stack>
        </Stack>
      );
    case "APPLICANT_MESSAGE":
      return (
        <Stack direction="row" width="100%" spacing={1.75}>
          <Avatar src={currentApplication.photo} />
          <Stack width="100%" justifyContent="space-between">
            <Typography variant="subtitle2" color="textSecondary">
              <Typography
                display="inline"
                variant="subtitle2"
                color="#000"
                fontWeight={500}
              >
                {currentApplication?.firstname || "Applicant"}{" "}
                {currentApplication?.lastname || ""}{" "}
              </Typography>
              {t("candidate.send_message_to_first_text")}{" "}
              <Typography
                display="inline"
                variant="subtitle2"
                color="#000"
                fontWeight={500}
              >
                {item.details?.firstname || "Recruiter"}{" "}
                {item.details?.lastname || ""}{" "}
              </Typography>
              {t("candidate.send_message_to_second_text")}
            </Typography>
            <ByDateText
              t={t}
              firstname={currentApplication.firstname}
              lastname={currentApplication.lastname}
              date={item.timestamp}
            />
          </Stack>
        </Stack>
      );
    case "RECRUITER_MESSAGE":
      return (
        <Stack direction="row" width="100%" spacing={1.75}>
          <Avatar src={recruiter?.profile_photo} />
          <Stack width="100%" justifyContent="space-between">
            <Typography variant="subtitle2" color="textSecondary">
              {t("candidate.received_message_from_first_text")}{" "}
              <Typography
                display="inline"
                variant="subtitle2"
                color="#000"
                fontWeight={500}
              >
                {item.details.recruiter.firstname}{" "}
                {item.details.recruiter.lastname}{" "}
              </Typography>
              {t("candidate.received_message_from_second_text")}
            </Typography>
            <ByDateText
              t={t}
              firstname={item.details.recruiter.firstname}
              lastname={item.details.recruiter.lastname}
              date={item.timestamp}
            />
          </Stack>
        </Stack>
      );
    case "STATUS_CHANGED":
      message = t("candidate.changed_status_from_first_text");
      if (item.details.is_talendpooled) {
        if (item.details.new_status === "rejected") {
          message = t("candidate.added_to_talent_pool", {
            jobTitle: currentApplication.title,
            talentPool: currentApplication.talentpool_title,
          });
          isTalentPoolAction = true;
        }
        if (item.details.new_status === "new") {
          message = t("candidate.added_to_job_from_talent_pool", {
            jobTitle: currentApplication.title,
            talentPool: currentApplication.talentpool_title,
          });
          isTalentPoolAction = true;
        }
      }
      return (
        <Stack direction="row" width="100%" spacing={1.75}>
          <Avatar src={recruiter?.profile_photo} />
          <Stack width="100%" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Typography variant="subtitle2" color="#000" fontWeight={500}>
                {item.details.recruiter.firstname}{" "}
                {item.details.recruiter.lastname}{" "}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {message}
              </Typography>
              {!isTalentPoolAction && (
                <>
                  <StatusCell
                    label={oldStatus?.label as string}
                    uniqueStatuses={allStatuses}
                    type={oldStatus?.value as string}
                  />
                  <Typography variant="subtitle2" color="textSecondary">
                    {t("candidate.changed_status_to")}
                  </Typography>
                  <StatusCell
                    label={newStatus?.label as string}
                    uniqueStatuses={allStatuses}
                    type={newStatus?.value as string}
                  />
                </>
              )}
              <Typography variant="subtitle2" color="textSecondary">
                {t("candidate.changed_status_from_second_text")}
              </Typography>
            </Stack>
            <ByDateText
              t={t}
              firstname={item.details.recruiter.firstname}
              lastname={item.details.recruiter.lastname}
              date={item.timestamp}
            />
          </Stack>
        </Stack>
      );
    case "NOTE_CREATED":
      return (
        <Stack direction="row" width="100%" spacing={1.75}>
          <Avatar src={recruiter?.profile_photo} />
          <Stack width="100%" justifyContent="space-between">
            <Typography variant="subtitle2" color="textSecondary">
              <Typography
                display="inline"
                variant="subtitle2"
                color="#000"
                fontWeight={500}
              >
                {item.details.recruiter.firstname}{" "}
                {item.details.recruiter.lastname}{" "}
              </Typography>
              {t("candidate.added_note")}
            </Typography>
            <ByDateText
              t={t}
              firstname={item.details.recruiter.firstname}
              lastname={item.details.recruiter.lastname}
              date={item.timestamp}
            />
          </Stack>
        </Stack>
      );
    default:
      return item.type;
  }
};

const AcivityLogItem = ({
  t,
  item,
  currentApplication,
}: {
  readonly t: TFunction;
  readonly item: any;
  readonly currentApplication: TSelectedJobCandidateApplication;
}) => (
  <Stack
    width="100%"
    padding="12px 24px"
    sx={{
      cursor: "pointer",
      "&:hover": {
        background: "#F9FAFB",
      },
    }}
  >
    <RenderActivityLogItem
      t={t}
      item={item}
      currentApplication={currentApplication}
    />
  </Stack>
);

export default AcivityLogItem;
