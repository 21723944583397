import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConnectDialog from "../../SingleJob_new/BasicJobBoard/ConnectDialog";
import FirstStepsBoxComponent from "./component";
import {
  fetchCompanyData,
  updateCompanyData,
} from "../../../store_new/reducers/CompanySettings";
import { fetchBasicJobBoards } from "../../../store_new/reducers/SingleJob";
import { getSingleJobState } from "../../../store_new/selectors/SingleJob";
import { TBasicJobBoard } from "../../../models/SingleJob";
import { getJobListJobsState } from "../../../store_new/selectors/JobList";
import { openJobEditor } from "../../../store_new/reducers/JobEditor";
import {
  setIsLimitDialogOpen,
  setIsUploadPictureDialogOpen,
} from "../../../store_new/reducers/dialogs";
import { getCompanySettingsCompanyState } from "../../../store_new/selectors/Company";

const FirstStepsBox = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { company } = useSelector(getCompanySettingsCompanyState);
  const { basicJobBoards } = useSelector(getSingleJobState);
  const { totalCount: jobCount } = useSelector(getJobListJobsState);

  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState(false);
  const [currentSocialMediaUrl, setCurrentSocialMediaUrl] = useState("");
  const [currentSocialMedia, setCurrentSocialMedia] =
    useState<TBasicJobBoard | null>(null);

  const { isLogoExist, isLinkedinExist, isXingExist, isCompanyInfoFull } =
    useMemo(
      () => ({
        isLogoExist: Boolean(company?.logo),
        isLinkedinExist: Boolean(company?.linkedin),
        isXingExist: Boolean(company?.xing),
        isCompanyInfoFull: Boolean(
          company?.website &&
            company.employees &&
            company.industry_id &&
            company.company_color &&
            company.about_us
        ),
      }),
      [company]
    );

  useEffect(() => {
    if (!isXingExist || !isLinkedinExist) dispatch(fetchBasicJobBoards());
  }, [isXingExist, isLinkedinExist]);

  const handleOnOpenUploadLogoDialog = () => {
    dispatch(setIsUploadPictureDialogOpen(true));
  };
  const handleOnOpenConnectDialog = (social: "Linkedin" | "Onlyfy") => {
    const targetSocial = basicJobBoards.find(
      (socialMedia) => socialMedia.title === social
    );
    setCurrentSocialMedia(targetSocial || null);
    setIsConnectDialogOpen(true);
  };
  const handleOnCloseConnectDialog = () => {
    setIsConnectDialogOpen(false);
    setCurrentSocialMediaUrl("");
    setCurrentSocialMedia(null);
  };
  const handleOnSaveUpdateSocialMediaUrl = () => {
    const data =
      currentSocialMedia?.title === "Linkedin"
        ? { linkedin_url: currentSocialMediaUrl }
        : { xing_url: currentSocialMediaUrl };
    dispatch(
      updateCompanyData({
        type: "form",
        data,
        callback: () => {
          dispatch(fetchCompanyData());
          handleOnCloseConnectDialog();
        },
      })
    );
  };
  const handleOnChangeSocialMediaUrl = (val: string) => {
    setCurrentSocialMediaUrl(val);
  };
  const handleOnNavigateCompanySettings = () => {
    navigate("/company-settings");
  };

  const handleOnOpenJobEditor = () => {
    if (company?.jobs_creation_allowed) {
      dispatch(openJobEditor());
    } else {
      dispatch(setIsLimitDialogOpen(true));
    }
  };

  return (
    <>
      <FirstStepsBoxComponent
        t={t}
        onNavigateCompanySettings={handleOnNavigateCompanySettings}
        onOpenConnectDialog={handleOnOpenConnectDialog}
        onOpenJobEditor={handleOnOpenJobEditor}
        onOpenUploadLogoDialog={handleOnOpenUploadLogoDialog}
        isCompanyInfoFull={isCompanyInfoFull}
        isJobExist={+jobCount > 0}
        isLogoExist={isLogoExist}
        isLinkedinExist={isLinkedinExist}
        isXingExist={isXingExist}
      />
      <ConnectDialog
        t={t}
        companyProfileUrl={currentSocialMediaUrl}
        jobBoard={currentSocialMedia}
        isLoading={false}
        isOpen={isConnectDialogOpen}
        onClose={handleOnCloseConnectDialog}
        onSave={handleOnSaveUpdateSocialMediaUrl}
        onChangeUrl={handleOnChangeSocialMediaUrl}
      />
    </>
  );
};

export default FirstStepsBox;
