import * as yup from "yup";
import { TFunction } from "i18next";
import { ISendMessageFormField } from "../../../../models/Automation";
import { isEmptyHtmlString } from "../../../../utils/singleJob";

export const getSendMessageFormFields = (t: TFunction) =>
  yup.object().shape({
    to: yup.string().required(t("register.message") as string),
    message: yup
      .string()
      .transform((htmlString: string) =>
        isEmptyHtmlString(htmlString) ? null : htmlString
      )
      .required(t("register.message") as string),
  });

export const sendMessageFormFieldsDefaultValue: ISendMessageFormField = {
  to: "",
  message: "",
};
