import { Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledProfilePictureContainer = styled(Stack)({
  position: "relative",
});

export const StyledProfilePictureOverlay = styled(Stack)({
  position: "absolute",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: 8,
  background: "#818CF8",
  transition: ".5s ease",
  opacity: 0,
  "&:hover": {
    opacity: 0.8,
  },
  ".overlay-icon": {
    width: 24,
    height: 24,
    strokeWidth: 1.5,
    stroke: "#fff",
    cursor: "pointer",
  },
  ".overlay-divider": {
    borderColor: "#fff",
    height: 24,
  },
});
