import React from "react";
import { Button, Dialog, Divider, Stack, Typography } from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import CardView from "../../../pages/Applications_new/CardView";
import SelectorFilter from "../../filters_new/SelectorFilter";
import { ISelectWorkflowDialog } from "../../../models/dialogs";

const SelectWorkflowDialog = ({
  t,
  isOpen,
  initialColumns,
  selectedWorkflow,
  selectedJob,
  workflows,
  onSetWorkflow,
  onChangeName,
  onClose,
}: ISelectWorkflowDialog) => (
  <Dialog open={isOpen} onClose={onClose}>
    <Stack
      p={3}
      width={512}
      spacing={1}
      overflow="hidden auto"
      sx={{ backgroundColor: "#fff" }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="body1" fontWeight={500}>
            {t("workflow.setWorkflow")}
          </Typography>
        </Stack>
        <Stack>
          <XMarkIcon
            onClick={onClose}
            width={24}
            height={24}
            stroke="#131E30"
            style={{ cursor: "pointer" }}
          />
        </Stack>
      </Stack>
      <Stack py={2}>
        <Divider
          sx={{
            width: "100%",
            borderColor: "#E5E7EB",
          }}
        />
      </Stack>
      <SelectorFilter
        className={"workflow-selector-filter"}
        label={t("applications.name")}
        value={selectedWorkflow?.value || ""}
        options={workflows}
        onChange={(value) => {
          onChangeName(value);
        }}
        hideCloseIcon
      />
      <Typography pt={1} variant="body2" fontWeight={500} color="#000">
        {t("workflow.statusOptions")}
      </Typography>
      <CardView
        t={t}
        variant="workflowStatus"
        initialColumns={initialColumns}
      />
      <Stack pt={2} direction="row" spacing={1.5} justifyContent="flex-end">
        <Button onClick={onClose} variant={"outlined"}>
          {t("button.secondCancel")}
        </Button>
        <Button
          disabled={selectedWorkflow?.value == selectedJob?.workflowId}
          onClick={onSetWorkflow}
          variant={"contained"}
        >
          {t("button.save")}
        </Button>
      </Stack>
    </Stack>
  </Dialog>
);

export default SelectWorkflowDialog;
