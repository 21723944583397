import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useForm } from "react-hook-form";
import { debounce } from "debounce";
import { useSelector, useDispatch } from "react-redux";
import JobDescriptionFormComponent from "./component";
import { getCompanySettingsCompanyState } from "../../../store_new/selectors/Company";
import { SELECTED_EDIT_SECTIONS } from "../../../models/SingleJob";
import { getJobEditorState } from "../../../store_new/selectors/JobEditor";
import { updateJobDescription } from "../../../store_new/reducers/JobEditor";
import {
  IJobDescriptionForm,
  TJobDescriptionFormFields,
} from "../../../models/JobEditor";
import {
  getJobDescriptionFormFields,
  jobDescriptionFormDefaultValues,
} from "../config";

const JobDescriptionForm = ({
  t,
  defaultValues,
  formRef,
  selectedSections = [
    SELECTED_EDIT_SECTIONS.JOB_DESCRIPTION_MEDIA_SECTION,
    SELECTED_EDIT_SECTIONS.JOB_DESCRIPTION_MAIN_SECTION,
  ],
  updateJobDescriptionData,
  onSubmit,
}: IJobDescriptionForm) => {
  const dispatch = useDispatch();
  const {
    jobDescription,
    jobDescriptionData,
    updateDraftInProgress,
    isJobEditorOpen,
    draftData,
  } = useSelector(getJobEditorState);
  const { company } = useSelector(getCompanySettingsCompanyState);

  const formDefaultValues = defaultValues
    ? {
        ...defaultValues,
        logo: jobDescription.logo,
        company_color: company?.company_color || "#178CF2",
      }
    : {
        ...jobDescriptionFormDefaultValues,
        logo: jobDescription.logo,
        company_color: company?.company_color || "#178CF2",
      };

  const formValues = jobDescriptionData as TJobDescriptionFormFields;

  const {
    control,
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    watch,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(getJobDescriptionFormFields(t)),
    defaultValues: formDefaultValues,
    values: updateDraftInProgress ? jobDescription : formValues,
  });

  const handleOnSubmit = (data: TJobDescriptionFormFields) => {
    onSubmit(data);
  };

  const debouncedUpdateJobDescription = debounce(updateJobDescriptionData, 300);

  useEffect(() => {
    if (
      company?.company_color &&
      !draftData?.job?.template_title_color.length
    ) {
      setValue("company_color", company?.company_color as string);
      dispatch(
        updateJobDescription({
          jobDescription: {
            ...jobDescription,
            company_color: company?.company_color as string,
          },
        })
      );
    }
  }, [company, draftData]);

  useEffect(() => {
    const subscription = watch((value) => {
      if (value && isJobEditorOpen) {
        debouncedUpdateJobDescription(value);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, debouncedUpdateJobDescription]);

  useEffect(() => {
    if (!updateDraftInProgress) {
      reset();
    }
  }, [updateDraftInProgress]);

  return (
    <JobDescriptionFormComponent
      t={t}
      register={register}
      errors={errors}
      control={control}
      formRef={formRef}
      selectedSections={selectedSections}
      onSubmit={handleOnSubmit}
      handleSubmit={handleSubmit}
    />
  );
};

export default JobDescriptionForm;
