import { Box, Stack, TextField } from "@mui/material";
import React from "react";
import AutocompleteFilter from "../../../../components/filters_new/AutocompleteFilter";
import { IProductsFilter } from "../../../../models/SingleJob";

const ProductsFilterComponent = ({
  t,
  filterValue,
  industries,
  isJobEditorPage,
  countries,
  durations,
  isPremiumJobBoardsTab,
  onChange,
}: IProductsFilter) => (
  <Stack
    direction={"row"}
    spacing={2}
    mt={2}
    mb={isJobEditorPage ? 0 : isPremiumJobBoardsTab ? 2 : 0}
  >
    <Box flex={isJobEditorPage ? 1 : undefined}>
      <TextField
        fullWidth={isJobEditorPage}
        value={filterValue.name}
        placeholder={t("create_job_fourth_step.productName") as string}
        onChange={(event) => {
          onChange({ type: "name", value: event.target.value });
        }}
      />
    </Box>
    <Box flex={isJobEditorPage ? 1 : undefined}>
      <AutocompleteFilter
        value={filterValue.country}
        options={countries}
        placeholder={t("create_job_fourth_step.country") as string}
        onChange={(value) => {
          onChange({ type: "country", value: value as string });
        }}
      />
    </Box>
    <Box flex={isJobEditorPage ? 1 : undefined}>
      <AutocompleteFilter
        value={filterValue.industry}
        options={industries}
        placeholder={t("create_job_fourth_step.industry") as string}
        onChange={(value) => {
          onChange({ type: "industry", value: value as string });
        }}
      />
    </Box>
    <Box flex={isJobEditorPage ? 1 : undefined}>
      <AutocompleteFilter
        value={filterValue.duration}
        options={durations}
        placeholder={t("create_job_fourth_step.duration") as string}
        onChange={(value) => {
          onChange({ type: "duration", value: value as string });
        }}
      />
    </Box>
  </Stack>
);

export default ProductsFilterComponent;
