import React from "react";
import { Box, InputLabel, Stack, Tooltip, Typography } from "@mui/material";
import IconWithTooltip from "./IconWithTooltip";
import { IApplicationAdditionalInfoLanguages } from "../../../models/ApplicationPage";
import {
  StyledLanguageContainer,
  StyledLinearProgressBar,
  StyledQuestionTitle,
} from "../styles";

const Languages = ({
  t,
  languages,
  isActive,
}: IApplicationAdditionalInfoLanguages) => (
  <Stack spacing={1}>
    <StyledQuestionTitle direction={"row"}>
      <InputLabel>{t("candidate_questions.language")}</InputLabel>{" "}
      {isActive ? <IconWithTooltip /> : null}
    </StyledQuestionTitle>
    <Stack spacing={2}>
      {languages.length ? (
        languages.map(
          (lang: { label: string; value: number; level: string }) => (
            <StyledLanguageContainer
              key={lang.label}
              direction={"row"}
              spacing={2}
            >
              <Box flex={1}>{lang.label}</Box>
              <Tooltip
                title={
                  <span style={{ textTransform: "capitalize" }}>
                    {lang.level.toLowerCase()}
                  </span>
                }
                followCursor
                placement={"top"}
              >
                <StyledLinearProgressBar
                  variant="determinate"
                  value={lang.value}
                />
              </Tooltip>
            </StyledLanguageContainer>
          )
        )
      ) : (
        <Typography variant={"subtitle2"}>
          {t("candidate_questions.no_answer")}
        </Typography>
      )}
    </Stack>
  </Stack>
);

export default Languages;
