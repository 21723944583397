import {
  Box,
  Chip,
  Divider,
  LinearProgress,
  Stack,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";

export const StyledCandidateContentContainer = styled(Stack)({
  position: "relative",
  background: "#fff",
  borderRadius: "6px",
  border: "1px solid #E5E7EB",
  padding: 24,
  height: "100%",
});

export const StyledMessageBlockContainer = styled(Stack)({
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: 60,
  marginTop: 24,
  background: "#fff",
  borderRadius: "6px",
  border: "1px solid #E5E7EB",
  padding: "8px 24px",
  span: {
    fontSize: "14px",
    color: "#6B7280",
    margin: 0,
    lineHeight: "20px",
    maxWidth: "80%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  button: {
    height: "32px!important",
  },
});

export const StyledNoDocumentsContainer = styled(Stack)({
  position: "absolute",
  height: "100%",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  margin: 0,
  top: 0,
  left: 0,
  svg: {
    width: 20,
    height: 20,
    color: "#818CF8",
    padding: 6,
    background: "#EEF2FF",
    boxSizing: "content-box",
    borderRadius: "100%",
  },
});

export const StyledCandidateContentTitleContainer = styled(Stack)({
  marginTop: "0px !important",
  flexDirection: "row",
  alignItems: "center",
  gap: 8,
  svg: {
    "&.info-icon": { margin: 0, width: 16, height: 16, cursor: "pointer" },
    "&.edit-icon": {
      width: 24,
      height: 24,
      cursor: "pointer",
    },
    color: "#178CF2",
  },
  button: {
    padding: 0,
    "&:hover": {
      background: "#fff",
    },
  },
  zIndex: 1,
});

export const StyledCandidateContentRow = styled(Stack)(
  ({ desktop, document }: { desktop: boolean; document?: boolean }) => ({
    justifyContent: desktop || document ? "space-between" : "flex-start",
    alignItems: "center",
    gap: document ? 0 : 12,
  }),
);

export const StyledCandidateContentRowTitleContainer = styled(Stack)(
  ({ desktop, document }: { desktop?: boolean; document?: boolean }) => ({
    alignItems: "center",
    span: {
      color: "#131E30",
      margin: 0,
      fontSize: 14,
      fontWeight: "500",
      lineHeight: "20px",
    },
    svg: {
      width: 20,
      height: 20,
      color: "#818CF8",
      padding: 6,
      background: "#EEF2FF",
      boxSizing: "content-box",
      borderRadius: "100%",
    },
    ".filename": {
      color: "#6B7280",
      margin: 0,
      fontSize: 14,
      width: desktop && document ? 180 : "37vw",
      fontWeight: "400",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  }),
);

export const StyledIconWrapper = styled(Box)({
  maxHeight: 32,
  svg: {
    cursor: "pointer",
    padding: 6,
    boxSizing: "content-box",
    borderRadius: 6,
  },
  ".eye-icon": {
    border: "1px solid #178CF2",
    color: "#178CF2",
    height: 20,
    width: 20,
    padding: 5,
    "&:hover": {
      color: "#0e6dbf",
      border: "1px solid #0e6dbf",
    },
  },
  ".download-icon": {
    background: "#178CF2",
    color: "#fff",
    height: 20,
    width: 20,
    "&:hover": {
      background: "#0e6dbf",
    },
  },
  ".cv-icon": {
    color: "#178CF2",
    cursor: "pointer",
    height: 24,
    width: 24,
    strokeWidth: 1,
    padding: 4,
  },
  ".delete-icon": {
    stroke: "#EF4444",
    height: 24,
    width: 24,
    padding: 4,
  },
});

export const StyledCandidateContentValue = styled(Stack)(
  ({ props }: { props?: { isEditMode: boolean } }) => ({
    ...(props?.isEditMode && { width: "75%" }),
    color: "#6B7280",
    margin: 0,
    fontSize: 14,
    lineHeight: "20px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
);

export const StyledDivider = styled(Divider)({
  height: 1,
  borderColor: "#E5E7EB",
});

export const StyledTextField = styled(TextField)({
  ".MuiInputBase-root": {
    background: "#F9FAFB !important",
    color: "#6B7280",
    pointerEvents: "none",
  },
});

export const StyledSkillChip = styled(Chip)({
  color: "#178CF2!important",
  background: "#EDF7FF!important",
  fontSize: "14px!important",
  border: "inherit!important",
});

export const StyledLanguageContainer = styled(Stack)({
  alignItems: "center",
  div: {
    color: "#6B7280",
    fontSize: 14,
    fontWeight: "500",
    lineHeight: "20px",
  },
});

export const StyledLinearProgressBar = styled(LinearProgress)({
  height: 6,
  borderRadius: 6,
  flex: 11,
  backgroundColor: "#F3F4F6",
  ".MuiLinearProgress-bar": {
    background: "#178CF2",
    borderRadius: 6,
  },
});

export const StyledQuestionTitle = styled(Stack)({
  alignItems: "center",
  svg: {
    stroke: "#fff",
    width: 20,
    height: 20,
    fill: "#22C55E",
  },
});

export const StyledRequestDataContainer = styled(Stack)({
  background: "#EDF7FF",
  borderRadius: 6,
  padding: "6px 10px",
  color: "#178CF2",
  fontSize: 14,
  lineHeight: "20px",
  marginLeft: "auto",
  cursor: "pointer",
});
