import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RegisterRecruiterComponent from "./component";
import { TCompanyRecruiterFormFields } from "../../../models/Registration";
import {
  checkRecruiterEmail,
  completeInvitation,
  confirmInvitation,
  registerCompany,
  setCompanyData,
} from "../../../store_new/reducers/Registration";
import { getRegistrationState } from "../../../store_new/selectors/Registration";
import { formDefaultValues, getFormSettings } from "./config";

const RegisterRecruiter_new = ({ desktop }: { readonly desktop: boolean }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isInvitationPath = location.pathname.includes(
    "/registration/invitation-confirm/"
  );
  const url_key = isInvitationPath
    ? location.pathname.substring(33)
    : undefined;

  const { isLoading, error, companyData, invitationEmail } =
    useSelector(getRegistrationState);
  const [showPassword, setShowPassword] = useState(false);

  const {
    getValues,
    register,
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(getFormSettings(t)),
    defaultValues: formDefaultValues,
  });

  useEffect(() => {
    if (error) {
      setError("email", {
        type: "manual",
        message: t("email_exist") as string,
      });
    }
  }, [error]);

  const onSubmit = (data: TCompanyRecruiterFormFields) => {
    const { firstname, lastname, password, phone, email } = data;
    if (url_key) {
      dispatch(
        completeInvitation({
          url_key,
          recruiterData: { firstname, lastname, phone, password },
          callback: () => navigate("/"),
        })
      );
    } else
      dispatch(checkRecruiterEmail({ email, callback: handleOnSubmitSuccess }));
  };

  const handleOnSubmitSuccess = () => {
    if (companyData) {
      const recruiterData = getValues();
      dispatch(setCompanyData({ ...companyData, email: recruiterData.email }));
      const data = {
        ...companyData,
        ...recruiterData,
      };
      dispatch(
        registerCompany({
          data,
          callback: () => navigate("/register/resend-email"),
        })
      );
    }
  };

  const handleOnSignIn = () => {
    navigate("/login");
  };

  const handleOnShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (isInvitationPath) {
      dispatch(
        confirmInvitation({
          urlKey: url_key as string,
        })
      );
    }
  }, []);

  useEffect(() => {
    setValue("email", invitationEmail as string);
  }, [invitationEmail]);

  return (
    <RegisterRecruiterComponent
      t={t}
      errors={errors}
      control={control}
      register={register}
      isLoading={isLoading}
      showPassword={showPassword}
      invitationEmail={invitationEmail}
      desktop={desktop}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      onSignIn={handleOnSignIn}
      onShowPassword={handleOnShowPassword}
    />
  );
};

export default RegisterRecruiter_new;
