import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { IInitialColumns } from "../../models/Kanban";

export const StyledList = styled(Stack)(
  ({
    variant,
    isDraggingOver,
    isDraggingFrom,
  }: {
    variant: string;
    isDraggingOver: boolean;
    isDraggingFrom: boolean;
  }) => ({
    padding: variant === "candidate" ? "10px 8.18px" : 0,
    height: variant === "candidate" ? window.innerHeight - 213 : "auto",
    borderRadius: 8,
    minWidth: variant === "candidate" ? "19.4vw" : "100%",
    background:
      variant === "workflowStatus"
        ? "transparent"
        : isDraggingOver
        ? "#E6FCFF !important"
        : isDraggingFrom
        ? "#FFEBE6!important"
        : "#F3F4F6 !important",
  })
);

export const StyledListContainer = styled(Stack)({
  overflow: "hidden auto",
  "&::-webkit-scrollbar": {
    width: "0px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "transparent",
  },
});

export const StyledColumns = styled(Stack)(
  ({ variant, columns }: { variant: string; columns: IInitialColumns }) => ({
    marginTop:
      variant === "candidate" && Object.keys(columns).length > 4 ? 64 : 24,
    flexDirection: "row",
    gap: 16,
    height: variant === "candidate" ? window.innerHeight - 214 : "auto",
    width: variant === "candidate" ? "auto" : "100%",
  })
);

export const StyledAddWorkflowStatusButton = styled(Stack)({
  marginTop: 24,
  height: 40,
  fontSize: 14,
  color: "#178CF2",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#EDF7FF",
  cursor: "pointer",
});

export const StyledCandidateItem = styled(Stack)({
  cursor: "pointer !important",
  borderRadius: 6,
  "&:hover": {
    boxShadow: `0px 0px 0px 0px rgba(37, 46, 55, 0.06),
                0px 7px 15px 0px rgba(37, 46, 55, 0.06),
                0px 27px 27px 0px rgba(37, 46, 55, 0.05),
                0px 60px 36px 0px rgba(37, 46, 55, 0.03),
                0px 106px 43px 0px rgba(37, 46, 55, 0.01),
                0px 166px 47px 0px rgba(37, 46, 55, 0.00)`,
  },
});

export const StyledCandidateItemElement = styled(Stack)({
  flexDirection: "row",
  gap: 8,
  "& svg": {
    position: "relative",
    top: 1.5,
    width: 16,
    height: 16,
    stroke: "#178CF2",
    strokeWidth: 1,
  },
});

export const StyledStatusInColumnText = styled(Typography)(({
  type,
}: {
  type: string;
}) => {
  const baseStyle = {
    fontSize: 12,
    lineHeight: "20px",
  };
  switch (type) {
    case "normal":
      return {
        ...baseStyle,
        color: "#6B7280",
      };
    case "orange":
      return {
        ...baseStyle,
        color: "#D97706",
      };
    case "red":
      return {
        ...baseStyle,
        color: "#DC2626",
      };
    default:
  }
  return {
    ...baseStyle,
    color: "#6B7280",
  };
});
