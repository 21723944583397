import { TListOption } from "../models/common";

export const getEmployeeId = ({
  employees,
  value,
}: {
  readonly employees: TListOption[];
  readonly value: string;
}) =>
  employees.find((employee: TListOption) => employee.label === value)?.value ||
  value;
