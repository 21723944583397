import React from "react";
import { Stack, TextField, Button } from "@mui/material";
import NoData from "../../components/NoData";
import Loading from "../../components/Loading";
import DataGrid from "../../components/DataGrid_new";
import DeleteDialog from "../../components/dialogs/DeleteDialog";

import { IAutomationsComponent } from "../../models/Automation";
import { DATA_GRID_LIST_TYPE } from "../../models/DataGrid";

const AutomationsComponent = ({
  t,
  automations,
  isListLoading,
  isDeleteDialogOpen,
  onEnabledDisabledAutomation,
  onDeleteAutomation,
  onCreateAutomation,
  onOpenAutomation,
  onOpenCloseDeleteDialog,
  onChangeAutomationsFilter,
}: IAutomationsComponent) => (
  <Stack mt={4}>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <TextField
        placeholder={t("applications.search_placeholder") as string}
        onChange={(e) => onChangeAutomationsFilter(e.target.value)}
      />
      <Button variant="contained" onClick={onCreateAutomation}>
        {t("automation.createNew")}
      </Button>
    </Stack>
    {isListLoading ? (
      <Loading />
    ) : automations.length > 0 ? (
      <Stack mt={3} height={window.innerHeight - 220}>
        <DataGrid
          t={t}
          type={DATA_GRID_LIST_TYPE.AUTOMATIONS}
          list={automations}
          actions={{
            onClick: onOpenAutomation,
            onRemove: onOpenCloseDeleteDialog,
            onChangeEnabledDisabledStatus: onEnabledDisabledAutomation,
          }}
        />
      </Stack>
    ) : (
      <NoData
        title={t("joblist.empty_first_text_filters_exists")}
        containerHeight={window.innerHeight - 220}
        noButton
      />
    )}
    <DeleteDialog
      t={t}
      isOpen={isDeleteDialogOpen}
      title={t("automation.deleteAutomation")}
      body={t("automation.deleteAutomationText")}
      customDeleteButtonText={t("talentPools.delete") as string}
      onCancel={() => onOpenCloseDeleteDialog(false)}
      onDelete={onDeleteAutomation}
    />
  </Stack>
);

export default AutomationsComponent;
