import React from "react";
import { StyledChatUsersContainer } from "./styles";
import { IUserListComponent } from "../../models/Messages";
import User from "./User";

const UserListComponent = ({
  t,
  desktop,
  recruiterId,
  latestMessages,
  anchorEl,
  handleCloseUserMenu,
  handleOpenUserMenu,
  handleReadUnread,
  handleSetActiveUser,
}: IUserListComponent) => {
  const fullContainer: HTMLElement | null = document.querySelector(
    "#messages-container"
  );
  const containerHeight: number = fullContainer?.offsetHeight || 0;
  const headerHeight = 88;
  const leftSideHeight = containerHeight - headerHeight;

  return (
    <StyledChatUsersContainer
      minWidth={desktop ? "345px" : "100%"}
      maxWidth={desktop ? "345px" : "100%"}
      height={desktop ? leftSideHeight : "auto"}
      borderRight={desktop ? "1px solid #E5E7EB" : "none"}
    >
      {latestMessages.map((item, index) => (
        <User
          key={index}
          t={t}
          recruiterId={recruiterId}
          user={item}
          desktop={desktop}
          users={latestMessages}
          index={index}
          anchorEl={anchorEl}
          handleCloseUserMenu={handleCloseUserMenu}
          handleOpenUserMenu={handleOpenUserMenu}
          handleReadUnread={handleReadUnread}
          handleSetActiveUser={handleSetActiveUser}
        />
      ))}
    </StyledChatUsersContainer>
  );
};

export default UserListComponent;
