import React from "react";
import { Stack, Typography } from "@mui/material";
import { TFiltersComponent } from "../../models/CompanyInfo";
import AutocompleteFilter from "../../components/filters_new/AutocompleteFilter";

const CompanyFilters = ({
  t,
  desktop,
  filterOptions,
  filterValue,
  company,
  isJobWidgetPage = false,
  onFilterChange,
}: TFiltersComponent) => {
  return (
    <Stack width="100%" py={3} maxWidth={1128}>
      <Stack spacing={3}>
        <Typography variant="h6" fontWeight={500}>
          {t("company_page.open_position", { company_name: company?.title })}
        </Typography>
      </Stack>
      <Stack
        mt={3}
        width="100%"
        direction={desktop ? "row" : "column"}
        alignItems={desktop ? "center" : "flex-start"}
        justifyContent="space-between"
        spacing={desktop ? 0 : 3}
      >
        {!isJobWidgetPage && (
          <Typography variant="subtitle2" color="textSecondary">
            {t("company_page.currently_open", { total: company?.jobs_total })}
          </Typography>
        )}
        <Stack
          width={isJobWidgetPage ? "100%" : desktop ? "auto" : "100%"}
          direction={desktop ? "row" : "column"}
          alignItems="flex-end"
          spacing={desktop ? 3 : 1.5}
        >
          <AutocompleteFilter
            value={filterValue.workField}
            options={filterOptions.workFields}
            placeholder={t("company_page.departments") as string}
            onChange={(value) => {
              onFilterChange({ key: "workField", value: value as string });
            }}
          />
          <AutocompleteFilter
            value={filterValue.location}
            options={filterOptions.locations || []}
            placeholder={t("company_page.location") as string}
            onChange={(value) => {
              onFilterChange({ key: "location", value: value as string });
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CompanyFilters;
