import React from "react";
import { Button, Divider, Link, Stack } from "@mui/material";
import Header from "./components/Header";
import JobInfo from "./components/JobInfo";
import SuccessScreen from "./components/SuccessScreen";
import Languages from "./components/Languages";
import Documents from "./components/Documents";
import AdditionalQuestionsSkills from "./components/Skills";
import Phone from "./components/Phone";
import AdditionalQuestionsSalary from "./components/Salary";
import AdditionalQuestionsStartDate from "./components/StartDate";
import AdditionalQuestionsProfessionalStatus from "./components/ProfessionalStatus";
import AdditionalQuestionsHighestDegree from "./components/HighestDegree";
import AdditionalQuestionsDriverLicenses from "./components/DriverLicenses";
import AdditionalQuestionsLocations from "./components/Locations";
import AdditionalQuestionsPhoto from "./components/Photo";
import AdditionalQuestionsQuestions from "./components/Questions";
import Loading from "../../components/Loading";
import { IAdditionalQuestionsComponent } from "../../models/JobPreview";
import {
  StyledAdditionalQuestionsContainer,
  StyledAdditionalQuestionsInfoBlock,
} from "./styles";

const AdditionalQuestionComponent = ({
  t,
  navigate,
  control,
  currentLocation,
  desktop,
  job,
  hasQuestions,
  isSubmitted,
  isLoading,
  isLoadingAdditionalQuestionsUpdate,
  setValue,
  logo,
  companyColor,
  handleSubmit,
  onSubmit,
  moreDetails,
  language,
  languages,
  onChangeLanguage,
  onSkip,
}: IAdditionalQuestionsComponent) =>
  isLoading || !job?.title || isLoadingAdditionalQuestionsUpdate ? (
    <Loading />
  ) : (
    <StyledAdditionalQuestionsContainer
      desktop={desktop}
      companyColor={companyColor}
    >
      <Header
        t={t}
        companyTitle={job?.company}
        logo={logo}
        language={language}
        languages={languages}
        desktop={desktop}
        onChangeLanguage={onChangeLanguage}
      />
      <JobInfo
        desktop={desktop}
        location={currentLocation}
        title={job?.title}
        type={job?.work_type}
        position={job?.position_type}
        companyColor={companyColor}
      />
      <SuccessScreen t={t} desktop={desktop} isSubmitted={isSubmitted} />
      {isSubmitted || !moreDetails ? (
        <Stack mt={3} width="100%" alignItems="center" px={desktop ? 3.8 : 0}>
          <Button
            variant="contained"
            onClick={() => navigate("/")}
            className="check-status-button"
          >
            {t("application_complete.dashboard_button")}
          </Button>
        </Stack>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack mt={3}>
            <Divider />
            {!!job?.phone_required && (
              <Phone t={t} control={control} desktop={desktop} />
            )}
            {!!job?.location_required && (
              <AdditionalQuestionsLocations
                control={control}
                desktop={desktop}
                setValue={setValue}
              />
            )}
            {!!job?.salary_expectation_required && (
              <AdditionalQuestionsSalary
                t={t}
                control={control}
                desktop={desktop}
              />
            )}
            {!!job?.earliest_start_date_required && (
              <AdditionalQuestionsStartDate
                t={t}
                control={control}
                desktop={desktop}
              />
            )}
            {!!job?.current_professional_status_required && (
              <AdditionalQuestionsProfessionalStatus
                t={t}
                control={control}
                desktop={desktop}
              />
            )}
            {!!job?.highest_degree_required && (
              <AdditionalQuestionsHighestDegree
                t={t}
                control={control}
                desktop={desktop}
              />
            )}

            {!!job?.driver_license_required && (
              <AdditionalQuestionsDriverLicenses
                control={control}
                desktop={desktop}
                companyColor={companyColor}
              />
            )}
            {!!job?.langs_required && (
              <Languages
                control={control}
                desktop={desktop}
                companyColor={companyColor}
              />
            )}
            {!!job?.skills_required && (
              <AdditionalQuestionsSkills
                control={control}
                desktop={desktop}
                companyColor={companyColor}
              />
            )}
            {!!job?.other_documents_required && (
              <Documents
                control={control}
                desktop={desktop}
                companyColor={companyColor}
              />
            )}
            {!!job?.profile_photo_required && (
              <AdditionalQuestionsPhoto
                t={t}
                control={control}
                desktop={desktop}
                companyColor={companyColor}
              />
            )}
            {hasQuestions && (
              <AdditionalQuestionsQuestions
                control={control}
                companyColor={companyColor}
                desktop={desktop}
              />
            )}
          </Stack>
          <Stack
            mt={3}
            mb={9}
            direction={"row"}
            spacing={2}
            justifyContent={"flex-end"}
          >
            <Button
              disabled={isLoadingAdditionalQuestionsUpdate}
              variant={"outlined"}
              className="skip-button"
              onClick={onSkip}
            >
              {t("button.skip")}
            </Button>
            <Button
              disabled={isLoadingAdditionalQuestionsUpdate}
              variant={"contained"}
              type={"submit"}
              className="submit-button"
            >
              {t("button.submit")}
            </Button>
          </Stack>
        </form>
      )}
      <StyledAdditionalQuestionsInfoBlock isSubmitted={true} desktop={desktop}>
        <Link
          sx={{ cursor: "pointer" }}
          underline="none"
          display="inline"
          target={"_blank"}
          href={"https://karriera.de/agb"}
          fontSize={14}
          color={`${companyColor} !important`}
        >
          {t("terms")}
        </Link>
        <Link
          sx={{ cursor: "pointer" }}
          underline="none"
          display="inline"
          target={"_blank"}
          href={"https://karriera.de/privacy"}
          fontSize={14}
          color={`${companyColor} !important`}
        >
          {t("privacy")}
        </Link>
      </StyledAdditionalQuestionsInfoBlock>
    </StyledAdditionalQuestionsContainer>
  );

export default AdditionalQuestionComponent;
