import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { TListOption } from "../../models/common";
import { TJob } from "../../models/CompanyInfo";

export interface IAnalyticsStateInterface {
  readonly jobList: null | TListOption[];
  readonly jobListInitial: null | any[];
  readonly selectedJob: null | TListOption;
  readonly isLoading: boolean;
  readonly isLoadingAllAnalytics: boolean;
  readonly affectedStatuses: string[];
  readonly jobsWithStats:
    | {
        id: number;
        title: string;
        sumStat: { [x: string]: { value: number; quantity: number } };
      }[]
    | [];
  readonly error: unknown;
  readonly numberOfApplications: {
    readonly valuesByDay: null | number[];
    readonly valuesByMonth: null | number[];
    readonly labelsByDay: null | string[];
    readonly labelsByMonth: null | string[];
    readonly sources: { source: string; quantity: number }[];
    readonly error: unknown;
    readonly isLoading: boolean;
  };
}

const initialState: IAnalyticsStateInterface = {
  jobList: null,
  jobListInitial: null,
  selectedJob: null,
  isLoading: false,
  isLoadingAllAnalytics: false,
  affectedStatuses: [],
  jobsWithStats: [],
  error: "",
  numberOfApplications: {
    valuesByDay: null,
    valuesByMonth: null,
    labelsByDay: null,
    labelsByMonth: null,
    sources: [],
    error: "",
    isLoading: false,
  },
};

export const AnalyticsSlice = createSlice({
  name: "ANALYTICS",
  initialState,
  reducers: {
    fetchAnalyticsJobList: (state: Draft<IAnalyticsStateInterface>) => {
      state.isLoading = true;
    },
    fetchAnalyticsJobListSuccess: (
      state: Draft<IAnalyticsStateInterface>,
      action: PayloadAction<any[]>,
    ) => {
      state.jobListInitial = action.payload;
      state.jobList = action.payload.map(({ title, url_key }: TJob) => ({
        label: title,
        value: url_key,
      }));
      state.isLoading = false;
    },
    fetchAnalyticsJobListFailed: (
      state: Draft<IAnalyticsStateInterface>,
      action: PayloadAction<unknown>,
    ) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    setAnalyticsSelectedJob: (
      state: Draft<IAnalyticsStateInterface>,
      action: PayloadAction<TListOption | null>,
    ) => {
      state.selectedJob = action.payload;
    },
    fetchAnalyticsNumberOfApplicationsSuccess: (
      state: Draft<IAnalyticsStateInterface>,
      action: PayloadAction<{
        byDay: { date: string; quantity: number }[];
        byMonth: { date: string; quantity: number }[];
        source: { source: string; quantity: number }[];
        status: {
          application_id: number;
          job_id: number;
          details: null | string;
          application_timestamp: null | string;
          job_timestamp: string;
        }[];
      }>,
    ) => {
      const { byMonth, byDay, source } = action.payload;
      state.numberOfApplications.valuesByMonth = byMonth.map(
        ({ quantity }) => quantity,
      );
      state.numberOfApplications.labelsByMonth = byMonth.map(
        ({ date }) => date,
      );
      state.numberOfApplications.valuesByDay = byDay.map(
        ({ quantity }) => quantity,
      );
      state.numberOfApplications.labelsByDay = byDay.map(({ date }) => date);
      state.numberOfApplications.sources = source;
      state.numberOfApplications.isLoading = false;
    },
    fetchAnalyticsNumberOfApplications: (
      state: Draft<IAnalyticsStateInterface>,
      _: PayloadAction<{ since: string; until: string }>,
    ) => {
      state.numberOfApplications.isLoading = true;
    },
    fetchAnalyticsNumberOfApplicationsFailed: (
      state: Draft<IAnalyticsStateInterface>,
      action: PayloadAction<unknown>,
    ) => {
      state.numberOfApplications.error = action.payload;
      state.numberOfApplications.isLoading = false;
    },
    fetchAllAnalytics: (state: Draft<IAnalyticsStateInterface>) => {
      state.isLoadingAllAnalytics = true;
    },
    fetchAllAnalyticsSuccess: (
      state: Draft<IAnalyticsStateInterface>,
      action: PayloadAction<{
        jobsWithStats: {
          id: number;
          title: string;
          sumStat: { [x: string]: { value: number; quantity: number } };
        }[];
        affectedStatuses: string[];
      }>,
    ) => {
      const { affectedStatuses, jobsWithStats } = action.payload;
      state.isLoadingAllAnalytics = false;
      state.jobsWithStats = jobsWithStats;
      state.affectedStatuses = affectedStatuses;
    },
    fetchAllAnalyticsFailed: (
      state: Draft<IAnalyticsStateInterface>,
      action: PayloadAction<unknown>,
    ) => {
      state.isLoadingAllAnalytics = false;
      state.error = action.payload;
    },
    resetAnalytics: () => initialState,
  },
});

export const {
  fetchAnalyticsJobList,
  fetchAnalyticsJobListSuccess,
  fetchAnalyticsJobListFailed,
  setAnalyticsSelectedJob,
  fetchAnalyticsNumberOfApplications,
  fetchAnalyticsNumberOfApplicationsSuccess,
  fetchAnalyticsNumberOfApplicationsFailed,
  fetchAllAnalytics,
  fetchAllAnalyticsSuccess,
  fetchAllAnalyticsFailed,
  resetAnalytics,
} = AnalyticsSlice.actions;

export default AnalyticsSlice.reducer;
