import { useDispatch, useSelector } from "react-redux";
import { getJobPreviewState } from "../../../../store_new/selectors/JobPreview";
import React, { useEffect, useMemo, useState } from "react";
import { debounce } from "debounce";
import { fetchCities } from "../../../../store_new/reducers/JobPreview";
import AdditionalQuestionsLocationsComponent from "./component";
import { useTranslation } from "react-i18next";
import {
  IAdditionalQuestionSimpleSection,
  TAdditionalQuestionsFormFields
} from "../../../../models/JobPreview";
import { UseFormSetValue } from "react-hook-form";

const AdditionalQuestionsLocations = ({
  control,
  desktop,
  setValue
}: IAdditionalQuestionSimpleSection & {
  setValue: UseFormSetValue<TAdditionalQuestionsFormFields>;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { cities } = useSelector(getJobPreviewState);
  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    dispatch(fetchCities({ city: "Berlin" })); // get Berlin by default
  }, []);

  const handleOnSearch = useMemo(
    () =>
      debounce((city: string) => {
        dispatch(fetchCities({ city }));
      }, 500),
    []
  );

  const handleOnUpdateCountryCode = (newCountryCode: string) => {
    setCountryCode(newCountryCode);
    setValue("country", newCountryCode);
  };

  return (
    <AdditionalQuestionsLocationsComponent
      t={t}
      control={control}
      cities={cities}
      desktop={desktop}
      countryCode={countryCode}
      onSearch={handleOnSearch}
      onUpdateCountryCode={handleOnUpdateCountryCode}
    />
  );
};

export default AdditionalQuestionsLocations;
