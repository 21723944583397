import * as yup from "yup";

export const additionalInfoFormFieldsSchema = yup.object().shape({
  salary_expectation: yup.string().transform((value) => value || ""),
  earliest_start_date: yup.string().transform((value) => value || ""),
  current_professional_status: yup.string().transform((value) => value || ""),
  highest_degree: yup.string().transform((value) => value || ""),
  driverLicense: yup.array().of(
    yup.object().shape({
      value: yup
        .string()
        .required()
        .transform((value) => value || ""),
      label: yup
        .string()
        .required()
        .transform((value) => value || "")
    })
  ),
  languages: yup.array().of(
    yup.object().shape({
      language: yup.string().transform((value) => value || ""),
      level: yup.string().transform((value) => value || "")
    })
  ),
  skills: yup.array().of(
    yup.object().shape({
      id: yup.string().transform((value) => value || ""),
      title: yup.string().transform((value) => value || "")
    })
  ),
  questions: yup.array().of(
    yup.object().shape({
      answer: yup.string(),
      question_id: yup.number()
    })
  )
});
