import React, { useEffect } from "react";
import { Dialog, InputLabel, Button, Stack, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { ISelectCustomerDialog } from "../../../models/dialogs";
import { fetchAllActiveCustomers } from "../../../store_new/reducers/Customers";
import selectCustomerPicture from "../../../assets/select-customer.png";
import { getAllActiveCustomersListState } from "../../../store_new/selectors/Customers";
import AutocompleteFilter from "../../filters_new/AutocompleteFilter";
import { getFormSettings } from "./config";
import { TCustomer } from "../../../models/Agency";
import { getDialogsState } from "../../../store_new/selectors/Dialogs";
import { getJobEditorState } from "../../../store_new/selectors/JobEditor";

const SelectCustomerDialog = ({
  t,
  onClose,
  onSave
}: ISelectCustomerDialog) => {
  const dispatch = useDispatch();
  const { list } = useSelector(getAllActiveCustomersListState);

  const { isSelectCustomerDialogOpen } = useSelector(getDialogsState);
  const { companyId } = useSelector(getJobEditorState);

  const formattedList = list.map((item: TCustomer) => ({
    ...item,
    label: item.companyName,
    value: item.id as string,
    companyId: item.id
  }));

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    reset
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(getFormSettings(t)),
    defaultValues: {
      title: ""
    }
  });

  const handleOnClose = () => {
    onClose();
    reset();
  };

  const handleOnSave = () => {
    const { title } = getValues();
    onSave(parseInt(title));
    onClose();
  };

  useEffect(() => {
    if (isSelectCustomerDialogOpen) {
      dispatch(fetchAllActiveCustomers());
    }
  }, [isSelectCustomerDialogOpen]);

  useEffect(() => {
    if (companyId) {
      const findCustomer = list?.find((item) => item.id === companyId);
      const findCustomerName = findCustomer?.companyName;
      if (findCustomerName) {
        localStorage.setItem("company-name", findCustomerName);
      } else {
        localStorage.removeItem("company-name");
      }
    } else {
      localStorage.removeItem("company-name");
    }
  }, [companyId]);

  return (
    <Dialog open={!!isSelectCustomerDialogOpen} onClose={onClose}>
      <Stack width={446} px={3} alignItems="center" mt={7.5} spacing={2}>
        <img
          src={selectCustomerPicture}
          alt="select-customer"
          width={162.71}
          height={160}
        />
        <Typography variant="body2" fontWeight={500}>
          {t("create_job_first_step.create_job_ad")}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {t("customers.select_customer_subtitle")}
        </Typography>
      </Stack>
      <form onSubmit={handleSubmit(handleOnSave)}>
        <Stack px={3} mt={4.5}>
          <InputLabel>{t("customers.select_customer")}</InputLabel>
          <Controller
            name={"title"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutocompleteFilter
                onChange={onChange}
                value={value}
                options={formattedList}
                placeholder={t("customers.customer")}
                errors={errors["title"]}
              />
            )}
          />
        </Stack>
        <Stack
          direction="row"
          width="100%"
          justifyContent="flex-end"
          p={3}
          spacing={2}
        >
          <Button variant={"outlined"} onClick={handleOnClose}>
            {t("button.secondCancel")}
          </Button>
          <Button variant={"contained"} type="submit">
            {t("button.continue")}
          </Button>
        </Stack>
      </form>
    </Dialog>
  );
};

export default SelectCustomerDialog;
