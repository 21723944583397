import React, { useMemo } from "react";
import CandidateNoteComponent from "./component";
import { IApplicationNotesNoteItem } from "../../../../models/Applications";
import { useSelector } from "react-redux";
import { getCurrentUserData } from "../../../../store_new/selectors/CurrentUser";
import { getSelectedApplicationDeletedNoteList } from "../../../../store_new/selectors/Applications";
import { useTranslation } from "react-i18next";

const CandidateNote = ({
  note,
  onDeleteNote,
  onSubmitComment,
}: IApplicationNotesNoteItem) => {
  const { t } = useTranslation();
  const { memberId } = useSelector(getCurrentUserData);
  const deletedNoteList = useSelector(getSelectedApplicationDeletedNoteList);

  const currentNote = note.note;
  const author = useMemo(
    () => `${currentNote.firstname} ${currentNote.lastname}`,
    [note],
  );

  const isCurrentUser = useMemo(
    () => memberId === currentNote.author_id,
    [memberId, note],
  );

  const isDeleted = useMemo(
    () => deletedNoteList.includes(currentNote.id.toString()),
    [deletedNoteList],
  );

  return (
    <CandidateNoteComponent
      t={t}
      avatar={currentNote.avatar}
      author={author}
      body={currentNote.note}
      comments={note.comments}
      noteId={currentNote.id.toString()}
      isCurrentUser={isCurrentUser}
      isDeleted={isDeleted}
      onDeleteNote={onDeleteNote}
      onSubmitComment={onSubmitComment}
    />
  );
};

export default CandidateNote;
