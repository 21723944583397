import React, { useMemo } from "react";
import { Button, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { ClockIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import EmptyCart from "../../assets/empty_cart.png";
import { openCheckout } from "../../store_new/reducers/JobEditor";
import { getCurrentUserData } from "../../store_new/selectors/CurrentUser";
import { getJobEditorState } from "../../store_new/selectors/JobEditor";
import { getSingleJobState } from "../../store_new/selectors/SingleJob";
import {
  setCartOpen,
  setSelectedProducts,
} from "../../store_new/reducers/SingleJob";
import { StyledCartDrawer } from "./style";
import { TCartComponent } from "../../models/JobEditor";
import {
  TConvertedPremiumProduct,
  TPremiumPackage,
  TPremiumProductItem,
} from "../../models/SingleJob";

export default function Cart({ t, isCartOpen }: TCartComponent) {
  const dispatch = useDispatch();

  const { isAgency } = useSelector(getCurrentUserData);
  const { packagesTotalPrice } = useSelector(getJobEditorState);
  const { selectedProducts } = useSelector(getSingleJobState);

  const selectedProductsSum = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const retail_price = selectedProducts.reduce(
      (accumulator: any, object: any) => {
        return accumulator + object.retail_price;
      },
      0
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const recommended_retail_price = selectedProducts.reduce(
      (accumulator: any, object: any) => {
        return accumulator + object.recommended_retail_price;
      },
      0
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const agencyTotal = selectedProducts.reduce(
      (accumulator: any, object: any) => {
        return accumulator + object.agency_price;
      },
      0
    );
    return [retail_price, recommended_retail_price, agencyTotal];
  }, [selectedProducts]);

  const discount = useMemo(() => {
    if (isAgency) {
      return selectedProductsSum[2] - packagesTotalPrice;
    }
    return 0;
  }, [packagesTotalPrice]);

  const redirectToCheckout = () => {
    dispatch(openCheckout());
  };

  const roundToTwoDecimals = (value: number) => {
    return value.toFixed(2);
  };

  const handleDeleteItemFromCart = (
    item: TPremiumPackage | TConvertedPremiumProduct | TPremiumProductItem
  ) => {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSelectedProducts(selectedProducts.filter((el) => el.id !== item.id))
    );
  };

  const handleCloseCart = () => {
    dispatch(setCartOpen(false));
  };

  return (
    <StyledCartDrawer variant="permanent" anchor="right" open={isCartOpen}>
      <Stack width="100%" height="100%" spacing={3}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Stack direction="row" alignItems="center" spacing={1.5}>
              <Typography fontSize={20} fontWeight={500}>
                {t("cart.title")}
              </Typography>
              {selectedProducts.length > 0 && (
                <Stack
                  color="#fff"
                  borderRadius="50%"
                  justifyContent="center"
                  alignItems="center"
                  width={20}
                  height={20}
                  fontSize={12}
                  fontWeight={500}
                  sx={{
                    cursor: "pointer",
                    backgroundColor: "#818CF8",
                  }}
                >
                  {selectedProducts.length}
                </Stack>
              )}
            </Stack>
            <Stack>
              <XMarkIcon className="remove-icon" onClick={handleCloseCart} />
            </Stack>
          </Stack>

          {selectedProducts.length > 0 ? (
            <Stack width="100%">
              {selectedProducts.map((item: any, index) => (
                <Stack key={index} width="100%">
                  <Divider className="divider" />
                  <Stack
                    borderRadius="6px"
                    key={index}
                    direction="row"
                    alignItems="center"
                    width="100%"
                    spacing={1}
                    sx={{
                      background: "#fff",
                    }}
                  >
                    <Stack width="62%" spacing={1}>
                      <Stack direction="row" spacing={1.25}>
                        <Tooltip title={item.name}>
                          <Typography
                            variant="body2"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            maxWidth={270}
                            noWrap
                          >
                            {item.name}
                          </Typography>
                        </Tooltip>
                        {item.discount > 0 && (
                          <Stack
                            borderRadius="6px"
                            minWidth="42px"
                            height={24}
                            color="#fff"
                            alignItems="center"
                            justifyContent="center"
                            p={1}
                            fontSize={12}
                            sx={{
                              backgroundColor: "#818CF8",
                            }}
                          >
                            {item.discount}%
                          </Stack>
                        )}
                      </Stack>
                      {item.runtime_in_days && (
                        <Stack direction="row" spacing={1}>
                          <ClockIcon className="clock-icon" />
                          <Typography variant="subtitle2" fontSize={12}>
                            {item.runtime_in_days}{" "}
                            {t("create_job_fourth_step.days")}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                    <Stack
                      width="21%"
                      height="100%"
                      spacing={0.5}
                      justifyContent={
                        item.retail_price < item.recommended_retail_price
                          ? "space-between"
                          : item.agency_price < item.recommended_retail_price
                            ? "space-between"
                            : "center"
                      }
                      alignItems="flex-end"
                    >
                      {item.agency_price
                        ? item.agency_price < item.recommended_retail_price && (
                            <Typography className="discount-price">
                              {roundToTwoDecimals(
                                item.recommended_retail_price
                              )}
                              €
                            </Typography>
                          )
                        : item.retail_price < item.recommended_retail_price && (
                            <Typography className="discount-price">
                              {roundToTwoDecimals(
                                item.recommended_retail_price
                              )}
                              €
                            </Typography>
                          )}
                      <Typography variant="body2" fontWeight={500}>
                        {roundToTwoDecimals(
                          item.agency_price || item.retail_price
                        )}
                        €
                      </Typography>
                    </Stack>
                    <Stack width="5%" alignItems="center">
                      <Stack
                        width="1px"
                        height={48}
                        sx={{
                          backgroundColor: "#E5E7EB",
                        }}
                      />
                    </Stack>
                    <Stack width="24px" direction="row" justifyContent="center">
                      <XCircleIcon
                        className="clear-icon"
                        onClick={() => handleDeleteItemFromCart(item)}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              ))}
              <Divider className="footer-divider" />
            </Stack>
          ) : (
            <Stack height={500} alignItems="center" justifyContent="center">
              <img src={EmptyCart} alt="empty" />
              <Typography
                variant="body1"
                color="#0F141B"
                fontWeight={500}
                paddingTop={4}
              >
                {t("cart.empty_title")}
              </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                paddingTop={1}
              >
                {t("cart.empty_text_one")}
              </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                paddingTop={1}
                paddingBottom={4}
              >
                {t("cart.empty_text_two")}
              </Typography>
              <Button variant="contained" onClick={handleCloseCart}>
                {t("cart.empty_button")}
              </Button>
            </Stack>
          )}
        </Stack>
        {selectedProducts.length > 0 && (
          <Stack width="100%" pb={3}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2">
                {t("create_job_fifth_step.original_price")}
              </Typography>
              <Typography variant="body2" fontWeight={500}>
                {roundToTwoDecimals(selectedProductsSum[1])} €
              </Typography>
            </Stack>
            {!!isAgency && (
              <Stack mt={3} direction="row" justifyContent="space-between">
                <Typography variant="body2">
                  {t("create_job_fifth_step.agency_price")}
                </Typography>
                <Typography variant="body2" fontWeight={500}>
                  {roundToTwoDecimals(selectedProductsSum[2])} €
                </Typography>
              </Stack>
            )}

            <Stack direction="row" justifyContent="space-between" mt={3}>
              <Typography variant="body2">
                {t("create_job_fifth_step.discount")}
              </Typography>
              <Typography variant="body2" fontWeight={500}>
                {roundToTwoDecimals(
                  isAgency
                    ? discount
                    : selectedProductsSum[1] - selectedProductsSum[0]
                )}{" "}
                €
              </Typography>
            </Stack>

            <Divider className="divider" />
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2">
                {t("create_job_fifth_step.sub_total")}
              </Typography>
              <Typography variant="body2" fontWeight={500}>
                {roundToTwoDecimals(
                  isAgency ? selectedProductsSum[2] : selectedProductsSum[1]
                )}{" "}
                €
              </Typography>
            </Stack>
            <Divider className="divider" />
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1" fontWeight={800} fontSize={20}>
                {t("create_job_fifth_step.total")}
              </Typography>
              <Typography color="#0F141B" fontWeight={800} fontSize={20}>
                {roundToTwoDecimals(
                  isAgency
                    ? selectedProductsSum[2] - discount
                    : selectedProductsSum[0]
                )}
                €
              </Typography>
            </Stack>
            <Divider className="divider" />
            {!!isAgency && (
              <>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2">
                    {t("create_job_fifth_step.customer_total")}
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {roundToTwoDecimals(selectedProductsSum[0])} €
                  </Typography>
                </Stack>

                <Stack mt={3} direction="row" justifyContent="space-between">
                  <Typography variant="body2">
                    {t("create_job_fifth_step.margin")}
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {roundToTwoDecimals(
                      selectedProductsSum[0] - selectedProductsSum[2] + discount
                    )}
                    €
                  </Typography>
                </Stack>

                <Divider className="terms-divider" />
              </>
            )}
            <Stack
              direction="row"
              width="100%"
              justifyContent="space-between"
              mt={3}
            >
              <Button variant="outlined" onClick={handleCloseCart}>
                {t("button.secondCancel")}
              </Button>
              <Button
                variant="contained"
                type="submit"
                onClick={redirectToCheckout}
              >
                {t("cart.checkout")}
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </StyledCartDrawer>
  );
}
