import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { TFunction } from "i18next";
import { Controller } from "react-hook-form";
import Flag from "react-country-flag";
import CVSection from "../../JobPreview/JobAction/CVSection";
import { TCity } from "../../../store_new/sagas/JobPreview";
import {
  StyledInputContainer,
  StyledInputFlagContainer,
  StyledOptionContainer,
} from "../../../components/filters_new/styles";

const AddProfileDialogComponent = ({
  t,
  errors,
  cities,
  countryCode,
  control,
  isOpen,
  isLoading,
  uploadedCV,
  handleSubmit,
  onChangeCountry,
  onClose,
  onSearchCity,
  onSubmit,
  onUploadCV,
  onResetCV,
  register,
}: {
  t: TFunction;
  errors: any;
  cities: TCity[];
  countryCode: string;
  control: any;
  isLoading: boolean;
  isOpen: boolean;
  uploadedCV: any;
  handleSubmit: any;
  onChangeCountry: (countryCode: string) => void;
  onClose: () => void;
  onSubmit: any;
  onSearchCity: (city: string) => void;
  onUploadCV: (cv: any) => void;
  onResetCV: (event: any) => void;
  register: any;
}) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    maxWidth={"xs"}
    fullWidth
    PaperProps={{ sx: { background: "white" } }}
  >
    <DialogTitle sx={{ p: 3 }}>{t("candidate.addProfile")}</DialogTitle>
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent sx={{ pt: 0, pb: 0, overflow: "hidden" }}>
        <Stack>
          <Stack
            mb={errors?.firstname || errors?.lastname ? 1 : 2}
            direction={"row"}
            alignItems={"center"}
            spacing={3}
          >
            <Box
              position="relative"
              bottom={errors?.lastname && !errors?.firstname ? 11 : 0}
            >
              <InputLabel>{t("candidate.firstName")}</InputLabel>
              <TextField
                {...register("firstname")}
                variant={"outlined"}
                size={"small"}
                fullWidth
                placeholder={t("candidate.firstName") as string}
                error={Boolean(errors.firstname)}
                helperText={errors.firstname?.message}
              />
            </Box>
            <Box
              position="relative"
              bottom={errors?.firstname && !errors?.lastname ? 11 : 0}
            >
              <InputLabel>{t("candidate.lastName")}</InputLabel>
              <TextField
                {...register("lastname")}
                variant={"outlined"}
                size={"small"}
                fullWidth
                placeholder={t("candidate.lastName") as string}
                error={Boolean(errors.lastname)}
                helperText={errors.lastname?.message}
              />
            </Box>
          </Stack>
          <Stack
            mb={errors?.email || errors?.phonenumber ? 1 : 2}
            direction={"row"}
            alignItems={"center"}
            spacing={3}
          >
            <Box
              position="relative"
              bottom={errors?.phonenumber && !errors?.email ? 11 : 0}
            >
              <InputLabel>{t("candidate.email")}</InputLabel>
              <TextField
                {...register("email")}
                variant={"outlined"}
                size={"small"}
                fullWidth
                placeholder={t("login.e_mail_example") as string}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
              />
            </Box>
            <Box
              position="relative"
              bottom={errors?.email && !errors?.phonenumber ? 11 : 0}
            >
              <InputLabel>{t("candidate.phone")}</InputLabel>
              <TextField
                {...register("phonenumber")}
                variant={"outlined"}
                size={"small"}
                fullWidth
                placeholder={t("candidate.phone") as string}
                error={Boolean(errors.phonenumber)}
                helperText={errors.phonenumber?.message}
              />
            </Box>
          </Stack>
          <Stack
            pb={errors?.location ? 2 : 0}
            direction={"row"}
            alignItems={"center"}
            flex={1}
          >
            <Box flex={1}>
              <InputLabel>{t("candidate.location")}</InputLabel>
              <Controller
                name={"location"}
                control={control}
                render={({ field: { onChange } }) => (
                  <Autocomplete
                    noOptionsText={t("additional_questions.city_no_options")}
                    options={cities}
                    getOptionLabel={(option) =>
                      option?.state
                        ? `${option.name}, ${option.state},  ${option.country}`
                        : `${option.name},  ${option.country}`
                    }
                    onInputChange={(_, value) => {
                      onSearchCity(value);
                    }}
                    onChange={(_, value) => {
                      onChange(value?.name || "");
                      onChangeCountry(value?.countrycode || "");
                    }}
                    renderInput={(params) => (
                      <StyledInputContainer>
                        <TextField
                          {...params}
                          placeholder={t("candidate.location") as string}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: countryCode && (
                              <InputAdornment position="start">
                                <StyledInputFlagContainer>
                                  <Flag countryCode={countryCode} svg />
                                </StyledInputFlagContainer>
                              </InputAdornment>
                            ),
                          }}
                          error={errors.location?.message}
                        />
                        {errors.location && (
                          <Typography color="error" variant="subtitle2">
                            {errors.location.message}
                          </Typography>
                        )}
                      </StyledInputContainer>
                    )}
                    renderOption={(optionProps, option) => (
                      <StyledOptionContainer
                        {...optionProps}
                        translate="no"
                        key={option.countrycode}
                      >
                        <Box>
                          <Flag countryCode={option.countrycode} svg />
                        </Box>
                        <Box>
                          <Typography variant="body2" color="textSecondary">
                            {option?.state
                              ? `${option.name}, ${option.state},  ${option.country}`
                              : `${option.name},  ${option.country}`}
                          </Typography>
                        </Box>
                      </StyledOptionContainer>
                    )}
                  />
                )}
              />
            </Box>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} flex={1} mt={2}>
            <Box flex={1}>
              <InputLabel>CV</InputLabel>
              <CVSection
                t={t}
                onResetCV={onResetCV}
                onUploadCV={onUploadCV}
                uploadedCV={uploadedCV}
                oldCVFileName={""}
                isRequired
              />
            </Box>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          ></Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button variant={"outlined"} onClick={onClose} disabled={isLoading}>
          {t("button.secondCancel")}
        </Button>
        <Button
          endIcon={
            isLoading ? (
              <CircularProgress
                size={16}
                style={{
                  color: "#9A9FAF",
                }}
              />
            ) : null
          }
          variant={"contained"}
          disabled={isLoading}
          type={"submit"}
        >
          {t("button.save")}
        </Button>
      </DialogActions>
    </form>
  </Dialog>
);

export default AddProfileDialogComponent;
