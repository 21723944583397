import React, { useEffect } from "react";
import { Dialog, Stack, Typography, Divider } from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import Jobs from "../CompanyInfo_new/Jobs";
import NoJobs from "../CompanyInfo_new/NoJobs";
import Filters from "../CompanyInfo_new/Filters";
import Loading from "../../components/Loading";
import { IJobWidgetPreviewDialog } from "../../models/dialogs";
import {
  setCompanyFilter,
  setPageNum,
  fetchCompanyInfo
} from "../../store_new/reducers/CompanyInfo";
import {
  getCompanyInfoState,
  getCompanyInfoPagination
} from "../../store_new/selectors/Company";
import { getCurrentUserData } from "../../store_new/selectors/CurrentUser";

export default function PreviewDialog({
  t,
  isOpen,
  desktop,
  onClose
}: IJobWidgetPreviewDialog) {
  const dispatch = useDispatch();
  const { company, filterOptions, filterValue, isLoading } =
    useSelector(getCompanyInfoState);
  const { companyUrlKey } = useSelector(getCurrentUserData);
  const { pageNum, pageSize } = useSelector(getCompanyInfoPagination);

  const onFilterChange = ({
    key,
    value
  }: {
    key: "location" | "workField";
    value: string;
  }) => {
    dispatch(setCompanyFilter({ key, value }));
  };

  const handlePaginate = (number: number) => {
    dispatch(setPageNum(number));
  };

  useEffect(() => {
    if (companyUrlKey) {
      dispatch(
        fetchCompanyInfo({
          companyKey: companyUrlKey,
          locationId: filterValue.location,
          workFieldId: filterValue.workField,
          pageSize,
          pageNum
        })
      );
    }
  }, [filterValue, pageNum, companyUrlKey]);

  return (
    <Dialog
      open={Boolean(isOpen)}
      PaperProps={{
        elevation: 0
      }}
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "960px" // Set your width here
          }
        }
      }}
      data-testid="preview-job-widget-dialog"
    >
      <Stack
        p={3}
        sx={{
          backgroundColor: "#fff"
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography variant="body1" fontWeight={500}>
              {t("job_widget.title")}
            </Typography>
          </Stack>
          <Stack>
            <XMarkIcon
              onClick={onClose}
              width={24}
              height={24}
              stroke="#131E30"
              style={{ cursor: "pointer" }}
            />
          </Stack>
        </Stack>
        <Divider
          sx={{
            width: "100%",
            borderColor: "#E5E7EB",
            my: 3
          }}
        />
        <Stack
          px={desktop ? 0 : 3}
          width="100%"
          margin="auto"
          maxWidth={1128}
          justifyContent="center"
          alignItems="center"
        >
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Filters
                t={t}
                company={company}
                desktop={desktop}
                filterOptions={filterOptions}
                filterValue={filterValue}
                onFilterChange={onFilterChange}
                isJobWidgetPage
              />
              {company.jobs_total === 0 ? (
                <NoJobs t={t} companyColor={company.color} />
              ) : (
                <Jobs
                  company={company}
                  page={pageNum}
                  desktop={desktop}
                  paginate={handlePaginate}
                  jobWidgetPage
                />
              )}
            </>
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
}
