import { styled } from "@mui/system";
import { Button, Chip, CSSObject, Drawer, Stack, Theme } from "@mui/material";

const drawerWidth = 230;

const openedMixin = (theme: Theme): CSSObject => ({
  background: "#131E30",
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: "hidden",
  borderRight: "4px solid #131E30",
});

const closedMixin = (theme: Theme): CSSObject => ({
  background: "#131E30",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
  borderRight: "4px solid #131E30",
});

export const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(
  ({
    theme,
    open,
    mobileDrawerOpen,
  }: {
    theme?: Theme;
    open: boolean;
    mobileDrawerOpen: boolean;
  }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme as Theme),
      "& .MuiDrawer-paper": {
        ...openedMixin(theme as Theme),
        width: mobileDrawerOpen ? "100%" : drawerWidth,
      },
    }),
    ...(!open && {
      ...closedMixin(theme as Theme),
      "& .MuiDrawer-paper": closedMixin(theme as Theme),
    }),
  })
);

export const StyledDrawerHeader = styled(Stack)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "32px 16px",
});

export const StyledDrawerContent = styled(Stack)(
  ({ open, isScrolling }: { open: boolean; isScrolling: boolean }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100%",
    marginTop: 32,
    padding: "0px 16px",
    overflow: "hidden auto",
    "&::-webkit-scrollbar ": {
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: isScrolling ? "#374151" : "transparent",
      borderRadius: "6px",
    },
    ".MuiCollapse-root.MuiCollapse-vertical.MuiTreeItem-group": {
      ...(!open && { marginLeft: "0!important" }),
    },
    ".MuiTreeItem-content": {
      width: "100%",
      height: 48,
      borderRadius: 6,
      padding: `6px 12px`,
      justifyContent: "center",
      color: "#fff",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#1F2937",
      },

      ".MuiTreeItem-label": {
        padding: 0,
        fontSize: 14,
      },
      ".MuiTreeItem-iconContainer": {
        margin: 0,
        alignItems: "center",
        justifyContent: "center",
        width: 24,
        height: 24,
        svg: {
          stroke: "#818CF8",
          strokeWidth: 1,
          width: 24,
          height: 24,
        },
      },
      "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
        backgroundColor: "#374151",
        "&:hover": {
          backgroundColor: "#374151",
        },
      },
      ".circle-icon": {
        fill: "#818CF8",
        width: 8,
        height: 8,
        padding: 8,
      },
    },
  })
);

export const StyledSubItemIconContainer = styled(Stack)({
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  background: "#131E30",
  width: 12,
  height: 12,
});

export const StyledToggleMenuButton = styled(Button)(
  ({ open, theme }: { open: boolean; theme?: Theme }) => ({
    position: "fixed",
    top: 64,
    minWidth: 32,
    width: 32,
    height: 32,
    padding: 0,
    background: "#374151",
    borderRadius: "50%",
    svg: { width: 16, height: 16, stroke: "#fff", zIndex: 3001 },
    "&:hover": { background: "#6B7280" },
    zIndex: 1299,
    ...(open && {
      transition: theme!.transitions.create("left", {
        easing: theme!.transitions.easing.sharp,
        duration: theme!.transitions.duration.leavingScreen,
      }),
      left: drawerWidth - 16,
    }),
    ...(!open && {
      transition: theme!.transitions.create("left", {
        easing: theme!.transitions.easing.sharp,
        duration: theme!.transitions.duration.leavingScreen,
      }),
      left: 80 - 16,
    }),
  })
);

export const StyledComingSoonLabel = styled(Chip)({
  color: "#fff!important",
  background: "#818CF8!important",
  border: "none!important",
});
