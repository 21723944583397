import React from "react";
import { Stack } from "@mui/material";
import {
  CheckCircleIcon,
  CloudArrowUpIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { StyledUploadCVContainer } from "../styles";
import { IJobPreviewCVSection } from "../../../models/JobPreview";
import { StyledOptionalLabel } from "../../../components/JobDialogs_new/styles";

const CVSection = ({
  t,
  oldCVFileName,
  uploadedCV,
  testId,
  onUploadCV,
  onResetCV,
  isRequired,
}: IJobPreviewCVSection) => (
  <StyledUploadCVContainer
    mt={isRequired ? 0 : 1.5}
    data-testid={"apply-job-existing-cv-container"}
  >
    {!uploadedCV && !oldCVFileName ? (
      <Stack component={"label"} className={"upload-section"}>
        <Stack direction="row" spacing={2}>
          {t("apply_page.upload_cv")}
          {!isRequired && (
            <Stack direction="row" alignItems="center">
              <StyledOptionalLabel display="flex" mb="0px !important">
                Optional
              </StyledOptionalLabel>
            </Stack>
          )}
        </Stack>
        <input
          hidden
          type="file"
          onChange={onUploadCV}
          data-testid={testId || "apply-job-cv-input"}
        />
        <CloudArrowUpIcon />
      </Stack>
    ) : (
      <Stack
        component={"label"}
        className={"uploaded-cv-section"}
        data-testid={"apply-job-existing-cv"}
      >
        <CheckCircleIcon className={"success-icon"} />
        <span data-testid={"apply-job-existing-cv-name"}>
          {uploadedCV ? uploadedCV?.name : oldCVFileName}
        </span>
        <XMarkIcon
          className={"delete-icon"}
          onClick={onResetCV}
          data-testid={"apply-job-reset-cv-button"}
        />
      </Stack>
    )}
  </StyledUploadCVContainer>
);

export default CVSection;
