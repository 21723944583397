import React, { useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import AutocompleteFilter from "../../filters_new/AutocompleteFilter";
import { companyAddressFormDefaultValues, companyDataSchema } from "./config";
import { getDialogsState } from "../../../store_new/selectors/Dialogs";
import {
  fetchCountries,
  updateCompanyAddress,
} from "../../../store_new/reducers/CompanySettings";
import { fetchUser } from "../../../store_new/reducers/CurrentUser";
import { setIsCompanyAddressDialogOpen } from "../../../store_new/reducers/dialogs";
import { TCompanyAddressUpdatePayload } from "../../../models/CompanySettings";
import { getCompanySettingsCompanyState } from "../../../store_new/selectors/Company";
import { isValidNumber } from "../../../utils/common";
import { logOut } from "../../../store_new/reducers/Auth";
import { StyledLoadingApplyButton } from "../../../pages/JobPreview/styles";
import { StyleCompanyAddressDialogContainer } from "./style";
import { addMessage } from "../../../store_new/reducers/Snackbar";

const CompanyAddressDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isCompanyAddressDialogOpen } = useSelector(getDialogsState);
  const { countries, isAddressUpdateLoading } = useSelector(
    getCompanySettingsCompanyState
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(companyDataSchema(t)),
    defaultValues: companyAddressFormDefaultValues,
  });

  const onSuccessAddressUpdate = () => {
    reset();
    dispatch(setIsCompanyAddressDialogOpen(false));
    dispatch(fetchUser());
    setTimeout(() => {
      dispatch(
        addMessage({
          type: "success",
          title: t("update_company_information.success_snackbar"),
        })
      );
    }, 750);
  };

  const onSubmit = (data: TCompanyAddressUpdatePayload) => {
    dispatch(
      updateCompanyAddress({
        data: data,
        callback: () => onSuccessAddressUpdate(),
      })
    );
  };

  const handleLogout = () => {
    dispatch(setIsCompanyAddressDialogOpen(false));
    dispatch(logOut(undefined as any));
  };

  useEffect(() => {
    if (isCompanyAddressDialogOpen && countries?.length === 0) {
      dispatch(fetchCountries());
    }
  }, [isCompanyAddressDialogOpen]);

  return (
    <Dialog
      open={isCompanyAddressDialogOpen}
      PaperProps={{
        elevation: 0,
      }}
    >
      <StyleCompanyAddressDialogContainer p={3} width={512}>
        <Typography variant="body1" fontWeight={500}>
          {t("update_company_information.title")}
        </Typography>
        <Stack mt={3}>
          <Typography variant="subtitle2" color="textSecondary">
            {t("update_company_information.textOne")}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {t("update_company_information.textTwo")}
          </Typography>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={1} mb={1}>
            <InputLabel className="required-label">
              {t("update_company_information.country")}
            </InputLabel>
            <Controller
              name="country"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AutocompleteFilter
                  onChange={onChange}
                  value={value}
                  options={countries}
                  placeholder={t("update_company_information.country")}
                  errors={errors?.country}
                  hasFlags
                />
              )}
            />
          </Box>
          <Stack
            direction="row"
            spacing={4.5}
            mt={errors?.country ? 3 : 1.5}
            mb={1}
          >
            <Stack flex={2}>
              <InputLabel className="required-label">
                {t("update_company_information.city")}
              </InputLabel>
              <TextField
                {...register("city")}
                variant="outlined"
                size="small"
                placeholder={t("update_company_information.city") as string}
                error={Boolean(errors.city)}
                helperText={errors?.city?.message}
              />
            </Stack>
            <Stack flex={1}>
              <InputLabel className="required-label">
                {t("update_company_information.postcode")}
              </InputLabel>
              <TextField
                {...register("plz")}
                variant="outlined"
                size="small"
                placeholder={t("update_company_information.postcode") as string}
                onKeyPress={(e) => isValidNumber(e as unknown as KeyboardEvent)}
                error={Boolean(errors.plz)}
                helperText={errors?.plz?.message}
              />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={4.5} mt={1.5}>
            <Stack flex={2}>
              <InputLabel className="required-label">
                {t("update_company_information.street")}
              </InputLabel>
              <TextField
                {...register("address")}
                variant="outlined"
                size="small"
                placeholder={t("update_company_information.street") as string}
                error={Boolean(errors.address)}
                helperText={errors?.address?.message}
              />
            </Stack>
            <Stack flex={1}>
              <InputLabel className="required-label">
                {t("update_company_information.number")}
              </InputLabel>
              <TextField
                {...register("street_number")}
                variant="outlined"
                size="small"
                placeholder={t("update_company_information.number") as string}
                error={Boolean(errors.street_number)}
                helperText={errors?.street_number?.message}
              />
            </Stack>
          </Stack>
          <Stack
            mt={4}
            direction="row"
            width="100%"
            justifyContent="flex-end"
            spacing={1.5}
          >
            <Button type={"button"} variant="outlined" onClick={handleLogout}>
              {t("userSettings.logout")}
            </Button>
            <StyledLoadingApplyButton
              type={"submit"}
              variant={"contained"}
              loadingPosition="end"
              companyColor="#178CF2"
              loading={isAddressUpdateLoading}
              sx={{
                width: 128,
              }}
            >
              {t("button.save")}
            </StyledLoadingApplyButton>
          </Stack>
        </form>
      </StyleCompanyAddressDialogContainer>
    </Dialog>
  );
};

export default CompanyAddressDialog;
