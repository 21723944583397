import { TFunction } from "i18next";
import * as yup from "yup";
import { TNewPasswordFormFields } from "../../../models/Auth";

export const getFormSettings = (t: TFunction) => {
  return yup.object().shape({
    password: yup.string().required(t("register.message") as string),
    confirmPassword: yup
      .string()
      .required(t("register.message") as string)
      .oneOf([yup.ref("password")], t("password_match_message") as string),
  });
};

export const formDefaultValues: TNewPasswordFormFields = {
  password: "",
  confirmPassword: "",
};
