import * as yup from "yup";
import { TFunction } from "i18next";
import { IApplicationCreatedTriggerFormFields } from "../../../../models/Automation";

export const getApplicationCreatedTriggerFormFields = (t: TFunction) =>
  yup.object().shape({
    jobs: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string(),
          value: yup.string(),
        })
      )
      .nullable()
      .transform((val) => (!val ? [] : val))
      .min(1, t("register.message") as string),
  });

export const applicationCreatedTriggerFormFieldsDefaultValue: IApplicationCreatedTriggerFormFields =
  {
    jobs: [],
  };
