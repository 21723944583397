import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../config";

export const getCustomersListState = (state: RootState) =>
  state.customers.customers;

export const getAllActiveCustomersListState = (state: RootState) =>
  state.customers.allActiveCustomers;

export const getCustomersState = (state: RootState) => state.customers;

export const getCustomersFilterState = (state: RootState) =>
  state.customers.filters;

export const getSelectedCustomer = createSelector(
  [getCustomersState],
  (customers) => customers.selectedCustomer
);
