import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { IApplicationRequestDataComponent } from "../../../models/ApplicationPage";

const RequestDataComponent = ({
  isOpen,
  t,
  title,
  listToRequest,
  isDisabled,
  onClose,
  onChange,
  onSubmit,
}: IApplicationRequestDataComponent) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <FormGroup>
        {listToRequest.map((field) => (
          <FormControlLabel
            checked={field.checked}
            key={field.id}
            control={
              <Checkbox
                color="secondary"
                onChange={() => {
                  onChange(field.id);
                }}
              />
            }
            label={
              <Typography variant="body2" fontSize={14} color="textSecondary">
                {field.title}
              </Typography>
            }
          />
        ))}
      </FormGroup>
    </DialogContent>
    <DialogActions sx={{ p: 3 }}>
      <Button variant="outlined" onClick={onClose} autoFocus>
        {t("button.secondCancel")}
      </Button>
      <Button variant="contained" onClick={onSubmit} disabled={isDisabled}>
        {title}
      </Button>
    </DialogActions>
  </Dialog>
);

export default RequestDataComponent;
