import React from "react";
import { TFunction } from "i18next";
import { TCountryListOption, TListOption } from "./common";
import { TJobListItem } from "./JobList";
import { TApplication } from "./Applications";

export interface ISingleJobState {
  readonly navigation: {
    readonly jobTabs: JOB_TABS_KEY[];
    readonly selectedJobTab: JOB_TABS_KEY;
  };
  readonly filterValue: {
    readonly name: string;
    readonly country: string;
    readonly industry: string;
    readonly duration: string;
  };
  readonly filterOptions: {
    readonly countries: TCountryListOption[];
    readonly industries: TListOption[];
    readonly countriesToIndustries: { [x: string]: number[] };
    readonly industriesToCountries: { [x: string]: string[] };
    readonly durations: TListOption[];
  };
  readonly jobId: string | null;
  readonly company: TSingleJobCompany | null;
  readonly jobDetails: TJobDetails | null;
  readonly jobTemplate: TJobTemplate | null;
  readonly jobLocations: TJobLocation[] | null;
  readonly jobQuestions: TJobQuestions[] | null;
  readonly jobOwners: TJobOwner[] | null;
  readonly packages: TPremiumPackage[] | null;
  readonly products: TConvertedPremiumProduct[] | null;
  readonly shopPackages: TPremiumPackage[] | null;
  readonly shopProducts: TConvertedPremiumProduct[] | null;
  readonly totalShopProducts: number;
  readonly basicJobBoards: TBasicJobBoard[];
  readonly error: unknown;
  readonly isLoading: boolean;
  readonly isListLoading: boolean;
  readonly bookings?: IJobOrder[];
  readonly tempJobDetails: TJobDetails | null;
  readonly selectedProducts:
    | TPremiumPackage[]
    | TConvertedPremiumProduct[]
    | TPremiumProductItem[];
  readonly isCartOpen: boolean;
  readonly noStepCheckout: boolean;
  readonly selectedPremiumJobBoardsTab: PREMIUM_ITEM_TYPES;
}

export type TSingleJobCompany = {
  readonly about_us: string;
  readonly employees: string;
  readonly jobs: TJobListItem[];
  readonly jobs_total: number;
  readonly linkedin_url: string;
  readonly logo: string;
  readonly title: string;
  readonly website: string;
  readonly xing_url: string;
};

export interface IBasicJobBoardComponent {
  readonly t: TFunction;
  readonly isListLoading: boolean;
  readonly jobList: TBasicJobBoard[];
  readonly actions: {
    readonly onConnect: (data: TBasicJobBoard) => void;
    readonly onUpload: (data?: File) => void;
  };
}

export interface IConnectDialogComponent {
  readonly t: TFunction;
  readonly companyProfileUrl: string;
  readonly jobBoard: TBasicJobBoard | null;
  readonly isLoading: boolean;
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onSave: () => void;
  readonly onChangeUrl: (data: string) => void;
}

export interface ISingleJobComponent {
  readonly t: TFunction;
  readonly jobTabs: JOB_TABS_KEY[];
  readonly selectedJobTab: JOB_TABS_KEY;
  readonly view: string;
  readonly tabsWidth: number | string;
  readonly cardsApplications: any;
  readonly list: TApplication[];
  readonly menuOpen: boolean;
  readonly anchorEl: HTMLElement | null;
  readonly selectedPremiumJobBoardsTab: PREMIUM_ITEM_TYPES;
  readonly lang: string;
  readonly onTabChange: (
    event: React.SyntheticEvent,
    value: JOB_TABS_KEY
  ) => void;
  readonly onOpenCart: () => void;
  readonly onSelectMenuItem: (option?: PREMIUM_ITEM_TYPES) => void;
  readonly onClickPremiumJobBoardsTab: (
    event: React.MouseEvent<HTMLElement>
  ) => void;
}

export interface IPremiumJobBoardsComponent {
  readonly t: TFunction;
  readonly isLoading: boolean;
  readonly packages: TPremiumPackage[] | null;
  readonly products: TConvertedPremiumProduct[] | null;
  readonly selectedProducts: any; //refactor
  readonly isDiscoverPage?: boolean;
  readonly isJobEditorPage?: boolean;
  readonly selectedTab: PREMIUM_ITEM_TYPES;
  readonly totalShopProducts: number;
  readonly isTabs: boolean;
  readonly isPremiumJobBoardsTab: boolean;
  readonly selectedPremiumJobBoardsTab: PREMIUM_ITEM_TYPES;
  readonly isSelected: (data: TPremiumPackage | TPremiumProductItem) => boolean;
  readonly onSelectTab: (
    event: React.SyntheticEvent,
    value: PREMIUM_ITEM_TYPES
  ) => void;
  readonly onOpenProduct: (
    data: TConvertedPremiumProduct | TPremiumPackage
  ) => void;
  readonly onAddPackage: (data: TPremiumPackage) => void;
  readonly onAddProduct: (data: TPremiumProductItem) => void;
  readonly onResetFilters: () => void;
  readonly onRemoveFromCart: (
    data: TPremiumPackage | TPremiumProductItem
  ) => void;
  readonly onOpenCart: () => void;
  readonly onHoverLeaveSelectPackageButton: (value: boolean) => void;
}

export interface IPremiumPackagesComponent {
  readonly t: TFunction;
  readonly isJobEditorPage?: boolean;
  readonly packages: TPremiumPackage[] | null;
  readonly isPremiumJobBoardsTab?: boolean;
  readonly selectedProducts?: any; //refactor
  readonly onOpenProduct: (data: TPremiumPackage) => void;
  readonly isSelected: (data: TPremiumPackage) => boolean;
  readonly onAddPackage: (data: TPremiumPackage) => void;
  readonly onRemoveFromCart: (data: TPremiumPackage) => void;
  readonly onResetFilters: () => void;
  readonly onHoverLeaveSelectPackageButton: (value: boolean) => void;
  readonly onOpenCart?: () => void;
}

export interface IJobOrder {
  readonly orderId: number | string;
  readonly publishedDate: string | number;
  readonly totalViews: string | number;
  readonly totalClicks: string | number;
  readonly status:
    | "not_started"
    | "in_production"
    | "customer_action_pending"
    | "published"
    | "finished"
    | "aborted";
  readonly products: IJobOrderProduct[];
}

export interface IJobOrderProduct {
  readonly supplierLogo: string;
  readonly productName: string;
  readonly totalDays: string | number;
  readonly remainingDays: string | number;
  readonly views: number | string;
  readonly clicks: number | string;
  readonly supplier: string;
}

export interface IPremiumProductsComponent {
  readonly t: TFunction;
  readonly products: TConvertedPremiumProduct[] | null;
  readonly totalShopProducts?: number;
  readonly isPremiumJobBoardsTab?: boolean;
  readonly selectedProducts?: any; //refactor
  readonly isSelected: (data: TPremiumProductItem) => boolean;
  readonly onAddProduct: (data: TPremiumProductItem) => void;
  readonly onRemoveFromCart: (data: TPremiumProductItem) => void;
  readonly onResetFilters: () => void;
  readonly onOpenProduct: (data: TConvertedPremiumProduct) => void;
  readonly onOpenCart?: () => void;
}

export interface IProductsFilter {
  readonly t: TFunction;
  readonly filterValue: ISingleJobState["filterValue"];
  readonly industries: TListOption[];
  readonly isJobEditorPage: boolean;
  readonly countries: TListOption[];
  readonly durations: TListOption[];
  readonly isPremiumJobBoardsTab?: boolean;
  readonly onChange: (data: {
    readonly type: keyof ISingleJobState["filterValue"];
    readonly value: string;
  }) => void;
}

export type TBasicJobBoard = {
  readonly title: string;
  readonly website: string;
  readonly logo: string;
  readonly is_active: number; // TODO should be boolean
  readonly created_at: Date | string;
  readonly remainingDays: number;
  readonly status: string;
  readonly hasConnectAction: boolean;
  readonly hasUploadAction: boolean;
};

export type TJobDetails = {
  readonly id: string;
  readonly title: string;
  readonly company: string;
  readonly url_key: string;
  readonly reference_number: null | string;
  readonly is_archived: string; // TODO should be boolean
  readonly company_id: string;
  readonly publish_date: null | string;
  readonly keywords: null | string;
  readonly notes: null | string;
  readonly min_salary: string;
  readonly max_salary: string;
  readonly salary_currency: string;
  readonly salary_type: string;
  readonly created_at: string;
  readonly updated_at: string;
  readonly earliest_start_date_required: string | number; // TODO should be boolean
  readonly driver_license_required: string | number; // TODO should be boolean
  readonly profile_photo_required: string | number; // TODO should be boolean
  readonly phone_required: string | number; // TODO should be boolean
  readonly location_required: string | number; // TODO should be boolean
  readonly salary_expectation_required: string | number;
  readonly current_professional_status_required: string | number; // TODO should be boolean
  readonly highest_degree_required: string | number; // TODO should be boolean
  readonly cv_required: string | number; // TODO should be boolean
  readonly skills_required: string | number; // TODO should be boolean
  readonly langs_required: string | number; // TODO should be boolean
  readonly other_documents_required: string | number; // TODO should be boolean
  readonly contract_type_id: string;
  readonly contract_type: string;
  readonly seniority_id: string;
  readonly seniority_level: string;
  readonly company_jass_id: string;
  readonly company_url_key: string;
  readonly work_id: string;
  readonly work_type: string;
  readonly position_id: string;
  readonly position_type: string;
  readonly industry_id: string;
  readonly industry: string;
  readonly qualification_id: string;
  readonly qualification_title: string;
  readonly template_id: string;
  readonly template: string;
  readonly template_introduction: string;
  readonly logo: null | string;
  readonly header_1: null | string;
  readonly header_2: null | string;
  readonly header_3: null | string;
  readonly publicationsCount?: string | number | null;
  readonly workflow_id: number | null;
  readonly job_type: string;
  readonly job_type_id: number;
  readonly owners?: TJobOwner[] | null;
};

export type TPremiumPackage = {
  readonly id: number;
  readonly package_name: string;
  readonly package_price: number;
  readonly discount?: number;
  readonly oldPrice?: number;
  readonly sales_products: TSalesProduct[];
  readonly type?: string;
  readonly quota_amount?: string | number | null;
  readonly quota_price?: string | number | null;
};

export type TSalesProduct = {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly type: string;
  readonly supplier: string;
  readonly country: string;
  readonly country_list: string[];
  readonly runtime_in_days: number;
  readonly rrp: number;
  readonly market_price: number;
  readonly image_url: string;
  readonly supplier_id: number;
  readonly top: boolean;
  readonly supplier_groups: { id: string | number; name: string }[];
};

export type TPremiumProductItem = TSalesProduct & {
  readonly discount?: number;
  readonly recommended_retail_price: number;
  readonly priority: number;
  readonly product_fields: TProductField[];
  readonly retail_price: number;
  readonly end_customer_price: null | number;
  readonly partner_price: number;
  readonly package_price: null | number;
  readonly agency_price: null | number;
  readonly is_free: null | string | number;
  readonly quota_amount: null | string | number;
  readonly quota_price: null | number;
  readonly external_purchase_price: null | number;
  readonly max_slots: null | string | number;
  readonly free_slots: null | string | number;
  readonly default_price: null | string | number;
  readonly budget_booking_count: null | string | number;
  readonly budget_active: boolean;
  readonly product_setting: TProductSettings;
};

export type TProductField = {
  readonly name: string;
  readonly description: string;
  readonly specific: number;
  readonly is_mandatory: boolean;
  readonly length: number;
  readonly count: number;
};

export type TProductSettings = {
  readonly id: number;
  readonly design_html: boolean;
  readonly design_text: boolean;
  readonly design_structured: boolean;
  readonly tracking_views: boolean;
  readonly tracking_clicks: boolean;
  readonly cities: null | string[];
  readonly sales_product_id: number;
  readonly created_at: Date;
  readonly updated_at: Date;
};

export type TPremiumProducts = {
  readonly [x: string]: TPremiumProductItem[];
};

export type TConvertedPremiumProduct = {
  readonly name: string;
  readonly country: string;
  readonly products: TPremiumProductItem[];
};

export type TJobTemplate = {
  readonly id: string;
  readonly member_id: string;
  readonly name: string;
  readonly introduction: string;
  readonly introduction_title: string;
  readonly vacancy_title: string;
  readonly tasks: string;
  readonly tasks_title: string;
  readonly profile: string;
  readonly profile_title: string;
  readonly offer: string;
  readonly offer_title: string;
  readonly contact: string;
  readonly contact_title: string;
  readonly logo: null | string;
  readonly background: null | string;
  readonly header_1: null | string;
  readonly header_2: null | string;
  readonly header_3: null | string;
  readonly video_url: string;
  readonly company_color: string;
  readonly background_color: string;
  readonly design: null | string;
  readonly created_at: Date;
  readonly updated_at: Date;
  readonly company_title: string;
  readonly logo_name: null | string;
  readonly background_name: null | string;
  readonly header_1_name: null | string;
  readonly header_2_name: null | string;
  readonly header_3_name: null | string;
  readonly logo_original_name: null | string;
  readonly background_original_name: null | string;
  readonly header_1_original_name: null | string;
  readonly header_2_original_name: null | string;
  readonly header_3_original_name: null | string;
};

export type TJobLocation = {
  readonly id: number;
  readonly country: string;
  readonly city: string;
  readonly zip: string;
  readonly job_id: number;
  readonly nicename: string;
};

export type TJobQuestions = {
  readonly id: number;
  readonly question: string;
  readonly is_answer_type_yesno: number;
};

export type TJobOwner = {
  id: number;
  firstname: string;
  lastname: string;
  profile_photo?: string;
};

export interface IJobDetailsComponent {
  readonly t: TFunction;
  readonly jobTemplate: ISingleJobState["jobTemplate"];
  readonly jobDetails: ISingleJobState["jobDetails"];
  readonly jobLocations: ISingleJobState["jobLocations"];
  readonly jobQuestions: ISingleJobState["jobQuestions"];
  readonly jobTypes: { id: number; title: string }[];
  readonly isLoading: boolean;
  readonly companyColor: string;
  readonly onEdit: (
    data: DIALOG_TYPE,
    selectedSections?: SELECTED_EDIT_SECTIONS[]
  ) => void;
}

export interface IJodDescriptionPreviewComponent {
  readonly t: TFunction;
  readonly jobTemplate: IJobDetailsComponent["jobTemplate"];
  readonly onEdit: () => void;
}

export interface IJodDetailsPreviewComponent {
  readonly t: TFunction;
  readonly jobDetails: IJobDetailsComponent["jobDetails"];
  readonly jobLocations: IJobDetailsComponent["jobLocations"];
  readonly jobTypes: { id: number; title: string }[];
  readonly onEdit: () => void;
}

export interface IJodBasicQuestionsPreviewComponent {
  readonly t: TFunction;
  readonly jobDetails: IJobDetailsComponent["jobDetails"];
  readonly onEdit: () => void;
}

export interface IJobDocumnetsPreviewComponent {
  readonly t: TFunction;
  readonly jobDetails: IJobDetailsComponent["jobDetails"];
  readonly onEdit: () => void;
}

export interface IJobAdditionalQuestionsPreviewComponent {
  readonly t: TFunction;
  readonly jobQuestions: IJobDetailsComponent["jobQuestions"];
  readonly onEdit: () => void;
}

export type TJobDataToExport = {
  readonly id?: TJobDetails["id"];
  readonly company_id?: TJobDetails["company_id"];
  readonly title?: TJobDetails["title"];
  readonly url_key?: TJobDetails["url_key"];
  readonly publish_date?: string;
  readonly created_at?: string;
  readonly contract_type_id?: TJobDetails["contract_type_id"];
  readonly contract_type?: TJobDetails["contract_type"];
  readonly seniority_id?: TJobDetails["seniority_id"];
  readonly seniority_level?: TJobDetails["seniority_level"];
  readonly work_field_id?: TJobDetails["work_id"];
  readonly field_of_work?: TJobDetails["work_type"];
  readonly position_type_id?: TJobDetails["position_id"];
  readonly industry_id?: TJobDetails["industry_id"];
  readonly industry_title?: TJobDetails["industry_id"];
  readonly position_type?: TJobDetails["position_type"];
  readonly company_title?: TJobDetails["industry"];
  readonly company_jass_id?: TJobDetails["company_jass_id"];
  readonly applicationsCount?: number;
  readonly locations?: string;
  readonly status: null | string;
};

export enum JOB_TABS_KEY {
  APPLICATIONS = "single_job.applications",
  JOB_DETAILS = "single_job.job_details",
  BASIC_JOB_BOARDS = "single_job.basic_job_boards",
  PREMIUM_JOB_BOARDS = "single_job.premium_job_boards",
  BOOKINGS = "single_job.bookings",
}

export enum PREMIUM_ITEM_TYPES {
  PRODUCTS = "products",
  PACKAGES = "packages",
}

export enum DIALOG_TYPE {
  JOB_DESCRIPTION = "jobDescription",
  JOB_DETAILS = "jobDetails",
  JOB_BASIC_QUESTIONS = "jobBasicQuestions",
  JOB_DOCUMENTS = "jobDocuments",
  JOB_ADDITIONAL_QUESTION = "jobAdditionalQuestions",
}

export enum SELECTED_EDIT_SECTIONS {
  JOB_DESCRIPTION_MAIN_SECTION = "jobDescriptionMainSection",
  JOB_DESCRIPTION_MEDIA_SECTION = "jobDescriptionMediaSection",
  JOB_APPLICATION_PROCESS_BASIC_QUESTIONS = "jobApplicationProcessBasicQuestionsSection",
  JOB_APPLICATION_PROCESS_ADDITIONAL_QUESTIONS = "jobApplicationProcessAdditionalQuestionsSection",
  JOB_APPLICATION_PROCESS_DOCUMENTS = "jobApplicationProcessDocumentsSection",
  JOB_DETAILS_LOCATIONS = "jobDetailsLocationsSection",
  JOB_DETAILS_GENERAL_INFO = "jobDetailsGeneralInfoSection",
  JOB_DETAILS_ADDITIONAL_INFO = "jobDetailsAdditionalInfoSection",
  JOB_DETAILS_SALARY = "jobDetailsSalarySection",
}
