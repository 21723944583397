import React from "react";
import {
  StyledJobPreviewTemplateHeaderContainer,
  StyledJobPreviewTemplateTitle,
} from "../styles";

const JobHeader = ({
  desktop,
  logo,
  customerName,
}: {
  readonly desktop: boolean;
  readonly logo?: string | null;
  readonly customerName?: string;
}) => (
  <StyledJobPreviewTemplateHeaderContainer logo={logo} desktop={desktop}>
    {logo && <img src={logo} alt={"company-logo"} />}
    <StyledJobPreviewTemplateTitle
      overflow="hidden"
      textOverflow="ellipsis"
      className="two-line-title"
    >
      {customerName}
    </StyledJobPreviewTemplateTitle>
  </StyledJobPreviewTemplateHeaderContainer>
);

export default JobHeader;
