import { Drawer } from "@mui/material";
import { styled } from "@mui/system";

export const StyledCartDrawer = styled(Drawer)({
  "& .MuiDrawer-paper": {
    padding: "32px !important",
    width: 560,
    overflowY: "auto",
    borderLeft: "1px solid #E5E7EB",
  },
  ".remove-icon": {
    cursor: "pointer",
    width: 24,
    height: 24,
    stroke: "#131E30",
    strokeWidth: 1.5,
  },
  ".divider": {
    width: "100%",
    borderColor: "#E5E7EB",
    marginTop: 24,
    marginBottom: 24,
  },
  ".terms-divider": {
    width: "100%",
    borderColor: "#E5E7EB",
    marginTop: 24,
    marginBottom: 11,
  },
  ".footer-divider": {
    width: "100%",
    borderColor: "#E5E7EB",
    marginTop: 24,
  },
  ".clock-icon": {
    width: 16,
    height: 16,
    stroke: "#9CA3AF",
    strokeWidth: 1,
  },
  ".clear-icon": {
    position: "relative",
    left: 3,
    cursor: "pointer",
    height: 24,
    width: 24,
    stroke: "#EF4444",
  },
  ".terms-text": {
    paddingTop: 13,
  },
  ".discount-price": {
    textDecoration: "line-through",
    color: "#EF4444",
    fontSize: 16,
    fontWeight: 500,
  },
});
