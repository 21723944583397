import * as yup from "yup";
import { TFunction } from "i18next";
import { TCompanyAddressUpdatePayload } from "../../../models/CompanySettings";

export const companyDataSchema = (t: TFunction) => {
  return yup.object().shape({
    country: yup.string().required(t("register.message") as string),
    city: yup.string().required(t("register.message") as string),
    plz: yup.string().required(t("register.message") as string),
    address: yup.string().required(t("register.message") as string),
    street_number: yup.string().required(t("register.message") as string),
  });
};

export const companyAddressFormDefaultValues: TCompanyAddressUpdatePayload = {
  country: "",
  city: "",
  plz: "",
  address: "",
  street_number: "",
};
