import React from "react";
import { Stack, Button } from "@mui/material";
import {
  IAutomationTrigger,
  ITriggerSelectionComponent,
} from "../../models/Automation";

const TriggerToolbar = ({
  triggersGroup,
  activeTriggerGroup,
  onChangeActiveTrigger,
}: ITriggerSelectionComponent) => (
  <Stack direction="row" spacing={1}>
    {triggersGroup.map((trigger: IAutomationTrigger, index: number) => (
      <Button
        className={
          activeTriggerGroup === trigger.type ? "" : "inactive-trigger-button"
        }
        key={index}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onClick={(e) => onChangeActiveTrigger(e.target.innerText)}
        variant="contained"
      >
        {trigger.name}
      </Button>
    ))}
  </Stack>
);

export default TriggerToolbar;
