import React, { useMemo } from "react";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DashboardJobsComponent from "./component";
import {
  getJobListIsListLoading,
  getJobListJobsState,
} from "../../../store_new/selectors/JobList";
import { useNavigate } from "react-router-dom";
import {
  setTopHeaderSubtitle,
  setTopHeaderTitle,
} from "../../../store_new/reducers/TopHeader";
import { openJobEditor } from "../../../store_new/reducers/JobEditor";
import { getCompanySettingsCompanyState } from "../../../store_new/selectors/Company";
import { setIsLimitDialogOpen } from "../../../store_new/reducers/dialogs";
import { getCurrentUserData } from "../../../store_new/selectors/CurrentUser";

const DashboardJobs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { applicationsAllowed, isAgency } = useSelector(getCurrentUserData);
  const { company } = useSelector(getCompanySettingsCompanyState);
  const { list } = useSelector(getJobListJobsState);
  const isLoading = useSelector(getJobListIsListLoading);
  const jobList = useMemo(() => {
    if (list) {
      if (list.length > 5) return list.slice(0, 5);
      return list;
    }
    return [];
  }, [list]);

  const handleOnJobNavigate = (jobId: string) => {
    if (!isAgency || applicationsAllowed) {
      const currentJob = jobList.find((job) => job.id === jobId);
      if (currentJob) {
        const { title, created_at } = currentJob;
        dispatch(setTopHeaderTitle(title));
        dispatch(
          setTopHeaderSubtitle(
            `${t("single_job.date_created")} ${moment(created_at).format(
              "DD.MM.YYYY"
            )}`
          )
        );
      }

      navigate(`/jobs/${jobId}/applications`);
    }
  };

  const handleOnOpenJobEditor = () => {
    if (company?.jobs_creation_allowed) {
      dispatch(openJobEditor());
    } else {
      dispatch(setIsLimitDialogOpen(true));
    }
  };

  return (
    <DashboardJobsComponent
      t={t}
      jobList={jobList}
      isLoading={isLoading}
      onJobNavigate={handleOnJobNavigate}
      onOpenJobEditor={handleOnOpenJobEditor}
    />
  );
};

export default DashboardJobs;
