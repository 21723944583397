import React from "react";
import { StyledLogoContainer } from "../styles";

const LogoCell = ({ logoSrc }: { logoSrc: string }) => (
  <StyledLogoContainer>
    <img src={logoSrc} height={33} width={100} />
  </StyledLogoContainer>
);

export default LogoCell;
