import React from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  InputLabel,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AuthLayout from "../../layouts/Auth";
import { ILoginComponent } from "../../models/Auth";

const LoginComponent = ({
  t,
  errors,
  register,
  showPassword,
  isLoading,
  desktop,
  handleSubmit,
  onResetPassword,
  onShowHidePassword,
  onSignUp,
  onSubmit,
}: ILoginComponent) => (
  <AuthLayout desktop={desktop}>
    <Typography variant="body1" lineHeight={2.5}>
      {t("login.welcome_to_karriera")}
    </Typography>
    <Typography variant={"subtitle2"} color={"textSecondary"}>
      {t("login.please_login")}
    </Typography>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mt={3.5} mb={2.5}>
        <InputLabel>{t("login.e_mail")}</InputLabel>
        <TextField
          {...register("email")}
          variant={"outlined"}
          size={"small"}
          fullWidth
          placeholder={t("login.e_mail_example") as string}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
        />
      </Box>
      <Box mt={2.5} mb={2}>
        <InputLabel>{t("login.password")}</InputLabel>
        <TextField
          {...register("password")}
          variant={"outlined"}
          size={"small"}
          fullWidth
          type={showPassword ? "text" : "password"}
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
          placeholder={t("login.your_password") as string}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Stack width={19.75} height={19.75}>
                  {showPassword ? (
                    <EyeSlashIcon
                      onClick={onShowHidePassword}
                      cursor={"pointer"}
                    />
                  ) : (
                    <EyeIcon onClick={onShowHidePassword} cursor={"pointer"} />
                  )}
                </Stack>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box mb={2.5}>
        <Link underline={"none"} onClick={onResetPassword}>
          <Typography variant={"body1"} fontSize={14}>
            {t("login.forgot_password")}
          </Typography>
        </Link>
      </Box>
      <Box>
        <Button
          color={"primary"}
          variant={"contained"}
          size={"small"}
          type={"submit"}
          disableElevation
          fullWidth
          endIcon={
            isLoading ? (
              <CircularProgress
                size={16}
                style={{
                  color: "#9A9FAF",
                }}
              />
            ) : null
          }
          disabled={isLoading}
        >
          {t("login.log_in")}
        </Button>
      </Box>
      {desktop ? (
        <Typography variant="subtitle2" align="center" color="textSecondary">
          {t("login.you_dont_have_acc")}
          <Link underline="none" display="inline" onClick={onSignUp}>
            {t("login.sign_up")}
          </Link>
        </Typography>
      ) : (
        <>
          <Typography variant="subtitle2" align="center" color="textSecondary">
            {t("login.you_dont_have_acc")}
          </Typography>
          <Typography variant="subtitle2" align="center" color="textSecondary">
            <Link underline="none" display="inline" onClick={onSignUp}>
              {t("login.sign_up")}
            </Link>
          </Typography>
        </>
      )}
    </form>
  </AuthLayout>
);

export default LoginComponent;
