import React from "react";
import { Stack, Typography } from "@mui/material";
import { TFunction } from "i18next";
import BookingsStatus from "../../../components/BookingsStatus";
import { IJobOrder } from "../../../models/SingleJob";
import { shortMonthDateFormat } from "../../../utils";

const OrderItemDetails = ({
  t,
  order,
}: {
  readonly t: TFunction;
  readonly order: IJobOrder;
}) => {
  const expiredOrder = order.products.every((item) => item.remainingDays === 0);
  return (
    <Stack
      width="100%"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="subtitle2" fontWeight={500}>
        Order ID #{order.orderId} {t("basic_job_board.published_at")}{" "}
        {shortMonthDateFormat(order.publishedDate)}
      </Typography>
      <BookingsStatus
        status={
          expiredOrder
            ? (t("expired") as "aborted")
            : (order.status.replace(" ", "_") as
                | "not_started"
                | "in_production"
                | "customer_action_pending"
                | "published"
                | "finished"
                | "aborted")
        }
      />
    </Stack>
  );
};

export default OrderItemDetails;
