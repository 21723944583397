import { Button } from "@mui/material";
import React from "react";
import SimpleFilter from "../../components/filters_new/SimpleFilter";
import { DIALOG_TYPE } from "../../models/common";
import { ITeamsToolbar, TEAM_USER_STATUSES } from "../../models/Team";
import { StyledTeamsToolbarContainer } from "./styles";

const TeamSettingsToolbar = ({
  quickSearch,
  onChangeFilters,
  t,
  onOpenDialog,
  selectedUserStatus
}: ITeamsToolbar) => (
  <StyledTeamsToolbarContainer direction={"row"}>
    <SimpleFilter
      testId={"teams-toolbar-simple-filter"}
      value={quickSearch}
      onChange={onChangeFilters}
      placeholder={t("team_members.search_placeholder")}
    />
    {selectedUserStatus === TEAM_USER_STATUSES.PENDING && (
      <Button
        data-testid={"teams-toolbar-invite-button"}
        variant={"contained"}
        onClick={() => onOpenDialog({ type: DIALOG_TYPE.INVITE })}
      >
        {t("team_members.invite")}
      </Button>
    )}
  </StyledTeamsToolbarContainer>
);

export default TeamSettingsToolbar;
