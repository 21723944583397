import React from "react";
import CandidateItem from "./CandidateItem";
import StatusItem from "./StatusItem";
import { IRenderItemComponent, ICandidateItem } from "../../models/Kanban";
import { IWorkflowStatus } from "../../models/Workflow";

const RenderItem = ({
  t,
  variant = "candidate",
  provided,
  data,
  selectedItems,
  column,
  index,
  setColumns,
  onSelectItem,
  onItemClick,
}: IRenderItemComponent) => {
  switch (variant) {
    case "candidate":
      return (
        <CandidateItem
          t={t}
          provided={provided}
          data={data as ICandidateItem}
          selectedItems={selectedItems}
          onSelectItem={onSelectItem}
          onItemClick={onItemClick}
        />
      );
    case "workflowStatus":
      return (
        <StatusItem
          item={data as IWorkflowStatus}
          column={column}
          provided={provided}
          index={index}
          setColumns={setColumns}
        />
      );
    default:
      return (
        <CandidateItem
          t={t}
          provided={provided}
          data={data as ICandidateItem}
          selectedItems={selectedItems}
          onSelectItem={onSelectItem}
          onItemClick={onItemClick}
        />
      );
  }
};

export default RenderItem;
