import React from "react";
import { Stack, CircularProgress } from "@mui/material";

const Loading = ({
  color = "secondary",
  height = 500,
  size = 50,
}: {
  readonly color?: "primary" | "secondary" | "info" | "success" | "warning";
  readonly height?: number | string;
  readonly size?: number;
}) => (
  <Stack
    width="100%"
    height={height || 500}
    justifyContent="center"
    alignItems="center"
  >
    <CircularProgress color={color} size={size} />
  </Stack>
);

export default Loading;
