import * as yup from "yup";
import { TFunction } from "i18next";
import { TJobActionRegisterFormFields } from "../../../models/JobPreview";

export const jobActionAuthFormFields = (t: TFunction) => {
  return yup.object().shape({
    email: yup
      .string()
      .email(t("valid_email_message") as string)
      .required(t("register.message") as string),
    firstname: yup.string().required(t("register.message") as string),
    lastname: yup.string().required(t("register.message") as string),
    agreement: yup.bool().oneOf([true], t("accept_terms_text") as string)
  });
};

export const jobActionRegisterFormDefaultValues: TJobActionRegisterFormFields =
  {
    email: "",
    firstname: "",
    lastname: "",
    agreement: false
  };
