import React from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

const IconWithTooltip = () => {
  const { t } = useTranslation();
  return (
    <Tooltip
      arrow
      style={{ cursor: "pointer", marginLeft: 8 }}
      placement={"right"}
      title={t("candidate_questions.tooltip_text" as string)}
      componentsProps={{
        arrow: {
          sx: {
            color: "#178CF2",
          },
        },
        tooltip: {
          sx: {
            fontSize: "12px",
            padding: 1,
            background: "#178CF2",
          },
        },
      }}
    >
      <CheckCircleIcon />
    </Tooltip>
  );
};

export default IconWithTooltip;
