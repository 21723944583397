import React, { useMemo } from "react";
import moment from "moment";
import { Stack, Avatar } from "@mui/material";
import { TrashIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { IMessageComponent, MESSAGES_DIALOG_TYPE } from "../../models/Messages";
import {
  StyledNameTypography,
  StyledSmallTypography,
  StyledMessageTypography
} from "./styles";
import { generateFullDate } from "../../utils";

const MessageComponent = ({
  t,
  message,
  messagesInfo,
  loggedUser,
  firstname,
  lastname,
  profilePhoto,
  activeMessageId,
  setDeleteMessageId,
  setActiveMessageId,
  handleEdit,
  onOpenDialog
}: IMessageComponent) => {
  const isLoggedUser = useMemo<boolean>(() => {
    return message.origin === loggedUser;
  }, [loggedUser, message]);

  return (
    <Stack
      direction="row"
      spacing={1}
      onMouseOver={() => setActiveMessageId(message.message_id)}
    >
      <Avatar src={isLoggedUser ? profilePhoto : messagesInfo?.profile_photo} />
      <Stack
        p={1.5}
        spacing={1}
        borderRadius={1.5}
        sx={{
          backgroundColor: isLoggedUser ? "#EEF2FF" : "#F9FAFB",
          maxWidth: "100%"
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Stack direction="row" spacing={0.5}>
            <StyledNameTypography>
              {isLoggedUser
                ? `${firstname} ${lastname}`
                : `${messagesInfo?.firstname} ${messagesInfo?.lastname}`}
            </StyledNameTypography>
          </Stack>
          {isLoggedUser && (
            <Stack direction="row" alignItems="center" spacing={1.25}>
              <PencilSquareIcon
                width={20}
                height={20}
                color="#178CF2"
                cursor="pointer"
                onClick={handleEdit}
              />
              <TrashIcon
                width={20}
                height={20}
                color="#178CF2"
                cursor="pointer"
                onClick={() => {
                  setDeleteMessageId(activeMessageId);
                  onOpenDialog({ type: MESSAGES_DIALOG_TYPE.DELETE });
                }}
              />
            </Stack>
          )}
        </Stack>
        <StyledMessageTypography>{message.text}</StyledMessageTypography>
        <StyledSmallTypography>
          {generateFullDate(message.created_at)} {t("messages_page.at")}{" "}
          {moment(message.created_at).format("HH:mm")}
        </StyledSmallTypography>
      </Stack>
    </Stack>
  );
};

export default MessageComponent;
