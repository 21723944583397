import React from "react";
import { Box, Divider, Grid } from "@mui/material";
import BigColorPicker from "../../../../components/BigColorPicker";
import ImageUpload from "../../../../components/ImageUpload";
import StyledSectionTitle from "../../../../components/SectionTitle";
import { IJobMediaPreviewComponent } from "../../../../models/JobEditor";
import { StyledSectionContainer } from "./styles";

const colorPickerStyle = {
  marginLeft: -240,
};

const JobMediaPreview = ({
  t,
  onEdit,
  jobTemplate,
  companyColor,
}: IJobMediaPreviewComponent) => (
  <StyledSectionContainer spacing={2}>
    <StyledSectionTitle
      title={t("singleJob.jobDetails.titles.jobMedia")}
      onClick={onEdit}
    />
    <Divider />
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ImageUpload
            title={t("create_job_first_step.upload_photo", { number: 1 })}
            subtitle={t("create_job_first_step.photo_size")}
            image={jobTemplate?.header_1_original_name || ""}
          />
        </Grid>
        <Grid item xs={4}>
          <ImageUpload
            title={t("create_job_first_step.upload_photo", { number: 2 })}
            subtitle={t("create_job_first_step.photo_size")}
            image={jobTemplate?.header_2_original_name || ""}
          />
        </Grid>
        <Grid item xs={4}>
          <ImageUpload
            title={t("create_job_first_step.upload_photo", { number: 3 })}
            subtitle={t("create_job_first_step.photo_size")}
            image={jobTemplate?.header_3_original_name || ""}
          />
        </Grid>
        <Grid item xs={4}>
          <ImageUpload
            title={t("create_job_first_step.upload_logo")}
            subtitle={t("create_job_first_step.photo_size")}
            image={jobTemplate?.logo_original_name || ""}
          />
        </Grid>
        <Grid item xs={4}>
          <ImageUpload
            title={t("create_job_first_step.upload_background_image")}
            subtitle={t("create_job_first_step.photo_size")}
            image={jobTemplate?.background_original_name || ""}
          />
        </Grid>
        <Grid item xs={4}>
          <BigColorPicker
            title={t("company_settings.company_color")}
            color={companyColor || "#178CF2"}
            customStyle={colorPickerStyle}
          />
        </Grid>
      </Grid>
    </Box>
  </StyledSectionContainer>
);

export default JobMediaPreview;
