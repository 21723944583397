import React from "react";
import { Typography } from "@mui/material";
import { TFunction } from "i18next";
import {
  BriefcaseIcon,
  FolderOpenIcon,
  MapPinIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import {
  StyledJobListItemContainer,
  StyledJobListItemDescriptionContainer,
  StyledJobListItemIconContainer,
  StyledJobTitle,
} from "../styles";
import { TJob } from "../../../models/CompanyInfo";

const JobListItem = ({
  t,
  applicationsCount,
  city,
  positionType,
  title,
  workType,
  onJobNavigate,
}: {
  readonly t: TFunction;
  readonly applicationsCount: string;
  readonly city: string;
  readonly positionType: TJob["position_type"];
  readonly title: TJob["title"];
  readonly workType: TJob["field_of_work"];
  readonly onJobNavigate: () => void;
}) => (
  <StyledJobListItemContainer data-testid={"dashboard-job-list-item-container"}>
    <StyledJobTitle onClick={onJobNavigate}>{title}</StyledJobTitle>
    <StyledJobListItemDescriptionContainer>
      <StyledJobListItemIconContainer>
        <MapPinIcon />
        <Typography variant="subtitle2" display="inline" color="textSecondary">
          {city}
        </Typography>
      </StyledJobListItemIconContainer>
      <StyledJobListItemIconContainer>
        <BriefcaseIcon />
        <Typography variant="subtitle2" display="inline" color="textSecondary">
          {positionType}
        </Typography>
      </StyledJobListItemIconContainer>
      <StyledJobListItemIconContainer>
        <FolderOpenIcon />
        <Typography variant="subtitle2" display="inline" color="textSecondary">
          {workType}
        </Typography>
      </StyledJobListItemIconContainer>
      <StyledJobListItemIconContainer
        className={"link"}
        onClick={onJobNavigate}
      >
        <UserIcon />
        <Typography variant="subtitle2" display="inline">
          {applicationsCount} {t("dashboard.jobs.applications")}
        </Typography>
      </StyledJobListItemIconContainer>
    </StyledJobListItemDescriptionContainer>
  </StyledJobListItemContainer>
);

export default JobListItem;
