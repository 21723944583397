import { call, put, select, takeLatest } from "redux-saga/effects";
import { apiDeleteUser, apiGetUsers, apiInviteUsers } from "../../api/Users";
import {
  ITeamsState,
  TEAM_USER_STATUSES,
  TTeamsInvitation,
  TTeamsMember,
} from "../../models/Team";
import {
  fetchUsers,
  fetchUsersFailed,
  fetchUsersSuccess,
  inviteUsers,
  inviteUsersFailed,
  inviteUsersSuccess,
  removeUser,
  removeUserFailed,
  removeUserSuccess,
} from "../reducers/Team";
import {
  getTeamsFilteredPaginationState,
  getTeamsFiltersState,
  getTeamsNavigationState,
} from "../selectors/Team";
import { PayloadActionWithCallback } from "../../models/common";

const baseUrl = "/company/members";

function* handleOnFetchUsers({ payload }: { payload: TEAM_USER_STATUSES }) {
  const { pageSize: pagesize, pageNum: pagenum } = yield select(
    getTeamsFilteredPaginationState
  );
  const { quickSearch: email } = yield select(getTeamsFiltersState);
  const url =
    payload === TEAM_USER_STATUSES.JOINED ? baseUrl : `${baseUrl}/invited`;
  const apiPayload = {
    pagenum,
    pagesize,
    email,
  };

  try {
    const { data } = yield call(apiGetUsers, { url, apiPayload });
    const list =
      payload === TEAM_USER_STATUSES.JOINED ? data.members : data.invitations;
    yield put(fetchUsersSuccess({ list, totalCount: data.totalCount }));
  } catch (e) {
    yield put(fetchUsersFailed(e)); // TODO handle error
  }
}

function* handleOnRemoveUsers({
  payload,
}: {
  payload: TTeamsMember & TTeamsInvitation;
}) {
  const { id, username, email } = payload;
  const { selectedUserStatus }: ITeamsState["navigation"] = yield select(
    getTeamsNavigationState
  );
  const url =
    selectedUserStatus === TEAM_USER_STATUSES.JOINED
      ? `${baseUrl}/delete`
      : `${baseUrl}/delete-invitation`;

  const apiPayload = {
    id,
    email: selectedUserStatus === TEAM_USER_STATUSES.JOINED ? username : email,
  };

  try {
    yield call(apiDeleteUser, { url, apiPayload });
    yield put(removeUserSuccess());
    yield put(fetchUsers(selectedUserStatus)); // dispatch fetchUser with current selected user statuses
  } catch (e) {
    yield put(removeUserFailed(e)); // TODO handle error
  }
}

function* handleOnInviteUsers({
  payload,
}: PayloadActionWithCallback<{ emails: string[] }>) {
  const { selectedUserStatus }: ITeamsState["navigation"] = yield select(
    getTeamsNavigationState
  );
  const url = `${baseUrl}/invite`;

  const { emails, callback } = payload;

  try {
    yield call(apiInviteUsers, { url, apiPayload: { emails } });
    yield put(inviteUsersSuccess());
    yield put(fetchUsers(selectedUserStatus)); // dispatch fetchUser with current selected user statuses
    yield call(callback);
  } catch (e) {
    yield put(inviteUsersFailed(e)); // TODO handle error
  }
}

function* UserSaga() {
  yield takeLatest(fetchUsers, handleOnFetchUsers);
  yield takeLatest(removeUser, handleOnRemoveUsers);
  yield takeLatest(inviteUsers, handleOnInviteUsers);
}

export default UserSaga;
