import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { PayloadActionWithCallback } from "../../models/common";
import {
  ICompanyRegistrationState,
  TCompanyRecruiterFormFields,
  TCompanyRegistrationCompanyData,
  TCompanyRegistrationCompanyDataPayload
} from "../../models/Registration";

const initialState: ICompanyRegistrationState = {
  companyData: null,
  isLoading: false,
  error: null,
  invitationEmail: undefined,
  agency_id: undefined
};

const companyReducers = {
  checkCompanyName: (
    state: Draft<ICompanyRegistrationState>,
    _: PayloadActionWithCallback<{ company: string }>
  ) => {
    state.isLoading = true;
  },
  checkCompanyNameSuccess: (state: Draft<ICompanyRegistrationState>) => {
    state.isLoading = false;
    state.error = null;
  },
  checkCompanyNameFailed: (
    state: Draft<ICompanyRegistrationState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload; // TODO handle error
  },
  checkRecruiterEmail: (
    state: Draft<ICompanyRegistrationState>,
    _: PayloadActionWithCallback<{ email: string }>
  ) => {
    state.isLoading = true;
  },
  checkRecruiterEmailSuccess: (state: Draft<ICompanyRegistrationState>) => {
    state.isLoading = false;
    state.error = null;
  },
  checkRecruiterEmailFailed: (
    state: Draft<ICompanyRegistrationState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload; // TODO handle error
  },
  setCompanyData: (
    state: Draft<ICompanyRegistrationState>,
    action: PayloadAction<TCompanyRegistrationCompanyData>
  ) => {
    state.companyData = action.payload;
  },
  confirmInvitation: (
    state: Draft<ICompanyRegistrationState>,
    _: PayloadAction<{ urlKey: string }>
  ) => {
    state.isLoading = true;
  },
  confirmInvitationSuccess: (
    state: Draft<ICompanyRegistrationState>,
    action: PayloadAction<any>
  ) => {
    state.isLoading = false;
    state.error = null;
    state.invitationEmail = action.payload.email;
    state.agency_id = action.payload.agency_id;
  },
  confirmInvitationFailed: (
    state: Draft<ICompanyRegistrationState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload; // TODO handle error
  },
  completeInvitation: (
    state: Draft<ICompanyRegistrationState>,
    _: PayloadActionWithCallback<{
      url_key: string;
      recruiterData: Omit<TCompanyRecruiterFormFields, "checked" | "email">;
    }>
  ) => {
    state.isLoading = true;
  },
  completeInvitationSuccess: (state: Draft<ICompanyRegistrationState>) => {
    state.isLoading = false;
    state.error = null;
  },
  completeInvitationFailed: (
    state: Draft<ICompanyRegistrationState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload; // TODO handle error
  },
  resendLink: (
    state: Draft<ICompanyRegistrationState>,
    _: PayloadActionWithCallback<{
      candidateUrlKey?: string;
      jobUrlKey?: string;
    }>
  ) => {
    state.isLoading = true;
  },
  resendLinkSuccess: (state: Draft<ICompanyRegistrationState>) => {
    state.isLoading = false;
    state.error = null;
  },
  resendLinkFailed: (
    state: Draft<ICompanyRegistrationState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload; // TODO handle error
  },
  confirmEmailCode: (
    state: Draft<ICompanyRegistrationState>,
    _: PayloadActionWithCallback<{ code: string }>
  ) => {
    state.isLoading = true;
  },
  confirmEmailCodeSuccess: (state: Draft<ICompanyRegistrationState>) => {
    state.isLoading = false;
    state.error = null;
  },
  confirmEmailCodeFailed: (
    state: Draft<ICompanyRegistrationState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload; // TODO handle error
  },
  confirmExistingCompanyToAgency: (
    state: Draft<ICompanyRegistrationState>,
    _: PayloadActionWithCallback<{ code: string }>
  ) => {
    state.isLoading = true;
  },
  confirmExistingCompanyToAgencySuccess: (
    state: Draft<ICompanyRegistrationState>
  ) => {
    state.isLoading = false;
    state.error = null;
  },
  confirmExistingCompanyToAgencyFailed: (
    state: Draft<ICompanyRegistrationState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload; // TODO handle error
  },
  registerCompany: (
    state: Draft<ICompanyRegistrationState>,
    _: PayloadActionWithCallback<{
      data: TCompanyRegistrationCompanyDataPayload;
    }>
  ) => {
    state.isLoading = true;
  },
  registerCompanySuccess: (state: Draft<ICompanyRegistrationState>) => {
    state.isLoading = false;
    state.error = null;
  },
  registerCompanyFailed: (
    state: Draft<ICompanyRegistrationState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload; // TODO handle error
  }
};

export const RegistrationSlice = createSlice({
  name: "REGISTRATION",
  initialState,
  reducers: {
    ...companyReducers
  }
});

export const {
  checkCompanyName,
  checkCompanyNameSuccess,
  checkCompanyNameFailed,
  checkRecruiterEmail,
  checkRecruiterEmailSuccess,
  checkRecruiterEmailFailed,
  confirmInvitation,
  confirmInvitationSuccess,
  confirmInvitationFailed,
  confirmExistingCompanyToAgency,
  confirmExistingCompanyToAgencySuccess,
  confirmExistingCompanyToAgencyFailed,
  completeInvitation,
  completeInvitationSuccess,
  completeInvitationFailed,
  resendLink,
  resendLinkSuccess,
  resendLinkFailed,
  confirmEmailCode,
  confirmEmailCodeSuccess,
  confirmEmailCodeFailed,
  registerCompany,
  registerCompanySuccess,
  registerCompanyFailed,
  setCompanyData
} = RegistrationSlice.actions;

export default RegistrationSlice.reducer;
