import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import {
  IWorkflowState,
  TApiAddUpdateWorkflow,
  IWorkflowStatus,
  ISelectedJob,
  IWorkflow,
} from "../../models/Workflow";
import {
  PayloadActionWithCallback,
  TWorkflowListOption,
} from "../../models/common";

const initialState: IWorkflowState = {
  isLoading: false,
  isListLoading: false,
  error: false,
  workflows: [],
  isWorkflowsListLoaded: false,
  currentWorkflowName: "",
  isDefaultWorkflow: false,
  selectedJob: null,
  selectedWorkflow: null,
  editWorkflow: null,
  currentWorkflowStatuses: [],
};

export const WorkflowsSlice = createSlice({
  name: "WORKFLOW",
  initialState,
  reducers: {
    fetchWorkflows: (
      state: Draft<IWorkflowState>,
      _: PayloadAction<IWorkflow[]>
    ) => {
      state.isListLoading = true;
    },
    fetchWorkflowsSuccess: (
      state: Draft<IWorkflowState>,
      action: PayloadAction<IWorkflow[]>
    ) => {
      state.isListLoading = false;
      state.isWorkflowsListLoaded = true;
      state.workflows = action.payload;
    },
    fetchWorkflowsFailed: (
      state: Draft<IWorkflowState>,
      action: PayloadAction<unknown>
    ) => {
      state.isListLoading = false;
      state.error = action.payload;
    },
    addUpdateWorkflow: (
      state: Draft<IWorkflowState>,
      _: PayloadActionWithCallback<TApiAddUpdateWorkflow>
    ) => {
      state.isLoading = true;
    },
    addUpdateWorkflowSuccess: (state: Draft<IWorkflowState>) => {
      state.isLoading = false;
    },
    addUpdateWorkflowFailed: (
      state: Draft<IWorkflowState>,
      action: PayloadAction<unknown>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteWorkflow: (
      state: Draft<IWorkflowState>,
      _: PayloadActionWithCallback<{ id: number }>
    ) => {
      state.isLoading = true;
    },
    deleteWorkflowwSuccess: (state: Draft<IWorkflowState>) => {
      state.isLoading = false;
    },
    deleteWorkflowFailed: (
      state: Draft<IWorkflowState>,
      action: PayloadAction<unknown>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setCurrentWorkflowStatuses: (
      state: Draft<IWorkflowState>,
      action: PayloadAction<IWorkflowStatus[]>
    ) => {
      state.currentWorkflowStatuses = action.payload;
    },
    resetCurrentWorkflowStatuses: (state: Draft<IWorkflowState>) => {
      state.currentWorkflowName = initialState.currentWorkflowName;
    },
    setCurrentWorkflowName: (
      state: Draft<IWorkflowState>,
      action: PayloadAction<string>
    ) => {
      state.currentWorkflowName = action.payload;
    },
    setIsDefaultWorkflow: (
      state: Draft<IWorkflowState>,
      action: PayloadAction<boolean | number>
    ) => {
      state.isDefaultWorkflow = action.payload;
    },
    setSelectedWorkflow: (
      state: Draft<IWorkflowState>,
      action: PayloadAction<TWorkflowListOption | null>
    ) => {
      state.selectedWorkflow = action.payload;
    },
    setSelectedJob: (
      state: Draft<IWorkflowState>,
      action: PayloadAction<ISelectedJob | null>
    ) => {
      state.selectedJob = action.payload;
    },
    setEditWorkflow: (
      state: Draft<IWorkflowState>,
      action: PayloadAction<IWorkflow | null>
    ) => {
      state.editWorkflow = action.payload;
    },
  },
});

export const {
  fetchWorkflows,
  fetchWorkflowsSuccess,
  fetchWorkflowsFailed,
  addUpdateWorkflow,
  addUpdateWorkflowSuccess,
  addUpdateWorkflowFailed,
  setCurrentWorkflowStatuses,
  setCurrentWorkflowName,
  setIsDefaultWorkflow,
  resetCurrentWorkflowStatuses,
  deleteWorkflow,
  deleteWorkflowFailed,
  deleteWorkflowwSuccess,
  setSelectedWorkflow,
  setSelectedJob,
  setEditWorkflow,
} = WorkflowsSlice.actions;

export default WorkflowsSlice.reducer;
