import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import { IJobListDeleteJobModal } from "../../models/JobList";
import { StyledJobListModalCancelButton } from "./styles";

const DeleteJobModal = ({
  t,
  isLoading,
  isOpen,
  onClose,
  onConfirmDeleteJob
}: IJobListDeleteJobModal) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogTitle sx={{ p: 3 }}>{t("delete_job_dialog.title")}</DialogTitle>
    <DialogContent>
      <Typography variant="body2" color="textSecondary">
        {t("delete_job_dialog.body")}
      </Typography>
    </DialogContent>
    <DialogActions sx={{ p: 3 }}>
      <StyledJobListModalCancelButton
        color={"error"}
        variant={"outlined"}
        onClick={onClose}
        disabled={isLoading}
      >
        {t("delete_job_dialog.cancel")}
      </StyledJobListModalCancelButton>
      <Button
        color={"error"}
        endIcon={
          isLoading ? (
            <CircularProgress
              size={16}
              style={{
                color: "#9A9FAF"
              }}
            />
          ) : null
        }
        variant={"contained"}
        onClick={onConfirmDeleteJob}
        disabled={isLoading}
      >
        {t("delete_job_dialog.delete")}
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteJobModal;
