import React from "react";
import {
  Stack,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Divider
} from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import DataGrid from "../../DataGrid_new";
import NoData from "../../NoData";
import { IDataGrid } from "../../../models/DataGrid";
import { IImportCustomersDialog } from "../../../models/dialogs";
import { DATA_GRID_LIST_TYPE } from "../../../models/DataGrid";

const ImportCustomerDialog = ({
  t,
  isOpen,
  selectedCustomers,
  jassCustomers,
  onSelect,
  onClose,
  onImport
}: IImportCustomersDialog) => (
  <Dialog
    open={Boolean(isOpen)}
    onClose={onClose}
    PaperProps={{
      elevation: 0
    }}
    sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          minWidth: 920,
          maxWidth: 920
        },
        "& hr": {
          margin: "19px 0px 4px 0px"
        }
      }
    }}
  >
    <Stack px={3}>
      <Stack
        pt={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack>
          <Typography variant="h6">
            {t("customers.import_customer")}{" "}
            {jassCustomers && jassCustomers.length > 0 && (
              <span>
                ({selectedCustomers.length} {t("customers.selected")})
              </span>
            )}
          </Typography>
        </Stack>
        <Stack>
          <XMarkIcon
            width={24}
            height={24}
            stroke="#131E30"
            strokeWidth={2}
            cursor="pointer"
            onClick={onClose}
          />
        </Stack>
      </Stack>
      <Divider />
    </Stack>
    <DialogContent>
      {jassCustomers && jassCustomers.length > 0 ? (
        <Stack
          width={872}
          height={
            jassCustomers
              ? jassCustomers?.length > 5
                ? 400
                : jassCustomers?.length * 49 + 56
              : 0
          }
        >
          <DataGrid
            t={t}
            list={jassCustomers}
            type={DATA_GRID_LIST_TYPE.IMPORT_CUSTOMERS}
            actions={{
              onClick: () => {}
            }}
            onSelect={onSelect as IDataGrid["onSelect"]}
          />
        </Stack>
      ) : (
        <NoData
          title={t("customers.no_customers_dialog_title")}
          containerHeight={400}
          noButton
        />
      )}
    </DialogContent>
    <DialogActions style={{ padding: "16px 24px", paddingTop: "0px" }}>
      <Button variant="outlined" onClick={onClose} autoFocus>
        {t("button.secondCancel")}
      </Button>
      <Button
        variant="contained"
        onClick={onImport}
        disabled={
          !jassCustomers ||
          jassCustomers.length < 1 ||
          selectedCustomers.length < 1
        }
      >
        {t("customers.import")}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ImportCustomerDialog;
