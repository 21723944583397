import { styled } from "@mui/system";
import { Stack } from "@mui/material";
import { JOB_TABS_KEY } from "../../models/SingleJob";

export const StyledMainLayoutWrapper = styled(Stack)(
  ({
    selectedJobTab,
    view,
  }: {
    selectedJobTab: JOB_TABS_KEY;
    view: string;
  }) => ({
    position: "relative",
    flexDirection: "row",
    backgroundColor: "#F9FAFB",
    minHeight:
      view === "cards" && selectedJobTab === JOB_TABS_KEY.APPLICATIONS
        ? "98.5vh"
        : "100vh",
    maxHeight:
      view === "cards" && selectedJobTab === JOB_TABS_KEY.APPLICATIONS
        ? "98.5vh"
        : "auto",
  })
);
