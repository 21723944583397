import React from "react";
import { IDataGridStatusCell } from "../../../models/DataGrid";
import { StyledStatusChip } from "../styles";

const StatusCell = ({ uniqueStatuses, label, type }: IDataGridStatusCell) => {
  return (
    <StyledStatusChip
      label={label}
      type={type}
      uniqueStatuses={uniqueStatuses}
    />
  );
};

export default StatusCell;
