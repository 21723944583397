import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import SendEmailComponent from "./component";
import {
  IAutomationAction,
  ISelectedActionComponent,
  ISendEmailFormField,
  TRIGGERS_TYPE,
} from "../../../../models/Automation";
import {
  getSendEmailFormFields,
  sendEmailFormFieldsDefaultValue,
} from "./config";
import { getAutomationState } from "../../../../store_new/selectors/Automation";
import {
  fetchSelectedActionData,
  setActions,
  setSelectedAction,
} from "../../../../store_new/reducers/Automation";
import { TListOption } from "../../../../models/common";
import {
  convertArrayToString,
  convertStringToArray,
  generateApplicationTriggerTypeEmailValue,
} from "../../../../utils";

const SendEmail = ({
  t,
  selectedAction,
  onCloseAction,
}: ISelectedActionComponent) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedEmails, setSelectedEmails] = useState<string | string[]>([]);
  const [selectedCC, setSelectedCC] = useState<string | string[]>([]);
  const [selectedBCC, setSelectedBCC] = useState<string | string[]>([]);
  const open = Boolean(anchorEl);

  const { selectedActionData, currentAutomation } =
    useSelector(getAutomationState);

  const {
    control,
    formState: { errors },
    clearErrors,
    register,
    setValue,
    handleSubmit,
    getValues,
  } = useForm({
    mode: "onSubmit",
    defaultValues: sendEmailFormFieldsDefaultValue,
    resolver: yupResolver(getSendEmailFormFields(t)),
  });

  const errorsOrCC =
    Object.keys(errors).length > 0 || selectedAction?.params.isCCorBCC;
  const smallScreenHeight = window.innerHeight < 704;
  const maximumFormHeight = errorsOrCC && smallScreenHeight ? 340 : "auto";

  const formattedMembers = useMemo(() => {
    if (selectedActionData?.members) {
      const newMembers = Object.entries(selectedActionData.members).map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([value, label]) => ({
          value: label,
          label: `${t("automation.teamMember")}: ${label}`,
        })
      );
      if (currentAutomation?.trigger?.type === "Application") {
        const formattedValue = generateApplicationTriggerTypeEmailValue(
          currentAutomation.trigger.const as
            | TRIGGERS_TYPE.APPLICATION_CREATED
            | TRIGGERS_TYPE.APPLICATION_UPDATED
            | TRIGGERS_TYPE.APPLICATION_STATUS_UPDATED
        );
        const candidateEmailVariableArray = [
          {
            value: formattedValue,
            label: `${t(
              "automation.variables.candidate_email"
            )}: {placeholderemailaddress@email.de}`,
          },
        ];
        const variablesAndMembers = candidateEmailVariableArray.concat(
          newMembers as any
        );
        return variablesAndMembers;
      }
      return newMembers;
    }
    return [];
  }, [selectedActionData]);

  const formattedVariables = useMemo(() => {
    if (currentAutomation.trigger) {
      const variables = Object.entries(currentAutomation.trigger.params).map(
        ([key]) => ({
          value: key,
          label: t(`automation.variables.${key}`),
        })
      );
      return variables;
    }

    return [];
  }, [currentAutomation.trigger?.params]);

  const filteredVariables = formattedVariables.filter(
    (variable) => variable.value !== "jobId"
  );

  const handleChangeCC = (values: string | string[]) => {
    setSelectedCC(values);
    setValue("cc", values as string[]);
  };

  const handleChangeBCC = (values: string | string[]) => {
    setSelectedBCC(values);
    setValue("bcc", values as string[]);
  };

  const handleChangeEmails = (values: string | string[]) => {
    setSelectedEmails(values);
    setValue("to", values as string[]);
    if (Object.keys(errors).length && values.length) {
      clearErrors();
    }
  };

  const handleChangeCCorBcc = (CCOrBCC: boolean) => {
    const updatedActions = currentAutomation.actions.map(
      (action: IAutomationAction) => {
        if (action.id === selectedAction?.id) {
          const formattedSelectedAction = {
            ...selectedAction,
            params: {
              ...selectedAction?.params,
              to: getValues().to,
              isCCorBCC: CCOrBCC ?? false,
            },
          };
          dispatch(
            setSelectedAction(formattedSelectedAction as IAutomationAction)
          );
          return {
            ...action,
            params: {
              ...action.params,
              isCCorBCC: CCOrBCC ?? false,
            },
          };
        }
        return action;
      }
    );
    dispatch(setActions(updatedActions as IAutomationAction[]));
  };

  const onSubmit = (data: ISendEmailFormField) => {
    const formattedTo = convertArrayToString(data.to);
    const formattedCC = data.cc.length
      ? convertArrayToString(data.cc)
      : undefined;
    const formattedBCC = data.bcc.length
      ? convertArrayToString(data.bcc)
      : undefined;

    const updatedActions = currentAutomation.actions.map(
      (action: IAutomationAction) => {
        if (action.id === selectedAction?.id) {
          return {
            ...selectedAction,
            actionCompleted: true,
            params: {
              ...data,
              sender_name: "Karriera",
              sender_email: "support@karriera.de",
              to: formattedTo,
              cc: formattedCC,
              bcc: formattedBCC,
            },
          };
        }
        return action;
      }
    );
    dispatch(setActions(updatedActions as IAutomationAction[]));
    dispatch(setSelectedAction(undefined));
  };

  const handleOpenVariables = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAnchorEl = () => {
    setAnchorEl(null);
  };

  const handleSelectVariable = (value: string) => {
    const currentValue = getValues().subject;
    const newValue = currentValue + value;
    setValue("subject", newValue);
    handleCloseAnchorEl();
  };

  const loadTo = () => {
    if (typeof selectedAction?.params.to !== "string") {
      setSelectedEmails(selectedAction?.params.to as string[]);
      setValue("to", selectedAction?.params.to as string[]);
    } else {
      const formattedTo = convertStringToArray(
        selectedAction?.params.to,
        formattedMembers
      );
      setSelectedEmails(formattedTo);
      setValue("to", formattedTo);
    }
  };

  const loadCCBCC = () => {
    if (selectedAction?.params?.isCCorBCC) {
      if (
        selectedAction.params.cc &&
        typeof selectedAction.params.cc !== "string"
      ) {
        setValue("cc", selectedAction?.params.cc as string[]);
        setSelectedCC(selectedAction?.params.cc as string[]);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } else if (selectedAction.params.cc && selectedAction.params.cc.length) {
        const formattedCC = convertStringToArray(
          selectedAction?.params?.cc,
          formattedMembers
        );
        setValue("cc", formattedCC);
        setSelectedCC(formattedCC);
      }
      if (
        selectedAction.params.bcc &&
        typeof selectedAction.params.bcc !== "string"
      ) {
        setValue("bcc", selectedAction?.params.bcc as string[]);
        setSelectedBCC(selectedAction?.params.bcc as string[]);
      } else if (
        selectedAction.params.bcc &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        selectedAction.params.bcc.length
      ) {
        const formattedCC = convertStringToArray(
          selectedAction?.params?.bcc,
          formattedMembers
        );
        setValue("bcc", formattedCC);
        setSelectedBCC(formattedCC);
      }
    } else if (selectedAction?.params?.cc || selectedAction?.params.bcc) {
      handleChangeCCorBcc(true);
    }
  };

  const loadSubjectMessage = () => {
    if (selectedAction?.params.subject !== "string") {
      setValue("subject", selectedAction?.params.subject as string);
    }
    if (selectedAction?.params.message !== "string") {
      setValue("message", selectedAction?.params.message as string);
    }
  };

  useEffect(() => {
    if (
      selectedActionData &&
      !Object.keys(selectedActionData?.members).length
    ) {
      dispatch(fetchSelectedActionData("members"));
    }
  }, []);

  useEffect(() => {
    loadTo();
    loadCCBCC();
    loadSubjectMessage();
  }, [selectedAction, formattedMembers]);

  return (
    <SendEmailComponent
      t={t}
      open={open}
      anchorEl={anchorEl}
      formattedMembers={formattedMembers as TListOption[]}
      formattedVariables={filteredVariables}
      maximumFormHeight={maximumFormHeight}
      selectedAction={selectedAction}
      errors={errors}
      control={control}
      selectedEmails={selectedEmails}
      selectedCC={selectedCC}
      selectedBCC={selectedBCC}
      register={register}
      onChangeEmails={handleChangeEmails}
      onChangeCC={handleChangeCC}
      onChangeBCC={handleChangeBCC}
      onChangeCCorBcc={handleChangeCCorBcc}
      onCloseAction={onCloseAction}
      onCloseAnchorEl={handleCloseAnchorEl}
      onOpenVariables={handleOpenVariables}
      onSelectVariable={handleSelectVariable}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
    />
  );
};

export default SendEmail;
