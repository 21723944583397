import { Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledImageUploadContainer = styled(Stack)({
  margin: "8px 0",
  height: 96,
  borderRadius: 12,
  ".upload-image-button": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height: "100%",
    width: "100%",
    border: "1.5px dashed #E5E7EB",
    color: "#178CF2",
  },
  "&:hover": {
    border: "1px solid #178CF2",
  },
});

export const StyledExistingImageContainer = styled(Stack)({
  width: "100%",
  borderRadius: 12,
  backgroundColor: "#F9FAFB",
  justifyContent: "space-between",
  height: 96,
  ".upload-button": {
    width: 32,
    height: 32,
    background: "#EDF7FF",
    svg: {
      stroke: "#178CF2",
      strokeWidth: 1,
    },
    pointerEvents: "none",
  },
  ".remove-button": {
    width: 32,
    height: 32,
    background: "#FEF2F2",
    svg: {
      stroke: "#EF4444",
      strokeWidth: 1,
    },
  },
});
