import { TApiGetJobsPayload } from "../models/JobList";
import { apiRequest } from "./config";

export const apiGetJobs = ({
  url,
  apiPayload
}: {
  readonly url: string;
  readonly apiPayload: TApiGetJobsPayload;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiArchiveJobs = ({ url }: { readonly url: string }) =>
  apiRequest({ type: "post", url });

export const apiDeleteJobs = ({ jobUrlKey }: { readonly jobUrlKey: string }) =>
  apiRequest({ type: "post", url: `/company/jobs/delete/${jobUrlKey}` });
