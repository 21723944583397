import React, { useEffect, useMemo, useRef, useState } from "react";
import { Dialog } from "@mui/material";
import { debounce } from "debounce";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import JobEditorComponent from "./component";
import SaveDraftDialog from "../dialogs/SaveDraftDialog";
import {
  TJobApplicationProcessFormFields,
  TJobDescriptionDataFields,
  TJobDescriptionFormFields,
  TJobDetailsFormFields,
} from "../../models/JobEditor";
import {
  closeJobEditor,
  createJob,
  fetchJobData,
  openCheckout,
  previewJobTemplate,
  resetJobDescription,
  resetJobEditor,
  resetSteps,
  saveDraft,
  setCompletedStep,
  setDefaultLogo,
  setJobDescripitonData,
  setJobEditorStep,
  setUpdateDraftInProgress,
  updateDraft,
  updateJobApplicationProcess,
  updateJobDescription,
  updateJobDetails,
} from "../../store_new/reducers/JobEditor";
import { getJobEditorState } from "../../store_new/selectors/JobEditor";
import { getCurrentJobStatus } from "../../store_new/selectors/JobList";
import { JOB_STATUSES } from "../../models/JobList";
import {
  fetchJobs,
  setSelectedJobStatus,
} from "../../store_new/reducers/JobList";
import {
  downloadCompanyLogo,
  fetchCompanyData,
  fetchLogoBlob,
  updateCompanyData,
} from "../../store_new/reducers/CompanySettings";
import { getCompanySettingsCompanyState } from "../../store_new/selectors/Company";
import { TJobQuestions } from "../../models/SingleJob";
import { getSingleJobState } from "../../store_new/selectors/SingleJob";
import { SNACKBAR_ACTION_TYPE } from "../../models/Snackbar";
import {
  addMessage,
  turnOffNotifications,
} from "../../store_new/reducers/Snackbar";

const JobEditor = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const jobDescriptionFormRef = useRef<HTMLFormElement>(null);
  const jobDetailsFormRef = useRef<HTMLFormElement>(null);
  const jobApplicationProcessFormRef = useRef<HTMLFormElement>(null);

  const { isJobEditorOpen } = useSelector(getJobEditorState);
  const { selectedProducts } = useSelector(getSingleJobState);

  const jobStatus = useSelector(getCurrentJobStatus);

  const { draftData, updateDraftInProgress, jobDescriptionData } =
    useSelector(getJobEditorState);
  const {
    currentStep,
    isLoading,
    jobDescription,
    jobDetails,
    jobApplicationProcess,
  } = useSelector(getJobEditorState);

  const { company, companyLogoBlob, companyLogo } = useSelector(
    getCompanySettingsCompanyState,
  );

  const [isSaveDraftDialogOpen, setIsSaveDraftDialogOpen] =
    useState<boolean>(false);

  const selectedProductsSum = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const retail_price = selectedProducts.reduce(
      (accumulator: number, object: any) => {
        return accumulator + object.retail_price || object.package_price;
      },
      0,
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const recommended_retail_price = selectedProducts.reduce(
      (accumulator: number, object: any) => {
        return (
          accumulator + object.recommended_retail_price || object.package_price
        );
      },
      0,
    );
    return [retail_price, recommended_retail_price];
  }, [selectedProducts]);

  useEffect(() => {
    if (company?.logo && !updateDraftInProgress) {
      dispatch(downloadCompanyLogo());
    }
  }, [company, draftData, updateDraftInProgress]);

  useEffect(() => {
    if (companyLogo && !updateDraftInProgress) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchLogoBlob(companyLogo));
    }
  }, [companyLogo, draftData, updateDraftInProgress]);

  useEffect(() => {
    if (companyLogoBlob && !jobDescription.logo) {
      const imgBlob = companyLogoBlob as Blob;
      const type = imgBlob.type.split("/")[1];
      const imageFile = new File([imgBlob], `company_logo.${type}`);
      dispatch(setDefaultLogo(imageFile));
    }
  }, [companyLogoBlob, updateDraftInProgress]);

  useEffect(() => {
    if (isJobEditorOpen) {
      dispatch(
        fetchJobData({
          callback: () => {},
        }),
      );
    }
  }, [isJobEditorOpen]);

  useEffect(() => {
    return () => {
      dispatch(resetJobEditor());
      dispatch(resetJobDescription());
    };
  }, []);

  const handleOnNextStep = () => {
    switch (currentStep) {
      case 1:
        jobDescriptionFormRef.current?.requestSubmit();
        break;
      case 2:
        jobDetailsFormRef.current?.requestSubmit();
        break;
      case 3:
        jobApplicationProcessFormRef.current?.requestSubmit();
        break;
      case 4:
        dispatch(openCheckout());
    }
  };

  const onCreateJobSuccess = () => {
    if (jobDescription.company_color !== company?.company_color) {
      dispatch(
        updateCompanyData({
          type: "form",
          data: {
            color: jobDescription.company_color,
          },
          callback: () => dispatch(fetchCompanyData()),
        }),
      );
    }

    if (
      location.pathname === "/jobs/active" &&
      jobStatus === JOB_STATUSES.ACTIVE
    ) {
      dispatch(fetchJobs(JOB_STATUSES.ACTIVE));
    }

    dispatch(fetchCompanyData());
    dispatch(setJobEditorStep(4));
  };

  const handleOnSubmit = (data: unknown) => {
    switch (currentStep) {
      case 1: {
        const jobDescription: TJobDescriptionFormFields =
          data as TJobDescriptionFormFields;
        dispatch(updateJobDescription({ jobDescription }));
        dispatch(setCompletedStep(1));
        dispatch(setJobEditorStep(2));
        break;
      }
      case 2: {
        dispatch(setCompletedStep(2));
        dispatch(setJobEditorStep(3));
        break;
      }
      case 3: {
        dispatch(setCompletedStep(3));
        dispatch(
          createJob({
            callback: () => onCreateJobSuccess(),
          }),
        );
        break;
      }
      default:
        break;
    }
  };

  const handleOnChangeJobEditorState = (data: unknown) => {
    switch (currentStep) {
      case 2: {
        const jobDetails: TJobDetailsFormFields = data as TJobDetailsFormFields;
        debounce(() => dispatch(updateJobDetails({ jobDetails })), 500)();
        break;
      }
      case 3: {
        const jobApplicationProcess: TJobApplicationProcessFormFields =
          data as TJobApplicationProcessFormFields;
        dispatch(updateJobApplicationProcess({ jobApplicationProcess }));
        break;
      }
      default:
        break;
    }
  };

  const handleOnClose = () => {
    dispatch(setJobDescripitonData(null));
    dispatch(closeJobEditor());
    dispatch(resetJobDescription());
    if (currentStep === 4) {
      dispatch(
        addMessage({
          type: "success",
          title: t("create_job_fourth_step.success_title"),
        }),
      );
    }
    dispatch(setUpdateDraftInProgress(false));
    dispatch(resetSteps());
  };
  const handleOnPrevStep = () => {
    if (currentStep === 1) handleOnClose();
    dispatch(setJobEditorStep(currentStep - 1));
  };

  const updateJobDescriptionData = (data: TJobDescriptionDataFields) => {
    dispatch(setJobDescripitonData(data));
  };

  const handleOnPreview = () => {
    const formValues: TJobDescriptionFormFields = {
      header_1: jobDescription.header_1,
      header_2: jobDescription.header_2,
      header_3: jobDescription.header_3,
      logo: jobDescription.logo,
      background: jobDescription.background,
      company_color: jobDescription.company_color,
      background_color: jobDescription.background_color,
      job_title: jobDescriptionData?.job_title as string,
      introduction_title: jobDescriptionData?.introduction_title as string,
      introduction: jobDescriptionData?.introduction as string,
      tasks_title: jobDescriptionData?.tasks_title as string,
      tasks: jobDescriptionData?.tasks as string,
      offer_title: jobDescriptionData?.offer_title as string,
      offer: jobDescriptionData?.offer as string,
      profile_title: jobDescriptionData?.profile_title as string,
      profile: jobDescriptionData?.profile as string,
      contact_title: jobDescriptionData?.contact_title as string,
      contact: jobDescriptionData?.contact as string,
      video_url: jobDescriptionData?.video_url as string,
    };
    dispatch(
      previewJobTemplate({ formValues, callback: handleOnPreviewSuccess }),
    );
  };

  const handleOnPreviewSuccess = (template_id: string) => {
    const url = `${window.location.origin}/template/preview/${template_id}`;
    window.open(url, "_blank");
  };

  const handleOnOpenDraftDialog = () => {
    dispatch(updateJobDescription({ jobDescription }));
    setIsSaveDraftDialogOpen(true);
  };

  const handleOnCloseDraftDialog = () => {
    setIsSaveDraftDialogOpen(false);
  };

  const onSaveDraftSuccess = () => {
    if (
      location.pathname === "/jobs/draft" &&
      jobStatus === JOB_STATUSES.DRAFT
    ) {
      dispatch(fetchJobs(JOB_STATUSES.DRAFT));
    }
    handleOnCloseDraftDialog();
    dispatch(closeJobEditor());
    dispatch(setSelectedJobStatus(JOB_STATUSES.DRAFT));
    navigate("/jobs/draft");
    dispatch(resetJobEditor());
    dispatch(resetJobDescription());
    dispatch(setJobDescripitonData(null));
    dispatch(setUpdateDraftInProgress(false));
    dispatch(
      addMessage({
        type: "success",
        title: t("joblist.save_draft_snackbar_title"),
        action: SNACKBAR_ACTION_TYPE.CONTAINED_BUTTON_WITH_CLOSE_ICON,
        firstButtonText: t("candidate_dashboard.open_drafts") as string,
        onFirstButtonClick: () => {
          navigate("/jobs/draft");
          dispatch(turnOffNotifications());
        },
      }),
    );
  };

  const handleOnSaveAsDraft = () => {
    const questions = Array.isArray(jobApplicationProcess.questions)
      ? jobApplicationProcess?.questions.map((qItem: TJobQuestions) => ({
          question: qItem.question,
          answer: qItem.is_answer_type_yesno,
        }))
      : undefined;

    const formattedFormValues = {
      ...jobDetails,
      ...jobApplicationProcess,
      questions,
      company_color: jobDescription.company_color,
      background_color: jobDescription.background_color,
      job_title: jobDescriptionData?.job_title as string,
      template_header_1: jobDescription.header_1,
      template_header_2: jobDescription.header_2,
      template_header_3: jobDescription.header_3,
      template_logo: jobDescription.logo,
      template_background: jobDescription.background,
      template_introduction_title: jobDescriptionData?.introduction_title || "",
      template_introduction: jobDescriptionData?.introduction as string,
      template_tasks_title: jobDescriptionData?.tasks_title as string,
      template_tasks: jobDescriptionData?.tasks as string,
      template_offer_title: jobDescriptionData?.offer_title as string,
      template_offer: jobDescriptionData?.offer as string,
      template_profile_title: jobDescriptionData?.profile_title as string,
      template_profile: jobDescriptionData?.profile as string,
      template_contact_title: jobDescriptionData?.contact_title as string,
      template_contact: jobDescriptionData?.contact as string,
      template_video_url: jobDescriptionData?.video_url as string,
      title:
        jobDescriptionData?.job_title || t("create_job_first_step.job_title"),
      handle: jobDetails?.reference_number,
      template_title_color: jobDescriptionData?.company_color,
      template_background_color: jobDescriptionData?.background_color,
      profile_photo_required: 1,
      cv_required: 1,
      draft_stage_step: currentStep || 1,
    };

    if (jobDescription.jobUrlKey) {
      dispatch(
        updateDraft({
          formValues: formattedFormValues,
          jobUrlKey: jobDescription.jobUrlKey,
          callback: () => onSaveDraftSuccess(),
        }),
      );
    } else {
      dispatch(
        saveDraft({
          formValues: formattedFormValues,
          callback: () => onSaveDraftSuccess(),
        }),
      );
    }
  };

  return (
    <Dialog open={isJobEditorOpen} fullScreen>
      <JobEditorComponent
        t={t}
        currentStep={currentStep}
        isLoading={isLoading}
        jobDescriptionFormRef={jobDescriptionFormRef}
        jobDetailsFormRef={jobDetailsFormRef}
        jobApplicationProcessFormRef={jobApplicationProcessFormRef}
        jobApplicationProcess={jobApplicationProcess}
        jobDetails={jobDetails}
        selectedProducts={selectedProducts}
        selectedProductsSum={selectedProductsSum}
        updateJobDescriptionData={updateJobDescriptionData}
        onClose={handleOnClose}
        onOpenDraftDialog={handleOnOpenDraftDialog}
        onSubmit={handleOnSubmit}
        onPreview={handleOnPreview}
        onPrevStep={handleOnPrevStep}
        onNextStep={handleOnNextStep}
        onChangeForm={handleOnChangeJobEditorState}
      />
      <SaveDraftDialog
        t={t}
        isOpen={isSaveDraftDialogOpen}
        title={t("create_job_first_step.save_as_draft")}
        paragraphs={[
          t("create_job_first_step.save_draft_first_text"),
          t("create_job_first_step.save_draft_second_text"),
        ]}
        onClose={handleOnCloseDraftDialog}
        onSave={handleOnSaveAsDraft}
        submitButtonText={t("create_job_first_step.save_draft")}
        cancelButtonText={t("create_job_first_step.continue_editing")}
      />
    </Dialog>
  );
};

export default JobEditor;
