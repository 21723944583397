import { styled } from "@mui/system";
import { Accordion, List, ListItem, Stack, Typography } from "@mui/material";

export const StyledCandidateNoteListWrapper = styled(List)({
  width: "100%",
});

export const StyledCandidateNoteListItem = styled(ListItem)(
  ({ isCurrentUSer }: { isCurrentUSer?: boolean }) => ({
    alignItems: "flex-start",
    paddingLeft: 0,
    paddingRight: 0,
    ".text-container": {
      background: isCurrentUSer ? "#EEF2FF" : "#F9FAFB",
      margin: 0,
      padding: "12px 12px 0 12px",
      borderRadius: "0 6px 6px 6px",
    },
    ".text-container-deleted": {
      background: isCurrentUSer ? "#EEF2FF" : "#F9FAFB",
      margin: 0,
      padding: 12,
      borderRadius: "0 6px 6px 6px",
    },
  }),
);

export const StyledCandidateNoteListItemPrimaryText = styled(Typography)({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "20px",
  color: "#374151",
});

export const StyledCandidateNoteListItemSecondaryText = styled(Typography)(
  ({ deleted }: { deleted?: boolean }) => ({
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "left",
    color: "#6B7280",
    ...(deleted && { paddingBottom: 12 }),
  }),
);

export const StyledCandidateNoteCommentAccordion = styled(Accordion)({
  marginLeft: -12,
  width: "calc(100% + 24px)",
  background: "#F3F4F6",
  ".MuiAccordionSummary-root": {
    gap: 6,
    flexDirection: "row-reverse",
    svg: { fill: "#178CF2!important", width: 16, height: 16 },
  },
  ".MuiAccordionSummary-content": {
    p: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "20px",
      textAlign: "left",
      color: "#178CF2",
    },
  },
});

export const StyledCandidateNoteInputFieldContainer = styled(Stack)({
  width: "100%",
  flexDirection: "row",
  alignItems: "flex-end",
  button: { minWidth: 40, width: 40, height: 40 },
  ".mentions-input-container": {
    marginRight: 12,
    width: "100%",
    minHeight: 40,
    maxHeight: "120px!important",
    overFlow: "auto",
    textarea: {
      outline: "none!important",
      border: "none!important",
      fontSize: "12px!important",
      lineHeight: "20px",
      textAlign: "left",
      resize: "none",
    },
  },
});

export const StyledCandidateNoteActionsButtonsContainer = styled(Stack)({
  width: "100%",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  height: 40,
  gap: 4,
  color: "#178CF2",
  button: { minWidth: "fit-content", width: "fit-content", padding: "0 6px" },
});

export const StyledCandidateNoteActionsEditModeContainer = styled(Stack)({
  height: 64,
  flexDirection: "row",
  gap: 12,
  alignItems: "center",
  button: {
    minWidth: 32,
    width: 32,
    height: "32px!important",
  },
  svg: {
    width: 16,
    height: 16,
  },
  ".MuiInputBase-root": {
    borderRadius: "20px!important",
  },
});

export const StyledCandidateMessagesEmptyScreen = styled(Stack)({
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  svg: {
    width: "24px",
    height: "24px",
    padding: "8px",
    borderRadius: "100%",
    background: "#EEF2FF",
    color: "#818CF8",
    boxSizing: "content-box",
  },
  p: {
    color: "#6B7280",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
  },
});
