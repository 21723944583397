import { Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledDataContainer = styled(Stack)({
  background: "#fff",
  marginTop: 24,
  padding: 24,
  border: "1px solid #E5E7EB",
  borderRadius: 6,
  ".divider": {
    borderColor: "#E5E7EB",
    margin: "24px 0px",
  },
  ".input-selector-filter": {
    width: "100%",
    textDecoration: "none",
    textTransform: "none",
    "[data-testid=CloseIcon]": {
      cursor: "pointer",
      width: 18,
      height: 18,
      marginRight: 8,
    },
    "& .MuiInputBase-input": {
      width: "100%",
      lineHeight: "40px",
      height: "40px!important",
    },
  },
});
