import {
  TJobApplicationProcessFormFields,
  TJobDescriptionFormFieldsPayload,
  TJobDetailsFormFields,
  TJobDetailsUpdateJobPayload,
} from "../models/JobEditor";
import { apiRequest } from "./config";

export const apiUpdateJob = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: TJobDetailsUpdateJobPayload;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiFetchJobData = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiSaveTemplate = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: TJobDescriptionFormFieldsPayload;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiCreateJob = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: TJobDescriptionFormFieldsPayload &
    TJobDetailsFormFields &
    TJobApplicationProcessFormFields;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiGetPackagesPrice = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: { product_ids: number[] };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiPublishProducts = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: {
    sales_products: number[];
    sales_products_selected_price_input: number;
  };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiGetDraftData = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiExtendLimit = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });
