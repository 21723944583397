import React from "react";
import { Stack, Typography } from "@mui/material";
import { TFunction } from "i18next";
import Product from "./Product";
import BuyProductDialog from "../../components/dialogs/BuyProductDialog";
import PremiumPackagesComponent from "../SingleJob_new/PremiumJobBoards/Packages";
import {
  TConvertedPremiumProduct,
  TPremiumPackage,
  TPremiumProductItem,
} from "../../models/SingleJob";
import { StyledPremiumPackagesProductsContainer } from "../SingleJob_new/styles";

const ProductPageComponent = ({
  t,
  activeProduct,
  activeProductId,
  productPackages,
  onOpenProduct,
  isSelected,
  onChangeActiveProductId,
  onOpenBuyProductDialog,
  onHoverLeaveSelectPackageButton,
}: {
  readonly t: TFunction;
  readonly activeProduct?: TConvertedPremiumProduct;
  readonly activeProductId: number | null;
  readonly productPackages: TPremiumPackage[];
  readonly onOpenProduct: (data: TPremiumPackage) => void;
  readonly isSelected: (data: TPremiumPackage | TPremiumProductItem) => boolean;
  readonly onChangeActiveProductId: (id: number | null) => void;
  readonly onOpenBuyProductDialog: (productItem: TPremiumProductItem) => void;
  readonly onHoverLeaveSelectPackageButton: (value: boolean) => void;
}) => (
  <Stack spacing={2} mt={3}>
    <StyledPremiumPackagesProductsContainer>
      <Typography variant="body1">
        {t("create_job_fourth_step.products")}
      </Typography>
      <Stack mt={3} spacing={2}>
        {activeProduct?.products.map((product: TPremiumProductItem) => (
          <Product
            key={product.id}
            t={t}
            productItem={product}
            activeProductId={activeProductId}
            onChangeActiveProductId={onChangeActiveProductId}
            onOpenBuyProductDialog={onOpenBuyProductDialog}
          />
        ))}
      </Stack>
      <BuyProductDialog />
    </StyledPremiumPackagesProductsContainer>
    {!!productPackages.length && (
      <PremiumPackagesComponent
        t={t}
        packages={productPackages}
        isSelected={isSelected}
        onAddPackage={() => {}}
        onOpenProduct={onOpenProduct}
        onRemoveFromCart={() => {}}
        onResetFilters={() => {}}
        onHoverLeaveSelectPackageButton={onHoverLeaveSelectPackageButton}
      />
    )}
  </Stack>
);

export default ProductPageComponent;
