import React from "react";
import { Checkbox } from "@mui/material";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { TDataGridGetColDef } from "../../../models/DataGrid";

import iconAndText from "../cellRenderers/iconAndText";

const getImportCustomerColDef = ({
  t,
  onSelect,
}: Pick<TDataGridGetColDef, "t" | "onSelect">): ColDef[] => [
  {
    field: "",
    flex: 0.3,
    cellClass: "ag-grid-cell-checkbox",
    cellRenderer: (params: ICellRendererParams) => (
      <Checkbox
        onClick={() => onSelect && onSelect(params.data)}
        color="secondary"
      />
    ),
  },
  {
    field: "company",
    headerName: t("customers.company") as string,
    flex: 1,
    cellRenderer: (params: ICellRendererParams) =>
      iconAndText({
        src: params.data.company_logo,
        text: params.data.company,
        variant: "photo",
      }),
  },
  {
    field: "email",
    headerName: t("email") as string,
    flex: 0.5,
  },
  {
    field: "city",
    headerName: t("joblist.location_placeholder") as string,
    flex: 0.5,
  },
  {
    field: "plz",
    headerName: t("update_company_information.postcode") as string,
    flex: 0.5,
  },
  {
    field: "country",
    headerName: t("update_company_information.country") as string,
    flex: 0.45,
  },
];

export default getImportCustomerColDef;
