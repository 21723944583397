export default {
  50: "#EEF1FD",

  100: "#D1DBFF",

  200: "#BBC7F5",

  300: "#99ABEF",

  400: "#6B8AFF",

  500: "#178CF2",

  600: "#4E68CF",

  700: "#455CB8",

  800: "#3850A2",

  900: "#2B3A73",
};
