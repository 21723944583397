import i18next from "i18next";
import moment from "moment";
import "moment/locale/de";
import { axiosInstanceDownload } from "../api/config";
import { ICandidateItem, IInitialColumns } from "../models/Kanban";
import { IWorkflow, IWorkflowStatus } from "../models/Workflow";
import { TJobQuestions, TPremiumPackage } from "../models/SingleJob";
import { TListOption } from "../models/common";
import { TRIGGERS_TYPE } from "../models/Automation";

export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });

export async function getCroppedImg(
  imageSrc: string,
  crop: any,
  fileName: string
) {
  const image: any = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;
  canvas.width = crop.width * pixelRatio;
  canvas.height = crop.height * pixelRatio;
  const ctx: any = canvas.getContext("2d");

  ctx.imageSmoothingQuality = "high";

  // Set the background as transparent
  ctx.fillStyle = "rgba(0, 0, 0, 0)"; // Transparent color
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Draw the cropped image region
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * pixelRatio,
    crop.height * pixelRatio
  );

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        blob.name = fileName;
        resolve(blob);
      },
      "image/png",
      0.8
    );
  });
}

export const generateTime = (time: string) => {
  const language = localStorage.getItem("lang");
  const localeLanguage = language === "de_DE" ? "de" : "en";
  const today = moment();
  if (today.isSame(time, "day")) {
    return moment(time).locale(localeLanguage).format("LT");
  }
  if (today.isSame(time, "year")) {
    return moment(time).locale(localeLanguage).format("MMM D");
  }
  return moment(time).locale(localeLanguage).format("MMM D, YYYY");
};

export const generateFullDate = (time?: string | null) => {
  const language = localStorage.getItem("lang");
  const localeLanguage = language === "de_DE" ? "de" : "en";
  return moment(time).locale(localeLanguage).format("DD. MMM YYYY");
};

export const generateCandidateDate = (time?: string) => {
  const language = localStorage.getItem("lang");
  const dateFormat = language === "en_US" ? "MM/DD/YYYY" : "DD.MM.YYYY";
  return moment(time).format(dateFormat);
};

export const generateActivityLogDate = (time: string) => {
  const language = localStorage.getItem("lang");
  const formattedDate = moment(time).format(
    language === "en_US" ? "MM/DD/YYYY" : "DD.MM.YYYY"
  );
  const localeLanguage = language === "de_DE" ? "de" : "en";
  const hourFormat = moment(time).locale(localeLanguage).format("LT");
  const at = language === "en_US" ? "at" : "um";
  const fullFormat = `${formattedDate} ${at} ${hourFormat}`;
  return fullFormat;
};

export const sumDays = (date: any, value: any, period: any) => {
  return moment(date).subtract(value, period).calendar();
};

export const getTextBetweenBrackets = (text?: string) => {
  const regex = /\(([^)]+)\)/;
  const matches = regex.exec(text as string);
  if (matches === null) {
    return "";
  }
  return matches[1];
};

export const employmentTypes = {
  FULL_TIME: "FULL_TIME",
  PART_TIME: "PART_TIME",
  CONTRACTOR: "CONTRACTOR",
  TEMPORARY: "TEMPORARY",
  INTERN: "INTERN",
  VOLUNTEER: "VOLUNTEER",
  PER_DIEM: "PER_DIEM",
  OTHER: "OTHER",
};

export const jobTypeSchema = [
  {
    id: "7",
    title: "Aufsichtsratmitglied",
    type: employmentTypes.OTHER,
  },
  {
    id: "14",
    title: "Ausbildung",
    type: employmentTypes.OTHER,
  },
  {
    id: "11",
    title: "Aushilfe",
    type: employmentTypes.PART_TIME,
  },
  {
    id: "17",
    title: "Befristet/Saisonal",
    type: employmentTypes.TEMPORARY,
  },
  {
    id: "18",
    title: "Duales Studium",
    type: employmentTypes.OTHER,
  },
  {
    id: "8",
    title: "Ehrenamtlich",
    type: employmentTypes.OTHER,
  },
  {
    id: "5",
    title: "Eigentümer",
    type: employmentTypes.OTHER,
  },
  {
    id: "4",
    title: "Freelancer",
    type: employmentTypes.CONTRACTOR,
  },
  {
    id: "15",
    title: "Geringfügig",
    type: employmentTypes.PART_TIME,
  },
  {
    id: "6",
    title: "Partner",
    type: employmentTypes.OTHER,
  },
  {
    id: "3",
    title: "Praktikum",
    type: employmentTypes.INTERN,
  },
  {
    id: "12",
    title: "Soziales Engagement",
    type: employmentTypes.VOLUNTEER,
  },
  {
    id: "2",
    title: "Teilzeit",
    type: employmentTypes.PART_TIME,
  },
  {
    id: "9",
    title: "Teilzeit / Vollzeit",
    type: [employmentTypes.FULL_TIME, employmentTypes.PART_TIME],
  },
  {
    id: "10",
    title: "Traineeship",
    type: employmentTypes.OTHER,
  },
  {
    id: "1",
    title: "Vollzeit",
    type: employmentTypes.FULL_TIME,
  },
  {
    id: "16",
    title: "Werkstudent",
    type: employmentTypes.INTERN,
  },
];

export const formatPositionType = (positionTypeId: number | string) => {
  const findJobTypeId = jobTypeSchema.find(
    (item) => item.id === positionTypeId
  );
  if (findJobTypeId) {
    return findJobTypeId.type;
  }
  return employmentTypes.OTHER;
};

export const openJobInNewTab = (urlKey: string) => {
  window.open(`/job/${urlKey}`, "_blank");
};

export const openUrlnNewTab = (url?: string) => {
  window.open(url, "_blank");
};

export const onDragEnd = (
  result: any,
  columns: IInitialColumns,
  setColumns: (columns: IInitialColumns) => void,
  apiCall: (
    draggbleId: string,
    droppableId: string,
    destinationIndex: string
  ) => void
) => {
  if (!result.destination) return;
  const { source, destination, draggableId } = result;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const newStatus = generateStatusFromColumnId(destination.droppableId);

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    const formatedRemoved = {
      ...removed,
      updated_at: moment(),
    };
    destItems.splice(destination.index, 0, formatedRemoved);
    const finalItems = destItems.map((item) => ({
      ...item,
      status: newStatus,
    }));
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: finalItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
  if (apiCall) {
    apiCall(draggableId, destination.droppableId, destination.index);
  }
};

export const generateGridTemplateColumns = (object: any) => {
  let columns = "";
  for (let i = 0; i < Object.keys(object).length; i++) {
    columns += "1fr ";
  }
  return columns.slice(0, -1);
};

export const generateStatusFromColumnId = (
  statuses: any,
  droppableId: string
) => {
  return statuses[droppableId];
};

export const removeSelectedItemsFromColumn = (
  columns: IInitialColumns,
  selectedItems: ICandidateItem[]
) => {
  return Object.keys(columns).reduce((updatedColumns, columnKey) => {
    const column = columns[columnKey];
    const items = column.items.filter(
      (item) => !selectedItems.some((selected) => selected.id === item.id)
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    updatedColumns[columnKey] = {
      ...column,
      items,
    };
    return updatedColumns;
  }, {});
};

export const getColumnItem = (status: string, columns: IInitialColumns) => {
  const findColumn = Object.entries(columns).find(
    ([_, card]) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      card.title.toLowerCase() == status.toLowerCase()
  );
  if (findColumn) {
    return findColumn[0];
  }
};

export const shortMonthDateFormat = (date: string | number) => {
  const language = localStorage.getItem("lang");
  const localeLanguage = language === "de_DE" ? "de" : "en";
  return moment(date).locale(localeLanguage).format("DD MMM YYYY");
};

export const formatLogoUrl = (logo?: string) => {
  const host = !window.location.host.includes("localhost")
    ? window.location.host
    : "app.staging.karriera.de";
  const formattedHost = host.includes("staging")
    ? host.replace("app.staging", "https://i.staging")
    : host.replace("app", "https://i");
  return `${formattedHost}/${logo}`;
};

export const formatLogoPrefix = () => {
  const host = !window.location.host.includes("localhost")
    ? window.location.host
    : "app.staging.karriera.de";
  return host.includes("dev")
    ? host.replace("app.staging", "https://i.staging")
    : host.replace("app", "https://i");
};

export const redirectionPrivacy = () => {
  window.location.href = "https://karriera.de/privacy";
};

export const redirectionTerms = () => {
  window.location.href = "https://karriera.de/agb";
};

export const embedVideoLink = (videoLink: string) => {
  let videoId;
  let embedLink;

  if (videoLink.includes("youtube.com") || videoLink.includes("youtu.be")) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    videoId = videoLink.match(
      /(?:[?&]v=|\/embed\/|\/\d\d?\/|\/(?:watch\/)?(?:youtube.com\w{0,5}[?/].*?[&?](?:v|vi)=))([^&?\s]{11})/
    )[1];
    embedLink = `https://www.youtube.com/embed/${videoId}?si=Qs4cFuzRFiRL8ORi`;
  } else if (
    videoLink.includes("dailymotion.com") ||
    videoLink.includes("dai.ly")
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    videoId = videoLink.match(
      /(?:dailymotion.com\/(?:video|hub)\/|dai.ly\/)([\w-]+)/
    )[1];
    embedLink = `https://www.dailymotion.com/embed/video/${videoId}`;
  } else if (videoLink.includes("vimeo.com")) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    videoId = videoLink.match(
      /(?:https?:\/\/)?(?:www\.)?(?:vimeo(?:pro)?.com)\/(?:[^\d]+)?(\d+)(?:.*)?$/
    )[1];
    embedLink = `https://player.vimeo.com/video/${videoId}`;
  } else {
    return "";
  }

  return embedLink;
};

export const downloadTemplateMedia = async (
  templateId: string | number,
  mediaType: string
) => {
  const response = await axiosInstanceDownload.post(
    `/company/companies/download-media/${templateId}`,
    {
      media: mediaType,
    }
  );
  const blob = new Blob([response.data], {
    type: response.data.type,
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  blob.name = "logo.60e48654.png";
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  blob.lastModified = new Date();
  return URL.createObjectURL(blob);
};

export const formatLogoBlobForRedux = async (blob: any) => {
  return await fetch(blob).then((response) => response.blob());
};

export const getTimeAgo = (date: string) => {
  const language = localStorage.getItem("lang");
  const localeLanguage = language === "de_DE" ? "de" : "en";
  return moment(date).locale(localeLanguage).fromNow();
};

export const filterPackages = (
  packages: TPremiumPackage[],
  productName: string
) => {
  const filteredPackages = [];

  for (const packageItem of packages) {
    const findProductName = packageItem.sales_products.some(
      (currentPackageItem) => currentPackageItem.supplier === productName
    );
    if (findProductName) {
      filteredPackages.push(packageItem);
    }
  }

  return filteredPackages;
};

export const convertRequiredProperties = (jobData: any) => {
  const convertedJobData = {};

  for (const property in jobData) {
    if (property.includes("required")) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      convertedJobData[property] = parseInt(jobData[property]);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      convertedJobData[property] = jobData[property];
    }
  }

  return convertedJobData;
};

export const sortByAnswerTypeYesno = (data: TJobQuestions[]) => {
  // Create a copy of the array using slice()
  const sortedData = data.slice();
  return sortedData.sort(
    (a: TJobQuestions, b: TJobQuestions) =>
      b.is_answer_type_yesno - a.is_answer_type_yesno
  );
};

export const getWorkflowStatuses = (stringStatuses: IWorkflowStatus[]) => {
  const parsedData = JSON.parse(stringStatuses as unknown as string);
  const statuses = JSON.parse(parsedData);
  if (statuses.colors) {
    return statuses.colors;
  }
  const finalStatuses = JSON.parse(statuses);
  return finalStatuses.colors;
};

export const convertArrayToObject = (originalArray: any) =>
  originalArray.reduce((acc: any, item: any, index: number) => {
    acc[index] = item;
    return acc;
  }, {});

export const converWorkflowtArrayToObject = (array: []) => {
  return array.reduce((obj: any, item: any) => {
    obj[item.id] = item.name.toLowerCase();
    return obj;
  }, {});
};

export const translateDefaultWorkflowStatuses = (workflows: IWorkflow[]) => {
  const language = i18next.language;
  const translatedStatuses = {
    new: language === "en_US" ? "New" : "Neu",
    review: language === "en_US" ? "In Progress" : "Noch offen",
    hired: language === "en_US" ? "Hired" : "Angestellt",
    rejected: language === "en_US" ? "Rejected" : "Ablehnen",
  };

  workflows.forEach((workflow) => {
    workflow.statuses.forEach((status) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      status.label = translatedStatuses[status.value] || status.label;
    });
  });

  return workflows;
};

export const getBackgroundColor = (
  isDraggingOver: boolean,
  isDraggingFrom: string
) => {
  if (isDraggingOver) {
    return "#FFEBE6";
  }
  if (isDraggingFrom) {
    return "#E6FCFF";
  }
  return "#F3F4F6";
};

export const onRedirectPrivacy = (type: string) => {
  if (type === "newTab") {
    window.open("https://karriera.de/privacy", "_blank");
  } else {
    window.location.href = "https://karriera.de/privacy";
  }
};

export const onRedirectTerms = (type: string) => {
  if (type === "newTab") {
    window.open("https://karriera.de/agb", "_blank");
  } else {
    window.location.href = "https://karriera.de/agb";
  }
};

export const onOpenUrlInNewTab = (url: string) => {
  window.open(url, "_blank");
};

export const convertArrayToString = (array: any) =>
  array.map((item: any) => item.value).join("|");

export const convertStringToArray = (
  valuesString: string,
  originalArray: any
) => {
  const values = valuesString.split("|");
  const data: any = [];
  values.forEach((value) => {
    const findItem = originalArray.find((item: any) => item.value === value);
    if (findItem) {
      data.push(findItem);
    }
  });
  return data;
};

export const convertImageToBlob = async (companyLogo: string) => {
  const companyLogoBlob = await fetch(companyLogo).then((response) =>
    response.blob()
  );

  return companyLogoBlob;
};

export const generateBackgroundColor = (options: any, value?: string) => {
  if (value) {
    const findOption = options.find(
      (option: TListOption) => option.value === value
    );
    return findOption?.color || "#fff";
  }
};

export const generateApplicationTriggerTypeEmailValue = (
  applicationType:
    | TRIGGERS_TYPE.APPLICATION_CREATED
    | TRIGGERS_TYPE.APPLICATION_UPDATED
    | TRIGGERS_TYPE.APPLICATION_STATUS_UPDATED
) => {
  if (applicationType === TRIGGERS_TYPE.APPLICATION_STATUS_UPDATED) {
    return "{candidate_email}";
  }
  return "{email}";
};
