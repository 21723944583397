import { Autocomplete, TextField } from "@mui/material";
import { styled } from "@mui/system";

export const StyledTextfield = styled(TextField)({
  width: "100%",
  maxWidth: 520,
  padding: 0,
  textDecoration: "none",
  textTransform: "none",
  height: 40,
  "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
    position: "relative",
    bottom: 16.5,
    width: "100%",
  },
});

export const StyledAutocomplete = styled(Autocomplete)({
  "&::-webkit-scrollbar ": {
    width: "4px",
  },

  "&::-webkit-scrollbar-thumb": {
    background: "#E5E7EB",
    borderRadius: "4px",
  },
});
