import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NewPasswordComponent from "./component";
import { TNewPasswordFormFields } from "../../../models/Auth";
import { recoveryPassword } from "../../../store_new/reducers/Auth";
import { getAuthLoginState } from "../../../store_new/selectors/Auth";
import { formDefaultValues, getFormSettings } from "./config";

const NewPassword_new = ({ desktop }: { readonly desktop: boolean }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const { isLoading } = useSelector(getAuthLoginState);
  const code = location.pathname.substring(19);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getFormSettings(t)),
    defaultValues: formDefaultValues,
  });

  const onSubmit = (data: TNewPasswordFormFields) => {
    dispatch(
      recoveryPassword({
        code,
        ...data,
        callback: () => navigate("/password/confirmation"),
      })
    );
  };

  const handleOnReturn = () => {
    navigate("/");
  };

  const handleOnShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <NewPasswordComponent
      t={t}
      register={register}
      errors={errors}
      isLoading={isLoading}
      showPassword={showPassword}
      desktop={desktop}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      onReturn={handleOnReturn}
      onShowPassword={handleOnShowPassword}
    />
  );
};

export default NewPassword_new;
