import { call, put, takeLatest } from "redux-saga/effects";
import {
  extractJobData,
  extractJobDataFailed,
  extractJobDataSuccess,
  sendJobCompanyData,
  sendJobCompanyDataFailed,
  sendJobCompanyDataSuccess,
} from "../reducers/Marketing";
import { apiExtractJobData, apiSendJobCompanyData } from "../../api/Marketing";
import { PayloadAction } from "@reduxjs/toolkit";
import { PayloadActionWithCallback } from "../../models/common";
import { TMarketingForm } from "../../models/Marketing";

function* handleOnExtractJobData({ payload }: PayloadAction<string>) {
  const apiPayload = {
    link: payload,
  };
  try {
    const { data } = yield call(apiExtractJobData, {
      url: "/extract",
      apiPayload,
    });
    yield put(extractJobDataSuccess(data.jobs));
  } catch (e) {
    yield put(extractJobDataFailed(e)); // TODO define type of error and handle error
  }
}

function* handleOnSenJobCompanyData({
  payload,
}: PayloadActionWithCallback<{
  content: TMarketingForm;
}>) {
  const { content, callback } = payload;
  const apiPayload = {
    content,
  };

  try {
    yield call(apiSendJobCompanyData, {
      url: "/send",
      apiPayload: apiPayload,
    });
    yield put(sendJobCompanyDataSuccess());
    yield call(callback);
  } catch (e) {
    yield put(sendJobCompanyDataFailed(e)); // TODO define type of error and handle error
  }
}

function* MarketingSaga() {
  yield takeLatest(sendJobCompanyData, handleOnSenJobCompanyData);
  yield takeLatest(extractJobData, handleOnExtractJobData);
}

export default MarketingSaga;
