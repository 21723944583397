import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiGetCompanyInfo } from "../../api/Company";
import {
  fetchCompanyInfo,
  fetchCompanyInfoFailed,
  fetchCompanyInfoSuccess,
} from "../reducers/CompanyInfo";
import { convertObjectToArray } from "../../utils/common";

function* handleOnFetchCompanyInfo({
  payload,
}: PayloadAction<{
  readonly companyKey: string;
  readonly locationId?: string;
  readonly workFieldId?: string;
  readonly pageNum?: number;
  readonly pageSize?: number;
}>) {
  const { companyKey, workFieldId, locationId, pageNum, pageSize } = payload;
  const formattedPageNum = pageNum ?? 1;
  const formattedPageSize = pageSize ?? 10;

  const mainUrl = `/companies/${companyKey}?pagenum=${formattedPageNum}&pagesize=${formattedPageSize}`;
  const onlyWorkFieldUrl = `/companies/${companyKey}?pagenum=${formattedPageNum}&pagesize=${formattedPageSize}&work_field_id=${workFieldId}`;
  const fullUrl = `/companies/${companyKey}?pagenum=${formattedPageNum}&pagesize=${formattedPageSize}&work_field_id=${workFieldId}&location=${locationId}`;

  const formattedUrl =
    !workFieldId && !locationId
      ? mainUrl
      : workFieldId && !locationId
      ? onlyWorkFieldUrl
      : fullUrl;

  try {
    const { data } = yield call(apiGetCompanyInfo, {
      url: formattedUrl,
    });
    const { filters, company } = data;

    const locationsFilters = convertObjectToArray(filters.locations);
    const workFieldsFilters = convertObjectToArray(filters.work_fields);
    yield put(
      fetchCompanyInfoSuccess({
        ...data,
        filters: {
          locations: locationsFilters,
          workFields: workFieldsFilters,
        },
        jobs: company.jobs,
      })
    );
  } catch (e) {
    yield put(fetchCompanyInfoFailed(e)); // TODO handle error
  }
}

function* CompanyInfoSaga() {
  yield takeLatest(fetchCompanyInfo, handleOnFetchCompanyInfo);
}

export default CompanyInfoSaga;
