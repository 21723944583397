import { all } from "redux-saga/effects";
import ApplicationsSaga from "./Applications";
import AuthSaga from "./Auth";
import CompanySettingsSaga from "./CompanySettings";
import JobListSaga from "./JobList";
import MessagesSaga from "./Messages";
import RegistrationSaga from "./Registration";
import SingleJobSaga from "./SingleJob";
import TeamsSaga from "./Teams";
import JobEditorSaga from "./JobEditor";
import CandidateSaga from "./Candidate";
import CustomersSaga from "./Customers";
import UserSaga from "./CurrentUser";
import JobPreviewSaga from "./JobPreview";
import CompanyInfoSaga from "./CompanyInfo";
import TalentPoolsSaga from "./TalentPools";
import AnalyticsSaga from "./Analytics";
import WorkflowsSaga from "./Workflow";
import MarketingSaga from "./Marketing";
import AutomationSaga from "./Automation";

export default function* rootSaga() {
  yield all([
    AuthSaga(),
    TeamsSaga(),
    JobListSaga(),
    ApplicationsSaga(),
    MessagesSaga(),
    CompanySettingsSaga(),
    RegistrationSaga(),
    SingleJobSaga(),
    JobEditorSaga(),
    CandidateSaga(),
    CustomersSaga(),
    UserSaga(),
    JobPreviewSaga(),
    CompanyInfoSaga(),
    TalentPoolsSaga(),
    AnalyticsSaga(),
    WorkflowsSaga(),
    MarketingSaga(),
    AutomationSaga(),
  ]);
}
