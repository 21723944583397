import React from "react";
import { UserGroupIcon } from "@heroicons/react/24/solid";
import { Stack } from "@mui/material";
import ShowMoreTooltipComponent from "../ShowMoreTooltip";

const CandidateInTalentPoolIcon = ({
  firstname,
  lastname,
  photo,
}: {
  readonly firstname: string;
  readonly lastname: string;
  readonly photo?: string | null;
}) => (
  <ShowMoreTooltipComponent
    items={
      [
        {
          firstname,
          lastname,
          photo,
        },
      ] as unknown as []
    }
  >
    <Stack
      p={0.5}
      height={20}
      width={20}
      borderRadius="50%"
      alignItems="center"
      justifyContent="center"
      sx={{
        cursor: "pointer",
        background: "#178CF2",
      }}
    >
      <UserGroupIcon stroke="#fff" fill="#fff" />
    </Stack>
  </ShowMoreTooltipComponent>
);

export default CandidateInTalentPoolIcon;
