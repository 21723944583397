import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  apiAddNewNote,
  apiAddNewNoteComment,
  apiDeleteApplication,
  apiDeleteComment,
  apiFetchActivityLog,
  apiFetchApplicationNoteList,
  apiFetchSelectedJobOwners,
  apiGetAllApplications,
  apiGetApplications,
  apiGetCandidateAnswers,
  apiGetJobApplicationFromTalentPool,
  apiGetSelectedJobApplications,
  apiRequestApplicationUnlock,
  apiUpdateApplicationAnswers,
  apiUpdateApplicationStatus,
} from "../../api/Applications";
import {
  TApplication,
  TApplicationNoteAddCommentPayload,
  TApplicationNoteAddPayload,
} from "../../models/Applications";
import {
  addNewNote,
  addNewNoteComment,
  addNewNoteCommentFailed,
  addNewNoteCommentSuccess,
  addNewNoteFailed,
  addNewNoteSuccess,
  deleteApplication,
  deleteApplicationFailed,
  deleteApplications,
  deleteApplicationsFailed,
  deleteApplicationsSuccess,
  deleteApplicationSuccess,
  deleteNote,
  deleteNoteFailed,
  deleteNoteSuccess,
  fetchActivityLog,
  fetchActivityLogFailed,
  fetchActivityLogSuccess,
  fetchAllApplications,
  fetchAllApplicationsSuccess,
  fetchApplicationFromTalentPool,
  fetchApplicationFromTalentPoolFailed,
  fetchApplicationFromTalentPoolSuccess,
  fetchApplicationNoteList,
  fetchApplicationNoteListFailed,
  fetchApplicationNoteListSuccess,
  fetchApplications,
  fetchApplicationsFailed,
  fetchApplicationsSuccess,
  fetchSelectedJobApplicationAnswers,
  fetchSelectedJobApplicationAnswersFailed,
  fetchSelectedJobApplicationAnswersSuccess,
  fetchSelectedJobApplications,
  fetchSelectedJobApplicationsFailed,
  fetchSelectedJobApplicationsSuccess,
  fetchSelectedJobOwners,
  fetchSelectedJobOwnersFailed,
  fetchSelectedJobOwnersSuccess,
  requestApplicationUnlock,
  requestApplicationUnlockFailed,
  requestApplicationUnlockSuccess,
  setApplicationStatus,
  setApplicationStatusFailed,
  setApplicationStatusSuccess,
  updateApplicationAnswers,
  updateApplicationAnswersFailed,
  updateApplicationAnswersSuccess,
  updateApplicationStatus,
  updateApplicationStatusFailed,
  updateApplicationStatusSuccess,
} from "../reducers/Applications";
import {
  getApplicationsFiltersState,
  getApplicationsPaginationState,
  getCurrentApplication,
} from "../selectors/Applications";
import { getSingleJobState } from "../selectors/SingleJob";
import { PayloadAction } from "@reduxjs/toolkit";
import { PayloadActionWithCallback } from "../../models/common";
import {
  getCurrentUserData,
  getCurrentUserIsCandidate,
} from "../selectors/CurrentUser";
import { convertArrayToObject } from "../../utils";
import { IWorkflow, IWorkflowStatus } from "../../models/Workflow";
import { ICandidateItem } from "../../models/Kanban";
import { getWorkflowState } from "../selectors/Workflow";

const baseUrl = "/company/candidates";
const agencyBaseUrl = "/company/agency";

function* handleOnFetchApplications() {
  const { jobId } = yield select(getSingleJobState);
  const { workflows } = yield select(getWorkflowState);
  const { pageSize: pagesize, pageNum: pagenum } = yield select(
    getApplicationsPaginationState,
  );
  const { quickSearch, location, status, appliedTo, company } = yield select(
    getApplicationsFiltersState,
  );
  const { applicationsAllowed } = yield select(getCurrentUserData);

  const language = localStorage.getItem("lang");

  const statuses = {
    new: language === "en_US" ? "New" : "Neu",
    review: language === "en_US" ? "In Progress" : "Noch offen",
    hired: language === "en_US" ? "Hired" : "Angestellt",
    rejected: language === "en_US" ? "Rejected" : "Ablehnen",
  };

  const url = applicationsAllowed
    ? `${agencyBaseUrl}/applications`
    : `${baseUrl}/applications`;

  const apiPayload = {
    pagenum,
    pagesize,
    name: quickSearch || undefined,
    location: location || undefined,
    jobId: jobId || appliedTo.value || undefined,
    job_id: jobId || appliedTo.value || undefined,
    status: status.value || undefined,
    company: company.value || undefined,
  };

  try {
    const { data } = yield call(apiGetApplications, { url, apiPayload });
    const { applications, totalCount, job } = data;

    const newWorkflow = workflows.find(
      (workflow: IWorkflow) => workflow.id == job?.workflow_id,
    );

    const newWorkflowStatuses = newWorkflow?.statuses.map(
      (status: IWorkflowStatus) => ({
        items: [],
        columnId: status.value,
        title: status.label,
        value: status.value,
        color: status.color,
      }),
    );

    let cardsApplications = {
      "0": {
        items: [],
        columnId: "new",
        title: statuses.new,
        color: "#818CF8",
      },
      "1": {
        items: [],
        columnId: "inProgress",
        title: statuses.review,
        color: "#F59E0B",
      },
      "2": {
        items: [],
        columnId: "hired",
        title: statuses.hired,
        color: "#22C55E",
      },
      "3": {
        items: [],
        columnId: "rejected",
        title: statuses.rejected,
        color: "#EF4444",
      },
    };

    const defaultWorkflow = {
      id: 0.1,
      defaultWorkflow: true,
      title: "Default Workflow",
      is_default: 1,
      created_at: "2024-08-20 14:57:32",
      updated_at: "2024-08-20 14:57:32",
      statuses: [
        {
          id: "0",
          title: statuses.new,
          value: "new",
          color: "#818CF8",
          defaultStatus: true,
        },
        {
          id: "1",
          title: statuses.review,
          value: "review",
          color: "#F59E0B",
          defaultStatus: true,
        },
        {
          id: "2",
          title: statuses.hired,
          value: "hired",
          color: "#22C55E",
          defaultStatus: true,
        },
        {
          id: "3",
          title: statuses.rejected,
          value: "rejected",
          color: "#EF4444",
          defaultStatus: true,
        },
      ],
    };

    const defaultWorkflowStatuses = defaultWorkflow?.statuses.map((status) => ({
      items: [],
      columnId: status.value,
      label: status.title,
      value: status.value,
      color: status.color,
    }));

    const newCardsWithoutApplications = newWorkflow
      ? newWorkflowStatuses
      : defaultWorkflowStatuses;
    const convertedApplications = convertArrayToObject(
      newCardsWithoutApplications,
    );

    applications.forEach((item: ICandidateItem) => {
      const foundColumn = Object.entries(convertedApplications).find(
        ([_, card]) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          card.columnId.toLowerCase() == item.status.toLowerCase(),
      );

      const findCV = item?.documents?.find((document: any) => document.isCV);

      const formatedItem: ICandidateItem = {
        ...item,
        id: item.application_id.toString(),
        name: `${item.firstname} ${item.lastname}`,
        photo: item.photo,
        cv: findCV?.link as string,
        jobTitle: item.title,
        date: item.timestamp,
        location: item.location,
        appIndex: item.app_index,
        candidateUrlKey: item.candidate_url_key,
        jobId: item.job_id,
      };

      if (foundColumn) {
        const [key] = foundColumn;
        convertedApplications[key].items.push(formatedItem);
      } else {
        convertedApplications["0"].items.push(formatedItem);
      }
    });
    cardsApplications = convertedApplications;

    const jobWorkflow = newWorkflow ?? defaultWorkflow;

    const urlKey = job?.url_key ?? null;

    yield put(
      fetchApplicationsSuccess({
        urlKey,
        list: applications,
        totalCount,
        cardsApplications,
        jobWorkflow: jobWorkflow || null,
      }),
    );
  } catch (e) {
    yield put(fetchApplicationsFailed(e)); // TODO handle error
  }
}

function* handleOnFetchAllApplications() {
  const { applicationsAllowed } = yield select(getCurrentUserData);
  const url = applicationsAllowed
    ? `${agencyBaseUrl}/applications`
    : `${baseUrl}/applications`;

  const apiPayload = {
    pagesize: -1,
  };
  try {
    const { data } = yield call(apiGetAllApplications, { url, apiPayload });
    const { applications } = data;
    const list = applications.map((application: TApplication) => ({
      ...application,
      name: `${application.firstname} ${application.lastname}`,
    }));
    yield put(fetchAllApplicationsSuccess(list));
  } catch (e) {
    yield put(fetchApplicationsFailed(e)); // TODO handle error
  }
}

function* handleOnFetchSelectedJobApplications({
  payload,
}: PayloadAction<{ job_id: string; candidateUrlKey: string }>) {
  const { job_id, candidateUrlKey } = payload;
  const { isAgency } = yield select(getCurrentUserData);
  const url = isAgency
    ? `${agencyBaseUrl}/view-application/${candidateUrlKey}`
    : `${baseUrl}/view/${candidateUrlKey}`;
  try {
    const { data } = yield call(apiGetSelectedJobApplications, {
      url,
      apiPayload: { job_id },
    });

    const applications = data.applications.map((application: any) => ({
      ...application,
      id: application.application_id,
      documents: application.documents.map((doc: any) => ({
        // TODO ask BE to return same model as for candidate application
        ...doc,
        is_cv: doc.isCV,
        filename: doc.link,
      })),
    }));

    yield put(fetchSelectedJobApplicationsSuccess(applications));
  } catch (e) {
    yield put(fetchSelectedJobApplicationsFailed(e)); // TODO handle error
  }
}

function* handleOnFetchJobApplicationFromTalentPool({
  payload,
}: PayloadActionWithCallback<{ applicationId: number }>) {
  const { applicationId, callback } = payload;
  try {
    const {
      data: { application },
    } = yield call(apiGetJobApplicationFromTalentPool, {
      applicationId,
    });

    yield put(fetchApplicationFromTalentPoolSuccess(application));
    yield call(callback, application);
  } catch (e) {
    yield put(fetchApplicationFromTalentPoolFailed(e)); // TODO handle error
  }
}

function* handleOnSetApplicationStatus({
  payload,
}: {
  payload: {
    readonly applicationId: string;
    readonly newStatus: string;
    readonly index: string;
  };
}) {
  const { applicationId, newStatus, index } = payload;
  const { isAgency } = yield select(getCurrentUserData);
  const url = isAgency
    ? `${agencyBaseUrl}/application-status-update/${applicationId}`
    : `${baseUrl}/application-status-update/${applicationId}`;
  const apiPayload = {
    status: newStatus,
    index: index || "0",
  };

  try {
    yield call(apiUpdateApplicationStatus, { url, apiPayload });
    yield put(setApplicationStatusSuccess()); // TODO handle error
  } catch (e) {
    yield put(setApplicationStatusFailed(e)); // TODO handle error
  }
}

function* handleOnUpdateApplicationStatus({
  payload,
}: PayloadActionWithCallback<{
  readonly applicationId: string;
  readonly newStatus: string;
  readonly index: string;
}>) {
  const { applicationId, newStatus, index, callback } = payload;
  const { isAgency } = yield select(getCurrentUserData);
  const url = isAgency
    ? `${agencyBaseUrl}/application-status-update/${applicationId}`
    : `${baseUrl}/application-status-update/${applicationId}`;
  const apiPayload = {
    status: newStatus,
    index: index || "0",
  };

  try {
    yield call(apiUpdateApplicationStatus, { url, apiPayload });
    yield put(updateApplicationStatusSuccess());
    yield call(callback);
  } catch (e) {
    yield put(updateApplicationStatusFailed(e)); // TODO handle error
  }
}

function* handleOnFetchCandidateAnswers({
  payload,
}: PayloadAction<{ jobId: string; applicationId: string }>) {
  const { jobId, applicationId } = payload;
  const { isAgency } = yield select(getCurrentUserData);

  const url = isAgency
    ? `${agencyBaseUrl}/application-answers/${applicationId}`
    : `${baseUrl}/answers/${applicationId}`;

  const apiPayload = {
    job_id: jobId,
  };

  try {
    const { data } = yield call(apiGetCandidateAnswers, { url, apiPayload });
    const formatedAnswers = data.questions_answers.map((item: any) => ({
      label: item.question,
      value: item.answer,
      isTypeYesNo: !!item.is_answer_type_yesno,
    }));
    yield put(fetchSelectedJobApplicationAnswersSuccess(formatedAnswers));
  } catch (e) {
    yield put(fetchSelectedJobApplicationAnswersFailed(e)); // TODO handle error
  }
}

function* handleOnFetchSelectedJobOwners({ payload }: PayloadAction<string>) {
  const { isAgency } = yield select(getCurrentUserData);

  const url = isAgency
    ? `/company/agency/job-owners/${payload}`
    : `/company/jobs/owners/${payload}`;
  try {
    const { data } = yield call(apiFetchSelectedJobOwners, { url });
    yield put(fetchSelectedJobOwnersSuccess(data));
  } catch (e) {
    yield put(fetchSelectedJobOwnersFailed(e)); // TODO handle error
  }
}

function* handleOnFetchActivityLog({
  payload,
}: PayloadAction<{
  applicationId: string;
}>) {
  const { applicationId } = payload;
  const url = `${baseUrl}/application-activities/${applicationId}`;
  try {
    const { data } = yield call(apiFetchActivityLog, { url });
    yield put(fetchActivityLogSuccess(data));
  } catch (e) {
    yield put(fetchActivityLogFailed(e)); // TODO handle error
  }
}

function* handleOnDeleteApplication({
  payload,
}: PayloadActionWithCallback<{
  applicationId: number;
}>) {
  const isCandidate: boolean = yield select(getCurrentUserIsCandidate);
  const { applicationId, callback } = payload;
  const url = isCandidate
    ? `/candidate/applications/delete/${applicationId}`
    : `${baseUrl}/application-delete/${applicationId}`;
  try {
    yield call(apiDeleteApplication, { url });
    yield put(deleteApplicationSuccess());
    yield call(callback);
  } catch (e) {
    yield put(deleteApplicationFailed(e)); // TODO handle error
  }
}

function* handleOnDeleteApplications({
  payload,
}: PayloadActionWithCallback<{
  applicationsIds: number[];
}>) {
  const { applicationsIds, callback } = payload;

  try {
    const requests: any = [];
    applicationsIds.map((id: number) => {
      const url = `${baseUrl}/application-delete/${id}`;
      requests.push(
        call(apiDeleteApplication, {
          url,
        }),
      );
    });
    yield all(requests);
    yield put(deleteApplicationsSuccess());
    yield call(callback);
  } catch (e) {
    yield put(deleteApplicationsFailed(e)); // TODO handle error
  }
}

function* handleOnUpdateApplicationAnswers({
  payload,
}: PayloadActionWithCallback<{
  data: any;
  applicationId: string;
}>) {
  const { data, applicationId, callback } = payload;
  try {
    const languages_and_levels = data.languages?.reduce(
      (result: any, item: any) => {
        result[item.language] = item.level;
        return result;
      },
      {},
    );

    const skills = data.skills?.reduce((result: any, item: any) => {
      result[item.id] = item.title;
      return result;
    }, {});

    const drivers_license = data.driverLicense?.reduce(
      (result: any, item: any) => {
        result[item.value] = item.label;
        return result;
      },
      {},
    );

    const {
      salary_expectation,
      earliest_start_date,
      current_professional_status,
      highest_degree,
      questions,
    } = data;

    // TODO check API - this example doesn't work
    const apiPayload = {
      salary_expectation: salary_expectation
        ? parseInt(salary_expectation)
        : undefined,
      earliest_start_date,
      current_job: current_professional_status,
      highest_degree,
      answers: questions,
      languages_and_levels: JSON.stringify(languages_and_levels),
      skills: JSON.stringify(skills),
      drivers_license: JSON.stringify(drivers_license),
    };

    yield call(apiUpdateApplicationAnswers, { apiPayload, applicationId });
    yield call(callback);

    yield put(updateApplicationAnswersSuccess());
  } catch (e) {
    yield put(updateApplicationAnswersFailed(e)); // TODO handle error
  }
}

function* handleOnRequestApplicationUnlock({
  payload,
}: PayloadActionWithCallback<{ applicationId: string }>) {
  const { applicationId, callback } = payload;

  const url = `${baseUrl}/request-unlock/${applicationId}`;

  try {
    yield call(apiRequestApplicationUnlock, { url });
    yield put(requestApplicationUnlockSuccess());
    yield call(callback);
  } catch (e) {
    yield put(requestApplicationUnlockFailed(e)); // TODO handle error
  }
}

function* handleOnFetchApplicationNoteList() {
  const { application_id } = yield select(getCurrentApplication);

  try {
    const { data } = yield call(apiFetchApplicationNoteList, application_id);
    yield put(fetchApplicationNoteListSuccess(data?.application_notes || []));
  } catch (e) {
    yield put(fetchApplicationNoteListFailed(e as string)); // TODO handle error
  }
}

function* handleOnAddNewNote({
  payload,
}: PayloadAction<TApplicationNoteAddPayload>) {
  const { application_id } = yield select(getCurrentApplication);
  const { tagged_ids, note } = payload;

  try {
    yield call(apiAddNewNote, {
      application_id,
      apiPayload: { note, tagged_ids },
    });
    yield put(addNewNoteSuccess);
    yield call(handleOnFetchApplicationNoteList);
  } catch (e) {
    yield put(addNewNoteFailed(e as string)); // TODO handle error
  }
}

function* handleOnAddNewNoteComment({
  payload,
}: PayloadAction<TApplicationNoteAddCommentPayload>) {
  const { application_id } = yield select(getCurrentApplication);
  const { comment, note_id } = payload;

  try {
    yield call(apiAddNewNoteComment, {
      application_id,
      apiPayload: { comment, note_id },
    });
    yield put(addNewNoteCommentSuccess);
    yield call(handleOnFetchApplicationNoteList);
  } catch (e) {
    yield put(addNewNoteCommentFailed(e as string)); // TODO handle error
  }
}

function* handleOnDeleteNote({ payload: note_id }: PayloadAction<string>) {
  const { application_id } = yield select(getCurrentApplication);

  try {
    yield call(apiDeleteComment, {
      application_id,
      apiPayload: { note_id },
    });
    yield put(deleteNoteSuccess(note_id));
  } catch (e) {
    yield put(deleteNoteFailed(e as string)); // TODO handle error
  }
}

function* ApplicationsSaga() {
  yield takeLatest(fetchApplications, handleOnFetchApplications);
  yield takeLatest(fetchAllApplications, handleOnFetchAllApplications);
  yield takeLatest(
    fetchSelectedJobApplications,
    handleOnFetchSelectedJobApplications,
  );
  yield takeLatest(updateApplicationStatus, handleOnUpdateApplicationStatus);
  yield takeLatest(setApplicationStatus, handleOnSetApplicationStatus);
  yield takeLatest(
    fetchSelectedJobApplicationAnswers,
    handleOnFetchCandidateAnswers,
  );
  yield takeLatest(fetchSelectedJobOwners, handleOnFetchSelectedJobOwners);
  yield takeLatest(fetchActivityLog, handleOnFetchActivityLog);
  yield takeLatest(deleteApplication, handleOnDeleteApplication);
  yield takeLatest(deleteApplications, handleOnDeleteApplications);
  yield takeLatest(updateApplicationAnswers, handleOnUpdateApplicationAnswers);
  yield takeLatest(
    fetchApplicationFromTalentPool,
    handleOnFetchJobApplicationFromTalentPool,
  );
  yield takeLatest(requestApplicationUnlock, handleOnRequestApplicationUnlock);
  yield takeLatest(fetchApplicationNoteList, handleOnFetchApplicationNoteList);
  yield takeLatest(addNewNote, handleOnAddNewNote);
  yield takeLatest(addNewNoteComment, handleOnAddNewNoteComment);
  yield takeLatest(deleteNote, handleOnDeleteNote);
}

export default ApplicationsSaga;
