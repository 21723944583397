import React from "react";
import {
  Stack,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Alert
} from "@mui/material";
import { IDeleteDialog } from "../../../models/dialogs";

const DeleteDialog = ({
  t,
  isLoading,
  title,
  body,
  secondBody,
  isOpen,
  customDeleteButtonText,
  note,
  onCancel,
  onDelete
}: IDeleteDialog) => (
  <Dialog
    open={isOpen}
    onClose={onCancel}
    fullWidth
    maxWidth={"sm"}
    data-testid={"teams-settings-delete-dialog"}
  >
    <DialogTitle sx={{ p: 3 }}>{title}</DialogTitle>
    {note && (
      <Stack width="100%" padding="0px 24px 0px 16px">
        <Alert severity="info">{note}</Alert>
      </Stack>
    )}
    <DialogContent sx={{ pb: 0 }}>
      <DialogContentText>
        <Typography variant="subtitle2">{body}</Typography>
        {secondBody && (
          <Typography variant="subtitle2">{secondBody}</Typography>
        )}
      </DialogContentText>
    </DialogContent>
    <DialogActions sx={{ p: 3 }}>
      <Button
        variant="outlined"
        color="error"
        onClick={onCancel}
        disabled={isLoading}
      >
        {t("button.secondCancel")}
      </Button>
      <Button
        variant="contained"
        color="error"
        onClick={onDelete}
        endIcon={
          isLoading ? (
            <CircularProgress
              size={16}
              style={{
                color: "#9A9FAF"
              }}
            />
          ) : null
        }
        disabled={isLoading}
      >
        {customDeleteButtonText || t("button.remove")}
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteDialog;
