import * as yup from "yup";
import { TFunction } from "i18next";
import { IArchiveJobFormField } from "../../../../models/Automation";

export const getDeleteArchiveJobFormFields = (t: TFunction) =>
  yup.object().shape({
    job_id: yup.string().required(t("register.message") as string),
  });

export const deleteArchiveJobFormFieldsDefaultValue: IArchiveJobFormField = {
  job_id: "",
};
