import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TalentPoolComponent from "./component";
import {
  getTalentPoolCandidatesFilters,
  getTalentPoolCandidatesPagination,
  getTalentPoolState,
} from "../../store_new/selectors/Talentpool";
import {
  addCandidateInTalentPoolManually,
  deleteCandidate,
  fetchTalentPoolCandidates,
  setSelectedJob,
  setSelectedTalentPool,
  setTalentPoolCandidatePage,
} from "../../store_new/reducers/TalentPools";
import {
  ITalentPool,
  ITalentPoolCandidate,
  ITalentPoolProfile,
} from "../../models/TalentPools";
import { DIALOG_TYPE } from "../../models/common";
import { JOB_STATUSES, TJobListSimpleFilterTypes } from "../../models/JobList";
import {
  getCurrentStatusJobListFiltersState,
  getJobListFilteredPaginationState,
  getJobListJobsState,
} from "../../store_new/selectors/JobList";
import {
  fetchJobs,
  resetJobsFilters,
  setPageNum,
  setSimpleJobFilter,
} from "../../store_new/reducers/JobList";
import { applyToJob } from "../../store_new/reducers/JobPreview";
import { getApplicationListState } from "../../store_new/selectors/Applications";
import {
  fetchApplications,
  setSelectedJobApplications,
} from "../../store_new/reducers/Applications";
import { TSelectedJobCandidateApplication } from "../../models/ApplicationPage";
import { addMessage } from "../../store_new/reducers/Snackbar";

const TalentPoolNewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source") || "";
  const { id } = useParams();
  const { t } = useTranslation();

  const [openedDialogType, setOpenedDialogType] = useState<
    | DIALOG_TYPE.ADD_CANDIDATE_TO_JOB
    | DIALOG_TYPE.DELETE
    | DIALOG_TYPE.ADD_PROFILE
    | null
  >(null);

  const [selectedCandidate, setSelectedCandidate] =
    useState<ITalentPoolCandidate | null>(null);

  const { list: applicationsList } = useSelector(getApplicationListState);

  const {
    selectedTalentPool,
    talentPools,
    isCandidatesListLoading,
    talentPoolCandidates,
    selectedJob,
  } = useSelector(getTalentPoolState);

  const { list, totalCount: jobsCount } = useSelector(getJobListJobsState);

  const { pageSize: jobsPageSize, pageNum: jobsPageNum } = useSelector(
    getJobListFilteredPaginationState
  );

  const { quickSearch: jobsQuickSearch } = useSelector(
    getCurrentStatusJobListFiltersState
  );

  const { pageNum, pageSize, totalCount } = useSelector(
    getTalentPoolCandidatesPagination
  );

  const { quickSearch, location, skill } = useSelector(
    getTalentPoolCandidatesFilters
  );

  useEffect(() => {
    if (!applicationsList.length) {
      dispatch(fetchApplications());
    }
  }, [applicationsList]);

  const handleOnChangeCandidatePage = (page: number) => {
    dispatch(setTalentPoolCandidatePage(page));
  };

  const handleOnOpenDialog = (
    data: unknown,
    type: DIALOG_TYPE.ADD_CANDIDATE_TO_JOB | DIALOG_TYPE.DELETE
  ) => {
    setOpenedDialogType(type);
    setSelectedCandidate(data as ITalentPoolCandidate);
    if (type === DIALOG_TYPE.ADD_CANDIDATE_TO_JOB) {
      dispatch(fetchJobs(JOB_STATUSES.ACTIVE));
    }
  };

  const handleOnAddProfile = () => {
    setOpenedDialogType(DIALOG_TYPE.ADD_PROFILE);
  };

  const handleOnConfirmAddProfile = (profile: ITalentPoolProfile) => {
    dispatch(
      addCandidateInTalentPoolManually({
        ...profile,
        talentpool_id: id || "",
        callback: () => {
          handleOnConfirmAddProfileSuccess();
        },
      })
    );
  };

  const handleOnConfirmAddProfileSuccess = () => {
    handleOnCloseDialog();
    dispatch(fetchTalentPoolCandidates(id as unknown as number));
    dispatch(
      addMessage({
        type: "success",
        title: t("talentPools.addProfileToTalentpoolManuallySucces"),
      })
    );
  };

  const onSuccessApplyCandidateToJob = () => {
    handleOnCloseDialog();
    dispatch(
      addMessage({
        type: "success",
        title: t("talentPools.successAddCandidateToJob"),
      })
    );
    dispatch(
      deleteCandidate({
        id: id as unknown as number,
        applicationId: selectedCandidate?.application_id as number,
        recordId: selectedCandidate?.record_id as string,
        callback: () =>
          dispatch(fetchTalentPoolCandidates(id as unknown as number)),
      })
    );
  };

  const handleOnApplyCandidateToJob = () => {
    const formattedCandidatePayload = {
      email: selectedCandidate?.email as string,
      firstname: selectedCandidate?.firstname as string,
      lastname: selectedCandidate?.lastname as string,
      use_latest_cv: true,
      ...(utm_source && { utm_source }),
    };
    dispatch(
      applyToJob({
        body: formattedCandidatePayload,
        jobUrlKey: selectedJob?.url_key as string,
        callback: () => onSuccessApplyCandidateToJob(),
      })
    );
  };

  const onSuccessDeleteCandidateFromTalentPool = () => {
    dispatch(fetchTalentPoolCandidates(id as unknown as number));
    if (openedDialogType === DIALOG_TYPE.ADD_CANDIDATE_TO_JOB) {
      dispatch(resetJobsFilters());
    }
    handleOnCloseDialog();
  };

  const handleOnDeleteCandidateFromTalentPool = () => {
    dispatch(
      deleteCandidate({
        id: id as unknown as number,
        applicationId: selectedCandidate?.application_id as number,
        recordId: selectedCandidate?.record_id as string,
        callback: () => onSuccessDeleteCandidateFromTalentPool(),
      })
    );
  };

  const handleOnChangeJobPage = (pageNum: number) => {
    dispatch(setPageNum(pageNum));
  };

  const handleOnCloseDialog = () => {
    setOpenedDialogType(null);
    dispatch(setSelectedJob(null));
    setSelectedCandidate(null);
  };

  const handleOnChangeSimpleFilter = ({
    filter,
    value,
  }: {
    readonly filter: keyof TJobListSimpleFilterTypes;
    readonly value: string;
  }) => {
    dispatch(setSimpleJobFilter({ filter, value }));
  };

  useEffect(() => {
    if (!selectedTalentPool && talentPools) {
      const talentPool = talentPools.find((item: ITalentPool) => item.id == id);
      dispatch(setSelectedTalentPool(talentPool as ITalentPool));
    }
  }, [talentPools]);

  useEffect(() => {
    if (selectedTalentPool) {
      dispatch(fetchTalentPoolCandidates(id as unknown as number));
    }
  }, [selectedTalentPool, pageNum, skill, location, quickSearch]);

  useEffect(() => {
    if (pageNum !== 1 && totalCount !== 0 && !talentPoolCandidates?.length) {
      dispatch(setTalentPoolCandidatePage(pageNum - 1));
    }
  }, [talentPoolCandidates]);

  useEffect(() => {
    dispatch(fetchJobs(JOB_STATUSES.ACTIVE));
  }, [jobsQuickSearch, jobsPageNum]);

  const handleOnOpenApplication = (application: ITalentPoolCandidate) => {
    const formattedApplication = {
      ...application,
      is_talentpooled: 1,
    };
    if (application.url_key && application.job_id) {
      navigate(
        `/talentpool/${id}/applications/candidate/${application.url_key}/${application.job_id}`
      );
    } else {
      dispatch(
        setSelectedJobApplications([
          formattedApplication as unknown as TSelectedJobCandidateApplication,
        ])
      );
      navigate(`/talentpool/${id}/candidate`);
    }
  };

  return (
    <TalentPoolComponent
      t={t}
      isListLoading={isCandidatesListLoading}
      candidates={talentPoolCandidates}
      pageNum={pageNum}
      pageSize={pageSize}
      totalCount={totalCount}
      dialogType={openedDialogType}
      selectedCandidate={selectedCandidate}
      jobsCount={jobsCount}
      jobsPageNum={jobsPageNum}
      jobsPageSize={jobsPageSize}
      jobs={list}
      jobsQuickSearch={jobsQuickSearch}
      selectedJob={selectedJob}
      onAddProfile={handleOnAddProfile}
      onConfirmAddProfile={handleOnConfirmAddProfile}
      onChangeSimpleFilter={handleOnChangeSimpleFilter}
      onOpenDialog={handleOnOpenDialog}
      onCloseDialog={handleOnCloseDialog}
      onApplyCandidateToJob={handleOnApplyCandidateToJob}
      onDeleteCandidateFromTalentPool={handleOnDeleteCandidateFromTalentPool}
      onPaginate={handleOnChangeCandidatePage}
      onJobsPaginate={handleOnChangeJobPage}
      onOpenApplication={handleOnOpenApplication}
    />
  );
};

export default TalentPoolNewPage;
