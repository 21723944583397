import React from "react";
import { Stack } from "@mui/material";
import MarketingAction from "./Action";
import MarketingStepOne from "./MarketingStepOne";
import MarketingStepTwo from "./MarketingStepTwo";
import MarketingFooter from "./MarketingFooter";
import Loading from "../../components/Loading";
import MarketingStepThree from "./MarketingStepThree";
import karrieraBlackLogo from "../../assets/black-logo.png";
import { IMarketingPageComponent } from "../../models/Marketing";
import { openUrlnNewTab } from "../../utils";

const MarketingPageComponent = ({
  desktop,
  isOrderDisabled,
  isAgree,
  jobData,
  isLoading,
  isError,
  countries,
  control,
  step,
  errors,
  register,
  handleSubmit,
  onChangeJobUrl,
  onGenerateJobData,
  onSendData,
  onChangeAgreement,
  onOpenSecondStep,
  onRequestAppointment,
}: IMarketingPageComponent) => (
  <Stack my={5}>
    <Stack alignItems="center">
      <Stack width={desktop ? 1128 : "90vw"} alignItems="flex-start">
        <img
          onClick={() => openUrlnNewTab("https://karriera.de")}
          height={32}
          src={karrieraBlackLogo}
          alt="logo"
          style={{ objectFit: "contain", cursor: "pointer" }}
        />
      </Stack>
      <form onSubmit={handleSubmit(onSendData)}>
        <Stack
          mt={5}
          direction={desktop ? "row" : "column"}
          height="100%"
          spacing={3}
        >
          {step === 1 ? (
            <MarketingStepOne
              desktop={desktop}
              isError={isError}
              countries={countries}
              jobData={jobData}
              control={control}
              register={register}
              onChangeJobUrl={onChangeJobUrl}
              onGenerateJobData={onGenerateJobData}
              onRequestAppointment={onRequestAppointment}
            />
          ) : step === 2 ? (
            isLoading ? (
              <Stack width={desktop ? 744 : "100%"}>
                <Loading />
              </Stack>
            ) : (
              <MarketingStepTwo
                desktop={desktop}
                errors={errors}
                countries={countries}
                control={control}
                register={register}
              />
            )
          ) : null}
          {step !== 3 && (
            <MarketingAction
              desktop={desktop}
              step={step}
              isOrderDisabled={isOrderDisabled}
              isAgree={isAgree}
              onChangeAgreement={onChangeAgreement}
              onOpenSecondStep={onOpenSecondStep}
            />
          )}
          {step === 3 && <MarketingStepThree desktop={desktop} />}
        </Stack>
      </form>
    </Stack>
    <MarketingFooter desktop={desktop} />
  </Stack>
);

export default MarketingPageComponent;
