import { Box, Paper, TextField } from "@mui/material";
import { styled } from "@mui/system";

export const StyledSimpleFilterSearchField = styled(TextField)({
  svg: {
    width: 20,
  },
});

export const StyledInputContainer = styled(Box)({
  width: "100%",
  textDecoration: "none",
  textTransform: "none",
  height: 40,
  "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
    position: "relative",
    width: "100%",
  },
});

export const StyledInputContainerMultipleValues = styled(Box)({
  "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
    paddingTop: "4px!important",
    paddingBottom: "4px!important",
    height: "auto!important",
    "& .MuiInputBase-inputMultiline": {
      height: "26px!important",
    },
  },
});

export const StyledOptionContainer = styled("li")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  lineHeight: "100%",
  img: {
    width: 20,
    height: 20,
    display: "flex",
    marginRight: 8,
  },
});

export const StyledInputFlagContainer = styled(Box)({
  display: "flex",
  position: "relative",
});

export const StyledPlaceholder = styled(Box)({
  color: "#9CA3AF",
  fontWeight: 400,
  opacity: 0.5,
});

export const StyledAutocompletePaperComponent = styled(Paper)({
  "& ::-webkit-scrollbar": {
    width: "6px",
  },
  "& ::-webkit-scrollbar-thumb": {
    background: "#E5E7EB",
    borderRadius: "6px",
  },
});
