import { DocumentTextIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import {
  StyledExistingImageContainer,
  StyledImageUploadContainer,
} from "./styles";

const ImageUpload = ({
  image,
  onChange,
  title,
  subtitle,
}: {
  image?: File | string | null;
  title: string;
  subtitle: string;
  onChange?: (file: File | undefined) => void;
}) => {
  const imageName = image
    ? typeof image === "string"
      ? image
      : image.name
    : "";
  return (
    <StyledImageUploadContainer>
      {image ? (
        <StyledExistingImageContainer direction={"row"} px={1}>
          <Stack flex={1} justifyContent="center">
            <IconButton className={"upload-button"}>
              <DocumentTextIcon />
            </IconButton>
          </Stack>
          <Stack
            flex={6}
            justifyContent="center"
            px={1}
            width={"calc(100% - 80px)"}
          >
            <Tooltip title={imageName}>
              <Typography
                variant="subtitle2"
                overflow="hidden"
                textOverflow="ellipsis"
                noWrap
              >
                {imageName}
              </Typography>
            </Tooltip>
          </Stack>
          <Stack flex={1} justifyContent="center">
            {onChange && (
              <IconButton
                onClick={() => onChange && onChange(undefined)}
                className={"remove-button"}
              >
                <TrashIcon />
              </IconButton>
            )}
          </Stack>
        </StyledExistingImageContainer>
      ) : (
        <Button
          variant="text"
          component="label"
          className={"upload-image-button"}
          disableRipple
          disabled={!onChange}
        >
          <input
            hidden
            type="file"
            accept=".png, .jpg, .jpeg"
            onChange={(e) => {
              if (onChange) onChange(e.target.files?.[0]);
            }}
          />
          <Typography variant="body2">{title}</Typography>
          <Typography variant="subtitle2" color="textSecondary" pt={0.5}>
            {subtitle}
          </Typography>
        </Button>
      )}
    </StyledImageUploadContainer>
  );
};

export default ImageUpload;
