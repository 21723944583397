import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PackagePageComponent from "./component";
import { getActiveProduct } from "../../store_new/selectors/CurrentUser";
import { setIsBuyProductDialogOpen } from "../../store_new/reducers/dialogs";
import {
  TConvertedPremiumProduct,
  TPremiumPackage,
} from "../../models/SingleJob";
import { setSelectedProducts } from "../../store_new/reducers/SingleJob";

const PackagePageNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const activeProduct = useSelector(getActiveProduct);

  const handleOpenBuyProductDialog = () => {
    dispatch(setSelectedProducts([activeProduct as TConvertedPremiumProduct]));
    dispatch(setIsBuyProductDialogOpen(true));
  };

  useEffect(() => {
    if (!activeProduct) {
      navigate("/discover");
    }
  }, []);

  return (
    <PackagePageComponent
      t={t}
      activeProduct={activeProduct as TPremiumPackage}
      onOpenBuyProductDialog={handleOpenBuyProductDialog}
    />
  );
};

export default PackagePageNew;
