import { Chip } from "@mui/material";
import { styled } from "@mui/system";

export const StyledAutocompleteChip = styled(Chip)({
  background: "#EDF7FF !important",
  borderRadius: 24,
  flexDirection: "row",
  gap: 6,
  alignItems: "center",
  padding: "2px 12px",
  color: "#178CF2 !important",
  border: "none !important",
  "& svg": {
    width: 16,
    height: 16,
    strokeWidth: 0.5,
    fill: "#178CF2 !important",
    stroke: "#178CF2 !important",
    cursor: "pointer",
  },
});
