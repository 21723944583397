import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
  Stack,
  Tooltip,
  Typography,
  Box,
  Button,
  Divider,
} from "@mui/material";
import {
  ClockIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import { TFunction } from "i18next";
import { TPremiumProductItem } from "../../models/SingleJob";
import {
  StyledDaysBlock,
  StyledDiscountLabel,
  StyledProductBodyItem,
  StyledProductContainer,
} from "../SingleJob_new/styles";
import bgImg from "../../assets/productBgImg.png";

const Product = ({
  t,
  productItem,
  activeProductId,
  onChangeActiveProductId,
  onOpenBuyProductDialog,
}: {
  readonly t: TFunction;
  readonly productItem: TPremiumProductItem;
  readonly activeProductId: number | null;
  readonly onChangeActiveProductId: (id: number | null) => void;
  readonly onOpenBuyProductDialog: (productItem: TPremiumProductItem) => void;
}) => (
  <StyledProductContainer spacing={activeProductId === productItem.id ? 3 : 0}>
    <img alt={"bgImg"} src={bgImg} className={"bgImg"} />
    <Stack direction="row" justifyContent="space-between">
      <Stack flex={1}>
        <Stack direction={"row"} spacing={1}>
          <Tooltip title={productItem.name}>
            <Typography
              variant="subtitle2"
              noWrap
              maxWidth={300}
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {productItem.name}
            </Typography>
          </Tooltip>
          {productItem.discount && productItem.discount > 0 ? (
            <StyledDiscountLabel>{productItem.discount} %</StyledDiscountLabel>
          ) : null}
        </Stack>
        <StyledDaysBlock mt={0.5}>
          <ClockIcon />
          <span className="product-days">
            {productItem.runtime_in_days} {t("create_job_fourth_step.days")}
          </span>
        </StyledDaysBlock>
      </Stack>
      <Stack direction="row" spacing={3} alignItems="center">
        <StyledProductBodyItem
          padding="0px !important"
          sx={{ background: "transparent", border: "none" }}
        >
          <Stack justifyContent="space-between">
            <Stack direction={"column"} alignItems={"flex-end"} mt={0}>
              {productItem.agency_price
                ? productItem.agency_price <
                    productItem.recommended_retail_price && (
                    <Box className={"old-price"}>
                      {productItem.recommended_retail_price} €
                    </Box>
                  )
                : productItem.retail_price <
                    productItem.recommended_retail_price && (
                    <Box className={"old-price"}>
                      {productItem.recommended_retail_price} €
                    </Box>
                  )}
              <Box className={"new-price"}>
                {productItem.agency_price || productItem.retail_price} €
              </Box>
            </Stack>
          </Stack>
        </StyledProductBodyItem>
        <Button
          variant="contained"
          onClick={() => onOpenBuyProductDialog(productItem)}
        >
          {t("create_job_fourth_step.select_product")}
        </Button>
        <Stack height="100%" justifyContent="center" zIndex={1}>
          {productItem.id === activeProductId ? (
            <ChevronUpIcon
              width={20}
              height={20}
              stroke="#131E30"
              cursor="pointer"
              onClick={() => onChangeActiveProductId(null)}
            />
          ) : (
            <ChevronDownIcon
              width={20}
              height={20}
              stroke="#131E30"
              cursor="pointer"
              onClick={() => onChangeActiveProductId(productItem.id)}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
    <Stack spacing={3}>
      {productItem.id === activeProductId && <Divider />}
      <AnimatePresence>
        {productItem.id === activeProductId && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Typography fontSize={12} color="textSecondary">
              {productItem.description ||
                t("create_job_fourth_step.no_description")}
            </Typography>
          </motion.div>
        )}
      </AnimatePresence>
    </Stack>
  </StyledProductContainer>
);

export default Product;
