import React from "react";
import { Dialog, Stack, Typography } from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import JobLimitPicture from "../../../assets/JobLimit.png";
import { IExtendLimitConfirmationDialog } from "../../../models/dialogs";

const LimitConfirmationDialog = ({
  t,
  isOpen,
  onClose,
}: IExtendLimitConfirmationDialog) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    PaperProps={{
      elevation: 0,
    }}
  >
    <Stack
      p={3}
      width={336}
      borderRadius={1.5}
      sx={{ backgroundColor: "#fff" }}
    >
      <Stack alignItems="flex-end">
        <XMarkIcon
          width={24}
          height={24}
          stroke="#131E30"
          style={{ cursor: "pointer" }}
          onClick={onClose}
        />
      </Stack>
      <Stack mt={3} alignItems="center">
        <img
          src={JobLimitPicture}
          alt="job-limit"
          width={230.772}
          height={139.938}
        />
      </Stack>
      <Stack mt={3} spacing={1} alignItems="center" textAlign="center">
        <Typography>{t("limit_dialog.confirmation_title")}</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {t("limit_dialog.confirmation_text")}
        </Typography>
      </Stack>
    </Stack>
  </Dialog>
);

export default LimitConfirmationDialog;
