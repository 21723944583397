import { Button, CircularProgress, Dialog } from "@mui/material";
import { TFunction } from "i18next";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import JobApplicationProcessForm from "../../../../components/JobDialogs_new/JobApplicationProcess";
import { TJobDocumentsFormFields } from "../../../../models/JobEditor";
import { SELECTED_EDIT_SECTIONS } from "../../../../models/SingleJob";
import { getJobEditorState } from "../../../../store_new/selectors/JobEditor";
import { getSingleJobState } from "../../../../store_new/selectors/SingleJob";
import { StyledDialogActions } from "../../styles";

const JobDocumentsEditDialog = ({
  t,
  isOpen,
  onClose,
  onUpdateJob,
}: {
  t: TFunction;
  isOpen: boolean;
  onClose: () => void;
  onUpdateJob: (data: TJobDocumentsFormFields) => void;
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { jobDetails } = useSelector(getSingleJobState);
  const { isLoading } = useSelector(getJobEditorState);
  const { cv_required, other_documents_required } = jobDetails || {};

  const handleOnSubmit = (data: TJobDocumentsFormFields) => {
    onUpdateJob(data);
  };

  return (
    <Dialog open={isOpen} maxWidth={"md"} fullWidth onClose={onClose}>
      <JobApplicationProcessForm
        t={t}
        defaultValues={{ cv_required, other_documents_required }}
        selectedSections={[
          SELECTED_EDIT_SECTIONS.JOB_APPLICATION_PROCESS_DOCUMENTS,
        ]}
        formRef={formRef}
        onSubmit={handleOnSubmit}
      />
      <StyledDialogActions>
        <Button variant="outlined" onClick={onClose} disabled={isLoading}>
          {t("button.secondCancel")}
        </Button>
        <Button
          variant="contained"
          type="submit"
          endIcon={
            isLoading ? (
              <CircularProgress
                size={16}
                style={{
                  color: "#9A9FAF",
                }}
              />
            ) : null
          }
          disabled={isLoading}
          onClick={() => formRef.current?.requestSubmit()}
        >
          {t("button.save")}
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

export default JobDocumentsEditDialog;
