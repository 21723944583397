import React from "react";
import { Stack } from "@mui/material";
import InviteDialog from "../../components/dialogs/InviteDialog";
import DataGrid from "../../components/DataGrid_new";
import Loading from "../../components/Loading";
import FiltersAndButtons from "./FiltersAndButtons";
import NoCustomers from "./NoCustomers";
import { DATA_GRID_LIST_TYPE } from "../../models/DataGrid";
import { ICustomerComponent } from "../../models/Agency";
import { DIALOG_TYPE } from "../../models/common";
import ImportCustomerDialog from "../../components/dialogs/ImportCustomerDialog";

const CustomerComponent = ({
  t,
  list,
  isListLoading,
  openedDialogType,
  emailsToInvite,
  jassId,
  quickSearch,
  location,
  isLoading,
  selectedImportCustomers,
  jassCustomers,
  onSelectCustomer,
  onChangeFilters,
  onCloseDialog,
  onEmailsChange,
  onInvite,
  onOpenInviteDialog,
  onOpenImportCustomersDialog,
  onImportCustomers,
  onSelectDescelectCustomer,
  onLogInAsCustomer,
}: ICustomerComponent) => (
  <Stack mt={4}>
    <FiltersAndButtons
      t={t}
      isLoading={isLoading}
      quickSearch={quickSearch}
      location={location}
      onChangeFilters={onChangeFilters}
      onOpenDialog={onOpenInviteDialog}
      onImportCustomers={onOpenImportCustomersDialog}
      jassId={jassId}
    />
    {isListLoading ? (
      <Loading />
    ) : list.length ? (
      <Stack mt={3} height={list.length * 49 + 56}>
        <DataGrid
          t={t}
          type={DATA_GRID_LIST_TYPE.CUSTOMERS}
          list={list}
          actions={{
            onClickItem: onSelectCustomer,
            onLogInAsCustomer: onLogInAsCustomer,
          }}
        />
      </Stack>
    ) : (
      <NoCustomers
        t={t}
        onOpenImportCustomersDialog={onOpenImportCustomersDialog}
        onOpenDialog={onOpenInviteDialog}
        jassId={jassId}
      />
    )}
    <InviteDialog
      t={t}
      title={t("customers.invite")}
      isLoading={false}
      isOpen={openedDialogType === DIALOG_TYPE.INVITE}
      emailsToInvite={emailsToInvite}
      onCancel={onCloseDialog}
      onEmailsChange={onEmailsChange}
      onInvite={onInvite}
    />
    <ImportCustomerDialog
      jassCustomers={jassCustomers}
      isOpen={openedDialogType === DIALOG_TYPE.SYNC_CUSTOMERS}
      t={t}
      selectedCustomers={selectedImportCustomers}
      onSelect={onSelectDescelectCustomer}
      onClose={onCloseDialog}
      onImport={onImportCustomers}
    />
  </Stack>
);

export default CustomerComponent;
