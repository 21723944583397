import { styled } from "@mui/system";
import { Grid } from "@mui/material";
import PublicAuthImage from "../../assets/background.png";

export const StyledBackgroundImageContainer = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  backgroundImage: `url(${PublicAuthImage})`,
  backgroundSize: "cover",
  backgroundPosition: "top center"
});

export const StyledBackgroundTextContainer = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  marginTop: "40vh",
  marginLeft: "6vw"
});
