import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Stack, Typography } from "@mui/material";
import React from "react";

const StyledSectionTitle = ({
  title,
  onClick,
  onClose
}: {
  title: string;
  onClick?: () => void;
  onClose?: () => void;
}) => (
  <Stack direction={"row"} justifyContent={"space-between"}>
    <Typography fontSize={18} color={"#0F141B"} fontWeight={500}>
      {title}
    </Typography>
    {onClick && (
      <PencilSquareIcon
        width={24}
        height={24}
        onClick={onClick}
        cursor={"pointer"}
        stroke={"#178CF2"}
      />
    )}
    {onClose && (
      <PencilSquareIcon
        width={24}
        height={24}
        onClick={onClose}
        cursor={"pointer"}
      />
    )}
  </Stack>
);

export default StyledSectionTitle;
