import React from "react";
import { StyledFileContainer, StyledUploadButtonContainer } from "./styles";
import {
  CheckCircleIcon,
  CloudArrowUpIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import { Button } from "@mui/material";

const UploadDocument = ({
  placeholder,
  value,
  onChange,
  companyColor
}: {
  placeholder: string;
  value: string; // file name
  companyColor?: string;
  onChange: (file?: File) => void;
}) => (
  <StyledUploadButtonContainer companyColor={companyColor}>
    {value ? (
      <StyledFileContainer direction={"row"} spacing={1}>
        <CheckCircleIcon className={"success-icon"} />
        <span>{value}</span>
        <XMarkIcon
          onClick={() => {
            onChange(undefined);
          }}
          className={"remove-icon"}
        />
      </StyledFileContainer>
    ) : (
      <Button variant={"outlined"} component={"label"}>
        {placeholder}
        <input
          hidden
          type={"file"}
          onChange={(event) => onChange(event.target.files?.[0])}
        />
        <CloudArrowUpIcon />
      </Button>
    )}
  </StyledUploadButtonContainer>
);

export default UploadDocument;
