import React, { useEffect, useMemo, useState } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Languages from "./Languages";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import Questions from "./Questions";
import Skills from "./Skills";
import EditModal from "./EditModal";
import {
  getApplicationAdditionalInfo,
  getCandidateAnswers,
  getCurrentApplication,
} from "../../../store_new/selectors/Applications";
import { getCandidateState } from "../../../store_new/selectors/Candidate";
import { getSingleJobState } from "../../../store_new/selectors/SingleJob";
import { TJobDetails, TJobQuestions } from "../../../models/SingleJob";
import { getJobPreviewState } from "../../../store_new/selectors/JobPreview";
import {
  StyledCandidateContentContainer,
  StyledCandidateContentTitleContainer,
  StyledDivider,
  //  StyledRequestDataContainer,
} from "../styles";
import { convertRequiredProperties } from "../../../utils";
import { fetchJob } from "../../../store_new/reducers/JobPreview";
/* import { setIsRequestDataDialogOpen } from "../../../store_new/reducers/dialogs";
import {
  setRequestedDataList,
  setRequestedDataTitle,
} from "../../../store_new/reducers/Applications"; */

const AdditionalInfo = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const candidateAnswers = useSelector(getCandidateAnswers);
  const currentApplication = useSelector(getCurrentApplication);

  const { isCandidate } = useSelector(getCandidateState);
  const {
    additionalQuestions,
    mainQuestions,
    skills,
    languages,
    driverLicenses,
  } = useSelector(getApplicationAdditionalInfo);
  const { jobDetails, jobQuestions } = useSelector(getSingleJobState);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const targetJobDetails = useMemo(
    () => jobDetails || currentApplication?.job,
    [jobDetails, currentApplication]
  );

  const showEditIcon = isCandidate
    ? currentApplication?.job.is_archived != "1"
    : jobDetails && jobDetails?.is_archived != "1";

  const formattedTargetJobDetails: any =
    convertRequiredProperties(targetJobDetails);

  const {
    jobData: { job_questions: candidateJobQuestions },
  } = useSelector(getJobPreviewState);

  const sortedQuestion = useMemo(() => {
    let sorted: any[] = mainQuestions || [];

    if (targetJobDetails && mainQuestions) {
      sorted = mainQuestions.sort((a) => {
        if (
          !targetJobDetails[
            `${a.type}_required` as keyof Omit<
              TJobDetails,
              "job_type_id" | "job_type"
            >
          ]
        )
          return 1;
        return -1;
      });
    }

    return sorted;
  }, [targetJobDetails, mainQuestions]);

  const targetAdditionalQuestions = useMemo(() => {
    let questions = [];

    if (isCandidate) questions = additionalQuestions;
    else questions = candidateAnswers;

    if (!questions.length) {
      const targetJobQuestions = (jobQuestions ||
        candidateJobQuestions) as TJobQuestions[];
      questions =
        targetJobQuestions?.map(({ question, is_answer_type_yesno }) => ({
          label: question,
          isTypeYesNo: Boolean(is_answer_type_yesno),
          value: "",
        })) || [];
    }

    return questions;
  }, [additionalQuestions, candidateAnswers, jobQuestions]);

  /*   const questionsWithoutData = useMemo(() => {
    const res: { title: string; id: string }[] = [];

    sortedQuestion.forEach((question) => {
      if (!question.value)
        res.push({ title: t(question.labelKey), id: question.type });
    });

    targetAdditionalQuestions.forEach((question) => {
      if (!question.value)
        res.push({ title: question.label, id: question.label });
    });
    if (!driverLicenses.length)
      res.push({
        title: t("candidate_questions.driving_licenses"),
        id: "driverLicenses",
      });
    if (!languages.length)
      res.push({ title: t("candidate_questions.language"), id: "languages" });
    if (!skills.length)
      res.push({ title: t("candidate_questions.skills"), id: "skills" });
    return res;
  }, [sortedQuestion, targetAdditionalQuestions]); */

  const handleOnOpenEditModal = () => {
    setIsOpenEditModal(true);
  };

  const handleOnCloseEditModal = () => {
    setIsOpenEditModal(false);
  };

  /*   const handleOnRequestData = () => {
    if (questionsWithoutData.length) {
      dispatch(setIsRequestDataDialogOpen(true));
      dispatch(setRequestedDataList(questionsWithoutData));
      dispatch(setRequestedDataTitle(t("request_additional_information")));
    }
  }; */

  useEffect(() => {
    if (showEditIcon) {
      const key = jobDetails?.url_key || currentApplication?.job.url_key;
      dispatch(
        fetchJob({
          jobUrlKey: key as string,
        })
      );
    }
  }, [jobDetails?.is_archived]);

  return (
    <StyledCandidateContentContainer spacing={2}>
      <StyledCandidateContentTitleContainer>
        <Typography
          fontSize={18}
          color={"#131E30"}
          fontWeight={500}
          lineHeight={"18px"}
        >
          {t("candidate.additional_information")}
        </Typography>
        <Tooltip
          arrow
          title={
            <Stack alignItems={"flex-start"}>
              <span>{t("candidate.additionalInfoTooltip.firstStr")}</span>
              <span>{t("candidate.additionalInfoTooltip.secondStr")}</span>
            </Stack>
          }
          placement={"right"}
          componentsProps={{
            arrow: {
              sx: {
                color: "#178CF2",
              },
            },
            tooltip: {
              sx: {
                fontSize: "12px",
                padding: 1,
                background: "#178CF2",
                maxWidth: 500,
              },
            },
          }}
        >
          <InformationCircleIcon className={"info-icon"} />
        </Tooltip>
        <Stack
          sx={{ marginLeft: "auto" }}
          direction={"row"}
          spacing={2}
          alignItems={"center"}
        >
          {/*       {!isCandidate && !!questionsWithoutData.length && (
            <StyledRequestDataContainer onClick={handleOnRequestData}>
              {t("request_data")}
            </StyledRequestDataContainer>
          )} */}
          {showEditIcon && (
            <PencilSquareIcon
              className={"edit-icon"}
              onClick={handleOnOpenEditModal}
            />
          )}
        </Stack>
      </StyledCandidateContentTitleContainer>
      <StyledDivider />
      <Questions
        t={t}
        questions={sortedQuestion}
        jobDetails={formattedTargetJobDetails}
      />
      <Skills
        isActive={Boolean(formattedTargetJobDetails?.driver_license_required)}
        t={t}
        skills={driverLicenses}
        label={t("candidate_questions.driving_licenses") as string}
      />
      <Languages
        isActive={Boolean(formattedTargetJobDetails?.langs_required)}
        t={t}
        languages={languages}
      />
      <Skills
        isActive={Boolean(formattedTargetJobDetails?.skills_required)}
        t={t}
        skills={skills}
        label={t("candidate_questions.skills") as string}
      />
      <Questions t={t} questions={targetAdditionalQuestions} />
      <EditModal isOpen={isOpenEditModal} onClose={handleOnCloseEditModal} />
    </StyledCandidateContentContainer>
  );
};

export default AdditionalInfo;
