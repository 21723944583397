import { createSelector } from "@reduxjs/toolkit";
import { TConvertedPremiumProduct } from "../../models/SingleJob";
import { RootState } from "../config";

export const getSingleJobNavigationState = ({ singleJob }: RootState) =>
  singleJob.navigation;

export const getSingleJobBookingsState = ({ singleJob }: RootState) =>
  singleJob.bookings;

export const getSingleJobState = ({ singleJob }: RootState) => singleJob;

export const getSingleJobProducts = ({ singleJob }: RootState) =>
  singleJob.products;

export const getSingleJobFilterValue = ({ singleJob }: RootState) =>
  singleJob.filterValue;

export const getSingleJobFilterOptions = ({ singleJob }: RootState) =>
  singleJob.filterOptions;

export const getSingleJobFilteredProducts = createSelector(
  [getSingleJobProducts, getSingleJobFilterValue],
  (products, filterValue) => {
    if (products) {
      let filteredByName: TConvertedPremiumProduct[];
      let filteredByCountryAndName: TConvertedPremiumProduct[];
      let filteredByIndustryAndCountry: TConvertedPremiumProduct[];
      let filteredByDurationAndIndustry: TConvertedPremiumProduct[];

      if (filterValue.name) {
        filteredByName = products.filter((item) =>
          item.name.toLowerCase().includes(filterValue.name.toLowerCase())
        );
      } else filteredByName = products;
      if (filterValue.country) {
        filteredByCountryAndName = filteredByName.filter(
          (item) =>
            filterValue.country.toLowerCase() === item.country.toLowerCase()
        );
      } else filteredByCountryAndName = filteredByName;
      if (filterValue.industry) {
        filteredByIndustryAndCountry = filteredByCountryAndName
          .map((item) => {
            const products = item.products.filter((product) => {
              const industryIds = product.supplier_groups.map(
                (industry: { id: number | string }) => industry.id.toString()
              );
              return industryIds.includes(filterValue.industry);
            });
            return { ...item, products };
          })
          .filter((item) => item.products.length);
      } else filteredByIndustryAndCountry = filteredByCountryAndName;
      if (filterValue.duration) {
        filteredByDurationAndIndustry = filteredByIndustryAndCountry
          .map((item) => {
            const products = item.products.filter((product) =>
              parseInt(filterValue.duration) < 0
                ? product.runtime_in_days <= parseInt(filterValue.duration) * -1
                : product.runtime_in_days > parseInt(filterValue.duration)
            );
            return { ...item, products };
          })
          .filter((item) => item.products.length);
      } else filteredByDurationAndIndustry = filteredByIndustryAndCountry;

      return filteredByDurationAndIndustry;
    }

    return products;
  }
);

export const getSingleJobFilteredShopProducts = createSelector(
  [getSingleJobProducts, getSingleJobFilterValue],
  (shopProducts, filterValue) => {
    if (shopProducts) {
      let filteredByName: TConvertedPremiumProduct[];
      let filteredByCountryAndName: TConvertedPremiumProduct[];
      let filteredByIndustryAndCountry: TConvertedPremiumProduct[];
      let filteredByDurationAndIndustry: TConvertedPremiumProduct[];

      if (filterValue.name) {
        filteredByName = shopProducts.filter((item) =>
          item.name.toLowerCase().includes(filterValue.name.toLowerCase())
        );
      } else filteredByName = shopProducts;
      if (filterValue.country) {
        filteredByCountryAndName = filteredByName.filter(
          (item) =>
            filterValue.country.toLowerCase() === item.country.toLowerCase()
        );
      } else filteredByCountryAndName = filteredByName;
      if (filterValue.industry) {
        filteredByIndustryAndCountry = filteredByCountryAndName
          .map((item) => {
            const products = item.products.filter((product) => {
              const industryIds = product.supplier_groups.map(
                (industry: { id: number | string }) => industry.id.toString()
              );
              return industryIds.includes(filterValue.industry);
            });
            return { ...item, products };
          })
          .filter((item) => item.products.length);
      } else filteredByIndustryAndCountry = filteredByCountryAndName;
      if (filterValue.duration) {
        filteredByDurationAndIndustry = filteredByIndustryAndCountry
          .map((item) => {
            const products = item.products.filter((product) =>
              parseInt(filterValue.duration) < 0
                ? product.runtime_in_days <= parseInt(filterValue.duration) * -1
                : product.runtime_in_days > parseInt(filterValue.duration)
            );
            return { ...item, products };
          })
          .filter((item) => item.products.length);
      } else filteredByDurationAndIndustry = filteredByIndustryAndCountry;

      return filteredByDurationAndIndustry;
    }

    return shopProducts;
  }
);

export const getSingleJobFilteredFilterOptions = createSelector(
  [getSingleJobFilterOptions],
  ({ industries, countries }) => {
    return { industries, countries };
  }
);
