import React from "react";
import { TFunction } from "i18next";
import { Stack } from "@mui/material";
import OrderItem from "./OrderItem";
import { IJobOrder } from "../../../models/SingleJob";

const Orders = ({
  t,
  orders,
}: {
  readonly t: TFunction;
  readonly orders: IJobOrder[];
}) => (
  <Stack height="100%" spacing={4}>
    {orders?.map((order: IJobOrder, index: number) => (
      <OrderItem key={index} t={t} order={order} />
    ))}
  </Stack>
);

export default Orders;
