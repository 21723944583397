import { Button, Divider, Link, Stack } from "@mui/material";
import React from "react";
import {
  StyledDashboardItemContainer,
  StyledDashboardItemTitle,
  StyledDashboardSecondaryText,
  StyledSupportWrapper
} from "../styles";
import { useTranslation } from "react-i18next";
import supportImg from "../../../assets/support.png";

const SupportBox = () => {
  const { t } = useTranslation();
  const supportLink = "mailto:support@karriera.de";
  return (
    <StyledDashboardItemContainer>
      <Stack spacing={3} className={"full-height"}>
        <StyledDashboardItemTitle>
          {t("dashboard.support.title")}
        </StyledDashboardItemTitle>
        <Divider />
        <StyledSupportWrapper>
          <img alt={"support"} src={supportImg} />
          <Button variant={"contained"} href={supportLink}>
            {t("dashboard.support.ctaBtn")}
          </Button>
          <StyledDashboardSecondaryText className={"support-footer"}>
            {t("dashboard.support.footerText")}{" "}
            <Link href={supportLink}>support@karriera.de</Link>
          </StyledDashboardSecondaryText>
        </StyledSupportWrapper>
      </Stack>
    </StyledDashboardItemContainer>
  );
};

export default SupportBox;
