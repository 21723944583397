import React from "react";
import { Stack, Typography } from "@mui/material";
import { PlayCircleIcon } from "@heroicons/react/24/solid";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import ShowIcon from "./ShowIcon";
import IncompleteDeleteData from "./IncompleteDeleteData";
import {
  ACTION_TRIGGER_VARIANT,
  IAutomationSelectedTrigger,
} from "../../models/Automation";
import { StyledSelectedTriggerAutomationContainer } from "./style";

const SelectedTriggerAutomation = ({
  t,
  data,
  variant,
  currentAutomation,
  hideWhenThenText,
  subText,
  index,
  onDeleteTriggerAction,
  onHoverLeaveDeleteActionIcon,
  onClick = () => {},
}: IAutomationSelectedTrigger) => (
  <StyledSelectedTriggerAutomationContainer
    className="selected-trigger"
    variant={variant}
    currentAutomation={currentAutomation}
    onClick={() => {
      if (variant === ACTION_TRIGGER_VARIANT.TRIGGER) {
        onClick(ACTION_TRIGGER_VARIANT.TRIGGER);
      } else if (
        variant === ACTION_TRIGGER_VARIANT.TRIGGERS_LIST ||
        variant === ACTION_TRIGGER_VARIANT.ADD_ACTION_CONTAINER ||
        variant == ACTION_TRIGGER_VARIANT.COMPONENT ||
        variant === ACTION_TRIGGER_VARIANT.ACTIONS_LIST
      ) {
        onClick(
          variant === ACTION_TRIGGER_VARIANT.COMPONENT ? data : data.name
        );
      }
    }}
  >
    <Stack direction="row" alignItems="center" spacing={1.5}>
      <Stack
        height={40}
        width={40}
        alignItems="center"
        justifyContent="center"
        borderRadius={1.5}
        sx={{
          backgroundColor:
            variant === ACTION_TRIGGER_VARIANT.TRIGGER
              ? "#ECFDF5"
              : variant === ACTION_TRIGGER_VARIANT.TRIGGERS_LIST
              ? "#EEF2FF"
              : variant === ACTION_TRIGGER_VARIANT.EMPTY_COMPONENT
              ? "#F3F4F6"
              : "#EFF6FF",
        }}
      >
        {variant === ACTION_TRIGGER_VARIANT.TRIGGER ? (
          <PlayCircleIcon height={20} width={20} fill="#059669" />
        ) : variant === ACTION_TRIGGER_VARIANT.EMPTY_COMPONENT ? (
          <PaperClipIcon height={20} width={20} stroke="#6B7280" />
        ) : (
          <ShowIcon type={data.const} currentAutomation={currentAutomation} />
        )}
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack justifyContent="space-between" spacing={0.5}>
          <Typography
            display="inline"
            variant="body2"
            fontWeight={500}
            color={
              variant === ACTION_TRIGGER_VARIANT.TRIGGER ? "#059669" : "#3B82F6"
            }
            textTransform="uppercase"
          >
            {variant === ACTION_TRIGGER_VARIANT.TRIGGER
              ? t("automation.when")
              : hideWhenThenText
              ? ""
              : index
              ? index === 0
                ? t("automation.then")
                : t("automation.and")
              : t("automation.then")}
            {!hideWhenThenText && ":"}{" "}
            <Typography
              textTransform="none"
              display="inline"
              variant="body2"
              fontWeight={500}
              color="#111827"
            >
              {variant === ACTION_TRIGGER_VARIANT.TRIGGER
                ? data.name ?? t("automation.selectTriggerTitle")
                : data.name}
            </Typography>
          </Typography>
          {subText && (
            <Typography fontSize={12} color="textSecondary">
              {subText}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>

    {!hideWhenThenText && variant !== ACTION_TRIGGER_VARIANT.ACTIONS_LIST && (
      <IncompleteDeleteData
        t={t}
        data={data}
        variant={variant}
        onDeleteTriggerAction={onDeleteTriggerAction}
        onHoverLeaveDeleteActionIcon={onHoverLeaveDeleteActionIcon}
        currentAutomation={currentAutomation}
      />
    )}
  </StyledSelectedTriggerAutomationContainer>
);

export default SelectedTriggerAutomation;
