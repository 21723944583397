import React from "react";
import { Stack } from "@mui/material";
import { TFunction } from "i18next";
import TextfildsComponent from "../../components/MultipleTextFields";
import { TSelectedCustomer } from "../../models/Agency";

const TextFields = ({
  t,
  selectedCustomer,
}: {
  t: TFunction;
  selectedCustomer: TSelectedCustomer;
}) => {
  return (
    <Stack
      mt={4}
      p={2}
      spacing={2}
      borderRadius="6px"
      sx={{ backgroundColor: "#F9FAFB" }}
    >
      <TextfildsComponent
        items={[
          {
            placeholder: t("customers.company_name"),
            value: selectedCustomer?.name,
            inputLabel: t("customers.company_name"),
            flexRatio: 1,
          },
          {
            placeholder: t("update_company_information.city"),
            value: selectedCustomer?.city,
            inputLabel: t("update_company_information.city"),
            flexRatio: 0.7,
          },
          {
            placeholder: t("update_company_information.postcode"),
            value: selectedCustomer?.plz,
            inputLabel: t("update_company_information.postcode"),
            flexRatio: 0.25,
          },
        ]}
      />
      <TextfildsComponent
        items={[
          {
            placeholder: t("update_company_information.street"),
            value: selectedCustomer?.street,
            inputLabel: t("update_company_information.street"),
            flexRatio: 0.7,
          },
          {
            placeholder: t("update_company_information.number"),
            value: selectedCustomer?.street_number,
            inputLabel: t("update_company_information.number"),
            flexRatio: 0.25,
          },
          {
            placeholder: t("update_company_information.country"),
            value: selectedCustomer?.country,
            inputLabel: t("update_company_information.country"),
            flexRatio: 1,
          },
        ]}
      />
      <TextfildsComponent
        items={[
          {
            placeholder: t("customers.contact_person_firstname"),
            value: selectedCustomer?.contact_firstname,
            inputLabel: t("customers.contact_person_firstname"),
            flexRatio: 1,
          },
          {
            placeholder: t("customers.contact_person_lastname"),
            value: selectedCustomer?.contact_lastname,
            inputLabel: t("customers.contact_person_lastname"),
            flexRatio: 1,
          },
        ]}
      />
      <TextfildsComponent
        items={[
          {
            placeholder: t("customers.contact_email"),
            value: selectedCustomer?.contact_email,
            inputLabel: t("customers.contact_email"),
            flexRatio: 1,
          },
          {
            placeholder: t("customers.contact_phone"),
            value: selectedCustomer?.contact_phone,
            inputLabel: t("customers.contact_phone"),
            flexRatio: 1,
          },
        ]}
      />
    </Stack>
  );
};

export default TextFields;
