import React from "react";
import { Stack, Tooltip, Typography, Divider } from "@mui/material";
import { ClockIcon } from "@heroicons/react/24/outline";
import { TFunction } from "i18next";
import { TSalesProduct } from "../../models/SingleJob";
import { StyledDaysBlock } from "../SingleJob_new/styles";

const PackageProduct = ({
  t,
  productItem,
}: {
  readonly t: TFunction;
  readonly productItem: TSalesProduct;
}) => (
  <Stack spacing={3} p={2} borderRadius={1.5} border="1px solid #E5E7EB">
    <Stack direction="row" justifyContent="space-between">
      <Stack flex={1}>
        <Stack direction={"row"} spacing={3} alignItems="center">
          {productItem.image_url ? (
            <img src={productItem.image_url} alt="supplier-logo" height={35} />
          ) : (
            <Tooltip title={productItem.name}>
              <Typography
                noWrap
                maxWidth={300}
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {productItem.supplier}
              </Typography>
            </Tooltip>
          )}
          <Divider
            orientation="vertical"
            sx={{ border: "1px solid #E5E7EB" }}
          />
          <Stack height="100%" justifyContent="space-between">
            <Typography variant="subtitle2">{productItem.name}</Typography>
            <StyledDaysBlock mt={0.5}>
              <ClockIcon />
              <span className="product-days">
                {productItem.runtime_in_days} {t("create_job_fourth_step.days")}
              </span>
            </StyledDaysBlock>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
    <Divider sx={{ borderColor: "#E5E7EB" }} />
    <Typography fontSize={12} color="textSecondary">
      {productItem.description || t("create_job_fourth_step.no_description")}
    </Typography>
  </Stack>
);

export default PackageProduct;
