import React from "react";
import {
  Button,
  Checkbox,
  Dialog,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import CardView from "../../../pages/Applications_new/CardView";
import { IAddUpdateWorkflowDialog } from "../../../models/dialogs";

const AddUpdateWorkflowDialog = ({
  t,
  isOpen,
  currentWorkflowId,
  initialColumns,
  isDefaultWorkflow,
  currentWorkflowName,
  isCreateWorkflowDisabled,
  defaultWorkflowId,
  workflows,
  onCreateUpdateWorkflow,
  onChangeIsDefaultWorkFlow,
  onChangeName,
  onClose,
}: IAddUpdateWorkflowDialog) => (
  <Dialog open={isOpen} onClose={onClose}>
    <Stack
      p={3}
      width={512}
      spacing={1}
      overflow="hidden auto"
      sx={{ backgroundColor: "#fff" }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="body1" fontWeight={500}>
            {currentWorkflowId
              ? t("workflow.adjustWorkflow")
              : t("workflow.addWorkflow")}
          </Typography>
        </Stack>
        <Stack>
          <XMarkIcon
            onClick={onClose}
            width={24}
            height={24}
            stroke="#131E30"
            style={{ cursor: "pointer" }}
          />
        </Stack>
      </Stack>
      <Stack py={2}>
        <Divider
          sx={{
            width: "100%",
            borderColor: "#E5E7EB",
          }}
        />
      </Stack>
      <TextField
        disabled={currentWorkflowId == 0.1}
        placeholder={t("applications.name") as string}
        defaultValue={currentWorkflowName}
        onChange={(e) => onChangeName(e.target.value)}
      />
      <Typography pt={1} variant="body2" fontWeight={500} color="#000">
        {t("workflow.statusOptions")}
      </Typography>
      <CardView
        t={t}
        variant="workflowStatus"
        initialColumns={initialColumns}
      />
      <Stack direction="row" alignItems="center">
        <Checkbox
          disabled={
            currentWorkflowId === defaultWorkflowId || workflows.length < 1
          }
          color="secondary"
          checked={Boolean(isDefaultWorkflow)}
          onChange={onChangeIsDefaultWorkFlow}
        />
        <Typography variant="subtitle2" color="textSecondary">
          {currentWorkflowId === defaultWorkflowId || workflows.length < 1
            ? t("workflow.defaultWorkflow")
            : t("workflow.defaultWorkflowText")}
        </Typography>
      </Stack>
      <Stack pt={2} direction="row" spacing={1.5} justifyContent="flex-end">
        <Button variant={"outlined"} onClick={onClose}>
          {t("button.secondCancel")}
        </Button>
        <Button
          disabled={isCreateWorkflowDisabled}
          variant={"contained"}
          onClick={onCreateUpdateWorkflow}
        >
          {t("button.save")}
        </Button>
      </Stack>
    </Stack>
  </Dialog>
);

export default AddUpdateWorkflowDialog;
