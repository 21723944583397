import * as yup from "yup";
import { TFunction } from "i18next";
import { ICreateNoteFormField } from "../../../../models/Automation";
import { isEmptyHtmlString } from "../../../../utils/singleJob";

export const getCreateNoteFormFields = (t: TFunction) =>
  yup.object().shape({
    message: yup
      .string()
      .transform((htmlString: string) =>
        isEmptyHtmlString(htmlString) ? null : htmlString
      )
      .required(t("register.message") as string),
  });

export const createNoteFormFieldsDefaultValue: ICreateNoteFormField = {
  message: "",
};
