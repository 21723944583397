import React from "react";
import { Stack } from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  StyledSuccessOutlineButton,
  StyledContainedInfoButton,
  StyledContainedSuccessButton,
} from "./style";
import {
  ISnackarContainedButton,
  ISnackbarActionContainer,
  SNACKBAR_ACTION_TYPE,
} from "../../models/Snackbar";

const ContainedButton = ({
  desktop,
  type,
  buttonText,
  onClick,
}: ISnackarContainedButton) => {
  switch (type) {
    case "info":
      return (
        <StyledContainedInfoButton
          desktop={desktop}
          variant="contained"
          onClick={onClick}
        >
          {buttonText}
        </StyledContainedInfoButton>
      );
    case "success":
      return (
        <StyledContainedSuccessButton
          desktop={desktop}
          variant="contained"
          onClick={onClick}
        >
          {buttonText}
        </StyledContainedSuccessButton>
      );
    default:
      return (
        <StyledContainedInfoButton
          desktop={desktop}
          variant="contained"
          onClick={onClick}
        >
          {buttonText}
        </StyledContainedInfoButton>
      );
  }
};

const RenderAction = ({
  desktop,
  type,
  action,
  firstButtonText,
  onClose,
  onFirstButtonClick,
}: ISnackbarActionContainer) => {
  switch (action) {
    case SNACKBAR_ACTION_TYPE.OUTLINE_BUTTON_WITH_CLOSE_ICON:
      return (
        <Stack
          height="100%"
          direction={desktop ? "row" : "column"}
          alignItems="center"
          spacing={1.15}
        >
          <StyledSuccessOutlineButton
            variant="outlined"
            onClick={onFirstButtonClick}
          >
            {firstButtonText}
          </StyledSuccessOutlineButton>
          <XMarkIcon
            width={20}
            height={20}
            onClick={onClose}
            cursor="pointer"
          />
        </Stack>
      );
    case SNACKBAR_ACTION_TYPE.CONTAINED_BUTTON_WITH_CLOSE_ICON:
      return (
        <Stack
          px={desktop ? 1.5 : 0}
          width={desktop ? "auto" : "100%"}
          maxWidth="90vw"
          height="100%"
          direction={desktop ? "row" : "column"}
          alignItems="center"
          justifyContent="center"
          spacing={1.15}
          marginLeft={desktop ? "auto" : "0px !important"}
        >
          <ContainedButton
            buttonText={firstButtonText}
            onClick={onFirstButtonClick}
            type={type}
            desktop={desktop}
            onClose={onClose}
          />
          {desktop && (
            <Stack>
              <XMarkIcon
                width={20}
                height={20}
                onClick={onClose}
                cursor="pointer"
              />
            </Stack>
          )}
        </Stack>
      );
    case SNACKBAR_ACTION_TYPE.CONTAINED_BUTTON_WITHOUT_CLOSE_ICON:
      return (
        <Stack
          width={desktop ? "auto" : "100%"}
          maxWidth="90vw"
          height="100%"
          direction={desktop ? "row" : "column"}
          alignItems="center"
          justifyContent="center"
          spacing={1.15}
        >
          <ContainedButton
            buttonText={firstButtonText}
            onClick={onFirstButtonClick}
            type={type}
            desktop={desktop}
            onClose={onClose}
          />
        </Stack>
      );
    default:
      return (
        <Stack height={desktop ? 80 : "auto"} justifyContent="center">
          {desktop && (
            <XMarkIcon
              width={20}
              height={20}
              onClick={onClose}
              cursor="pointer"
              style={{ marginBottom: 1 }}
            />
          )}
        </Stack>
      );
  }
};

export default RenderAction;
