import React from "react";
import { Stack } from "@mui/material";
import SelectorFilter from "../../../components/filters_new/SelectorFilter";
import SimpleFilter from "../../../components/filters_new/SimpleFilter";
import { IJobListFilterComponent } from "../../../models/JobList";
import { StyledJobsFilterContainer } from "../styles";

const JobListFiltersComponent = ({
  jobFilter,
  seniorityFilter,
  t,
  locations,
  quickSearch,
  companyName,
  isAgency,
  onChange,
  onChangeSimpleFilter,
}: IJobListFilterComponent) => (
  <StyledJobsFilterContainer
    className="quick-search-container"
    direction={"row"}
    spacing={4}
  >
    <Stack direction="row" spacing={3}>
      <SimpleFilter
        value={quickSearch}
        onChange={(value) =>
          onChangeSimpleFilter({ filter: "quickSearch", value })
        }
        placeholder={t("joblist.search_placeholder")}
      />
      {!!isAgency && (
        <SimpleFilter
          value={companyName}
          onChange={(value) =>
            onChangeSimpleFilter({ filter: "companyName", value })
          }
          placeholder={t("joblist.search_by_company_placeholder")}
        />
      )}
    </Stack>
    <Stack width="50%" alignItems="flex-end">
      <Stack
        direction={"row"}
        className={"advanced-filters-container"}
        spacing={4}
        alignItems="flex-end"
      >
        <Stack width="100%">
          <SimpleFilter
            className={"input-filter"}
            value={locations}
            onChange={(value) =>
              onChangeSimpleFilter({ filter: "locations", value })
            }
            placeholder={t("joblist.location_placeholder")}
          />
        </Stack>
        <Stack width="100%">
          <SelectorFilter
            isDisabled={!jobFilter.options.length}
            className={"input-selector-filter"}
            label={t("create_job_second_step.job_type")}
            value={jobFilter.value}
            options={jobFilter.options}
            onChange={(value: string) => {
              onChange({ value, filterType: "jobType" });
            }}
          />
        </Stack>
        <Stack width="100%">
          <SelectorFilter
            isDisabled={!seniorityFilter.options.length}
            className={"input-selector-filter"}
            label={t("joblist.seniority_placeholder")}
            value={seniorityFilter.value}
            options={seniorityFilter.options}
            onChange={(value: string) => {
              onChange({ value, filterType: "seniority" });
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  </StyledJobsFilterContainer>
);

export default JobListFiltersComponent;
