export default {
  black: "#000",
  headlines: "#1D1D21",
  primary: "#0F141B",
  secondaryVariant2: "#4C4C4C",
  secondary: "#6B7280",
  onPrimary: "#CCCCCC",
  white: "#FFF",
  selected: "#5073E8",
  secondaryVariant3: "#5073E8",
  textGradientVIP:
    "linear-gradient(180deg, rgba(79, 56, 0, 0.6) 0%, rgba(156, 129, 46, 0.6) 47.33%, rgba(135, 101, 1, 0.6) 52.15%, rgba(22, 16, 0, 0.6) 88.23%), #1D1D21;",
};
