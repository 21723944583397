import React from "react";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import Carousel from "./Carousel";
import JobDescription from "./JobDescription";
import OtherJobs from "./OtherJobs";
import JobHeader from "./JobHeader";
import { StyledJobPreviewTemplateContainer } from "../styles";
import { formatLogoPrefix, formatLogoUrl } from "../../../utils";
import { getJobPreviewState } from "../../../store_new/selectors/JobPreview";
import { getCurrentUserData } from "../../../store_new/selectors/CurrentUser";
import { getCompanySettingsCompanyState } from "../../../store_new/selectors/Company";

const JobTemplate = ({
  desktop,
  companyColor,
}: {
  readonly desktop: boolean;
  readonly companyColor: string;
}) => {
  const { jobTemplate, totalJobs, jobData } = useSelector(getJobPreviewState);
  const { company } = useSelector(getCompanySettingsCompanyState);
  const mainLogo = jobTemplate?.logo;
  const { isAgency } = useSelector(getCurrentUserData);
  const logoPrefix = formatLogoPrefix();
  const isHeaderPicture =
    jobTemplate?.header_1 || jobTemplate?.header_2 || jobTemplate?.header_3;

  const formattedLogo = isAgency
    ? null
    : mainLogo
      ? formatLogoUrl(mainLogo)
      : null;
  const agencyCustomerName = localStorage.getItem("company-name");
  const customerName = isAgency
    ? agencyCustomerName || ""
    : jobTemplate?.company;

  const formattedCompany = {
    title: customerName,
    about_us: jobData?.company_description || company?.about_us,
    industry: jobData?.company_industry || company?.industry,
    color: companyColor,
    website: jobTemplate?.company_website,
    logo: formattedLogo as string,
    linkedin_url: jobData?.company_linkedin || company?.linkedin,
    xing_url: jobData?.company_xing || company?.xing,
    employees: jobData?.company_employees || company?.employees,
  };

  return (
    <StyledJobPreviewTemplateContainer desktop={desktop} spacing={3}>
      <JobHeader
        desktop={desktop}
        customerName={formattedCompany.title}
        logo={formattedLogo}
      />
      {desktop && (
        <Stack
          mt={
            isHeaderPicture
              ? formattedLogo
                ? "12px !important"
                : "18px !important"
              : isHeaderPicture || formattedLogo
                ? "2px !important"
                : "8px !important"
          }
          pb={isHeaderPicture ? 6 : 4}
        >
          <Carousel logoPrefix={logoPrefix} />
        </Stack>
      )}
      <JobDescription desktop={desktop} companyColor={companyColor} />
      {desktop && (
        <OtherJobs
          desktop={desktop}
          companyColor={companyColor}
          logo={formattedLogo}
          totalJobs={totalJobs}
        />
      )}
    </StyledJobPreviewTemplateContainer>
  );
};

export default JobTemplate;
