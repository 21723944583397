import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LoginComponent from "./component";
import { TLoginFormFields } from "../../models/Auth";
import { logIn } from "../../store_new/reducers/Auth";
import { getAuthLoginState } from "../../store_new/selectors/Auth";
import { formDefaultValues, getFormSettings } from "./config";

const Login_new = ({ desktop }: { readonly desktop: boolean }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const { error, isLoading } = useSelector(getAuthLoginState);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(getFormSettings(t)),
    defaultValues: formDefaultValues,
  });

  useEffect(() => {
    if (error) {
      setError("email", {
        message: t("password_match_message_try_again") as string,
        type: "manual",
      });
      setError("password", {
        message: t("password_match_message_try_again") as string,
        type: "manual",
      });
    }
  }, [error]);

  const onSubmit = (data: TLoginFormFields) => {
    const { email, password } = data;
    dispatch(logIn({ username: email, password }));
  };

  const handleOnResetPassword = () => {
    navigate("/forgot-password");
  };

  const handleOnSignUp = () => {
    navigate("/register/company");
  };

  const handleOnShowHidePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <LoginComponent
      t={t}
      desktop={desktop}
      errors={errors}
      showPassword={showPassword}
      register={register}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      onResetPassword={handleOnResetPassword}
      onShowHidePassword={handleOnShowHidePassword}
      onSignUp={handleOnSignUp}
      onSubmit={onSubmit}
    />
  );
};

export default Login_new;
