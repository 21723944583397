import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TalentPoolFiltersComponent from "./component";
import { setTalentPoolCandidateFilter } from "../../../store_new/reducers/TalentPools";
import { getTalentPoolCandidatesFilters } from "../../../store_new/selectors/Talentpool";
import { ITalentPoolFilterData } from "../../../models/TalentPools";

const TalentPoolFilters = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { quickSearch, location, skill } = useSelector(
    getTalentPoolCandidatesFilters
  );

  const handleOnChangeFilter = ({
    value,
    filterType,
  }: ITalentPoolFilterData) => {
    dispatch(setTalentPoolCandidateFilter({ filterType, value }));
  };

  return (
    <TalentPoolFiltersComponent
      t={t}
      quickSearch={quickSearch}
      location={location}
      skill={skill}
      onChangeFilters={handleOnChangeFilter}
    />
  );
};

export default TalentPoolFilters;
