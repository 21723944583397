import { Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledUploadPictureDialogContainer = styled(Stack)({
  background: "#fff",
  padding: 24,
  borderRadius: 6,
  ".close-icon": {
    width: 24,
    height: 24,
    stroke: "#131E30",
    strokeWidth: 2,
    cursor: "pointer",
  },
  ".divider": {
    margin: "24px 0px",
    borderColor: "#E5E7EB",
  },
  ".slider": {
    height: 6,
    "& .MuiSlider-thumb": {
      width: 16,
      height: 16,
      background: "#fff",
    },
  },
});
