import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { apiArchiveJobs, apiDeleteJobs, apiGetJobs } from "../../api/JobList";
import { IJobListState, JOB_ACTIONS, JOB_STATUSES } from "../../models/JobList";
import { getFilters } from "../../utils/common";
import {
  archiveRestoreJobs,
  archiveRestoreJobsFailed,
  archiveRestoreJobsSuccess,
  deleteJobs,
  deleteJobsFailed,
  deleteJobsSuccess,
  fetchAllActiveJobs,
  fetchAllActiveJobsFailed,
  fetchAllActiveJobsSuccess,
  fetchJobs,
  fetchJobsFailed,
  fetchJobsSuccess,
  setFilterOptions,
} from "../reducers/JobList";
import {
  getCurrentStatusJobListFiltersState,
  getJobListFilteredPaginationState,
  getJobListNavigationState,
} from "../selectors/JobList";

const baseUrl = "/company/jobs";

function* handleOnFetchJobs({ payload }: { payload: JOB_STATUSES }) {
  const { pageSize: pagesize, pageNum: pagenum } = yield select(
    getJobListFilteredPaginationState
  );
  const { jobType, seniority, quickSearch, locations, companyName } =
    yield select(getCurrentStatusJobListFiltersState);
  const url =
    payload === JOB_STATUSES.ACTIVE
      ? `${baseUrl}/list`
      : payload === JOB_STATUSES.ARCHIVED
      ? `${baseUrl}/archived`
      : `/company/drafts/list`;
  const apiPayload = {
    title: quickSearch || undefined,
    company_title: companyName || undefined,
    seniority_id: seniority.value || undefined,
    job_type_id: jobType.value || undefined,
    pagenum,
    pagesize,
    location: locations,
  };

  try {
    const { data } = yield call(apiGetJobs, { url, apiPayload });
    const { jobs: list, totalCount } = data;
    const jobTypeFilterOptions = getFilters({
      list,
      targetField: "contract_type",
      targetValue: "contract_type_id",
    });
    const seniorityFilterOptions = getFilters({
      list,
      targetField: "seniority_level",
      targetValue: "seniority_id",
    });
    yield put(
      setFilterOptions({ filter: "jobType", options: jobTypeFilterOptions })
    );
    yield put(
      setFilterOptions({
        filter: "seniority",
        options: seniorityFilterOptions,
      })
    );
    yield put(fetchJobsSuccess({ list, totalCount }));
  } catch (e: unknown) {
    yield put(fetchJobsFailed(e)); // TODO handle error
  }
}

function* handleOnFetchAllActiveJobs() {
  const url = `${baseUrl}/list`;
  const apiPayload = {
    pagesize: -1,
  };
  try {
    const { data } = yield call(apiGetJobs, { url, apiPayload });
    const { jobs } = data;
    yield put(fetchAllActiveJobsSuccess(jobs));
  } catch (e: unknown) {
    yield put(fetchAllActiveJobsFailed(e)); // TODO handle error
  }
}

function* handleOnArchiveRestoreJobs({
  payload,
}: {
  payload: { jobUrlKeys: string[]; action: JOB_ACTIONS; callback: () => void };
}) {
  const { jobUrlKeys, action, callback } = payload;
  const apiCalls = jobUrlKeys.map((jobUrlKey) =>
    call(apiArchiveJobs, { url: `${baseUrl}/${action}/${jobUrlKey}` })
  );
  try {
    yield all(apiCalls);
    yield put(archiveRestoreJobsSuccess());
    yield call(callback);
  } catch (e: unknown) {
    yield put(archiveRestoreJobsFailed(e)); // TODO handle error
  }
}

function* handleOnDeleteJobs({ payload }: { payload: { jobUrlKey: string } }) {
  const { jobUrlKey } = payload;
  const { selectedJobStatus }: IJobListState["navigation"] = yield select(
    getJobListNavigationState
  );
  try {
    yield call(apiDeleteJobs, { jobUrlKey });
    yield put(deleteJobsSuccess());
    yield put(fetchJobs(selectedJobStatus)); // dispatch fetchUser with current selected user statuses
  } catch (e: unknown) {
    yield put(deleteJobsFailed(e)); // TODO handle error
  }
}

function* JobListSage() {
  yield takeLatest(fetchJobs, handleOnFetchJobs);
  yield takeLatest(archiveRestoreJobs, handleOnArchiveRestoreJobs);
  yield takeLatest(deleteJobs, handleOnDeleteJobs);
  yield takeLatest(fetchAllActiveJobs, handleOnFetchAllActiveJobs);
}

export default JobListSage;
