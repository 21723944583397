import React from "react";
import { Divider, MenuItem, Stack, Typography } from "@mui/material";
import { IStatusMenuComponent } from "../../models/Kanban";
import { StyledStatusMenu } from "./style";

const StatusMenu = ({
  t,
  anchorEl,
  items,
  talentPoolMenuOpen,
  isAgency,
  currentApplication,
  onTalentPoolMenuClick,
  handleMoveAll,
  closeStatusMenu,
}: IStatusMenuComponent) => (
  <StyledStatusMenu
    anchorEl={anchorEl}
    currentApplication={currentApplication}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={closeStatusMenu}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
  >
    {items.map((item: any, index: number) => (
      <MenuItem key={index} onClick={() => handleMoveAll(item.label)}>
        <Typography variant="subtitle2">{item.label}</Typography>
      </MenuItem>
    ))}
    {!isAgency && (
      <Stack
        position="sticky"
        bottom={0}
        zIndex={2}
        sx={{ background: "#fff" }}
      >
        <Divider />
        <MenuItem
          aria-controls={talentPoolMenuOpen ? "actions-menu" : undefined}
          onClick={onTalentPoolMenuClick}
        >
          {t("talentPools.addToTalentPool")}
        </MenuItem>
      </Stack>
    )}
  </StyledStatusMenu>
);

export default StatusMenu;
