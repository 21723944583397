import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { INavigationState } from "../../models/Navigation";

const initialState: INavigationState = {
  open: true,
  expandShop: false,
  expandSettings: false,
  mobileDrawerOpen: undefined,
};

const reducers = {
  setOpen(state: Draft<INavigationState>, action: PayloadAction<boolean>) {
    state.open = action.payload;
  },
  setExpandShop(
    state: Draft<INavigationState>,
    action: PayloadAction<boolean>
  ) {
    state.expandShop = action.payload;
  },
  setExpandSettings(
    state: Draft<INavigationState>,
    action: PayloadAction<boolean>
  ) {
    state.expandSettings = action.payload;
  },
  setMobileDrawerOpen(
    state: Draft<INavigationState>,
    action: PayloadAction<boolean | undefined>
  ) {
    state.mobileDrawerOpen = action.payload;
  },
};

export const NavigationSlice = createSlice({
  name: "Navigation",
  initialState,
  reducers: {
    ...reducers,
  },
});

export const {
  setOpen,
  setExpandShop,
  setExpandSettings,
  setMobileDrawerOpen,
} = NavigationSlice.actions;

export default NavigationSlice.reducer;
