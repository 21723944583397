import React, { useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  BanknotesIcon,
  BriefcaseIcon,
  BuildingOffice2Icon,
  ChevronRightIcon,
  FolderOpenIcon,
} from "@heroicons/react/24/outline";
import JobLocations from "../../components/JobLocations";
import { TCompany, TJob } from "../../models/CompanyInfo";
import { StyledJobContainer } from "./style";
import { openJobInNewTab } from "../../utils";
import { useTranslation } from "react-i18next";
import { convertSalaryString, getSalarySymbol } from "../../utils/common";

const Job = ({
  desktop,
  job,
  company,
  jobWidgetPage = false,
}: {
  readonly desktop: boolean;
  readonly job: TJob;
  readonly company: TCompany;
  readonly jobWidgetPage?: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isJobHovered, setIsJobHovered] = useState<boolean>(false);
  const handleOpenJob = () => {
    if (jobWidgetPage) {
      openJobInNewTab(job.url_key);
    } else {
      navigate(`/job/${job.url_key}`);
    }
  };

  const { minSalary, maxSalary } = useMemo(
    () => ({
      minSalary: convertSalaryString(job?.min_salary),
      maxSalary: convertSalaryString(job?.max_salary),
    }),
    [job?.max_salary, job?.min_salary]
  );

  return (
    <StyledJobContainer
      isJobHovered={isJobHovered}
      companyColor={company.color}
      onMouseOver={() => setIsJobHovered(true)}
      onMouseLeave={() => setIsJobHovered(false)}
      onClick={handleOpenJob}
    >
      <Stack className="job" direction="row">
        {desktop && (
          <Stack>
            {company?.logo ? (
              <img src={company?.logo} alt="logo" className="company-logo" />
            ) : (
              <BuildingOffice2Icon className="avatar" />
            )}
          </Stack>
        )}
        <Stack paddingLeft={1.5} width="90%">
          <Typography variant="h6">{job.title}</Typography>
          <Stack
            direction={desktop ? "row" : "column"}
            mt={1}
            sx={{ flexWrap: "wrap", gap: desktop ? "12px" : "8px" }}
          >
            <Stack maxWidth="55%" direction={"row"}>
              <JobLocations
                desktop={desktop}
                jobLocations={job?.locations}
                companyColor={company?.color}
              />
            </Stack>
            <Stack direction="row" gap={1} alignItems="center">
              <BriefcaseIcon className="icon" />
              <Typography
                variant="subtitle2"
                display="inline"
                color="textSecondary"
              >
                {job.position_type}
              </Typography>
            </Stack>
            <Stack direction="row" gap={1} alignItems="center">
              <FolderOpenIcon className="icon" />
              <Typography
                variant="subtitle2"
                display="inline"
                color="textSecondary"
              >
                {job.field_of_work}
              </Typography>
            </Stack>
            {minSalary && maxSalary && (
              <Stack direction="row" gap={1} alignItems="center">
                <BanknotesIcon className="icon" />
                <Typography
                  variant="subtitle2"
                  display="inline"
                  color="textSecondary"
                >
                  {t("jobPreview.salary", {
                    currency: getSalarySymbol(job?.salary_currency || ""),
                    min: minSalary,
                    max: maxSalary,
                  })}

                  {job?.salary_type && (
                    <>
                      <span> / </span>
                      {t(
                        `singleJob.jobDetails.salary.type.${job?.salary_type}`
                      )}
                    </>
                  )}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
        {desktop && (
          <Stack alignSelf="center">
            <ChevronRightIcon className="arrow-icon" />
          </Stack>
        )}
      </Stack>
    </StyledJobContainer>
  );
};

export default Job;
