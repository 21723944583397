import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  apiCreateJob,
  apiExtendLimit,
  apiFetchJobData,
  apiGetDraftData,
  apiGetPackagesPrice,
  apiPublishProducts,
  apiSaveTemplate,
  apiUpdateJob,
} from "../../api/JobEditor";
import { PayloadActionWithCallback, TListOption } from "../../models/common";
import {
  TJobDescriptionFormFields,
  TJobDescriptionFormFieldsPayload,
  TJobDetailsUpdateJobPayload,
} from "../../models/JobEditor";
import {
  TJobQuestions,
  TPremiumPackage,
  TPremiumProductItem,
  TSalesProduct,
} from "../../models/SingleJob";
import { getCurrencyValues } from "../../utils/common";
import {
  createJob,
  createJobFailed,
  createJobSuccess,
  fetchJobData,
  fetchJobDataFailed,
  fetchJobDataSuccess,
  previewJobTemplate,
  previewJobTemplateFailed,
  previewJobTemplateSuccess,
  publishProducts,
  publishProductsFailed,
  publishProductsSuccess,
  saveDraft,
  saveDraftFailed,
  saveDraftSuccess,
  setAgencyPackagePrice,
  setAgencyPackagePriceFailed,
  setAgencyPackagePriceSuccess,
  updateDraftSuccess,
  updateDraftFailed,
  updateJob,
  updateJobFailed,
  updateJobSuccess,
  updateDraft,
  fetchDraftDataSuccess,
  fetchDraftData,
  fetchDraftDataDataFailed,
} from "../reducers/JobEditor";
import { fetchProductsAndPackages } from "../reducers/SingleJob";
import { getJobEditorState } from "../selectors/JobEditor";
import { getSingleJobState } from "../selectors/SingleJob";
import { getCurrentUserData } from "../selectors/CurrentUser";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  extendLimit,
  extendLimitFailed,
  extendLimitSuccess,
} from "../reducers/dialogs";
import { getCompanySettingsCompanyState } from "../selectors/Company";

function* handleOnUpdateJob({
  payload,
}: PayloadActionWithCallback<{
  url_key: string;
  apiPayload: TJobDetailsUpdateJobPayload;
}>) {
  const { url_key, apiPayload, callback } = payload;

  const { jobDetails } = yield select(getSingleJobState);
  const { agencyId, isAgency } = yield select(getCurrentUserData);
  const url = `/company/jobs/save/${url_key}`;

  const formattedApiPayload = {
    ...apiPayload,
    company_id: isAgency ? jobDetails.company_id : undefined,
    agency_admin_access: agencyId ? true : undefined,
  };

  try {
    yield call(apiUpdateJob, { url, apiPayload: formattedApiPayload });
    yield put(updateJobSuccess({ url_key }));
    yield call(callback, url_key);
  } catch (e: unknown) {
    yield put(updateJobFailed(e)); // TODO handle error
  }
}

function* handleOnSaveDraft({
  payload,
}: PayloadActionWithCallback<{
  formValues: any;
}>) {
  const { formValues, callback } = payload;

  const { companyId, jobDetails } = yield select(getJobEditorState);
  const { isAgency } = yield select(getCurrentUserData);

  const job_owners = Array.isArray(jobDetails.assign_job_to)
    ? jobDetails.assign_job_to.map((item: TListOption) => item.value)
    : undefined;

  const apiPayload = {
    ...formValues,
    job_owners,
    agency_admin_access: isAgency ? undefined : true,
    company_id: companyId,
  };

  const url = `/company/drafts/save`;

  try {
    yield call(apiUpdateJob, { url, apiPayload });
    yield put(saveDraftSuccess());
    yield call(callback);
  } catch (e: unknown) {
    yield put(saveDraftFailed(e)); // TODO handle error
  }
}

function* handleOnUpdateDraft({
  payload,
}: PayloadActionWithCallback<{
  formValues: any;
  jobUrlKey: string;
}>) {
  const { jobUrlKey, formValues, callback } = payload;

  const { companyId, jobDetails } = yield select(getJobEditorState);
  const { isAgency } = yield select(getCurrentUserData);

  const job_owners = Array.isArray(jobDetails.assign_job_to)
    ? jobDetails.assign_job_to.map((item: TListOption) => item.value)
    : undefined;

  const apiPayload = {
    ...formValues,
    job_owners,
    agency_admin_access: isAgency ? undefined : true,
    company_id: companyId,
  };

  const url = `/company/drafts/save/${jobUrlKey}`;

  try {
    yield call(apiUpdateJob, { url, apiPayload });
    yield put(updateDraftSuccess());
    yield call(callback);
  } catch (e: unknown) {
    yield put(updateDraftFailed(e)); // TODO handle error
  }
}

function* handleOnGetDraftData({
  payload,
}: PayloadActionWithCallback<{
  jobUrlKey: string;
}>) {
  const { jobUrlKey, callback } = payload;

  const url = `/company/drafts/update/${jobUrlKey}`;

  try {
    const { data } = yield call(apiGetDraftData, { url });
    yield put(fetchDraftDataSuccess(data));
    yield call(callback);
  } catch (e: unknown) {
    yield put(fetchDraftDataDataFailed(e)); // TODO handle error
  }
}

function* handleOnFetchJobData({
  payload,
}: PayloadActionWithCallback<undefined>) {
  const { callback } = payload;
  const url = `/company/jobs/create`;
  try {
    const { data } = yield call(apiFetchJobData, { url });
    const countries: TListOption[] = Object.keys(data.countries).map(
      (countryKey) => ({
        label: data.countries[countryKey],
        value: countryKey,
      }),
    );
    const contractTypes: TListOption[] = data.contractTypes.map(
      ({ id, title }: { id: string; title: string }) => ({
        value: id,
        label: title,
      }),
    );
    const seniorities: TListOption[] = data.seniorities.map(
      ({ id, title }: { id: string; title: string }) => ({
        value: id,
        label: title,
      }),
    );
    const qualificationsList: TListOption[] = data.qualificationsList.map(
      ({ id, title }: { id: string; title: string }) => ({
        value: id,
        label: title,
      }),
    );
    const workFields: TListOption[] = data.workFields.map(
      ({ id, title }: { id: string; title: string }) => ({
        value: id,
        label: title,
      }),
    );
    const positionTypes: TListOption[] = data.positionTypes.map(
      ({ id, title }: { id: string; title: string }) => ({
        value: id,
        label: title,
      }),
    );
    const industriesList: TListOption[] = data.industriesList.map(
      ({ id, title }: { id: string; title: string }) => ({
        value: id,
        label: title,
      }),
    );
    const salaryTypes = data.salaryTypes.map(
      (item: { const: string; title: string }) => ({
        value: item.const,
        label: item.title,
      }),
    );
    const salaryCurrencies = data.salaryCurrencies
      .map(({ code }: { code: string }) => ({ ...getCurrencyValues(code) }))
      .map(({ code, title }: { code: string; title: string }) => ({
        value: code,
        label: title,
      }));
    const companyMembers = data.companyMembers.map(
      ({
        firstname,
        lastname,
        id,
      }: {
        firstname: string;
        lastname: string;
        id: string;
      }) => ({
        label: `${firstname} ${lastname}`,
        value: id,
      }),
    );
    const jobTypes = data.jobTypes
      .slice()
      .sort((a: { id: number }, b: { id: number }) => a.id - b.id);

    yield put(
      fetchJobDataSuccess({
        ...data,
        contractTypes,
        seniorities,
        qualificationsList,
        salaryTypes,
        countries,
        workFields,
        positionTypes,
        industriesList,
        salaryCurrencies,
        companyMembers,
        jobTypes,
      }),
    );
    yield call(callback);
  } catch (e: unknown) {
    yield put(fetchJobDataFailed(e)); // TODO handle error
  }
}

function* handleOnPreviewJobTemplate({
  payload,
}: PayloadActionWithCallback<{ formValues: TJobDescriptionFormFields }>) {
  const { formValues, callback } = payload;
  const formatedFormValues = {
    ...formValues,
    title_color: formValues.company_color,
  };
  const url = "/company/templates/save";
  const apiPayload = Object.keys(formatedFormValues).reduce(
    (acc, objKey: string) => ({
      ...acc,
      [`template_${objKey}`]:
        formatedFormValues[objKey as keyof TJobDescriptionFormFields],
    }),
    {},
  ) as TJobDescriptionFormFieldsPayload;

  try {
    const { data } = yield call(apiSaveTemplate, { url, apiPayload });

    yield call(callback, data.template_id);
    yield put(previewJobTemplateSuccess());
  } catch (e) {
    yield put(previewJobTemplateFailed(e));
  }
}

function* handleOnCreateJob({ payload }: PayloadActionWithCallback<undefined>) {
  const { callback } = payload;

  const { jobDetails, jobDescription, jobApplicationProcess, companyId } =
    yield select(getJobEditorState);
  const { isAgency } = yield select(getCurrentUserData);
  const { company } = yield select(getCompanySettingsCompanyState);

  const convertedJobDescription = Object.keys(jobDescription).reduce(
    (acc, objKey: string) => ({
      ...acc,
      [`template_${objKey}`]:
        jobDescription[objKey as keyof TJobDescriptionFormFields],
    }),
    {},
  ) as TJobDescriptionFormFieldsPayload;

  const job_owners = Array.isArray(jobDetails.assign_job_to)
    ? jobDetails.assign_job_to.map((item: TListOption) => item.value)
    : undefined;

  const questions = Array.isArray(jobApplicationProcess.questions)
    ? jobApplicationProcess.questions.map((qItem: TJobQuestions) => ({
        question: qItem.question,
        answer: qItem.is_answer_type_yesno,
      }))
    : undefined;

  const apiPayload = {
    ...jobDetails,
    ...convertedJobDescription,
    ...jobApplicationProcess,
    title: jobDescription.job_title,
    handle: jobDetails.reference_number,
    profile_photo_required: 1,
    cv_required: 1,
    questions,
    job_owners,
    agency_admin_access: isAgency ? undefined : true,
    company_id: isAgency ? companyId : undefined,
    workflow: company.default_workflow_id,
  };
  const url = "/company/jobs/save";

  try {
    const { data } = yield call(apiCreateJob, { url, apiPayload });
    yield put(fetchProductsAndPackages({ urlKey: data.urlKey }));
    yield put(createJobSuccess({ url_key: data.urlKey }));
    yield call(callback);
  } catch (e) {
    yield put(createJobFailed(e));
  }
}

function* handleOnPublishProducts({
  payload,
}: PayloadActionWithCallback<undefined>) {
  const { callback } = payload;
  const { isAgency } = yield select(getCurrentUserData);
  const { url_key, packagesTotalPrice } = yield select(getJobEditorState);
  const { jobDetails, selectedProducts } = yield select(getSingleJobState);
  const urlKey = url_key || jobDetails.url_key;

  const packagesURL = "company/packages/prices";
  const productsURL = `/company/jobs/publish/${urlKey}`;

  const products = selectedProducts?.filter(
    (item: TPremiumPackage) => !item?.sales_products,
  );
  const packages = selectedProducts?.filter(
    (item: TPremiumPackage) => item.sales_products,
  );

  try {
    if (packages.length) {
      for (const element of packages) {
        const product_ids = element.sales_products.map(
          (salesProduct: TSalesProduct) => salesProduct.id,
        );
        const apiPayload = { product_ids };

        const { data } = yield apiGetPackagesPrice({
          url: packagesURL,
          apiPayload,
        });

        const publishProductPayload = {
          sales_products: product_ids,
          sales_products_selected_price_input: data.package_price,
        };

        yield call(apiPublishProducts, {
          url: productsURL,
          apiPayload: publishProductPayload,
        });
      }
    }

    if (products.length) {
      const price = products.reduce(
        (acc: number, item: TPremiumProductItem) => {
          if (item.agency_price) {
            return acc + item.agency_price;
          }
          return acc + item.retail_price;
        },
        0,
      );
      const finalPrice = isAgency ? packagesTotalPrice : price;
      const apiPayload = {
        sales_products: products.map((item: TPremiumPackage) => item.id),
        sales_products_selected_price_input: finalPrice,
      };
      yield call(apiPublishProducts, { url: productsURL, apiPayload });
    }

    yield put(publishProductsSuccess());
    yield call(callback);
  } catch (e) {
    yield put(publishProductsFailed(e));
  }
}

function* handleOnSetPackagePrice({ payload }: PayloadAction<any>) {
  const packagesURL = "company/packages/prices";
  try {
    const { data } = yield apiGetPackagesPrice({
      url: packagesURL,
      apiPayload: payload,
    });
    yield put(
      setAgencyPackagePriceSuccess({ packagesTotalPrice: data.package_price }),
    );
  } catch (e) {
    yield put(setAgencyPackagePriceFailed(e));
  }
}

function* handleOnExtendLimit({ payload }: PayloadActionWithCallback<unknown>) {
  const url = "/company/profile/request-pro";
  const { callback } = payload;
  try {
    yield apiExtendLimit({
      url,
    });
    yield put(extendLimitSuccess());
    yield call(callback);
  } catch (_error) {
    yield put(extendLimitFailed());
  }
}

function* JobListSage() {
  yield takeLatest(updateJob, handleOnUpdateJob);
  yield takeLatest(fetchJobData, handleOnFetchJobData);
  yield takeLatest(previewJobTemplate, handleOnPreviewJobTemplate);
  yield takeLatest(createJob, handleOnCreateJob);
  yield takeLatest(publishProducts, handleOnPublishProducts);
  yield takeLatest(setAgencyPackagePrice, handleOnSetPackagePrice);
  yield takeLatest(saveDraft, handleOnSaveDraft);
  yield takeLatest(updateDraft, handleOnUpdateDraft);
  yield takeLatest(fetchDraftData, handleOnGetDraftData);
  yield takeLatest(extendLimit, handleOnExtendLimit);
}

export default JobListSage;
