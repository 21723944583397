import React from "react";
import { Divider, Stack, Typography } from "@mui/material";
import StyledSectionTitle from "../../../../components/SectionTitle";
import {
  IJobAdditionalQuestionsPreviewComponent,
  TJobQuestions,
} from "../../../../models/SingleJob";
import { StyledSectionContainer } from "./styles";

const JobAdditionalQuestionsPreview = ({
  t,
  jobQuestions,
  onEdit,
}: IJobAdditionalQuestionsPreviewComponent) => (
  <StyledSectionContainer spacing={2}>
    <StyledSectionTitle
      title={t("singleJob.jobDetails.titles.additionalQuestions")}
      onClick={onEdit}
    />
    <Divider />
    {jobQuestions && jobQuestions?.length > 0 ? (
      jobQuestions?.map((item: TJobQuestions, index: number) => (
        <Stack key={index} direction="row" justifyContent="space-between">
          <Typography variant="subtitle2" color={"#6B7280"}>
            {item.question}
          </Typography>
          <Stack direction="row" spacing={12}>
            <Typography variant="subtitle2" color={"#6B7280"}>
              {t("create_job_third_step.answer_type")}
            </Typography>
            <Typography variant="subtitle2" color={"#6B7280"} width={80}>
              {item.is_answer_type_yesno
                ? t("create_job_third_step.yes_no")
                : t("create_job_third_step.free_text")}
            </Typography>
          </Stack>
        </Stack>
      ))
    ) : (
      <Stack pt={2} alignItems="center" justifyContent="center">
        <Typography variant="subtitle2" color="textSecondary">
          {t("singleJob.jobDetails.titles.noAdditionalQuestionsTitle")}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {t("singleJob.jobDetails.titles.noAdditionalQuestionsSubtitle")}
        </Typography>
      </Stack>
    )}
  </StyledSectionContainer>
);

export default JobAdditionalQuestionsPreview;
