import React, { useEffect } from "react";
import { Stack, Divider, Typography } from "@mui/material";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import { TStepsHeader } from "../../models/JobEditor";
import { fetchJobData } from "../../store_new/reducers/JobEditor";
import { getJobEditorJobDataState } from "../../store_new/selectors/JobEditor";

export const ActiveStep = () => (
  <Stack spacing={1}>
    <Stack
      width={36}
      height={36}
      borderRadius="50%"
      border="2px solid #818CF8"
      alignItems="center"
      justifyContent="center"
    >
      <Stack
        width={12}
        height={12}
        borderRadius="50%"
        sx={{
          backgroundColor: "#818CF8",
        }}
      ></Stack>
    </Stack>
  </Stack>
);

export const Step = () => (
  <Stack spacing={1}>
    <Stack
      width={36}
      height={36}
      borderRadius="50%"
      border="2px solid #E5E7EB"
      alignItems="center"
      justifyContent="center"
    ></Stack>
  </Stack>
);

export const CompletedStep = () => (
  <Stack spacing={1}>
    <Stack
      width={36}
      height={36}
      borderRadius="50%"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: "#818CF8",
      }}
    >
      <CheckIcon width={24} height={24} stroke="#F9FAFB" />
    </Stack>
  </Stack>
);

export const Header = ({
  t,
  stepsText = [
    t("create_job_first_step.describe_job"),
    t("create_job_first_step.specify_job"),
    t("create_job_first_step.application_process"),
  ],
  stepsStatus = ["inactive", "inactive", "inactive"],
}: TStepsHeader) => {
  const dispatch = useDispatch();
  const jobData = useSelector(getJobEditorJobDataState);

  useEffect(() => {
    if (Object.keys(jobData).length < 1) {
      dispatch(
        fetchJobData({
          callback: () => {},
        })
      );
    }
  }, []);

  return (
    <Stack mt={7} px="10%" spacing={1}>
      <Stack direction="row" width="92%" justifyContent="space-between">
        <Stack spacing={1} justifyContent="center" alignItems="center">
          <Stack direction="row" width="100%" justifyContent="center">
            <Stack width="100%">
              <Divider />
            </Stack>
            {stepsStatus[0] === "active" ? (
              <ActiveStep />
            ) : stepsStatus[0] === "completed" ? (
              <CompletedStep />
            ) : (
              <Step />
            )}
            <Stack width="100%">
              <Divider />
            </Stack>
          </Stack>
          <Typography variant="subtitle2" noWrap>
            {stepsText[0]}
          </Typography>
        </Stack>
        <Stack width="100%">
          <Divider />
        </Stack>
        <Stack spacing={1} justifyContent="center" alignItems="center">
          <Stack direction="row" width="100%" justifyContent="center">
            <Stack width="100%">
              <Divider />
            </Stack>
            {stepsStatus[1] === "active" ? (
              <ActiveStep />
            ) : stepsStatus[1] === "completed" ? (
              <CompletedStep />
            ) : (
              <Step />
            )}
            <Stack width="100%">
              <Divider />
            </Stack>
          </Stack>
          <Typography variant="subtitle2" noWrap>
            {stepsText[1]}
          </Typography>
        </Stack>
        <Stack width="100%">
          <Divider />
        </Stack>
        <Stack spacing={1} justifyContent="center" alignItems="center">
          <Stack direction="row" width="100%" justifyContent="center">
            <Stack width="100%">
              <Divider />
            </Stack>
            {stepsStatus[2] === "active" ? (
              <ActiveStep />
            ) : stepsStatus[2] === "completed" ? (
              <CompletedStep />
            ) : (
              <Step />
            )}
          </Stack>
          <Stack width="100%" position="relative" left="30%">
            <Typography variant="subtitle2" noWrap>
              {stepsText[2]}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
