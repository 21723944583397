import { apiRequest } from "./config";
import { TJobActionRegisterFormFields } from "../models/JobPreview";

export const apiFetchJob = ({ jobUrlKey }: { readonly jobUrlKey: string }) =>
  apiRequest({ type: "post", url: `/jobs/${jobUrlKey}` });

export const apiApplyToJob = ({
  jobUrlKey,
  apiPayload,
}: {
  jobUrlKey: string;
  apiPayload: TJobActionRegisterFormFields & { files?: FileList | null };
}) => apiRequest({ type: "post", url: `/apply/${jobUrlKey}`, apiPayload });

export const apiCheckCandidateExist = ({
  url,
  apiPayload,
}: {
  readonly url: string;
  readonly apiPayload: {
    readonly email: string;
  };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiSendMagicLink = ({
  url,
  apiPayload,
}: {
  readonly url: string;
  readonly apiPayload: {
    readonly email: string;
  };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiGetCities = ({ city }: { city: string }) => {
  const language = localStorage.getItem("lang");
  const localeLanguage = language === "en_US" ? "en" : "de";
  return apiRequest({
    type: "get",
    url: `https://photon.komoot.io/api/?q=${city}&lang=${localeLanguage}`,
  });
};

export const apiUpdateCandidateDocuments = ({
  files,
  jobUrlKey,
}: {
  files: (File | undefined)[];
  jobUrlKey: string;
}) =>
  apiRequest({
    type: "post",
    url: `/candidate/applications/upload-documents/${jobUrlKey}`,
    apiPayload: {
      "files[]": files,
    },
  });

export const apiUpdateCandidatePhoto = ({ file }: { file: File | undefined }) =>
  apiRequest({
    type: "post",
    url: `/candidate/upload-photo`,
    apiPayload: {
      photo: file,
    },
  });

export const apiSubmitAdditionalQuestions = ({
  apiPayload,
  jobUrlKey,
}: {
  apiPayload: any;
  jobUrlKey: string;
}) =>
  apiRequest({
    type: "post",
    url: `/candidate/applications/complete/${jobUrlKey}`,
    apiPayload,
  });

export const apiFetchTemplatePreview = ({ url }: { url: string }) =>
  apiRequest({
    type: "post",
    url: url,
  });
