import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import {
  ITeamsState,
  TEAM_USER_STATUSES,
  TTeamsInvitation,
  TTeamsMember,
} from "../../models/Team";
import { PayloadActionWithCallback } from "../../models/common";

const initialState: ITeamsState = {
  filters: {
    quickSearch: "",
  },
  navigation: {
    userStatuses: [TEAM_USER_STATUSES.JOINED, TEAM_USER_STATUSES.PENDING],
    selectedUserStatus: TEAM_USER_STATUSES.JOINED,
  },
  pagination: {
    joined: {
      pageNum: 1,
      pageSize: 10,
    },
    pending: {
      pageNum: 1,
      pageSize: 10,
    },
  },
  users: {
    list: [],
    totalCount: 0,
  },
  error: false,
  isLoading: false,
  isListLoading: false,
};

const filtersReducer = {
  setQuickSearch: (
    state: Draft<ITeamsState>,
    action: PayloadAction<string>,
  ) => {
    state.filters.quickSearch = action.payload;
  },
};

const navigationReducer = {
  setSelectedUserStatus: (
    state: Draft<ITeamsState>,
    action: PayloadAction<TEAM_USER_STATUSES>,
  ) => {
    state.navigation.selectedUserStatus = action.payload;
  },
};

const paginationReducer = {
  setPageNum: (state: Draft<ITeamsState>, action: PayloadAction<number>) => {
    if (state.navigation.selectedUserStatus === TEAM_USER_STATUSES.JOINED)
      state.pagination.joined.pageNum = action.payload;
    else state.pagination.pending.pageNum = action.payload;
  },
  setJoinedPaginationSize: (
    state: Draft<ITeamsState>,
    action: PayloadAction<number>,
  ) => {
    state.pagination.joined.pageSize = action.payload;
  },
};

const userReducer = {
  fetchUsers: (
    state: Draft<ITeamsState>,
    _: PayloadAction<TEAM_USER_STATUSES>,
  ) => {
    state.isListLoading = true;
  },
  fetchUsersSuccess: (
    state: Draft<ITeamsState>,
    action: PayloadAction<{
      list: (TTeamsInvitation & TTeamsMember)[];
      totalCount: number;
    }>,
  ) => {
    state.users.list = action.payload.list;
    state.users.totalCount = action.payload.totalCount;
    state.isListLoading = false;
  },
  fetchUsersFailed: (
    state: Draft<ITeamsState>,
    action: PayloadAction<unknown>,
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isListLoading = false;
  },
  removeUser: (
    state: Draft<ITeamsState>,
    _: PayloadAction<TTeamsInvitation & TTeamsMember>,
  ) => {
    state.isLoading = true;
  },
  removeUserSuccess: (state: Draft<ITeamsState>) => {
    state.isLoading = false;
  },
  removeUserFailed: (
    state: Draft<ITeamsState>,
    action: PayloadAction<unknown>,
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isLoading = false;
  },
  inviteUsers: (
    state: Draft<ITeamsState>,
    _: PayloadActionWithCallback<{ emails: string[] }>,
  ) => {
    state.isLoading = true;
  },
  inviteUsersSuccess: (state: Draft<ITeamsState>) => {
    state.isLoading = false;
  },
  inviteUsersFailed: (
    state: Draft<ITeamsState>,
    action: PayloadAction<unknown>, // TODO define type of error and handle error
  ) => {
    state.error = action.payload;
    state.isLoading = false;
  },
};

export const TeamSlice = createSlice({
  name: "TEAM",
  initialState,
  reducers: {
    ...filtersReducer,
    ...navigationReducer,
    ...paginationReducer,
    ...userReducer,
  },
});

export const {
  setQuickSearch,
  setSelectedUserStatus,
  setPageNum,
  fetchUsers,
  fetchUsersSuccess,
  fetchUsersFailed,
  removeUser,
  removeUserSuccess,
  removeUserFailed,
  inviteUsers,
  inviteUsersSuccess,
  inviteUsersFailed,
  setJoinedPaginationSize,
} = TeamSlice.actions;

export default TeamSlice.reducer;
