import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AccordionDetails,
  AccordionSummary,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  StyledCandidateNoteCommentAccordion,
  StyledCandidateNoteListItem,
  StyledCandidateNoteListItemPrimaryText,
  StyledCandidateNoteListItemSecondaryText,
} from "../../styles";
import { IApplicationNotesCommentsComponent } from "../../../../models/Applications";

const CandidateNoteCommentListComponent = ({
  t,
  comments,
  isExpanded,
  onToggle,
}: IApplicationNotesCommentsComponent) => (
  <StyledCandidateNoteCommentAccordion
    expanded={isExpanded}
    onChange={onToggle}
    disableGutters
    elevation={0}
  >
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>
        {isExpanded ? t("collapseAll") : `${comments.length} ${t("comments")}`}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      {comments.map((comment) => (
        <StyledCandidateNoteListItem key={comment.timestamp.toString()}>
          <ListItemAvatar>
            <Avatar alt={comment.firstname} src={comment.avatar}>
              {comment.firstname[0] + comment.lastname[0]}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <StyledCandidateNoteListItemPrimaryText>
                {comment.firstname} {comment.lastname}
              </StyledCandidateNoteListItemPrimaryText>
            }
            secondary={
              <StyledCandidateNoteListItemSecondaryText>
                {comment.comment}
              </StyledCandidateNoteListItemSecondaryText>
            }
          />
        </StyledCandidateNoteListItem>
      ))}
    </AccordionDetails>
  </StyledCandidateNoteCommentAccordion>
);

export default CandidateNoteCommentListComponent;
