import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import {
  TApiGetMessages,
  TApiPostMessage,
  TApiUpdateMessage,
  TApiDeleteMessage,
  TApiCompanyReadUnreadMessage,
  TApiCandidateReadUnreadMessage,
  IMessagesState,
  TLatestMessage,
  TMessagesInfo,
  TMessages,
} from "../../models/Messages";

const initialState: IMessagesState = {
  isLoading: false,
  error: false,
  messages: [],
  latestMessages: undefined,
  jobId: null,
  recruiterId: null,
  applicationId: null,
  messagesInfo: null,
};

const messagesReducers = {
  setActiveUser: (
    state: Draft<IMessagesState>,
    action: PayloadAction<TApiGetMessages>
  ) => {
    state.jobId = action.payload.job_id;
    state.applicationId = action.payload.application_id;
    state.recruiterId =
      action.payload.recruiter_id || action.payload.candidate_id;
  },
  resetMessageState: (state: Draft<IMessagesState>) => {
    state.recruiterId = initialState.recruiterId;
    state.jobId = initialState.jobId;
    state.applicationId = initialState.applicationId;
    state.latestMessages = initialState.latestMessages;
    state.messages = initialState.messages;
    state.messagesInfo = initialState.messagesInfo;
  },
  resetCurrentMessageState: (state: Draft<IMessagesState>) => {
    state.recruiterId = initialState.recruiterId;
    state.jobId = initialState.jobId;
    state.applicationId = initialState.applicationId;
    state.messages = initialState.messages;
    state.messagesInfo = initialState.messagesInfo;
  },
  getLatestMessages: (state: Draft<IMessagesState>) => {
    state.isLoading = true;
  },
  getLatestMessagesFailed: (state: Draft<IMessagesState>) => {
    state.error = true;
    state.isLoading = false;
  },
  getLatestMessagesSuccess: (
    state: Draft<IMessagesState>,
    action: PayloadAction<TLatestMessage[]>
  ) => {
    state.isLoading = false;
    state.latestMessages = action.payload;
  },
  getMessages: (
    state: Draft<IMessagesState>,
    _: PayloadAction<TApiGetMessages>
  ) => {
    state.isLoading = true;
  },
  getMessagesFailed: (state: Draft<IMessagesState>) => {
    state.error = true;
    state.isLoading = false;
  },

  getMessagesSuccess: (
    state: Draft<IMessagesState>,
    action: PayloadAction<{
      info: TMessagesInfo;
      messages: TMessages;
    }>
  ) => {
    state.isLoading = false;
    state.messagesInfo = action.payload.info;
    state.messages = action.payload.messages;
  },
  refreshMessages: (
    state: Draft<IMessagesState>,
    _: PayloadAction<TApiGetMessages>
  ) => {
    state.isLoading = true;
  },
  refreshMessagesFailed: (state: Draft<IMessagesState>) => {
    state.error = true;
    state.isLoading = false;
  },
  refreshMessagesSuccess: (
    state: Draft<IMessagesState>,
    action: PayloadAction<{
      messages: TMessages;
    }>
  ) => {
    state.isLoading = false;
    state.messages = action.payload.messages;
  },
  postMessage: (
    state: Draft<IMessagesState>,
    _: PayloadAction<TApiPostMessage>
  ) => {
    state.isLoading = true;
  },
  postMessageSuccess: (
    state: Draft<IMessagesState>,
    _: PayloadAction<TApiPostMessage>
  ) => {
    state.isLoading = false;
  },
  updateMessage: (
    state: Draft<IMessagesState>,
    _: PayloadAction<TApiUpdateMessage>
  ) => {
    state.isLoading = true;
  },
  updateMessageSuccess: (
    state: Draft<IMessagesState>,
    _: PayloadAction<TApiUpdateMessage>
  ) => {
    state.isLoading = true;
  },
  deleteMessage: (
    state: Draft<IMessagesState>,
    _: PayloadAction<TApiDeleteMessage>
  ) => {
    state.isLoading = true;
  },
  deleteMessageSuccess: (
    state: Draft<IMessagesState>,
    _: PayloadAction<TApiDeleteMessage>
  ) => {
    state.isLoading = true;
  },
  readUnreadMessage: (
    state: Draft<IMessagesState>,
    _: PayloadAction<
      TApiCompanyReadUnreadMessage | TApiCandidateReadUnreadMessage
    >
  ) => {
    state.isLoading = true;
  },
  readUnreadMessageSuccess: (state: Draft<IMessagesState>) => {
    state.isLoading = false;
  },
  readUnreadMessageFailed: (state: Draft<IMessagesState>) => {
    state.error = true;
    state.isLoading = false;
  },
  refreshEmail: (state: Draft<IMessagesState>) => {
    state.isLoading = true;
  },
  refreshEmailSuccess: (state: Draft<IMessagesState>) => {
    state.isLoading = false;
  },
  refreshEmailFailed: (state: Draft<IMessagesState>) => {
    state.error = true;
    state.isLoading = false;
  },
};

const SLICE_NAME = "MESSAGES";

export const MessagesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    ...messagesReducers,
  },
});

export const {
  setActiveUser,
  getLatestMessages,
  getLatestMessagesFailed,
  getLatestMessagesSuccess,
  getMessages,
  getMessagesFailed,
  getMessagesSuccess,
  refreshMessages,
  refreshMessagesFailed,
  refreshMessagesSuccess,
  resetMessageState,
  resetCurrentMessageState,
  postMessage,
  postMessageSuccess,
  updateMessage,
  updateMessageSuccess,
  readUnreadMessage,
  readUnreadMessageSuccess,
  readUnreadMessageFailed,
  deleteMessage,
  deleteMessageSuccess,
  refreshEmail,
  refreshEmailSuccess,
  refreshEmailFailed,
} = MessagesSlice.actions;

export default MessagesSlice.reducer;
