import { Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyleCompanyAddressDialogContainer = styled(Stack)({
  padding: 24,
  width: 512,
  background: "#fff",
  ".required-label": {
    textTransform: "capitalize",
    "&::after": {
      content: '"*"',
      position: "absolute",
      marginLeft: 4,
      color: "#EF4444",
      fontSize: 20,
    },
  },
});
