import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import React from "react";
import { IApplicationDocumentsDeleteDialog } from "../../../models/ApplicationPage";

const CandidateDocumentsDeleteDialog = ({
  t,
  isOpen,
  docName,
  onConfirmDelete,
  onClose
}: IApplicationDocumentsDeleteDialog) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogTitle>{t("delete_document")}</DialogTitle>
    <DialogContent>
      <p
        style={{
          fontSize: "14px",
          lineHeight: "20px",
          color: "#6B7280"
        }}
      >
        {t("document_dialog", { name: docName })}
      </p>
    </DialogContent>
    <DialogActions sx={{ pb: 3, pr: 3 }}>
      <Button variant="outlined" onClick={onClose} autoFocus>
        {t("button.secondCancel")}
      </Button>
      <Button variant="contained" onClick={onConfirmDelete}>
        {t("delete_document")}
      </Button>
    </DialogActions>
  </Dialog>
);

export default CandidateDocumentsDeleteDialog;
