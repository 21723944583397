import React from "react";
import {
  Button,
  Dialog,
  Divider,
  Stack,
  Typography,
  InputLabel,
  TextField,
  InputAdornment,
} from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ICreateUpdateTalentPoolDialog } from "../../../models/dialogs";
import { StyledTextArea } from "../../../pages/CompanySettings_new/styles";
import ColorPicker from "../../ColorPicker";

const AddUpdateTalentPoolDialog = ({
  t,
  isOpen,
  talentPool,
  colorPickerStyle,
  onClose,
  onCreateUpdateTalentPool,
  onChangeTalentPullValue,
}: ICreateUpdateTalentPoolDialog) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    data-testid="add-update-talent-pool-dialog"
  >
    <Stack p={3} width={512} spacing={1} sx={{ backgroundColor: "#fff" }}>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="body1" fontWeight={500}>
            {talentPool?.id
              ? t("talentPools.editTalentPool")
              : t("talentPools.createTalentPool")}
          </Typography>
        </Stack>
        <Stack>
          <XMarkIcon
            onClick={onClose}
            width={24}
            height={24}
            stroke="#131E30"
            style={{ cursor: "pointer" }}
          />
        </Stack>
      </Stack>
      <Stack pt={2}>
        <Divider
          sx={{
            width: "100%",
            borderColor: "#E5E7EB",
          }}
        />
      </Stack>
      <Stack pt={2}>
        <InputLabel>{t("applications.name")}</InputLabel>
        <TextField
          defaultValue={talentPool?.title || ""}
          placeholder={t("applications.name") as string}
          fullWidth
          onChange={(e) => onChangeTalentPullValue("title", e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography
                  variant="subtitle2"
                  paddingLeft={0.5}
                  color={
                    talentPool?.title && talentPool?.title?.length > 50
                      ? "#EF4444"
                      : "textSecondary"
                  }
                >
                  {talentPool?.title?.length || 0}/50
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack pt={1}>
        <InputLabel>{t("talentPools.description")}</InputLabel>
        <StyledTextArea
          rows={4}
          sx={{
            height: 92,
          }}
          value={talentPool?.description || ""}
          onChange={(e) =>
            onChangeTalentPullValue("description", e.target.value)
          }
        />
        <Typography
          align="right"
          variant="subtitle2"
          paddingRight={0.5}
          paddingTop={0.5}
          color={
            talentPool?.description && talentPool?.description?.length > 200
              ? "#EF4444"
              : "textSecondary"
          }
        >
          {talentPool?.description?.length || 0}/200
        </Typography>
      </Stack>

      <Stack>
        <InputLabel>{t("company_settings.color")}</InputLabel>
        <ColorPicker
          title={t("company_settings.color")}
          color={talentPool?.color || "#178CF2"}
          onChange={(value) => onChangeTalentPullValue("color", value)}
          customStyle={colorPickerStyle}
        />
      </Stack>

      <Stack pt={2} direction="row" spacing={1.5} justifyContent="flex-end">
        <Button variant={"outlined"} onClick={onClose}>
          {t("button.secondCancel")}
        </Button>
        <Button
          disabled={
            !talentPool?.title ||
            talentPool?.title?.length > 50 ||
            talentPool?.title?.length < 1 ||
            !talentPool?.description ||
            talentPool?.description?.length > 200 ||
            talentPool?.description?.length < 1
          }
          variant={"contained"}
          onClick={() => onCreateUpdateTalentPool(talentPool)}
        >
          {t("button.save")}
        </Button>
      </Stack>
    </Stack>
  </Dialog>
);

export default AddUpdateTalentPoolDialog;
