import { TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  Divider,
  Menu,
  Stack,
} from "@mui/material";
import { styled } from "@mui/system";
import bgImg from "../../assets/productBgImg.png";

export const StyledSingleJobContainer = styled(Box)({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  button: { border: "none" },
  ".premium-job-boards-tab": {
    alignItems: "center",
    display: "flex",
  },
});

export const StyledTabPanel = styled(TabPanel)(
  ({
    view,
    isPremiumJobBoardsTab,
    isPackages,
    isDicoverPage,
    isJobEditorPage,
  }: {
    view?: string;
    isPremiumJobBoardsTab?: boolean;
    isPackages?: boolean;
    isDicoverPage?: boolean;
    isJobEditorPage?: boolean;
  }) => ({
    marginTop: isPackages ? 0 : isPremiumJobBoardsTab ? 8 : 0,
    paddingTop: isPremiumJobBoardsTab
      ? 0
      : isDicoverPage && isPackages
      ? 16
      : isJobEditorPage && isPackages
      ? 16
      : 24,
    height: "100%",
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: view === "cards" ? 0 : 24,
  })
);

export const StyledPremiumPackagesProductsContainer = styled(Stack)({
  backgroundColor: "#fff",
  border: "1px solid #E5E7EB",
  borderRadius: 6,
  padding: 24,
});

export const StyledProductBodyItem = styled(Stack)({
  position: "relative",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: 16,
  alignItems: "center",
  border: "1px solid #E5E7EB",
  borderRadius: 12,
  fontWeight: 500,
  ".new-price": {
    fontSize: 16,
    color: "#818CF8",
  },
  ".old-price": {
    fontSize: 16,
    textDecoration: "line-through",
    color: "#EF4444",
  },
  ".bgImg": {
    top: 0,
    left: 0,
    position: "absolute",
    opacity: 0,
    width: "100%",
    height: "100%",
    borderRadius: 12,
    zIndex: 0,
  },
  "&:hover": {
    border: "1px solid #178CF2",
    ".bgImg": {
      opacity: 0.2,
    },
  },
});

export const StyledPackageHeader = styled(Stack)({
  flexDirection: "row",
  justifyContent: "space-between",
  padding: 12,
  alignItems: "center",
  backgroundColor: "#F9FAFB",
  ".new-price": {
    fontSize: 16,
    color: "#818CF8",
  },
  ".old-price": {
    fontSize: 16,
    textDecoration: "line-through",
    color: "#EF4444",
  },
});

export const StyledProductsContainer = styled(Stack)({
  border: "1px solid #E5E7EB",
  borderRadius: 6,
  height: "100%",
});

export const StyledDiscoverProductsContainer = styled(Stack)({
  position: "relative",
  cursor: "pointer",
  height: 80,
  padding: 16,
  border: "1px solid #E5E7EB",
  borderRadius: 6,
  ".bgImg": {
    top: 0,
    left: 0,
    position: "absolute",
    opacity: 0,
    width: "100%",
    height: "100%",
  },
  "&:hover": {
    border: "1px solid #178CF2",
    ".bgImg": {
      opacity: 0.2,
    },
  },
});

export const StyledProductContainerHeader = styled(Stack)(
  ({ fullCard }: { fullCard?: boolean }) => ({
    flexDirection: "row",
    justifyContent: "space-between",
    ...(fullCard && { padding: 16, borderBottom: "1px solid #E5E7EB" }),
    img: { width: 120, height: 40 },
    ".new-price": {
      fontSize: 16,
      color: "#818CF8",
    },
  })
);

export const StyledDivider = styled(Divider)({
  height: 1,
  marginTop: 16,
  borderColor: "#E5E7EB",
});

export const StyledVerticalDivider = styled(Divider)({
  height: "100%",
  marginLeft: 12,
});

export const StyledAddBtnContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: 0,
  marginLeft: 12,
  height: "100%",
  svg: {
    width: 24,
    height: 24,
    fill: "#178CF2",
    cursor: "pointer",
    ":hover": { fill: "#304BA8" },
  },
  ".success": {
    stroke: "#fff",
    strokeWidth: 0.1,
    fill: "#10B981",
    background: "transparent",
    ":hover": { fill: "#1b9f4b" },
  },
});

export const StyledPremiumJobBoardsContainer = styled(Stack)({
  width: "100%",
  height: "100%",
});

export const StyledPackageContainer = styled(Stack)({
  width: "100%",
  height: "100%",
  border: "1px solid #E5E7EB",
  borderRadius: 6,
  "&:hover": {
    border: "1px solid #178CF2",
    ".header": {
      color: "#fff",
      background: `url(${bgImg})`,
      borderRadius: "5px 5px 0px 0px",
    },
    ".price-bar": {
      background: "#EEF2FF",
    },
  },
});

export const StyledPackageContainerHeader = styled(Stack)({
  flexDirection: "row",
  justifyContent: "space-between",
  position: "relative",
  padding: 16,
  borderBottom: "1px solid #E5E7EB",
  border: "6px 6px 0 0",
});

export const StyledDiscountLabel = styled(Stack)({
  backgroundColor: "#818CF8",
  padding: 8,
  borderRadius: 6,
  minWidth: 42,
  height: 24,
  color: "#fff",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 12,
});

export const StyledPackageBodyItem = styled(Stack)({
  border: "1px solid #E5E7EB",
  borderRadius: 6,
  padding: 12,
  img: {
    width: 100,
    height: 33,
  },
  ".marketing-image": {
    height: 16,
    width: "auto",
    objectFit: "contain",
  },
  ".old-price": {
    fontSize: 12,
    color: "#9CA3AF",
    textDecoration: "line-through",
  },
});

export const StyledPackagePriceBlock = styled(Stack)({
  flexDirection: "row",
  justifyContent: "space-between",
  margin: "16px 0!important",
  padding: "0 12px",
  height: 48,
  alignItems: "center",
  backgroundColor: "#F9FAFB",
  borderRadius: 12,
  fontWeight: 500,
  ".new-price": {
    fontSize: 16,
    color: "#818CF8",
  },
  ".old-price": {
    fontSize: 16,
    textDecoration: "line-through",
    color: "#EF4444",
  },
});

export const StyledDaysBlock = styled(Stack)({
  height: 20,
  flexDirection: "row",
  alignItems: "center",
  gap: "7px",
  span: {
    lineHeight: 20,
    fontSize: 12,
    color: "#9CA3AF",
  },
  svg: {
    width: 16,
    height: 16,
    stroke: "#9CA3AF",
    strokeWidth: 1,
  },
});

export const StyledSuccessButton = styled(Button)({
  background: "#22C55E",
  "&:hover": {
    background: "#1b9f4b",
    boxShadow: "none",
  },
  "&:disabled": {
    background: "#86EFAC",
    color: "#fff",
  },
});

export const StyledDialogActions = styled(DialogActions)({
  padding: 24,
  background: "#fff",
});

export const StyledShoppingCartButtonContainer = styled(Stack)(
  ({ isPremiumJobBoardsTab }: { isPremiumJobBoardsTab?: boolean }) => ({
    top: isPremiumJobBoardsTab ? 0 : 217, // 104 + 41 + 24 + 24 + 24 - top header + tabs container + list wrapper padding + list container padding + list container margin;
    position: isPremiumJobBoardsTab ? "relative" : "fixed",
    marginRight: isPremiumJobBoardsTab ? 6 : -25,
    width: 40,
    height: 40,
    borderRadius: 1.5,
    justifyContent: "center",
    alignItems: "center",
    ".count-label": {
      position: "absolute",
      right: -5,
      bottom: -5,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#fff",
      color: "#178CF2",
      border: "1px solid #178CF2",
      borderRadius: "50%",
      width: 21,
      height: 21,
      fontSize: 10.5,
      fontWeight: 500,
      cursor: "pointer",
    },
  })
);

export const StyledProductContainer = styled(Stack)({
  position: "relative",
  padding: 16,
  borderRadius: 12,
  border: "1px solid #E5E7EB",
  button: { zIndex: 1 },
  ".bgImg": {
    margin: 0,
    top: 0,
    left: 0,
    position: "absolute",
    opacity: 0,
    width: "100%",
    height: "100%",
    zIndex: 0,
  },
  "&:hover": {
    border: "1px solid #178CF2",
    ".bgImg": {
      opacity: 0.2,
    },
  },
});

export const StyledTabsContainer = styled(Stack)(
  ({ isJobEditorPage }: { isJobEditorPage: boolean }) => ({
    flex: 1,
    flexDirection: "column",
    ...(isJobEditorPage && {
      paddingTop: 24,
    }),
    ".MuiTabs-flexContainer": {
      ...(isJobEditorPage && {
        width: "100%",
        justifyContent: "center",
      }),
      borderBottom: "1px solid #E5E7EB",
      button: {
        border: "none",
        textTransform: "capitalize",
      },
    },
  })
);

export const StyledPremiumJobBoardsTab = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "100%",
});

export const StyledPremiumJobBoardsTabMenu = styled(Menu)(
  ({ lang }: { lang: string }) => ({
    marginTop: 10,
    marginLeft: -7.5,
    ".MuiPaper-root": {
      width: lang === "en_US" ? 179.08 : 175.3,
      boxShadow: "0px 4px 6px -1px #1018281A !important",
      border: "1px solid #E5E7EB",
    },
  })
);
