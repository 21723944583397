import { Container, Divider, Link, Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  padding: "32px 0",
  height: "100%",
});
export const StyledCheckoutSectionContainer = styled(Stack)({
  padding: 24,
  backgroundColor: "#fff",
  borderRadius: "6px",
  ".MuiDivider-root": {
    borderColor: "#E5E7EB",
    margin: "24px 0",
  },
});

export const StyledProductItemContainer = styled(Stack)({
  width: "100%",
  alignItems: "center",
  padding: 16,
  border: "1px solid #E5E7EB",
  borderRadius: 6,
});

export const StyledDiscountLabel = styled(Stack)({
  backgroundColor: "#818CF8",
  padding: 8,
  borderRadius: 6,
  minWidth: 42,
  height: 24,
  color: "#fff",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 12,
});

export const StyledDaysBlock = styled(Stack)({
  alignItems: "center",
  span: { fontSize: 12, lineHeight: "16px" },
  svg: {
    width: 16,
    height: 16,
    stroke: "#9CA3AF",
    strokeWidth: 1,
  },
});

export const StyledPriceContainer = styled(Stack)({
  width: "10%",
  alignItems: "flex-end",
  ".new-price": {
    fontSize: 16,
    color: "#131E30",
  },
  ".old-price": {
    fontSize: 14,
    textDecoration: "line-through",
    color: "#EF4444",
  },
});

export const StyledVerticalDivider = styled(Divider)({
  margin: "0 16px 0 24px!important",
});

export const StyledRemoveButtonContainer = styled(Stack)({
  margin: "0!important",
  svg: {
    width: 24,
    height: 24,
    color: "#EF4444",
    cursor: "pointer",
  },
});

export const StyledLink = styled(Link)({
  textDecoration: "none",
  cursor: "pointer",
});

export const StyledTermsConditionsContainer = styled(Stack)({
  width: "fit-content",
  alignSelf: "center",
});
