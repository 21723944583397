import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  apiAddMessage,
  apiCandidateReadUnreadMessage,
  apiCompanyReadUnreadMessage,
  apiDeleteMessage,
  apiGetLatestMessages,
  apiGetMessages,
  apiRefresh,
  apiUpdateMessage,
} from "../../api/Messages";
import {
  getLatestMessagesSuccess,
  getLatestMessagesFailed,
  getLatestMessages,
  getMessagesSuccess,
  getMessagesFailed,
  getMessages,
  refreshMessagesSuccess,
  refreshMessagesFailed,
  refreshMessages,
  postMessage,
  postMessageSuccess,
  deleteMessage,
  deleteMessageSuccess,
  updateMessageSuccess,
  updateMessage,
  readUnreadMessage,
  readUnreadMessageSuccess,
  readUnreadMessageFailed,
  refreshEmail,
  refreshEmailSuccess,
  refreshEmailFailed,
} from "../reducers/Messages";
import { getMessagesState } from "../selectors/Messages";
import {
  TApiCandidateReadUnreadMessage,
  TApiCompanyReadUnreadMessage,
  TApiDeleteMessage,
  TApiPostMessage,
  TApiUpdateMessage,
  TLatestMessage,
} from "../../models/Messages";
import { getCurrentUserIsCandidate } from "../selectors/CurrentUser";
import { addMessage } from "../reducers/Snackbar";

const candidateBaseUrl = "candidate/messages";
const companyBaseUrl = "company/messages";

function* handleOnFetchLatestMessages() {
  const isCandidate: boolean = yield select(getCurrentUserIsCandidate);
  const url = isCandidate
    ? `${candidateBaseUrl}/get-latest`
    : `${companyBaseUrl}/get-latest`;
  try {
    const { data } = yield call(apiGetLatestMessages, { url });
    yield put(getLatestMessagesSuccess(data.messages));
  } catch (_error) {
    yield put(getLatestMessagesFailed()); // TODO handle error
  }
}

function* handleOnFetchMessages() {
  const isCandidate: boolean = yield select(getCurrentUserIsCandidate);
  const url = isCandidate ? `${candidateBaseUrl}/list/` : `/${companyBaseUrl}/`;
  const { recruiterId, applicationId, jobId, latestMessages } =
    yield select(getMessagesState);
  const readCandidateMessagesUrl = `${candidateBaseUrl}/read/`;
  const readCompanyMessagesUrl = `/${companyBaseUrl}/read`;

  try {
    const apiPayload = {
      recruiter_id: recruiterId,
      candidate_id: recruiterId,
      application_id: applicationId,
      job_id: jobId,
    };

    const readCandidateMessagePayload = {
      recruiter_id: recruiterId,
      application_id: applicationId,
      job_id: jobId,
    };

    const readCompanyMessagePayload = {
      candidate_id: recruiterId,
      application_id: applicationId,
      job_id: jobId,
    };

    const { data } = yield call(apiGetMessages, { url, apiPayload });

    const readMessage = latestMessages?.find(
      (item: TLatestMessage) => item.application_id === applicationId,
    );

    yield put(
      getMessagesSuccess({
        info: data[0].info,
        messages: data[0].messages || data[1].messages,
      }),
    );
    if (readMessage.is_new_messages !== "0") {
      if (isCandidate) {
        yield call(apiCandidateReadUnreadMessage, {
          url: readCandidateMessagesUrl,
          apiPayload: readCandidateMessagePayload,
        });
      } else {
        yield call(apiCompanyReadUnreadMessage, {
          url: readCompanyMessagesUrl,
          apiPayload: readCompanyMessagePayload,
        });
      }
      yield put(getLatestMessages());
    }
  } catch (_error) {
    yield put(getMessagesFailed()); // TODO handle error
  }
}

function* handleOnRefreshMessages() {
  const isCandidate: boolean = yield select(getCurrentUserIsCandidate);
  const url = isCandidate ? `${candidateBaseUrl}/list/` : `/${companyBaseUrl}/`;
  const { recruiterId, applicationId, jobId } = yield select(getMessagesState);
  try {
    const apiPayload = {
      recruiter_id: recruiterId,
      candidate_id: recruiterId,
      application_id: applicationId,
      job_id: jobId,
    };
    const { data } = yield call(apiGetMessages, { url, apiPayload });
    yield put(
      refreshMessagesSuccess({
        messages: data[1].messages,
      }),
    );
  } catch (_error) {
    yield put(refreshMessagesFailed()); // TODO handle error
  }
}

function* handleOnPostMessage({ payload }: { payload: TApiPostMessage }) {
  const isCandidate: boolean = yield select(getCurrentUserIsCandidate);
  const url = isCandidate ? `${candidateBaseUrl}/add` : `${companyBaseUrl}/add`;
  const refreshMessageUrl = isCandidate
    ? `${candidateBaseUrl}/list`
    : `/${companyBaseUrl}/`;
  const { recruiterId, applicationId, jobId } = yield select(getMessagesState);
  const apiPayload = {
    recruiter_id: recruiterId,
    candidate_id: recruiterId,
    application_id: applicationId,
    job_id: jobId,
  };

  try {
    yield call(apiAddMessage, { url, apiPayload: payload });
    yield put(postMessageSuccess);
    yield put(getLatestMessages());
    const { data } = yield call(apiGetMessages, {
      url: refreshMessageUrl,
      apiPayload,
    });
    yield put(
      getMessagesSuccess({
        info: data[0].info,
        messages: data[0].messages || data[1].messages,
      }),
    );
  } catch (_error) {
    yield put(getMessagesFailed()); // TODO handle error
  }
}

function* handleOnUpdateMessage({ payload }: { payload: TApiUpdateMessage }) {
  const isCandidate: boolean = yield select(getCurrentUserIsCandidate);
  const url = isCandidate
    ? `${candidateBaseUrl}/update`
    : `${companyBaseUrl}/update`;
  const refreshMessageUrl = isCandidate
    ? `${candidateBaseUrl}/list`
    : `/${companyBaseUrl}/`;
  const { recruiterId, applicationId, jobId } = yield select(getMessagesState);
  const apiPayload = {
    recruiter_id: recruiterId,
    candidate_id: recruiterId,
    application_id: applicationId,
    job_id: jobId,
  };
  try {
    yield call(apiUpdateMessage, { url, apiPayload: payload });
    yield put(updateMessageSuccess);
    yield put(getLatestMessages());
    const { data } = yield call(apiGetMessages, {
      url: refreshMessageUrl,
      apiPayload,
    });
    yield put(
      refreshMessagesSuccess({
        messages: data[0].messages || data[1].messages,
      }),
    );
  } catch (_error) {
    yield put(getMessagesFailed()); // TODO handle error
  }
}

function* handleOnDeleteMessage({ payload }: { payload: TApiDeleteMessage }) {
  const isCandidate: boolean = yield select(getCurrentUserIsCandidate);
  const url = isCandidate
    ? `${candidateBaseUrl}/delete`
    : `${companyBaseUrl}/delete`;
  const refreshMessageUrl = isCandidate
    ? `${candidateBaseUrl}/list`
    : `/${companyBaseUrl}/`;
  const { recruiterId, applicationId, jobId } = yield select(getMessagesState);
  const apiPayload = {
    recruiter_id: recruiterId,
    candidate_id: recruiterId,
    application_id: applicationId,
    job_id: jobId,
  };
  try {
    yield call(apiDeleteMessage, { url, apiPayload: payload });
    yield put(deleteMessageSuccess);
    yield put(getLatestMessages());
    const { data } = yield call(apiGetMessages, {
      url: refreshMessageUrl,
      apiPayload,
    });
    yield put(
      refreshMessagesSuccess({
        messages: data[0].messages || data[1].messages,
      }),
    );
    yield put(
      addMessage({
        type: "success",
        title: payload.snackbar_title,
      }),
    );
  } catch (_error) {
    yield put(refreshMessagesFailed()); // TODO handle error
  }
}
function* handleOnReadUnreadMessage({
  payload,
}: {
  payload: TApiCompanyReadUnreadMessage | TApiCandidateReadUnreadMessage;
}) {
  const isCandidate: boolean = yield select(getCurrentUserIsCandidate);

  try {
    const readCandidateMessagesUrl = !payload.isNewMessage
      ? `${candidateBaseUrl}/read/`
      : `${candidateBaseUrl}/unread/`;

    const readCompanyMessagesUrl = !payload.isNewMessage
      ? `/${companyBaseUrl}/read`
      : `/${companyBaseUrl}/unread`;

    if (isCandidate) {
      yield call(apiCandidateReadUnreadMessage, {
        url: readCandidateMessagesUrl,
        apiPayload: payload,
      });
    } else {
      yield call(apiCompanyReadUnreadMessage, {
        url: readCompanyMessagesUrl,
        apiPayload: payload,
      });
    }
    yield put(readUnreadMessageSuccess());
    yield put(getLatestMessages());
  } catch (_error) {
    readUnreadMessageFailed();
  }
}

function* handleOnRefreshEmail() {
  const url = "/refresh";
  try {
    yield call(apiRefresh, { url });
    yield put(refreshEmailSuccess());
  } catch (_error) {
    yield put(refreshEmailFailed()); // TODO handle error
  }
}

function* MessagesSaga() {
  yield takeLatest(getLatestMessages, handleOnFetchLatestMessages);
  yield takeLatest(getMessages, handleOnFetchMessages);
  yield takeLatest(refreshMessages, handleOnRefreshMessages);
  yield takeLatest(postMessage, handleOnPostMessage);
  yield takeLatest(deleteMessage, handleOnDeleteMessage);
  yield takeLatest(updateMessage, handleOnUpdateMessage);
  yield takeLatest(readUnreadMessage, handleOnReadUnreadMessage);
  yield takeLatest(refreshEmail, handleOnRefreshEmail);
}

export default MessagesSaga;
