import React from "react";
import {
  Button,
  CircularProgress,
  Container,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowDownTrayIcon,
  EyeIcon,
  ShoppingBagIcon,
} from "@heroicons/react/24/outline";
import JobDescriptionForm from "../JobDialogs_new/JobDescription";
import PremiumJobBoards from "../../pages/SingleJob_new/PremiumJobBoards";
import SuccessImage from "../../assets/create_job_success.png";
import JobApplicationProcessForm from "../JobDialogs_new/JobApplicationProcess";
import JobDetailsForm from "../JobDialogs_new/JobDetails";
import JobEditorStepper from "./Stepper";
import TopBarComponent from "./TopBar";
import { StyledJobEditorButtonContainer } from "./styles";
import {
  StyledPreviewButton,
  StyledPreviewButtonContainer,
} from "../JobDialogs_new/styles";
import { IJobEditorComponent } from "../../models/JobEditor";

const JobEditorComponent = ({
  t,
  currentStep,
  isLoading,
  jobDescriptionFormRef,
  jobDetailsFormRef,
  jobDetails,
  jobApplicationProcess,
  jobApplicationProcessFormRef,
  selectedProducts,
  selectedProductsSum,
  updateJobDescriptionData,
  onOpenDraftDialog,
  onNextStep,
  onPrevStep,
  onSubmit,
  onClose,
  onChangeForm,
  onPreview,
}: IJobEditorComponent) => (
  <Container data-testid={"job-editor-container"}>
    <TopBarComponent
      t={t}
      currentStep={currentStep}
      onClose={onClose}
      onPrevStep={onPrevStep}
    />
    {currentStep < 4 && (
      <Stack width="100%" alignItems="flex-end" position="relative" top={60}>
        <StyledPreviewButtonContainer spacing={1}>
          <Tooltip
            componentsProps={{
              tooltip: { sx: { background: "#178CF2" } },
              arrow: { sx: { color: "#178CF2" } },
            }}
            placement="right"
            title={
              <Typography variant="subtitle2">
                {t("create_job_first_step.preview")}
              </Typography>
            }
            arrow
          >
            <StyledPreviewButton onClick={onPreview} disabled={isLoading}>
              {isLoading ? (
                <CircularProgress
                  size={24}
                  style={{
                    color: "#fff",
                  }}
                />
              ) : (
                <EyeIcon width={24} height={24} />
              )}
            </StyledPreviewButton>
          </Tooltip>
          <Tooltip
            componentsProps={{
              tooltip: { sx: { background: "#178CF2" } },
              arrow: { sx: { color: "#178CF2" } },
            }}
            placement="right"
            title={
              <Typography variant="subtitle2">
                {t("create_job_first_step.save_as_draft")}
              </Typography>
            }
            arrow
          >
            <StyledPreviewButton
              onClick={onOpenDraftDialog}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress
                  size={24}
                  style={{
                    color: "#fff",
                  }}
                />
              ) : (
                <ArrowDownTrayIcon width={24} height={24} />
              )}
            </StyledPreviewButton>
          </Tooltip>
        </StyledPreviewButtonContainer>
      </Stack>
    )}
    {currentStep < 4 && <JobEditorStepper t={t} />}
    {currentStep === 1 && (
      <JobDescriptionForm
        t={t}
        formRef={jobDescriptionFormRef}
        onSubmit={onSubmit}
        updateJobDescriptionData={updateJobDescriptionData}
      />
    )}
    {currentStep === 2 && (
      <JobDetailsForm
        defaultValues={jobDetails}
        t={t}
        formRef={jobDetailsFormRef}
        onSubmit={onSubmit}
        onChangeForm={onChangeForm}
      />
    )}
    {currentStep === 3 && (
      <JobApplicationProcessForm
        defaultValues={jobApplicationProcess}
        t={t}
        formRef={jobApplicationProcessFormRef}
        onSubmit={onSubmit}
        onChangeForm={onChangeForm}
      />
    )}
    {currentStep === 4 && (
      <>
        <Stack mt={4.5} alignItems="center">
          <img src={SuccessImage} width={155} height={94} alt={"image"} />
          <Typography variant="body1">
            <Stack mt={3} alignItems="center">
              {t("create_job_fourth_step.succes_heading")}
              <Stack mt={1.5} spacing={1} alignItems="center">
                <Typography variant="body2" color="textSecondary">
                  {t("create_job_fourth_step.succes_subheading_one")}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {t("create_job_fourth_step.succes_subheading_two")}
                </Typography>
              </Stack>
            </Stack>
          </Typography>
        </Stack>
        <PremiumJobBoards onOpenCart={() => {}} isJobEditorPage />
      </>
    )}
    {!isLoading && (
      <StyledJobEditorButtonContainer
        direction={"row"}
        spacing={2}
        isJobEditorAdditionalSteps={currentStep >= 4}
        justifyContent="space-between"
      >
        {currentStep === 4 && (
          <Stack width="70%" direction="row" spacing={1.35} alignItems="center">
            <ShoppingBagIcon
              height={24}
              width={24}
              stroke="#6B7280"
              strokeWidth={1}
            />
            <Typography variant="subtitle2">
              {selectedProducts.length}{" "}
              {t("create_job_fourth_step.number_selected_products")}
            </Typography>
            {selectedProducts.length > 0 && (
              <Stack direction="row" spacing={1.5} alignItems="center">
                {selectedProductsSum[1] > selectedProductsSum[0] && (
                  <Typography
                    variant="subtitle2"
                    color="#EF4444"
                    sx={{ textDecoration: "line-through" }}
                  >
                    {selectedProductsSum[1].toFixed(2)} €
                  </Typography>
                )}
                <Typography variant="subtitle2" color="#111827">
                  {selectedProductsSum[0].toFixed(2)} €
                </Typography>
              </Stack>
            )}
          </Stack>
        )}
        <Button variant="outlined" onClick={onClose} disabled={isLoading}>
          {currentStep < 4 ? t("button.secondCancel") : t("button.no_thanks")}
        </Button>
        <Button
          variant="contained"
          type="submit"
          endIcon={
            isLoading ? (
              <CircularProgress
                size={16}
                style={{
                  color: "#9A9FAF",
                }}
              />
            ) : null
          }
          disabled={isLoading || (currentStep >= 4 && !selectedProducts.length)}
          onClick={onNextStep}
        >
          {currentStep < 4
            ? t("button.continue")
            : currentStep === 3
              ? t("button.save")
              : t("cart.checkout")}
        </Button>
      </StyledJobEditorButtonContainer>
    )}
  </Container>
);

export default JobEditorComponent;
