import React, { useState } from "react";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import { Button, Menu, MenuItem, Stack } from "@mui/material";
import { TListOption } from "../../models/common";

const CustomDropdown = ({
  items,
  onSelect,
}: {
  readonly items: TListOption[];
  readonly onSelect: (value: string) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnSelect = (value: string) => {
    onSelect(value);
    handleClose();
  };

  return (
    <Stack position="relative" mr={2}>
      <Button
        variant="contained"
        className="variable-button"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        endIcon={<CodeOutlinedIcon width={18} height={18} />}
        onClick={handleClick}
      >
        <span>Variable</span>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {items.map((item) => (
          <MenuItem
            key={item.value}
            onClick={() => handleOnSelect(`{${item.value}}`)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default CustomDropdown;
