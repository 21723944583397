import React from "react";
import {
  Dialog,
  Stack,
  DialogActions,
  Button,
  Typography,
  Divider
} from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { IRemoveDialog } from "../../../models/dialogs";

const RemoveDialog = ({
  t,
  isOpen,
  title,
  body,
  submitButtonText,
  onClose,
  onRemove
}: IRemoveDialog) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        elevation: 0
      }}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            minWidth: 512,
            p: "16px 24px 16px 24px"
          }
        }
      }}
    >
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <Stack>
          <Typography fontWeight={500}>{title}</Typography>
        </Stack>
        <Stack>
          <XMarkIcon onClick={onClose} />
        </Stack>
      </Stack>
      <Divider />
      <Stack my={2.5}>
        <Typography variant="subtitle2" color="textSecondary">
          {body}
        </Typography>
      </Stack>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={onClose} autoFocus>
          {t("button.secondCancel")}
        </Button>
        <Button variant="contained" color="error" onClick={onRemove}>
          {submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveDialog;
