import React from "react";
import { Avatar, Stack, Tooltip, Typography } from "@mui/material";
import { TJobOwner } from "../../../models/SingleJob";

const TeamMembers = ({
  owners,
  showInJobHeader = false
}: {
  owners: TJobOwner[];
  showInJobHeader?: boolean;
}) => (
  <Stack direction="row" position="relative" right={showInJobHeader ? 12 : 0}>
    {owners.slice(0, 3).map((item: TJobOwner, index: number) => (
      <Tooltip key={index} title={`${item.firstname} ${item.lastname}`}>
        <Avatar
          src={item.profile_photo as string}
          sx={{
            position: "relative",
            width: 32,
            height: 32,
            right: index !== 0 ? index * 9 : 0,
            fontSize: 16,
            border: "1.5px solid #fff"
          }}
        >
          {`${item.firstname.substring(0, 1)}${item.lastname.substring(0, 1)}`}
        </Avatar>
      </Tooltip>
    ))}
    {owners.length > 3 && (
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              background: "#EEF2FF"
            }
          }
        }}
        title={
          <Stack p={1} spacing={1}>
            {owners.map((item: TJobOwner, index: number) => (
              <Stack
                key={index}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Avatar
                  src={item.profile_photo as string}
                  sx={{
                    width: 24,
                    height: 24,
                    border: "1.5px solid #fff"
                  }}
                >
                  <Typography variant="caption" fontSize={11.5}>
                    {`${item.firstname.substring(
                      0,
                      1
                    )}${item.lastname.substring(0, 1)}`}
                  </Typography>
                </Avatar>

                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  fontSize={12}
                >
                  {item.firstname} {item.lastname}
                </Typography>
              </Stack>
            ))}
          </Stack>
        }
      >
        <Stack
          position="absolute"
          width={32}
          height={32}
          borderRadius="50%"
          alignItems="center"
          justifyContent="center"
          color="#818CF8"
          fontSize="14px"
          fontWeight={500}
          left={72}
          border="1.5px solid #fff"
          sx={{
            cursor: "pointer",
            backgroundColor: "#EEF2FF"
          }}
        >
          +3
        </Stack>
      </Tooltip>
    )}
  </Stack>
);

export default TeamMembers;
