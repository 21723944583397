import {
  ArchiveBoxIcon,
  ArrowDownTrayIcon,
  EyeIcon,
  UserPlusIcon
} from "@heroicons/react/24/outline";
import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { IJobListToolbar } from "../../models/JobList";
import { getJobListSelectedJobs } from "../../store_new/selectors/JobList";
import { StyledJobsToolbarContainer } from "./styles";

const JobListToolbar = ({
  t,
  onArchive,
  onExport,
  onPreview,
  onAddApplication
}: IJobListToolbar) => {
  const selectedJobs = useSelector(getJobListSelectedJobs);
  return (
    <StyledJobsToolbarContainer
      direction="row"
      spacing={1.5}
      data-testid={"jobs-list-toolbar-wrapper"}
    >
      <Button
        data-testid={"jobs-list-preview-button"}
        disabled={selectedJobs.length !== 1}
        startIcon={<EyeIcon className={"btn-icon"} />}
        onClick={() => onPreview(selectedJobs[0].url_key)}
      >
        {t("create_job_first_step.preview")}
      </Button>
      <Button
        data-testid={"jobs-list-export-button"}
        disabled={selectedJobs.length === 0}
        startIcon={<ArrowDownTrayIcon className={"btn-icon"} />}
        onClick={() => onExport(selectedJobs)}
      >
        {t("joblist.export")}
      </Button>
      <Button
        data-testid={"jobs-list-archive-button"}
        disabled={selectedJobs.length !== 1}
        startIcon={<ArchiveBoxIcon className={"btn-icon"} />}
        onClick={() => onArchive(selectedJobs[0])}
      >
        {t("joblist.archive")}
      </Button>
      <Button
        data-testid={"jobs-list-add-application-button"}
        disabled={selectedJobs.length !== 1}
        startIcon={<UserPlusIcon className={"btn-icon"} />}
        onClick={() => onAddApplication(selectedJobs[0].url_key)}
      >
        {t("joblist.add")}
      </Button>
    </StyledJobsToolbarContainer>
  );
};

export default JobListToolbar;
