import React from "react";
import { Button, Dialog, Stack, Typography } from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import JobLimitPicture from "../../../assets/JobLimit.png";
import { IExtendLimitDialog } from "../../../models/dialogs";

const ExtendLimitDialog = ({
  t,
  isOpen,
  onClose,
  onExtendLimit,
}: IExtendLimitDialog) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    PaperProps={{
      elevation: 0,
    }}
    sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          padding: "0px 24px",
        },
      },
    }}
  >
    <Stack p={3} width={336} borderRadius={1.5}>
      <Stack alignItems="flex-end">
        <XMarkIcon
          width={24}
          height={24}
          stroke="#131E30"
          style={{ cursor: "pointer" }}
          onClick={onClose}
        />
      </Stack>
      <Stack mt={3} alignItems="center">
        <img
          src={JobLimitPicture}
          alt="job-limit"
          width={230.772}
          height={139.938}
        />
      </Stack>
      <Stack mt={3} spacing={1} alignItems="center" textAlign="center">
        <Typography>{t("limit_dialog.title")}</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {t("limit_dialog.text")}
        </Typography>
      </Stack>
      <Stack mt={3}>
        <Button color="primary" variant="contained" onClick={onExtendLimit}>
          {t("limit_dialog.button")}
        </Button>
      </Stack>
    </Stack>
  </Dialog>
);

export default ExtendLimitDialog;
