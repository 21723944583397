import {
  TApiUpdateCurrentUserData,
  TApiUpdateCurrentUserPassword,
  TApiUpdateCurrentUserPhoto,
} from "../models/CurrentUser";
import { apiRequest } from "./config";

export const apiUpdateCurrentUserData = ({
  url,
  apiPayload,
}: {
  readonly url: string;
  readonly apiPayload: TApiUpdateCurrentUserData;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiUpdateCurrentUserPhoto = ({
  url,
  apiPayload,
}: {
  readonly url: string;
  readonly apiPayload: TApiUpdateCurrentUserPhoto;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiUpdateCurrentUserPassword = ({
  url,
  apiPayload,
}: {
  readonly url: string;
  readonly apiPayload: TApiUpdateCurrentUserPassword;
}) => apiRequest({ type: "post", url, apiPayload });
