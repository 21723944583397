import { styled } from "@mui/system";
import { Grid, Stack } from "@mui/material";

export const StyledMagicLinkWrapper = styled(Grid)({
  height: "100vh",
  display: "flex",
  background: "white"
});

export const StyledMagicLinkContainer = styled(Grid)({
  position: "relative",
  left: "7vw",
  alignSelf: "center"
});

export const StyledMagicLinkContent = styled(Stack)({
  height: "100%",
  img: {
    position: "relative",
    width: 127,
    height: 48,
    marginBottom: 12,
    right: 5
  },
  ".link-back-to-job": {
    cursor: "pointer",
    underline: "none",
    display: "inline",
    fontSize: 14
  }
});
