import { Button, CircularProgress, Dialog } from "@mui/material";
import { TFunction } from "i18next";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import JobDescriptionForm from "../../../../components/JobDialogs_new/JobDescription";
import {
  TJobDescriptionFormFields,
  TJobDescriptionFormFieldsPayload,
} from "../../../../models/JobEditor";
import { SELECTED_EDIT_SECTIONS } from "../../../../models/SingleJob";
import { getJobEditorState } from "../../../../store_new/selectors/JobEditor";
import { getSingleJobState } from "../../../../store_new/selectors/SingleJob";
import { StyledDialogActions } from "../../styles";

const JobDescriptionEditDialog = ({
  t,
  isOpen,
  selectedSections,
  onClose,
  onUpdateJob,
}: {
  t: TFunction;
  isOpen: boolean;
  selectedSections?: SELECTED_EDIT_SECTIONS[];
  onClose: () => void;
  onUpdateJob: (data: TJobDescriptionFormFieldsPayload) => void;
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { jobTemplate, jobDetails } = useSelector(getSingleJobState);
  const { isLoading } = useSelector(getJobEditorState);

  const handleOnUpdateJobDescription = (data: TJobDescriptionFormFields) => {
    const convertedPayload = Object.keys(data).reduce(
      (acc, dataKey: string) => ({
        ...acc,
        [`template_${dataKey}`]:
          data[dataKey as keyof TJobDescriptionFormFields],
      }),
      {}
    );
    const apiPayload = {
      ...convertedPayload,
      title: data.job_title,
    };
    onUpdateJob(apiPayload as TJobDescriptionFormFieldsPayload);
  };
  const {
    introduction_title,
    introduction,
    tasks,
    tasks_title,
    profile_title,
    profile,
    offer,
    offer_title,
    contact,
    contact_title,
    video_url,
    company_color,
    background_color,
    header_1_original_name,
    header_2_original_name,
    header_3_original_name,
    background_original_name,
    logo_original_name,
  } = jobTemplate || {};

  return (
    <Dialog open={isOpen} maxWidth={"md"} fullWidth onClose={onClose}>
      {jobTemplate && jobDetails && (
        <JobDescriptionForm
          t={t}
          defaultValues={{
            introduction_title: introduction_title || "",
            introduction: introduction || "",
            tasks_title: tasks_title || "",
            tasks: tasks || "",
            profile_title: profile_title || "",
            profile: profile || "",
            offer_title: offer_title || "",
            offer: offer || "",
            contact: contact || "",
            contact_title: contact_title || "",
            video_url: video_url || "",
            company_color: company_color || "",
            background_color: background_color || "",
            job_title: jobDetails.title,
            header_1: header_1_original_name,
            header_2: header_2_original_name,
            header_3: header_3_original_name,
            logo: logo_original_name,
            background: background_original_name,
          }}
          formRef={formRef}
          selectedSections={selectedSections}
          updateJobDescriptionData={() => {}}
          onSubmit={handleOnUpdateJobDescription}
        />
      )}
      <StyledDialogActions>
        <Button variant="outlined" onClick={onClose} disabled={isLoading}>
          {t("button.secondCancel")}
        </Button>
        <Button
          variant="contained"
          type="submit"
          endIcon={
            isLoading ? (
              <CircularProgress
                size={16}
                style={{
                  color: "#9A9FAF",
                }}
              />
            ) : null
          }
          onClick={() => formRef.current?.requestSubmit()}
          disabled={isLoading}
        >
          {t("button.save")}
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

export default JobDescriptionEditDialog;
