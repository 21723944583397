import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PersonalInfo from "../ApplicationPage_new/PersonalInfo";
import Documents from "../ApplicationPage_new/Documents";
import AddCandidateToJobDialog from "../../components/dialogs/AddCandidateToJobDialog";
import { getSelectedJobApplications } from "../../store_new/selectors/Applications";
import {
  getCurrentStatusJobListFiltersState,
  getJobListFilteredPaginationState,
  getJobListJobsState,
} from "../../store_new/selectors/JobList";
import { getTalentPoolState } from "../../store_new/selectors/Talentpool";
import {
  setPageNum,
  setSimpleJobFilter,
} from "../../store_new/reducers/JobList";
import { applyToJob } from "../../store_new/reducers/JobPreview";
import { ITalentPoolCandidate } from "../../models/TalentPools";
import { TJobListSimpleFilterTypes } from "../../models/JobList";
import { setIsAddCandidateToJobDialogOpen } from "../../store_new/reducers/dialogs";
import { getDialogsState } from "../../store_new/selectors/Dialogs";
import { deleteCandidate } from "../../store_new/reducers/TalentPools";
import { addMessage } from "../../store_new/reducers/Snackbar";

const DetailedApplication = ({ desktop }: { readonly desktop: boolean }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { talentpoolId } = useParams();

  const { list, totalCount: jobsCount } = useSelector(getJobListJobsState);
  const currentApplication = useSelector(getSelectedJobApplications)?.[0];

  const { pageSize: jobsPageSize, pageNum: jobsPageNum } = useSelector(
    getJobListFilteredPaginationState
  );
  const { quickSearch: jobsQuickSearch } = useSelector(
    getCurrentStatusJobListFiltersState
  );

  const { selectedJob } = useSelector(getTalentPoolState);
  const { isAddCandidateToJobDialogOpen } = useSelector(getDialogsState);

  const handleCloseDialog = () => {
    dispatch(setIsAddCandidateToJobDialogOpen(false));
  };

  const handleOnChangeJobPage = (pageNum: number) => {
    dispatch(setPageNum(pageNum));
  };

  const onSuccessApplyCandidateToJob = () => {
    handleCloseDialog();
    dispatch(
      deleteCandidate({
        id: talentpoolId as unknown as number,
        applicationId: currentApplication?.application_id as unknown as number,
        recordId: currentApplication?.record_id as string,
        callback: () => navigate(`/talentpool/${talentpoolId}`),
      })
    );
    dispatch(
      addMessage({
        type: "success",
        title: t("talentPools.successAddCandidateToJob"),
      })
    );
  };

  const handleOnApplyCandidateToJob = () => {
    const formattedCandidatePayload = {
      email: currentApplication?.email as string,
      firstname: currentApplication?.firstname as string,
      lastname: currentApplication?.lastname as string,
      use_latest_cv: true,
    };

    dispatch(
      applyToJob({
        body: formattedCandidatePayload,
        jobUrlKey: selectedJob?.url_key as string,
        callback: () => onSuccessApplyCandidateToJob(),
      })
    );
  };

  const handleOnChangeSimpleFilter = ({
    filter,
    value,
  }: {
    readonly filter: keyof TJobListSimpleFilterTypes;
    readonly value: string;
  }) => {
    dispatch(setSimpleJobFilter({ filter, value }));
  };

  useEffect(() => {
    if (!currentApplication) navigate(`/talentpool/${talentpoolId}`);
  }, [currentApplication]);

  return (
    <Grid container item spacing={3} py={3}>
      <Grid item xs={desktop ? 8 : 12}>
        <PersonalInfo
          currentApplication={currentApplication}
          desktop={desktop}
        />
      </Grid>
      <Grid item xs={desktop ? 4 : 12}>
        <Documents currentApplication={currentApplication} desktop={desktop} />
      </Grid>
      <AddCandidateToJobDialog
        t={t}
        isOpen={isAddCandidateToJobDialogOpen}
        selectedCandidate={
          currentApplication as unknown as ITalentPoolCandidate
        }
        jobs={list}
        selectedJob={selectedJob}
        jobsCount={jobsCount}
        pageNum={jobsPageNum}
        pageSize={jobsPageSize}
        quickSearch={jobsQuickSearch}
        onClose={handleCloseDialog}
        onJobsPaginate={handleOnChangeJobPage}
        onAddCandidateToJob={handleOnApplyCandidateToJob}
        onChangeSimpleFilter={handleOnChangeSimpleFilter}
      />
    </Grid>
  );
};

export default DetailedApplication;
