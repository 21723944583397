import React from "react";
import { Button, Divider, Stack, TextField } from "@mui/material";
import DeleteDialog from "../../../dialogs/DeleteDialog";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import {
  StyledCandidateNoteActionsButtonsContainer,
  StyledCandidateNoteActionsEditModeContainer,
} from "../../styles";
import { IApplicationNotesActionsComponent } from "../../../../models/Applications";

const CandidateNoteActionsComponent = ({
  t,
  comment,
  isCurrentUser,
  isEditMode,
  isDeleteDialogOpen,
  onChangeComment,
  onDelete,
  onSubmit,
  onToggleEditMode,
  onOpenCloseDeleteDialog,
}: IApplicationNotesActionsComponent) => (
  <Stack>
    {isEditMode ? (
      <StyledCandidateNoteActionsEditModeContainer>
        <TextField
          fullWidth
          value={comment}
          onChange={onChangeComment}
          onKeyUp={(e) => {
            if (e.code === "Enter") onSubmit();
          }}
        />
        <Stack
          width={26}
          height={26}
          p={0.25}
          justifyContent="center"
          alignItems="center"
          borderRadius="50%"
          sx={{
            cursor: "pointer",
            backgroundColor: "#178CF2",
            "&:hover": {
              backgroundColor: "#304BA8 !important",
            },
          }}
          onClick={() => onSubmit()}
        >
          <PaperAirplaneIcon width={16} height={16} stroke="#fff" />
        </Stack>
      </StyledCandidateNoteActionsEditModeContainer>
    ) : (
      <StyledCandidateNoteActionsButtonsContainer>
        <Button
          variant={"text"}
          sx={{ fontSize: 12 }}
          onClick={onToggleEditMode}
        >
          {t("candidate.reply")}
        </Button>
        {isCurrentUser && (
          <>
            <Divider flexItem orientation={"vertical"} />
            <Button
              variant={"text"}
              sx={{ fontSize: 12 }}
              onClick={() => onOpenCloseDeleteDialog(true)}
            >
              {t("button.remove")}
            </Button>
          </>
        )}
      </StyledCandidateNoteActionsButtonsContainer>
    )}
    <DeleteDialog
      t={t}
      isOpen={isDeleteDialogOpen}
      title={t("candidate.delete_note")}
      body={t("candidate.are_you_sure_delete_note")}
      onCancel={() => onOpenCloseDeleteDialog(false)}
      onDelete={onDelete}
    />
  </Stack>
);

export default CandidateNoteActionsComponent;
