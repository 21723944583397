import React from "react";
import { Grid, Stack } from "@mui/material";
// import QuickReport from "./QuickReport";
import { StyledReportChartContainer } from "./styles";
import NumberOfApplications from "./NumberOfApplications";
import SourceOfHire from "./SourceOfHire";
import Toolbar from "./Toolbar";
import Loading from "../../components/Loading";
import AverageDaysInStage from "./AverageDaysInStage";
// import AverageDaysInStage from "./AverageDaysInStage";

const AnalyticsComponent = ({ isLoading }: { isLoading: boolean }) => (
  <Stack width={"100%"}>
    <Toolbar />
    {isLoading ? (
      <Loading />
    ) : (
      <Grid container spacing={3}>
        {/*<Grid item xs={3}>*/}
        {/*  <QuickReport*/}
        {/*    diff={12}*/}
        {/*    type={"money"}*/}
        {/*    value={"7.345,00€"}*/}
        {/*    title={"Total hiring cost"}*/}
        {/*  />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={3}>*/}
        {/*  <QuickReport*/}
        {/*    diff={-3}*/}
        {/*    type={"money"}*/}
        {/*    value={"345,30€"}*/}
        {/*    title={"Avg Cost per hire"}*/}
        {/*  />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={3}>*/}
        {/*  <QuickReport*/}
        {/*    diff={5}*/}
        {/*    type={"date"}*/}
        {/*    value={"7 Days"}*/}
        {/*    title={"Avg Time-to-Hire"}*/}
        {/*  />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={3}>*/}
        {/*  <QuickReport*/}
        {/*    diff={0}*/}
        {/*    type={"docs"}*/}
        {/*    value={"4"}*/}
        {/*    title={"Active vacancies"}*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item xs={9}>
          <StyledReportChartContainer>
            <NumberOfApplications />
          </StyledReportChartContainer>
        </Grid>
        <Grid item xs={3}>
          <StyledReportChartContainer>
            <SourceOfHire />
          </StyledReportChartContainer>
        </Grid>
        <Grid item xs={12}>
          <StyledReportChartContainer>
            <AverageDaysInStage />
          </StyledReportChartContainer>
        </Grid>
      </Grid>
    )}
  </Stack>
);

export default AnalyticsComponent;
