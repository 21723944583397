import React from "react";
import { Divider, Stack, Switch, Tooltip, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { IJobDocumentsSection } from "../../../models/JobEditor";
import { StyledBasicQuestionsContainer } from "../styles";

const JobDocumentsSection = ({ t, control }: IJobDocumentsSection) => (
  <>
    <Typography variant="body2">
      {t("create_job_third_step.documents")}
    </Typography>
    <StyledBasicQuestionsContainer spacing={1}>
      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle2" color="textSecondary">
            CV
          </Typography>
          <Tooltip title={t("cv_text")}>
            <Switch color="secondary" checked={true} />
          </Tooltip>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle2" color="textSecondary">
            {t("create_job_third_step.additional_documents")}
          </Typography>
          <Controller
            name={"other_documents_required"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Switch
                color="secondary"
                onChange={(_, value) => onChange(value ? "1" : "0")}
                checked={Boolean(parseInt(value as string))}
              />
            )}
          />
        </Stack>
      </Stack>
    </StyledBasicQuestionsContainer>
  </>
);

export default JobDocumentsSection;
