import {
  TApiDeleteUserPayload,
  TApiGetUserPayload,
  TApiInviteUserPayload,
} from "../models/Team";
import { apiRequest } from "./config";

export const apiGetUser = () => apiRequest({ type: "post", url: "/me" });
export const apiUserLogout = () => apiRequest({ type: "post", url: "/logout" });

export const apiGetUsers = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: TApiGetUserPayload;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiDeleteUser = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: TApiDeleteUserPayload;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiInviteUsers = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: TApiInviteUserPayload;
}) => apiRequest({ type: "post", url, apiPayload });
