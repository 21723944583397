import React from "react";
import { IApplicationNotesComponent } from "../../models/Applications";
import {
  StyledCandidateContentContainer,
  StyledCandidateContentTitleContainer,
  StyledDivider,
} from "../../pages/ApplicationPage_new/styles";
import { Stack, Typography } from "@mui/material";
import CandidateNote from "./components/Note";
import { StyledCandidateNoteListWrapper } from "./styles";
import CandidateNoteInputField from "./components/InputField";
import CandidateMessagesEmptyScreen from "./components/EmptyScreen";

const CandidateMessagesComponent = ({
  t,
  noteList,
  inputValue,
  onDeleteNote,
  onChangeInput,
  onSubmit,
  onSubmitComment,
}: IApplicationNotesComponent) => (
  <StyledCandidateContentContainer>
    <StyledCandidateContentTitleContainer direction={"row"}>
      <Typography
        fontSize={18}
        color={"#131E30"}
        fontWeight={500}
        lineHeight={"24px"}
      >
        {t("create_job_second_step.notes")}
      </Typography>
    </StyledCandidateContentTitleContainer>
    <StyledDivider style={{ margin: "16px 0" }} />
    {noteList?.length ? (
      <StyledCandidateNoteListWrapper>
        {noteList.map((noteListItem) => (
          <CandidateNote
            key={noteListItem.note.id}
            note={noteListItem}
            onDeleteNote={onDeleteNote}
            onSubmitComment={onSubmitComment}
          />
        ))}
      </StyledCandidateNoteListWrapper>
    ) : (
      <CandidateMessagesEmptyScreen t={t} />
    )}

    <Stack sx={{ marginTop: "auto" }}>
      <StyledDivider style={{ margin: "16px 0" }} />
      <CandidateNoteInputField
        inputValue={inputValue}
        onSubmit={onSubmit}
        onChangeInput={onChangeInput}
      />
    </Stack>
  </StyledCandidateContentContainer>
);

export default CandidateMessagesComponent;
