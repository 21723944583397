import React from "react";
import { TFunction } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import JobListFiltersComponent from "./component";
import {
  TJobListFilterWithOptionsTypes,
  TJobListSimpleFilterTypes,
} from "../../../models/JobList";
import {
  setJobFilter,
  setSimpleJobFilter,
} from "../../../store_new/reducers/JobList";
import { getCurrentStatusJobListFiltersState } from "../../../store_new/selectors/JobList";

const JobListFilters = ({
  t,
  isAgency,
}: {
  readonly t: TFunction;
  readonly isAgency?: boolean;
}) => {
  const dispatch = useDispatch();
  const {
    jobType: jobFilter,
    seniority: seniorityFilter,
    quickSearch,
    companyName,
    locations,
  } = useSelector(getCurrentStatusJobListFiltersState);
  const handleOnChange = ({
    value,
    filterType,
  }: {
    value: string;
    filterType: keyof TJobListFilterWithOptionsTypes;
  }) => {
    dispatch(setJobFilter({ filter: filterType, value }));
  };

  const handleOnChangeSimpleFilter = ({
    filter,
    value,
  }: {
    readonly filter: keyof TJobListSimpleFilterTypes;
    readonly value: string;
  }) => {
    dispatch(setSimpleJobFilter({ filter, value }));
  };

  return (
    <JobListFiltersComponent
      jobFilter={jobFilter}
      seniorityFilter={seniorityFilter}
      t={t}
      locations={locations}
      quickSearch={quickSearch}
      companyName={companyName as string}
      isAgency={isAgency}
      onChange={handleOnChange}
      onChangeSimpleFilter={handleOnChangeSimpleFilter}
    />
  );
};

export default JobListFilters;
