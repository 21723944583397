import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MessagesComponent_NewPage from "./component";
import {
  deleteMessage,
  getLatestMessages,
  getMessages,
  postMessage,
  readUnreadMessage,
  refreshEmail,
  refreshMessages,
  resetCurrentMessageState,
  resetMessageState,
  setActiveUser,
  updateMessage,
} from "../../store_new/reducers/Messages";
import { getMessagesState } from "../../store_new/selectors/Messages";
import {
  IMessagesPage,
  MESSAGES_DIALOG_TYPE,
  TApiGetMessages,
  TLatestMessage,
} from "../../models/Messages";
import {
  fetchAllApplications,
  resetCurrentApplication,
} from "../../store_new/reducers/Applications";
import { getAllApplications } from "../../store_new/selectors/Applications";
import {
  getCurrentUserData,
  getCurrentUserIsCandidate,
} from "../../store_new/selectors/CurrentUser";

const Messages_NewPage = ({ desktop }: IMessagesPage) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const twoMinutes = 120000;
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [openedDialogType, setOpenedDialogType] =
    useState<MESSAGES_DIALOG_TYPE | null>(null);
  const [activeMessageId, setActiveMessageId] = useState<number | null>(null);
  const [editMessageId, setEditMessageId] = useState<number | null>(null);
  const [deleteMessageId, setDeleteMessageId] = useState<number | null>(null);

  const editTextarea = document.querySelector(
    "#edit-chat-area"
  ) as HTMLInputElement;

  const textarea = desktop
    ? (document.querySelector("#desktop-chat-area") as HTMLInputElement)
    : (document.querySelector("#mobile-chat-area") as HTMLInputElement);

  const {
    recruiterId,
    messagesInfo,
    messages,
    latestMessages,
    applicationId,
    jobId,
    isLoading,
  } = useSelector(getMessagesState);

  const allApplications = useSelector(getAllApplications);

  const { firstName, lastName, photo } = useSelector(getCurrentUserData);
  const isCandidate = useSelector(getCurrentUserIsCandidate);

  const openJob = () =>
    window.open(`/job/${messagesInfo?.job_url_key}`, "_blank");

  const handleSetActiveUser = (user: TApiGetMessages) => {
    if (!anchorEl) {
      if (user.candidate_id !== recruiterId) {
        dispatch(setActiveUser(user));
      }
    }
  };

  const handleRefreshMessages = () => {
    dispatch(getLatestMessages());
    dispatch(refreshEmail());
    if (recruiterId) {
      dispatch(
        refreshMessages({
          recruiter_id: recruiterId,
          candidate_id: recruiterId,
          application_id: applicationId,
          job_id: jobId,
        })
      );
    }
  };

  const handleOpenUserMenu = (event: React.TouchEvent<HTMLDivElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  const handleOnOpenDialog = ({ type }: { type: MESSAGES_DIALOG_TYPE }) => {
    setOpenedDialogType(type);
  };

  const handleCloseDialog = () => {
    setOpenedDialogType(null);
  };

  const handleReadUnread = (user: TLatestMessage, isNewMessage: boolean) => {
    dispatch(
      readUnreadMessage({
        recruiter_id: user.recruiter_id,
        application_id: user.application_id,
        candidate_id: user.candidate_id,
        job_id: user.job_id,
        isNewMessage,
      })
    );
  };

  const handleSaveDialog = () => {
    switch (openedDialogType) {
      case MESSAGES_DIALOG_TYPE.DELETE:
        if (messages.length === 1) {
          dispatch(resetCurrentMessageState());
        }
        dispatch(
          deleteMessage({
            message_id: deleteMessageId,
            application_id: applicationId,
            job_id: jobId,
            snackbar_title: t("messages_page.delete_message_snackbar_title"),
          })
        );
        setDeleteMessageId(null);
        break;
      case MESSAGES_DIALOG_TYPE.CONFIRMATION:
        if (editTextarea.value.length > 0) {
          dispatch(
            updateMessage({
              message_id: editMessageId,
              application_id: applicationId,
              job_id: jobId,
              text: editTextarea.value,
            })
          );
        } else {
          if (messages.length === 1) {
            dispatch(resetCurrentMessageState());
          }
          dispatch(
            deleteMessage({
              message_id: editMessageId,
              application_id: applicationId,
              job_id: jobId,
              snackbar_title: t("messages_page.delete_message_snackbar_title"),
            })
          );
        }

        setEditMessageId(null);
        break;
      case MESSAGES_DIALOG_TYPE.DISCARD:
        setEditMessageId(null);
        break;
      // no default
    }
    handleCloseDialog();
  };

  const handleEdit = (event: React.MouseEvent<SVGSVGElement>) => {
    event.preventDefault();
    setEditMessageId(activeMessageId);
  };

  const handleSendMessages = (e: KeyboardEvent) => {
    const value = textarea ? textarea.value : "";
    if (e.type === "click" && !e.shiftKey && value.trim().length > 0) {
      dispatch(
        postMessage({
          job_id: jobId,
          application_id: applicationId,
          recruiter_id: recruiterId,
          candidate_id: recruiterId,
          text: value.trim(),
        })
      );
    }
  };

  useEffect(() => {
    if (!isLoading) {
      if (textarea) {
        textarea.value = "";
        textarea.focus();
      }
    }
  }, [isLoading]);

  const editMessageDefaultValue = useMemo(() => {
    const findEditMessage = messages.find(
      (item) => item.message_id === activeMessageId
    );
    if (findEditMessage) {
      return findEditMessage.text;
    }
    return "";
  }, [editMessageId]);

  useEffect(() => {
    dispatch(resetCurrentApplication());
    if (!isCandidate && !allApplications) {
      dispatch(fetchAllApplications());
    }
    if (recruiterId && !messagesInfo) {
      dispatch(
        getMessages({
          recruiter_id: recruiterId,
          candidate_id: recruiterId,
          application_id: applicationId,
          job_id: jobId,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (isCandidate !== undefined) {
      dispatch(getLatestMessages());
    }
    if (recruiterId) {
      dispatch(
        getMessages({
          recruiter_id: recruiterId,
          candidate_id: recruiterId,
          application_id: applicationId,
          job_id: jobId,
        })
      );
    }
  }, [recruiterId, isCandidate]);

  useEffect(() => {
    const interval = setInterval(handleRefreshMessages, twoMinutes);
    return () => clearInterval(interval);
  }, [recruiterId]);

  useEffect(
    () => () => {
      dispatch(resetMessageState());
    },
    []
  );

  return (
    <MessagesComponent_NewPage
      t={t}
      desktop={desktop}
      recruiterId={recruiterId}
      messagesInfo={messagesInfo}
      messages={messages}
      latestMessages={latestMessages as []}
      firstname={firstName}
      lastname={lastName}
      isCandidate={isCandidate}
      editMessageId={editMessageId}
      deleteMessageId={deleteMessageId}
      profilePhoto={photo}
      openedDialogType={openedDialogType}
      activeMessageId={activeMessageId}
      editMessageDefaultValue={editMessageDefaultValue}
      anchorEl={anchorEl as HTMLDivElement}
      handleReadUnread={handleReadUnread}
      handleCloseUserMenu={handleCloseUserMenu}
      handleOpenUserMenu={handleOpenUserMenu}
      setActiveMessageId={setActiveMessageId}
      setEditMessageId={setEditMessageId}
      handleEdit={handleEdit}
      setDeleteMessageId={setDeleteMessageId}
      openJob={openJob}
      handleSetActiveUser={handleSetActiveUser}
      onOpenDialog={handleOnOpenDialog}
      onCloseDialog={handleCloseDialog}
      onSaveDialog={handleSaveDialog}
      handleSendMessage={handleSendMessages}
    />
  );
};

export default Messages_NewPage;
