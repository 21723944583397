import { Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledTwoTextfieldsContainer = styled(Stack)({
  ".disabled-field": {
    pointerEvents: "none",
    ".MuiInputBase-input": {
      color: "#6B7280!important",
      fontWeight: "400",
    },
  },
});
