import React, { useMemo } from "react";
import { Stack, Avatar } from "@mui/material";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  IEditMessageComponent,
  MESSAGES_DIALOG_TYPE
} from "../../models/Messages";
import { StyledChatTextarea, StyledNameTypography } from "./styles";

const EditMessageComponent = ({
  t,
  item,
  messagesInfo,
  loggedUser,
  firstname,
  lastname,
  profilePhoto,
  editMessageDefaultValue,
  onOpenDialog
}: IEditMessageComponent) => {
  const isLoggedUser = useMemo<boolean>(() => {
    return item.origin === loggedUser;
  }, [loggedUser, item]);

  return (
    <Stack spacing={1.5}>
      <Stack direction="row" spacing={1}>
        <Avatar
          src={isLoggedUser ? profilePhoto : messagesInfo?.profile_photo}
        />
        <Stack
          p={1.5}
          border="1px solid #E5E7EB"
          borderBottom="3px solid #818CF8"
          spacing={1}
          borderRadius={1.5}
          width="100%"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <StyledNameTypography>
              {isLoggedUser
                ? `${firstname} ${lastname}`
                : `${messagesInfo?.firstname} ${messagesInfo?.lastname}`}
            </StyledNameTypography>
          </Stack>
          <Stack width="100%">
            <StyledChatTextarea
              id="edit-chat-area"
              defaultValue={editMessageDefaultValue}
              placeholder={t("messages_page.new_message_placeholder") as string}
              rows={4}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        <XMarkIcon
          width={20}
          height={20}
          stroke="#178CF2"
          cursor="pointer"
          onClick={() => onOpenDialog({ type: MESSAGES_DIALOG_TYPE.DISCARD })}
        />
        <CheckIcon
          width={20}
          height={20}
          stroke="#178CF2"
          cursor="pointer"
          onClick={() =>
            onOpenDialog({ type: MESSAGES_DIALOG_TYPE.CONFIRMATION })
          }
        />
      </Stack>
    </Stack>
  );
};

export default EditMessageComponent;
