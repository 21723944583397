import React from "react";
import { Stack, Button, Typography, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import noCompanyInfo from "../../assets/no_archived_jobs.png";

const NoData = ({
  title,
  headings,
  subtitles,
  containerHeight = "100vh",
  noButton = false,
}: {
  readonly title: string;
  readonly headings?: {
    text: string;
    link?: string;
  }[];
  readonly subtitles?: string[];
  readonly containerHeight?: string | number;
  readonly noButton?: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Stack
      width="100%"
      height={containerHeight}
      alignItems="center"
      justifyContent="center"
      spacing={4}
    >
      <img src={noCompanyInfo} alt="no-company" width={212.78} height={200} />
      <Stack spacing={1} alignItems="center">
        <Typography>{title}</Typography>
        <Stack alignItems="center">
          {headings?.map((item, index) => (
            <Typography key={index} variant="subtitle2" color="textSecondary">
              {item.text}{" "}
              {item.link && <Link color="primary">{item.link}.</Link>}
            </Typography>
          ))}
        </Stack>
      </Stack>
      {subtitles?.map((item, index) => (
        <Typography key={index} variant="subtitle2" color="textSecondary">
          {item}
        </Typography>
      ))}
      {!noButton && (
        <Button variant="contained" onClick={() => navigate("/")}>
          {t("company_page.back_to_homepage")}
        </Button>
      )}
    </Stack>
  );
};

export default NoData;
