import React from "react";
import { Stack, InputLabel, TextField } from "@mui/material";
import { TTwoValidationTextFieldsComponent } from "../../models/common";

const TwoValidationTextfields = ({
  desktop = true,
  background = "#fff",
  padding = 3,
  spacing = 3,
  labels,
  placeholders,
  registers,
  errors,
  flex,
  register,
}: TTwoValidationTextFieldsComponent) => (
  <Stack
    direction={desktop ? "row" : "column"}
    padding={padding}
    spacing={spacing}
    sx={{
      background,
    }}
  >
    <Stack flex={flex ? flex[0] : 1} spacing={0.5}>
      <InputLabel>{labels[0]}</InputLabel>
      <TextField
        {...register(registers[0])}
        placeholder={placeholders[0]}
        error={Boolean(errors[registers[0]])}
        helperText={errors[registers[0]]?.message as string}
        fullWidth
      />
    </Stack>
    <Stack flex={flex ? flex[1] : 1} spacing={0.5}>
      <InputLabel>{labels[1]}</InputLabel>
      <TextField
        {...register(registers[1])}
        placeholder={placeholders[1]}
        error={Boolean(errors[registers[1]])}
        helperText={errors[registers[1]]?.message as string}
        fullWidth
      />
    </Stack>
  </Stack>
);

export default TwoValidationTextfields;
