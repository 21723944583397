import { Box, Stack, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import AutocompleteFilter from "../../../../components/filters_new/AutocompleteFilter";
import React from "react";
import { StyledAdditionalQuestionsFieldContainer } from "../../styles";
import { IAdditionalQuestionsDriverLicensesComponent } from "../../../../models/JobPreview";

const AdditionalQuestionsDriverLicensesComponent = ({
  t,
  control,
  desktop,
  driverLicenses,
  companyColor,
  boldHeading,
}: IAdditionalQuestionsDriverLicensesComponent) => (
  <StyledAdditionalQuestionsFieldContainer
    borderBottom={boldHeading ? "0px !important" : "1px solid #E5E7EB"}
    py={boldHeading ? "0px !important" : "24px !important"}
    desktop={desktop}
    companyColor={companyColor}
  >
    <Stack
      className={"title"}
      height={boldHeading ? "auto !important" : "30px !important"}
    >
      <Typography variant="subtitle2" fontWeight={boldHeading ? 500 : 400}>
        {t("additional_questions.driving_licenses")}
      </Typography>
    </Stack>
    <Controller
      name={"driverLicense"}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Box className={"input-field"}>
          <AutocompleteFilter
            multiple
            size={"small"}
            onChange={onChange}
            value={(value as unknown as string) || []}
            options={driverLicenses}
            placeholder={t("additional_questions.driving_licenses_placeholder")}
          />
        </Box>
      )}
    />
  </StyledAdditionalQuestionsFieldContainer>
);

export default AdditionalQuestionsDriverLicensesComponent;
