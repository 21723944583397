export const getFilters = ({
  list,
  targetField,
  targetValue
}: {
  list: { [x: string]: string }[];
  targetField: string;
  targetValue: string;
}): { label: string; value: string }[] | [] =>
  list.reduce(
    (
      acc: { label: string; value: string }[],
      listItem: { [x: string]: string }
    ) =>
      acc.findIndex(
        (item: { label: string; value: string }) =>
          item.value === listItem[targetValue]
      ) > -1
        ? acc
        : [
            ...acc,
            {
              label: listItem[targetField],
              value: listItem[targetValue]
            }
          ],
    []
  );

export const isEqualObjects = (
  object1: { [x: string]: unknown } | null,
  object2: { [x: string]: unknown } | null
) => {
  if (object1 && object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
      if (object1[key] !== object2[key]) return false;
    }

    return true;
  }
  return false;
};

export const getCurrencyValues = (codeString: string | null) => {
  if (!codeString) return { code: "", title: "" };
  const splitCode = codeString.split("(");
  return { code: `(${splitCode[1]}`, title: codeString };
};

interface MyObject {
  [key: string]: string;
}

export const convertObjectToArray = (
  object: MyObject
): Array<{ label: string; value: string }> => {
  const newArray: Array<{ label: string; value: string }> = [];
  for (const key in object) {
    const value = object[key];
    newArray.push({
      label: value,
      value: key
    });
  }
  return newArray;
};

export const isValidPhoneNumber = (e: KeyboardEvent) => {
  const specialCharacters = /((^|, )([+-]||[0-9]))+$/;
  if (!specialCharacters.test(e.key)) {
    e.preventDefault();
  }
};

export const isValidNumber = (e: KeyboardEvent) => {
  const specialCharacters = /^[0-9]*$/;
  if (!specialCharacters.test(e.key)) {
    e.preventDefault();
  }
};

export const getSalarySymbol = (currency: string) => {
  if (currency.toLowerCase().includes("eur")) return "€";
  if (currency.toLowerCase().includes("usd")) return "$";
  if (currency.toLowerCase().includes("chf")) return "₣";
  if (currency.toLowerCase().includes("gbp")) return "£";
};

export const convertSalaryString = (salary?: number) => {
  const salaryArr = salary ? salary.toString().split("") : [];
  for (let i = salaryArr.length - 1; i > 0; i--) {
    if (i % 3 === 0) {
      salaryArr.splice(-i, 0, ".");
    }
  }
  return salaryArr.join("");
};

export const convertLanguageLevel = (value: string) => {
  const lang = localStorage.getItem("lang");
  switch (value) {
    case "GRUNDKENNTNISSE":
    case "BASIC":
      return lang === "en_US" ? "BASIC" : "GRUNDKENNTNISSE";
    case "FORTGESCHRITTEN":
    case "INTERMEDIARY":
      return lang === "en_US" ? "INTERMEDIARY" : "FORTGESCHRITTEN";
    case "SEHR GUT":
    case "ADVANCED":
      return lang === "en_US" ? "ADVANCED" : "SEHR GUT";
    case "FLIEßEND":
    case "FLUENT":
      return lang === "en_US" ? "FLUENT" : "FLIEßEND";
    case "MUTTERSPRACHE":
    case "NATIVE":
      return lang === "en_US" ? "NATIVE" : "MUTTERSPRACHE";
    default:
      return "";
  }
};
