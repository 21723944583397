import React from "react";
import { Stack, Typography, Button } from "@mui/material";
import { TFunction } from "i18next";
import { generateFullDate } from "../../utils";

const ConnectedAgencyHeader = ({
  t,
  agencyName,
  connectionDate,
  openDisconnectDialog,
}: {
  readonly t: TFunction;
  readonly agencyName: string | null;
  readonly connectionDate: string | null;
  readonly openDisconnectDialog: () => void;
}) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <Stack direction="row" spacing={0.5}>
      <Typography variant="subtitle2" color="textSecondary">
        {t("customers.connected_with")}
      </Typography>
      <Typography variant="subtitle2" fontWeight={500}>
        {agencyName}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        {t("customers.since")}
      </Typography>
      <Typography variant="subtitle2" fontWeight={500}>
        {generateFullDate(connectionDate)}
      </Typography>
    </Stack>
    <Button variant="contained" color="warning" onClick={openDisconnectDialog}>
      {t("customers.disconnect")}
    </Button>
  </Stack>
);

export default ConnectedAgencyHeader;
