import { apiRequest } from "./config";

export const apiGetBasicJobBoards = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiGetJobDetails = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiGetJobTemplate = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: { templateId: string };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiUpdateHeaderImage = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: {
    title: string;
    template_name: string;
    template_header_1: File;
    company_id: string;
  };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiGetBookings = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiDuplicateJob = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });
