import React from "react";
import { Stack, Typography } from "@mui/material";
import { TFunction } from "i18next";
import { generateActivityLogDate, getTimeAgo } from "../../utils";

export default function ByDateText({
  t,
  firstname,
  lastname,
  date,
}: {
  readonly t: TFunction;
  readonly firstname?: string;
  readonly lastname?: string;
  readonly date: string;
}) {
  return (
    <Stack direction="row" width="100%" justifyContent="space-between" pr={3}>
      <Typography color="#6B7280" fontSize={12}>
        {firstname ? t("candidate.by_candidate") : ""} {firstname || ""}{" "}
        {lastname || ""} {t("candidate.on_date")}{" "}
        {generateActivityLogDate(date)}
      </Typography>
      <Typography color="#6B7280" fontSize={12}>
        {getTimeAgo(date)}
      </Typography>
    </Stack>
  );
}
