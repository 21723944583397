import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { PayloadActionWithCallback } from "../../models/common";
import {
  addCandidateInTalentPool,
  addCandidateInTalentPoolFailed,
  addCandidateInTalentPoolManually,
  addCandidateInTalentPoolManuallyFailed,
  addCandidateInTalentPoolManuallySuccess,
  addCandidateInTalentPoolSuccess,
  addUpdateTalentPool,
  addUpdateTalentPoolFailed,
  addUpdateTalentPoolSuccess,
  deleteCandidate,
  deleteCandidateFailed,
  deleteCandidateSuccess,
  deleteTalentPool,
  deleteTalentPoolFailed,
  deleteTalentPoolSuccess,
  fetchTalentPoolCandidates,
  fetchTalentPoolCandidatesFailed,
  fetchTalentPoolCandidatesSuccess,
  fetchTalentPools,
  fetchTalentPoolsFailed,
  fetchTalentPoolsSuccess,
} from "../reducers/TalentPools";
import {
  apiAddCandidateInTalentPool,
  apiAddCandidateToTalentPoolManually,
  apiAddTalentPool,
  apiCandidateChangeTalentPool,
  apiDeleteCandidateFromTalentPool,
  apiDeleteTalentPool,
  apiFetchTalentPoolCandidates,
  apiFetchTalentPools,
} from "../../api/TalentPools";
import {
  getTalentPoolCandidatesFilters,
  getTalentPoolCandidatesPagination,
} from "../selectors/Talentpool";
import { ITalentPool, ITalentPoolProfile } from "../../models/TalentPools";
import { TCandidateApplication } from "../../models/ApplicationPage";

const baseUrl = `/company/talentpools`;

function* handleOnFetchTalentPools() {
  const url = baseUrl;

  try {
    const { data } = yield call(apiFetchTalentPools, { url });
    const { talentpools } = data;
    yield put(fetchTalentPoolsSuccess(talentpools));
  } catch (e) {
    yield put(fetchTalentPoolsFailed(e)); // TODO handle error
  }
}

function* handleOnAddUpdateTalentPool({
  payload,
}: PayloadActionWithCallback<{ talentPool: ITalentPool }>) {
  const { talentPool, callback } = payload;
  const { id } = talentPool;
  const url = id ? `${baseUrl}/update/${id}` : `${baseUrl}/add`;

  try {
    yield call(apiAddTalentPool, { url, apiPayload: talentPool });
    yield put(addUpdateTalentPoolSuccess());
    const { data } = yield call(apiFetchTalentPools, {
      url: baseUrl,
    });
    const { talentpools } = data;
    yield put(fetchTalentPoolsSuccess(talentpools));
    yield call(callback);
  } catch (e) {
    yield put(addUpdateTalentPoolFailed(e)); // TODO handle error
  }
}

function* handleOnDeleteTalentPool({
  payload,
}: PayloadActionWithCallback<{
  id?: number;
}>) {
  const { id, callback } = payload;
  const url = `${baseUrl}/delete/${id}`;

  try {
    yield call(apiDeleteTalentPool, { url });
    yield put(deleteTalentPoolSuccess());
    yield call(callback);
  } catch (e) {
    yield put(deleteTalentPoolFailed(e)); // TODO handle error
  }
}

function* handleOnAddCandidateInTalentPool({
  payload,
}: PayloadActionWithCallback<{
  id: number;
  applications: any;
}>) {
  const { id, applications, callback } = payload;
  const addCandidateToTalentPoolUrl = `${baseUrl}-candidates/add/${id}`;
  const candidateChangeTalentPoolUrl = `${baseUrl}-candidates/move/${id}`;
  try {
    if (!applications.length) {
      const apiPayload = {
        application_id: applications.application_id,
      };

      if (applications?.is_talentpooled == 1) {
        yield call(apiCandidateChangeTalentPool, {
          url: candidateChangeTalentPoolUrl,
          apiPayload,
        });
      } else {
        yield call(apiAddCandidateInTalentPool, {
          url: addCandidateToTalentPoolUrl,
          apiPayload,
        });
      }
    } else {
      const requests: any = [];
      applications.map((application: TCandidateApplication) => {
        const apiPayload = {
          application_id: application.application_id as string,
        };
        if (application.is_talentpooled == 1) {
          requests.push(
            call(apiCandidateChangeTalentPool, {
              url: candidateChangeTalentPoolUrl,
              apiPayload,
            })
          );
        } else {
          requests.push(
            call(apiAddCandidateInTalentPool, {
              url: addCandidateToTalentPoolUrl,
              apiPayload,
            })
          );
        }
      });
      yield all(requests);
    }
    yield put(addCandidateInTalentPoolSuccess());
    yield call(callback);
  } catch (e) {
    yield put(addCandidateInTalentPoolFailed(e)); // TODO handle error
  }
}

function* handleOnDeleteCandidateFromTalentPool({
  payload,
}: PayloadActionWithCallback<{
  id?: number;
  recordId: string;
  applicationId: number;
}>) {
  const { id, applicationId, recordId, callback } = payload;
  const url = recordId
    ? `${baseUrl}-records/delete/${id}`
    : `${baseUrl}-candidates/delete/${id}`;

  const apiPayload = recordId
    ? {
        record_id: recordId,
      }
    : {
        application_id: applicationId,
      };

  try {
    yield call(apiDeleteCandidateFromTalentPool, { url, apiPayload });
    yield put(deleteCandidateSuccess());
    yield call(callback);
  } catch (e) {
    yield put(deleteCandidateFailed(e)); // TODO handle error
  }
}

function* handleOnAddCandidateToTalentPoolManually({
  payload,
}: PayloadActionWithCallback<ITalentPoolProfile & { talentpool_id: string }>) {
  const {
    talentpool_id,
    firstname,
    lastname,
    email,
    phonenumber,
    location,
    country,
    cv,
    callback,
  } = payload;
  const url = `${baseUrl}-records/add/${talentpool_id}`;

  try {
    yield call(apiAddCandidateToTalentPoolManually, {
      url,
      apiPayload: {
        talentpool_id,
        firstname,
        lastname,
        email,
        phonenumber,
        location,
        country,
        file: cv,
      },
    });
    yield put(addCandidateInTalentPoolManuallySuccess());
    yield call(callback);
  } catch (e) {
    yield put(addCandidateInTalentPoolManuallyFailed(e)); // TODO handle error
  }
}

function* handleOnFetchTalentPoolCandidates({ payload }: { payload: number }) {
  const url = `${baseUrl}/items-list/${payload}`;
  const { quickSearch, location, skill } = yield select(
    getTalentPoolCandidatesFilters
  );

  const { pageNum, pageSize } = yield select(getTalentPoolCandidatesPagination);

  const apiPayload = {
    name: quickSearch || undefined,
    location: location || undefined,
    skill: skill || undefined,
    pagenum: pageNum,
    pagesize: pageSize,
  };

  try {
    const { data } = yield call(apiFetchTalentPoolCandidates, {
      url,
      apiPayload,
    });
    const { items, totalCount } = data;
    yield put(
      fetchTalentPoolCandidatesSuccess({ candidates: items, totalCount })
    );
  } catch (e) {
    yield put(fetchTalentPoolCandidatesFailed(e)); // TODO handle error
  }
}

function* TalentPoolSaga() {
  yield takeLatest(addUpdateTalentPool, handleOnAddUpdateTalentPool);
  yield takeLatest(fetchTalentPools, handleOnFetchTalentPools);
  yield takeLatest(deleteTalentPool, handleOnDeleteTalentPool);
  yield takeLatest(addCandidateInTalentPool, handleOnAddCandidateInTalentPool);
  yield takeLatest(
    fetchTalentPoolCandidates,
    handleOnFetchTalentPoolCandidates
  );
  yield takeLatest(deleteCandidate, handleOnDeleteCandidateFromTalentPool);
  yield takeLatest(
    addCandidateInTalentPoolManually,
    handleOnAddCandidateToTalentPoolManually
  );
}

export default TalentPoolSaga;
