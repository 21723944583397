import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Stack, Typography } from "@mui/material";
import JobDescriptionFields from "./JobDescriptionFields";
import MarketingMainInfo from "./MarketingMainInfo";
import {
  StyledMarketingJobDataContainer,
  StyledMarketingContactButton,
  StyledMarketingPageContainer,
} from "./style";
import { IMarketingStepOneComponent } from "../../models/Marketing";

const MarketingStepOne = ({
  desktop,
  isError,
  countries,
  jobData,
  control,
  register,
  onChangeJobUrl,
  onGenerateJobData,
  onRequestAppointment,
}: IMarketingStepOneComponent) => (
  <Stack>
    {!jobData && (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Stack spacing={3}>
            <StyledMarketingPageContainer desktop={desktop}>
              <MarketingMainInfo
                desktop={desktop}
                isError={isError}
                onChangeJobUrl={onChangeJobUrl}
                onGenerateJobData={onGenerateJobData}
              />
            </StyledMarketingPageContainer>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              p={3}
              border="1px solid #E5E7EB"
              borderRadius={1.5}
              sx={{
                background: "#fff",
              }}
            >
              <Typography color="#6B7280" variant="body2">
                Sie haben noch fragen?
              </Typography>
              <StyledMarketingContactButton
                variant="outlined"
                onClick={onRequestAppointment}
              >
                Jetzt Termin buchen
              </StyledMarketingContactButton>
            </Stack>
          </Stack>
        </motion.div>
      </AnimatePresence>
    )}
    {desktop && jobData ? (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Stack direction={"row"} height="100%" spacing={3}>
            <StyledMarketingJobDataContainer desktop={desktop}>
              <Stack width="100%">
                <JobDescriptionFields
                  desktop={desktop}
                  control={control}
                  countries={countries}
                  register={register}
                />
              </Stack>
            </StyledMarketingJobDataContainer>
          </Stack>
        </motion.div>
      </AnimatePresence>
    ) : jobData ? (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Stack width="100%" borderRadius={6}>
            <Stack p="24px 0px">
              <JobDescriptionFields
                desktop={desktop}
                control={control}
                countries={countries}
                register={register}
              />
            </Stack>
          </Stack>
        </motion.div>
      </AnimatePresence>
    ) : null}
  </Stack>
);

export default MarketingStepOne;
