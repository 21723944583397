import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import CandidateNoteActionsComponent from "./component";
import { IApplicationNotesActions } from "../../../../models/Applications";

const CandidateNoteActions = ({
  noteId,
  isCurrentUser,
  onDeleteNote,
  onSubmitComment,
}: IApplicationNotesActions) => {
  const { t } = useTranslation();

  const [comment, setComment] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleOnChangeComment = (e: ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const handleOnToggleEditMode = () => {
    if (isEditMode) {
      setComment("");
    }
    setIsEditMode(!isEditMode);
  };

  const handleOnSubmitComment = () => {
    onSubmitComment({ comment, note_id: noteId });
  };

  const handleOnDeleteNote = () => {
    onDeleteNote(noteId);
  };

  const handleOpenCloseDeleteDialog = (value: boolean) => {
    setIsDeleteDialogOpen(value);
  };

  return (
    <CandidateNoteActionsComponent
      t={t}
      comment={comment}
      isCurrentUser={isCurrentUser}
      isEditMode={isEditMode}
      isDeleteDialogOpen={isDeleteDialogOpen}
      onChangeComment={handleOnChangeComment}
      onDelete={handleOnDeleteNote}
      onSubmit={handleOnSubmitComment}
      onToggleEditMode={handleOnToggleEditMode}
      onOpenCloseDeleteDialog={handleOpenCloseDeleteDialog}
    />
  );
};

export default CandidateNoteActions;
