import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../components/Loading";
import JobPreviewComponent from "../JobPreview/component";
import { getJobPreviewState } from "../../store_new/selectors/JobPreview";
import { formatLogoUrl } from "../../utils";
import { fetchPreviewTemplate } from "../../store_new/reducers/JobPreview";
import { getCompanySettingsCompanyState } from "../../store_new/selectors/Company";
import { getCurrentUserData } from "../../store_new/selectors/CurrentUser";

const TemplatePreviewNewPage = ({ desktop }: { readonly desktop: boolean }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const { company } = useSelector(getCompanySettingsCompanyState);
  const { jobTemplate, isLoading } = useSelector(getJobPreviewState);
  const { isAgency } = useSelector(getCurrentUserData);

  const mainLogo = jobTemplate?.logo;
  const formattedLogo = formatLogoUrl(mainLogo);

  useEffect(() => {
    if (!isLoading && company) {
      dispatch(fetchPreviewTemplate(id as string));
    }
  }, [company]);

  return isLoading || !jobTemplate ? (
    <Loading />
  ) : (
    <JobPreviewComponent
      disabled
      companyColor={jobTemplate.template_title_color as string}
      logo={isAgency ? null : formattedLogo}
      t={t}
      desktop={desktop}
    />
  );
};

export default TemplatePreviewNewPage;
