import React from "react";
import { Stack, Tooltip } from "@mui/material";
import { ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ACTION_TRIGGER_VARIANT,
  IIncompleteDeleteDataComponent,
} from "../../models/Automation";
import { StyledDeleteNotCompletedIcon } from "./style";

const IncompleteDeleteData = ({
  t,
  data,
  variant,
  currentAutomation,
  onHoverLeaveDeleteActionIcon,
  onDeleteTriggerAction,
}: IIncompleteDeleteDataComponent) => {
  const noTrigger =
    variant === ACTION_TRIGGER_VARIANT.TRIGGER && !currentAutomation?.trigger;
  const triggerExist =
    variant === ACTION_TRIGGER_VARIANT.TRIGGER && currentAutomation?.trigger;
  const incompleteAction =
    variant === ACTION_TRIGGER_VARIANT.COMPONENT && !data.actionCompleted;
  const showMissingDataIcon = noTrigger || incompleteAction;
  const showDeleteIcon =
    triggerExist || variant === ACTION_TRIGGER_VARIANT.COMPONENT;

  const tooltipText = noTrigger
    ? t("automation.noTriggerText")
    : incompleteAction
      ? t("automation.incompleteActionText")
      : "";

  return (
    <Stack direction="row" spacing={1}>
      {showMissingDataIcon && (
        <Tooltip title={tooltipText}>
          <StyledDeleteNotCompletedIcon background="#FEF3C7">
            <ExclamationCircleIcon
              width={20}
              height={20}
              stroke="#D97706"
              fill="transparent"
            />
          </StyledDeleteNotCompletedIcon>
        </Tooltip>
      )}
      {showDeleteIcon && (
        <StyledDeleteNotCompletedIcon
          background="#FEF2F2"
          onClick={() => {
            if (onDeleteTriggerAction) onDeleteTriggerAction(variant, data.id);
          }}
          onMouseLeave={() => {
            if (onHoverLeaveDeleteActionIcon)
              onHoverLeaveDeleteActionIcon(false);
          }}
          onMouseOver={() => {
            if (onHoverLeaveDeleteActionIcon)
              onHoverLeaveDeleteActionIcon(true);
          }}
        >
          <XMarkIcon width={20} height={20} stroke="#EF4444" />
        </StyledDeleteNotCompletedIcon>
      )}
    </Stack>
  );
};

export default IncompleteDeleteData;
