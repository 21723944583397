import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AdditionalQuestionComponent from "./component";
import {
  additionalQuestionsFormFieldsSchema,
  defaultAdditionalQuestionsValues
} from "./config";
import {
  fetchJob,
  skipAdditionalQuestionsUpdate,
  submitAdditionalQuestions
} from "../../store_new/reducers/JobPreview";
import { getJobPreviewState } from "../../store_new/selectors/JobPreview";
import { TAdditionalQuestionsFormFields } from "../../models/JobPreview";
import { useParams } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import { formatLogoUrl } from "../../utils";
import { candidateLogIn } from "../../store_new/reducers/Auth";
import {
  fetchUserWithCallback,
  updateCurrentUserData
} from "../../store_new/reducers/CurrentUser";
import { getCurrentUserData } from "../../store_new/selectors/CurrentUser";

const AdditionalQuestions_new = ({ desktop }: { desktop: boolean }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { code } = useParams();

  const { lang } = useSelector(getCurrentUserData);

  const languages = [
    {
      key: "de_DE",
      value: t("userSettings.german") as string,
      label: t("userSettings.german") as string
    },
    {
      key: "en_US",
      label: t("userSettings.english"),
      value: t("userSettings.english")
    }
  ];

  const [language, setLanguage] = useState<string>();

  const { control, handleSubmit, setValue } =
    useForm<TAdditionalQuestionsFormFields>({
      resolver: yupResolver(additionalQuestionsFormFieldsSchema),
      defaultValues: defaultAdditionalQuestionsValues
    });

  const {
    jobData,
    isSubmitted,
    isLoading,
    isLoadingAdditionalQuestionsUpdate
  } = useSelector(getJobPreviewState);
  const token = localStorage.getItem("token");

  const jobCode = location.search.substring(location.search.length - 10);
  const logo = formatLogoUrl(jobData?.job?.logo);

  const currentLocation = useMemo(() => {
    const primaryLocation = jobData?.job_locations?.length
      ? jobData?.job_locations[0]
      : undefined;
    if (primaryLocation) {
      return `${primaryLocation.city}, ${primaryLocation.nicename}`;
    }

    return t("company_page.unknown");
  }, [jobData]);

  const hasQuestions = useMemo(() => {
    if (jobData?.job_questions) {
      return Boolean(jobData.job_questions.length);
    }
    return false;
  }, [jobData]);

  const handleOnSubmit = (data: TAdditionalQuestionsFormFields) => {
    dispatch(
      submitAdditionalQuestions({ data, jobUrlKey: jobData.job.url_key })
    );
  };

  const handleOnSkip = () => {
    dispatch(skipAdditionalQuestionsUpdate());
  };

  const onCandidateLoginSuccess = () => {
    if (
      !location.search.includes("action") &&
      !location.pathname.includes("additional-questions")
    ) {
      navigate(`/job/${jobCode}`);
    }
  };

  const handleOnChangeLanguage = (newLanguage: string) => {
    const findLanguage = languages.find((item) => item.label === newLanguage);

    if (findLanguage?.label !== language) {
      dispatch(
        updateCurrentUserData({
          data: {
            lang: findLanguage?.key
          },
          callback: () => {
            dispatch(
              fetchUserWithCallback({
                callback: () =>
                  dispatch(
                    fetchJob({
                      jobUrlKey: jobCode
                    })
                  )
              })
            );
          }
        })
      );
    }
  };

  const isApplicationNeedsMoreDetails = useMemo(
    () =>
      jobData?.job?.phone_required ||
      jobData?.job?.phone_required ||
      jobData?.job?.location_required ||
      jobData?.job?.salary_expectation_required ||
      jobData?.job?.earliest_start_date_required ||
      jobData?.job?.current_professional_status_required ||
      jobData?.job?.highest_degree_required ||
      jobData?.job?.driver_license_required ||
      jobData?.job?.langs_required ||
      jobData?.job?.skills_required ||
      jobData?.job?.other_documents_required ||
      jobData?.job?.profile_photo_required ||
      hasQuestions,
    [jobData]
  );

  useEffect(() => {
    if (token) {
      dispatch(
        fetchJob({
          jobUrlKey: jobCode
        })
      );
    }
  }, [token]);

  useEffect(() => {
    if (!token && code) {
      dispatch(
        candidateLogIn({
          code,
          callback: () => onCandidateLoginSuccess()
        })
      );
    }
  }, []);

  useEffect(() => {
    const findLanguage = languages.find((item) => item.key === lang);
    setLanguage(findLanguage?.label);
  }, [lang, jobData]);

  return (
    <AdditionalQuestionComponent
      t={t}
      navigate={navigate}
      control={control}
      currentLocation={currentLocation}
      desktop={desktop}
      hasQuestions={hasQuestions}
      isLoading={isLoading}
      isLoadingAdditionalQuestionsUpdate={isLoadingAdditionalQuestionsUpdate}
      isSubmitted={isSubmitted}
      job={jobData?.job}
      companyColor={jobData.company_color}
      logo={logo}
      moreDetails={isApplicationNeedsMoreDetails}
      language={language as string}
      languages={languages as []}
      onSkip={handleOnSkip}
      setValue={setValue}
      handleSubmit={handleSubmit}
      onSubmit={handleOnSubmit}
      onChangeLanguage={handleOnChangeLanguage}
    />
  );
};

export default AdditionalQuestions_new;
