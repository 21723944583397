import React from "react";
import { Tooltip, Stack, Typography, Avatar } from "@mui/material";

const ShowMoreTooltipComponent = ({
  children,
  items,
}: {
  readonly children: any;
  readonly items: [];
}) => (
  <Tooltip
    componentsProps={{
      tooltip: {
        sx: {
          background: "#EEF2FF",
        },
      },
    }}
    title={
      <Stack p={1} spacing={1}>
        {items.map((item: any, index: number) => (
          <Stack key={index} direction="row" spacing={1} alignItems="center">
            {item.photo && (
              <Avatar
                src={item.photo as string}
                sx={{
                  width: 24,
                  height: 24,
                  border: "1.5px solid #fff",
                }}
              >
                <Typography variant="caption" fontSize={11.5}>
                  {`${item.firstname.substring(0, 1)}${item.lastname.substring(
                    0,
                    1
                  )}`}
                </Typography>
              </Avatar>
            )}
            {item.icon || null}
            <Typography variant="subtitle2" color="textSecondary" fontSize={12}>
              {item.firstname} {item.lastname || ""}
            </Typography>
          </Stack>
        ))}
      </Stack>
    }
  >
    {children}
  </Tooltip>
);

export default ShowMoreTooltipComponent;
