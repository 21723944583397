import React from "react";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import RadioButtonSelect from "../cellRenderers/radioButtonSelect";
import { TDataGridGetColDef } from "../../../models/DataGrid";

const getTalentPoolJobsColDef = ({
  t,
}: Pick<TDataGridGetColDef, "t">): ColDef[] => [
  {
    field: "",
    flex: 0.3,
    cellRenderer: (params: ValueGetterParams) => (
      <RadioButtonSelect data={params.data} />
    ),
  },
  {
    field: "title",
    headerName: t("joblist.title") as string,
    flex: 0.7,
  },
  {
    field: "locations",
    headerName: t("joblist.location_placeholder") as string,
    flex: 0.5,
    valueGetter: (params: ValueGetterParams) =>
      params.data["locations"][0]?.city,
  },
  {
    field: "seniority_level",
    headerName: t("joblist.seniority") as string,
    flex: 0.45,
  },
  {
    field: "industry_title",
    headerName: t("create_job_fourth_step.industry") as string,
    flex: 0.5,
  },
];

export default getTalentPoolJobsColDef;
