import React from "react";
import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { TFunction } from "i18next";
import { StyledAdditionalQuestionsFieldContainer } from "../styles";
import { IAdditionalQuestionSimpleSection } from "../../../models/JobPreview";
import { isValidNumber } from "../../../utils/common";

const AdditionalQuestionsSalary = ({
  t,
  desktop,
  control,
}: IAdditionalQuestionSimpleSection & { readonly t: TFunction }) => (
  <StyledAdditionalQuestionsFieldContainer desktop={desktop}>
    <Stack className={"title"}>
      <Typography variant="subtitle2">
        {t("additional_questions.salary_expectation")}
      </Typography>
    </Stack>
    <Controller
      name={"salary"}
      control={control}
      render={({ field }) => (
        <Box className={"input-field"}>
          <TextField
            onKeyPress={(e) => isValidNumber(e as unknown as KeyboardEvent)}
            placeholder={"0.00"}
            fullWidth
            {...field}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography variant="subtitle2" color="textSecondary">
                    €
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
    />
  </StyledAdditionalQuestionsFieldContainer>
);

export default AdditionalQuestionsSalary;
