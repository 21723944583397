import React from "react";
import { Stack, Typography } from "@mui/material";
import { UserIcon } from "@heroicons/react/24/outline";
import { StyledApplicationsNumber } from "../styles";

const ApplicationsNumber = ({
  applicantsNumber,
  onClick,
}: {
  readonly applicantsNumber: number;
  readonly onClick?: (data: any) => void;
}) => {
  return (
    <StyledApplicationsNumber onClick={onClick}>
      <Stack className="icon-container">
        <UserIcon />
      </Stack>
      <Typography
        variant="subtitle2"
        fontSize={12}
        color="#6B7280"
        fontWeight={500}
      >
        {applicantsNumber}
      </Typography>
    </StyledApplicationsNumber>
  );
};

export default ApplicationsNumber;
