import { Box, InputAdornment, Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledSectionContainer = styled(Stack)({
  padding: 24,
  backgroundColor: "#fff",
  border: "1px solid #E5E7EB",
  borderRadius: "6px",
  ".disabled-field": {
    pointerEvents: "none",
  },
  ".MuiInputBase-input": {
    color: "#6B7280 !important",
    fontWeight: "400",
  },
});

export const StyledFieldsContainer = styled(Box)(
  ({ fullWidth }: { fullWidth: boolean }) => ({
    width: fullWidth ? "100%" : "calc(50% - 8px)",
    ".disabled-field": {
      pointerEvents: "none",
      ".MuiInputBase-input": {
        color: "#6B7280 !important",
        fontWeight: "400",
      },
    },
  })
);

export const StyledFlagContainer = styled(InputAdornment)({
  display: "flex",
  width: 20,
  height: 14,
  overflow: "hidden",
  svg: {
    width: 20,
    height: 12,
  },
});
