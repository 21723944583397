import { Button, Chip, Stack, TextField } from "@mui/material";
import React from "react";
import { IChipsManagerComponent } from "../../models/common";
import { StyledChipsContainer, StyledChipsManagerContainer } from "./styles";

const ChipsManagerComponent = ({
  chip,
  chipList,
  placeholder,
  errorMessage,
  onAdd,
  onDelete,
  onChange,
}: IChipsManagerComponent) => (
  <StyledChipsManagerContainer spacing={2}>
    <Stack direction={"row"} spacing={2}>
      <TextField
        value={chip}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        error={Boolean(errorMessage)}
        placeholder={placeholder as string}
        helperText={errorMessage}
        fullWidth
      />
      <Button variant={"outlined"} onClick={onAdd} disabled={!chip.length}>
        +
      </Button>
    </Stack>
    {!!chipList?.length && (
      <StyledChipsContainer>
        {chipList.map((chip: string, index: number) => (
          <Chip
            className={"chips-manager"}
            size={"medium"}
            color={"secondary"}
            variant={"outlined"}
            key={chip + index}
            label={chip}
            onDelete={() => onDelete(index)}
          />
        ))}
      </StyledChipsContainer>
    )}
  </StyledChipsManagerContainer>
);

export default ChipsManagerComponent;
