import { apiRequest } from "./config";

export const apiGetCustomers = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload?: any;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiGetAllActiveCustomers = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiInviteCustomers = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload?: any;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiImportCustomers = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: any;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiGetCustomer = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload?: any;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiGetJassCustomers = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiDisconnectCustomer = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });
