import React from "react";
import { IApplicationNotesNoteItemComponent } from "../../../../models/Applications";
import {
  StyledCandidateNoteListItem,
  StyledCandidateNoteListItemPrimaryText,
  StyledCandidateNoteListItemSecondaryText,
} from "../../styles";
import { Avatar, ListItemAvatar, ListItemText } from "@mui/material";
import CandidateNoteCommentList from "../Comments";
import CandidateNoteActions from "../Actions";

const CandidateNoteComponent = ({
  t,
  avatar,
  author,
  body,
  comments,
  noteId,
  isCurrentUser,
  isDeleted,
  onDeleteNote,
  onSubmitComment,
}: IApplicationNotesNoteItemComponent) => (
  <StyledCandidateNoteListItem isCurrentUSer={isCurrentUser}>
    <ListItemAvatar>
      <Avatar alt={author} src={avatar} />
    </ListItemAvatar>
    {isDeleted ? (
      <ListItemText
        className={"text-container-deleted"}
        secondary={
          <StyledCandidateNoteListItemSecondaryText>
            {t("applications.deletedNote")}
          </StyledCandidateNoteListItemSecondaryText>
        }
      />
    ) : (
      <ListItemText
        className={"text-container"}
        primary={
          <StyledCandidateNoteListItemPrimaryText>
            {author}
          </StyledCandidateNoteListItemPrimaryText>
        }
        secondary={
          <StyledCandidateNoteListItemSecondaryText>
            {body}
            {!!comments.length && (
              <CandidateNoteCommentList comments={comments} />
            )}
            <CandidateNoteActions
              noteId={noteId}
              isCurrentUser={isCurrentUser}
              onDeleteNote={onDeleteNote}
              onSubmitComment={onSubmitComment}
            />
          </StyledCandidateNoteListItemSecondaryText>
        }
      />
    )}
  </StyledCandidateNoteListItem>
);

export default CandidateNoteComponent;
