import { TMarketingForm } from "../models/Marketing";
import { apiRequest } from "./config";

export const apiExtractJobData = ({
  url,
  apiPayload,
}: {
  readonly url: string;
  readonly apiPayload: {
    readonly link: string;
  };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiSendJobCompanyData = ({
  url,
  apiPayload,
}: {
  readonly url: string;
  readonly apiPayload: {
    readonly content: TMarketingForm;
  };
}) => apiRequest({ type: "post", url, apiPayload });
