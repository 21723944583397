import React from "react";
import { TFunction } from "i18next";
import { Paper, Stack } from "@mui/material";
import OrderItemDetails from "./OrderItemDetails";
import DataGrid from "../../../components/DataGrid_new";
import { DATA_GRID_LIST_TYPE } from "../../../models/DataGrid";
import { IJobOrder } from "../../../models/SingleJob";

export default function OrderItem({
  t,
  order
}: {
  readonly t: TFunction;
  readonly order: IJobOrder;
}) {
  const tableHeadHeight = 44;
  const tableRowHeight = 80;
  const tableHeight = tableRowHeight * order.products.length + tableHeadHeight;

  return (
    <Stack height="100%" spacing={0.75}>
      <OrderItemDetails t={t} order={order} />
      <Paper variant={"outlined"} sx={{ height: tableHeight + 3 }}>
        <DataGrid
          headerHeight={44}
          rowHeight={80}
          t={t}
          list={order.products as []}
          type={DATA_GRID_LIST_TYPE.BOOKINGS}
          actions={{}}
        />
      </Paper>
    </Stack>
  );
}
