import React from "react";
import TriggerSelection from "./TriggerSelection";
import SendEmail from "./actions/SendEmail";
import ChangeApplicationStatus from "./actions/ChangeApplicationStatus";
import SendMessage from "./actions/SendMessage";
import CreateNote from "./actions/CreateNote";
import DeleteArchiveJob from "./actions/DeleteArchiveJob";
import {
  ACTION_TYPE,
  ITriggerSelectionComponent,
} from "../../models/Automation";

const RenderAction = ({
  t,
  currentAutomation,
  triggers,
  activeTriggerGroup,
  triggersGroup,
  isTriggersLoading,
  selectedAction,
  onChangeActiveTrigger,
  onSelectTrigger,
}: ITriggerSelectionComponent) => {
  const handleCloseAction = () => {
    onChangeActiveTrigger(undefined);
  };
  switch (selectedAction?.const) {
    case ACTION_TYPE.SEND_MAIL:
      return (
        <SendEmail
          t={t}
          selectedAction={selectedAction}
          onCloseAction={handleCloseAction}
        />
      );
    case ACTION_TYPE.CHANGE_APPLICATION_STATUS:
      return (
        <ChangeApplicationStatus t={t} onCloseAction={handleCloseAction} />
      );
    case ACTION_TYPE.SEND_MESSAGE:
      return (
        <SendMessage
          t={t}
          selectedAction={selectedAction}
          onCloseAction={handleCloseAction}
        />
      );
    case ACTION_TYPE.CREATE_NOTE:
      return (
        <CreateNote
          t={t}
          selectedAction={selectedAction}
          onCloseAction={handleCloseAction}
        />
      );
    case ACTION_TYPE.ARCHIVE_JOB:
    case ACTION_TYPE.DELETE_JOB:
      return (
        <DeleteArchiveJob
          t={t}
          selectedAction={selectedAction}
          onCloseAction={handleCloseAction}
        />
      );
    default:
      return (
        <TriggerSelection
          t={t}
          triggers={triggers}
          currentAutomation={currentAutomation}
          activeTriggerGroup={activeTriggerGroup}
          triggersGroup={triggersGroup}
          isTriggersLoading={isTriggersLoading}
          onChangeActiveTrigger={onChangeActiveTrigger}
          onSelectTrigger={onSelectTrigger}
        />
      );
  }
};

const ShowAction = ({
  t,
  triggers,
  currentAutomation,
  activeTriggerGroup,
  triggersGroup,
  isTriggersLoading,
  selectedAction,
  onSelectTrigger,
  onChangeActiveTrigger,
}: ITriggerSelectionComponent) => (
  <RenderAction
    t={t}
    activeTriggerGroup={activeTriggerGroup}
    currentAutomation={currentAutomation}
    triggers={triggers}
    triggersGroup={triggersGroup}
    isTriggersLoading={isTriggersLoading}
    selectedAction={selectedAction}
    onChangeActiveTrigger={onChangeActiveTrigger}
    onSelectTrigger={onSelectTrigger}
  />
);

export default ShowAction;
