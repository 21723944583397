import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App_new";
import { Provider } from "react-redux";
import { store } from "./store_new/config";
import "./style.css";

const domNode = document.getElementById("root");

const root = createRoot(domNode);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
