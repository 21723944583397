import React from "react";
import {
  ArchiveBoxIcon,
  CheckIcon,
  PencilIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  SparklesIcon,
  Square2StackIcon,
  TrashIcon,
  ArrowDownTrayIcon,
  CheckCircleIcon,
  DocumentIcon,
  DocumentPlusIcon,
  UserPlusIcon,
  UserMinusIcon,
  PlayCircleIcon,
  MinusCircleIcon,
  EnvelopeIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  SignalIcon,
  ArrowPathIcon,
  BoltIcon,
} from "@heroicons/react/24/outline";
import {
  ACTION_TYPE,
  ICurrentAutomation,
  TRIGGERS_TYPE,
} from "../../models/Automation";

const RenderIcon = ({
  type,
  currentAutomation,
}: {
  readonly type: string;
  readonly currentAutomation?: ICurrentAutomation;
}) => {
  switch (type) {
    // triggers icons
    case TRIGGERS_TYPE.JOB_CREATED:
      return <SparklesIcon className="job-created-icon" />;
    case TRIGGERS_TYPE.JOB_UPDATED:
      return <PencilSquareIcon className="trigger-icon" />;
    case TRIGGERS_TYPE.DELETE_JOB:
    case TRIGGERS_TYPE.JOB_DELETED:
      return (
        <TrashIcon
          className={
            currentAutomation?.trigger ? "action-icon" : "trigger-icon"
          }
        />
      );
    case TRIGGERS_TYPE.JOB_ACTIVATED:
      return <CheckIcon className="trigger-icon" />;
    case TRIGGERS_TYPE.ARCHIVE_JOB:
    case TRIGGERS_TYPE.JOB_ARCHIVED:
      return (
        <ArchiveBoxIcon
          className={
            currentAutomation?.trigger ? "action-icon" : "trigger-icon"
          }
        />
      );
    case TRIGGERS_TYPE.APPLICATION_CREATED:
      return <PlusCircleIcon className="trigger-icon" />;
    case TRIGGERS_TYPE.APPLICATION_UPDATED:
      return <PencilSquareIcon className="trigger-icon" />;
    case TRIGGERS_TYPE.APPLICATION_STATUS_UPDATED:
      return <PencilIcon className="trigger-icon" />;
    case TRIGGERS_TYPE.JOB_DRAFT_DUPLICATED:
      return <Square2StackIcon className="trigger-icon" />;
    case TRIGGERS_TYPE.JOB_DRAFT_SAVED:
      return <ArrowDownTrayIcon className="trigger-icon" />;
    case TRIGGERS_TYPE.JOB_PUBLISHED:
      return <CheckCircleIcon className="trigger-icon" />;
    case TRIGGERS_TYPE.MEMBER_INVITED:
      return <UserPlusIcon className="trigger-icon" />;
    case TRIGGERS_TYPE.CREATE_NOTE:
    case TRIGGERS_TYPE.NOTE_CREATED:
      return <DocumentIcon className="trigger-icon" />;
    case TRIGGERS_TYPE.NOTE_MEMBER_TAGGED:
      return (
        <DocumentPlusIcon
          className={
            currentAutomation?.trigger ? "action-icon" : "trigger-icon"
          }
        />
      );
    case TRIGGERS_TYPE.TALENTPOOL_APPLICATION_ADDED:
      return <UserPlusIcon className="trigger-icon" />;
    case TRIGGERS_TYPE.TALENTPOOL_APPLICATION_DELETED:
      return <UserMinusIcon className="trigger-icon" />;
    case TRIGGERS_TYPE.TALENTPOOL_CREATED:
      return <PlayCircleIcon className="trigger-icon" />;
    case TRIGGERS_TYPE.TALENTPOOL_UPDATED:
      return <PencilSquareIcon className="trigger-icon" />;
    case TRIGGERS_TYPE.TALENTPOOL_RECORED_ADDED:
      return <PlusCircleIcon className="trigger-icon" />;
    case TRIGGERS_TYPE.TALENTPOOL_RECORD_DELETED:
      return <MinusCircleIcon className="trigger-icon" />;
    case TRIGGERS_TYPE.WORKFLOW_CREATED:
      return <PlayCircleIcon className="trigger-icon" />;
    case TRIGGERS_TYPE.WORKFLOW_UPDATED:
      return <PencilSquareIcon className="trigger-icon" />;
    case TRIGGERS_TYPE.WORKFLOW_DELETED:
      return <TrashIcon className="trigger-icon" />;
    // actions icons
    case ACTION_TYPE.SEND_MAIL:
      return <EnvelopeIcon className="action-icon" />;
    case ACTION_TYPE.SEND_MESSAGE:
      return <ChatBubbleOvalLeftEllipsisIcon className="action-icon" />;
    case ACTION_TYPE.CHANGE_APPLICATION_STATUS:
      return <SignalIcon className="action-icon" />;
    case ACTION_TYPE.TRANSITION_APPLICATION_TO:
      return <ArrowPathIcon className="action-icon" />;
    case ACTION_TYPE.ADD_ACTION_CONTAINER:
      return <BoltIcon className="action-icon" />;
    case ACTION_TYPE.CREATE_NOTE:
      return <DocumentIcon className="action-icon" />;

    default:
      return <></>;
  }
};

const ShowIcon = ({
  type,
  currentAutomation,
}: {
  readonly type: string;
  readonly currentAutomation?: ICurrentAutomation;
}) => <RenderIcon type={type} currentAutomation={currentAutomation} />;

export default ShowIcon;
