import React from "react";
import { Stack, Typography, Button } from "@mui/material";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import PreviewDialog from "./PreviewJobWidgetDialog";
import { IJobWidgetComponent } from "../../models/JobWidget";

const JobWidgetComponent_NewPage = ({
  t,
  code,
  desktop,
  isPreviewDialogOpen,
  onCopyCode,
  setIsPreviewDialogOpen
}: IJobWidgetComponent) => (
  <Stack
    mt={4}
    p={3}
    border="1px solid #E5E7EB"
    borderRadius={1.5}
    sx={{ backgroundColor: "#fff" }}
  >
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="subtitle2" fontWeight={500}>
        {t("job_widget.embed_code")}
      </Typography>
    </Stack>
    <Stack
      mt={1.5}
      p={1.5}
      border="1px solid #E5E7EB"
      borderRadius={1.5}
      sx={{
        backgroundColor: "#F9FAFB"
      }}
    >
      <Typography
        data-testid="job-widget-page-code"
        variant="subtitle2"
        color="textSecondary"
      >
        {code}
      </Typography>
    </Stack>
    <Stack
      mt={3}
      direction="row"
      width="100%"
      justifyContent="flex-end"
      spacing={1.5}
    >
      <Button
        data-testid="job-widget-page-copy-button"
        variant="outlined"
        startIcon={<DocumentDuplicateIcon width={24} height={24} />}
        onClick={onCopyCode}
      >
        {t("job_widget.copy")}
      </Button>
      <Button
        data-testid="job-widget-page-preview-button"
        variant="contained"
        onClick={() => setIsPreviewDialogOpen(true)}
      >
        {t("create_job_first_step.preview")}
      </Button>
    </Stack>
    <PreviewDialog
      t={t}
      isOpen={isPreviewDialogOpen}
      onClose={() => setIsPreviewDialogOpen(false)}
      desktop={desktop}
    />
  </Stack>
);

export default JobWidgetComponent_NewPage;
