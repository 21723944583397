import { Divider, Stack, Switch, Typography } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import {
  IJobBasicQuestionsSection,
  TJobBasicQuestionsFormFields
} from "../../../models/JobEditor";
import { StyledBasicQuestionsContainer } from "../styles";

const JobBasicQuestionsSection = ({
  t,
  control,
  basicQuestionsTitles
}: IJobBasicQuestionsSection) => (
  <>
    <Typography variant="body2">
      {t("create_job_third_step.basic_questions")}
    </Typography>
    <StyledBasicQuestionsContainer spacing={1}>
      {Object.keys(basicQuestionsTitles).map(
        (questionTitle: string, index: number) => (
          <Stack key={questionTitle} spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle2" color="textSecondary">
                {basicQuestionsTitles[questionTitle]}
              </Typography>
              <Controller
                name={questionTitle as keyof TJobBasicQuestionsFormFields}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    color="secondary"
                    onChange={(_, value) => onChange(value ? "1" : "0")}
                    checked={Boolean(parseInt(value as string))}
                  />
                )}
              />
            </Stack>
            {index !== Object.keys(basicQuestionsTitles).length - 1 && (
              <Divider />
            )}
          </Stack>
        )
      )}
    </StyledBasicQuestionsContainer>
  </>
);

export default JobBasicQuestionsSection;
