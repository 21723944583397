import React from "react";
import { Helmet } from "react-helmet";

const MetaDecorator = ({
  imgUrl = "https://i.imgur.com/rpcu9td.jpg",
  description = "Description",
  title = "Title"
}: {
  readonly imgUrl: string;
  readonly description: string;
  readonly title: string;
}) => {
  return (
    <Helmet>
      <meta property="og:image" content={imgUrl} />
      <meta name="description" content={description} />
      <link rel="apple-touch-icon" href="%PUBLIC_URL%/favicon.ico" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imgUrl} />

      <meta property="twitter:card" content={imgUrl} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={imgUrl} />
      <meta name="image" property="og:image" content={imgUrl} />
      <title>{title}</title>
    </Helmet>
  );
};

export default MetaDecorator;
