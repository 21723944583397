import React, { useState } from "react";
import {
  Dialog,
  Stack,
  Divider,
  Typography,
  Button,
  TextField,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import { XMarkIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import {
  fetchUserWithCallback,
  updateCurrentUserPassword,
} from "../../../store_new/reducers/CurrentUser";
import {
  setIsChangePasswordDialogOpen,
  setIsPasswordNotificationShowed,
} from "../../../store_new/reducers/dialogs";
import { IChangePasswordDialog } from "../../../models/dialogs";
import { addMessage } from "../../../store_new/reducers/Snackbar";

export default function ChangePasswordDialog({
  t,
  desktop = true,
  isOpen,
}: IChangePasswordDialog) {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const onDialogClose = () => {
    dispatch(setIsChangePasswordDialogOpen(false));
    setError(false);
  };

  const onSuccessUpdatePassword = () => {
    onDialogClose();
    dispatch(
      fetchUserWithCallback({
        callback: () => {
          dispatch(
            addMessage({
              type: "success",
              title: t("userSettings.change_password_snackbar"),
            })
          );
          dispatch(setIsPasswordNotificationShowed(false));
        },
      })
    );
  };

  const onSave = async () => {
    if (currentPassword !== newPassword) {
      setError(true);
      return;
    }
    dispatch(
      updateCurrentUserPassword({
        data: {
          password: newPassword,
        },
        callback: () => onSuccessUpdatePassword(),
      })
    );
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  return (
    <Dialog
      data-testid="change-password-dialog"
      open={isOpen}
      PaperProps={{
        elevation: 0,
      }}
      onClose={onDialogClose}
    >
      <Stack
        p={3}
        width={desktop ? 512 : "100%"}
        borderRadius={1.5}
        sx={{ background: "#fff" }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography>{t("userSettings.change_password")}</Typography>
          </Stack>
          <Stack>
            <XMarkIcon
              width={24}
              height={24}
              stroke="#131E30"
              style={{ cursor: "pointer" }}
              onClick={onDialogClose}
            />
          </Stack>
        </Stack>
        <Divider sx={{ my: 3, borderColor: "#E5E7EB" }} />
        <Stack width="100%" spacing={1.5}>
          <Stack>
            <InputLabel>{t("userSettings.new_password")}</InputLabel>
            <TextField
              type={showOldPassword ? "text" : "password"}
              placeholder={t("userSettings.new_password") as string}
              onChange={(e) => {
                setCurrentPassword(e.target.value);
                setError(false);
              }}
              error={error}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Stack width={19.75} height={19.75}>
                      {showOldPassword ? (
                        <EyeSlashIcon
                          style={{ cursor: "pointer" }}
                          onClick={handleClickShowOldPassword}
                        />
                      ) : (
                        <EyeIcon
                          style={{ cursor: "pointer" }}
                          onClick={handleClickShowOldPassword}
                        />
                      )}
                    </Stack>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
            {error && (
              <Typography color="error" variant="subtitle2">
                {t("userSettings.password_not_match")}
              </Typography>
            )}
          </Stack>
          <Stack>
            <InputLabel>{t("userSettings.confirm_password")}</InputLabel>
            <TextField
              type={showPassword ? "text" : "password"}
              placeholder={t("userSettings.confirm_password") as string}
              onChange={(e) => {
                setNewPassword(e.target.value);
                setError(false);
              }}
              error={error}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Stack width={19.75} height={19.75}>
                      {showPassword ? (
                        <EyeSlashIcon
                          style={{ cursor: "pointer" }}
                          onClick={handleClickShowPassword}
                        />
                      ) : (
                        <EyeIcon
                          style={{ cursor: "pointer" }}
                          onClick={handleClickShowPassword}
                        />
                      )}
                    </Stack>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
            {error && (
              <Typography color="error" variant="subtitle2">
                {t("userSettings.password_not_match")}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Stack
          mt={4}
          direction="row"
          width="100%"
          justifyContent="flex-end"
          spacing={1.5}
        >
          <Button variant="outlined" onClick={onDialogClose}>
            {t("button.secondCancel")}
          </Button>
          <Button
            variant="contained"
            disabled={error || !newPassword.length || !currentPassword.length}
            onClick={onSave}
          >
            {t("button.save")}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
