import React from "react";
import { Divider, Stack } from "@mui/material";
import { TFunction } from "i18next";
import { ActiveStep, CompletedStep, Step } from "../../Steps";
import { StyledStepContainer, StyledStepLabel } from "../styles";

const JobEditorStepperComponent = ({
  t,
  currentStep,
  completedSteps,
}: {
  t: TFunction;
  currentStep: number;
  completedSteps: number[];
}) => (
  <Stack mt={3} mb={8} direction={"row"}>
    <Stack flex={0.5} justifyContent={"center"}>
      <Divider sx={{ opacity: 0 }} />
    </Stack>

    <StyledStepContainer>
      {currentStep === 1 ? (
        <ActiveStep />
      ) : completedSteps.includes(1) ? (
        <CompletedStep />
      ) : (
        <Step />
      )}
      <StyledStepLabel noWrap>
        {t("create_job_first_step.describe_job")}
      </StyledStepLabel>
    </StyledStepContainer>

    <Stack flex={1} justifyContent={"center"}>
      <Divider />
    </Stack>

    <StyledStepContainer>
      {currentStep === 2 ? (
        <ActiveStep />
      ) : completedSteps.includes(2) ? (
        <CompletedStep />
      ) : (
        <Step />
      )}
      <StyledStepLabel noWrap>
        {t("create_job_first_step.specify_job")}
      </StyledStepLabel>
    </StyledStepContainer>

    <Stack flex={1} justifyContent={"center"}>
      <Divider />
    </Stack>

    <StyledStepContainer>
      {currentStep === 3 ? (
        <ActiveStep />
      ) : completedSteps.includes(3) ? (
        <CompletedStep />
      ) : (
        <Step />
      )}
      <StyledStepLabel noWrap>
        {t("create_job_first_step.application_process")}
      </StyledStepLabel>
    </StyledStepContainer>

    <Stack flex={0.5} justifyContent={"center"}>
      <Divider sx={{ opacity: 0 }} />
    </Stack>
  </Stack>
);

export default JobEditorStepperComponent;
